
const reqArr = ["from", "to","fromcity", "tocity","date", "cabin", "rbd", "airlines", "departuretime", "flexibag"];
 //
module.exports.getUrl =  (data)=> {
    let url = "";
    try{
        let sectorVal = [];
        data.forEach((x)=>{
            let reqVal = [];
            reqArr.forEach((param)=> {
                let value = "";
                console.log("test123", typeof x[param]);
                if(typeof x[param] == "object"){
                    value = x[param].join("~")
                }else
                    value = x[param] || ""; 
                reqVal.push(value);
            })
            sectorVal.push(reqVal.join("_"));
        })
        
        url = sectorVal.join("~~")
        // one sector order  // FROM_TO_DATE_CABIN_RBD_AIRLINES_DEPARTURE TIME_FLEXI BAGGAGE
    }catch(err){
        console.log("getflightsearchurl", err);
    }
    return url;
}

module.exports.parseurl =  (url) => {
    console.log("🚀 ~ file: urlParser.js:31 ~ url:", url)
 
    let retdata = [];
    try{
        // let url = req.body.url;
        if(url) {
        let decodeData = url?.split("~~");
        decodeData.forEach((de_data)=>{
            let sec_data = {};
            let sectorData = de_data.split("_");
                sectorData.forEach((secvalue, index)=>{
                    let val = secvalue;
                    if(reqArr[index] == "rbd" || reqArr[index] == "airlines"){
                        val = secvalue.split("~")
                        val = val.filter(el => el)
                    }
                    sec_data[reqArr[index]] = val;
                })
            retdata.push(sec_data);
        })
    }
    }catch(err){
        console.log("decodeParseurl", err);
    }
    return retdata;
}