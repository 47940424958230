import React, { useEffect, useState } from "react";

import commonFunction from "../../../../lib/utils/common";
// import styles from "./index.module.css";

export default function HotelAutoComplete(props) {
  console.log("propsprops",props)
  let hotelNameArry = props?.value?.split(",");
  const region = hotelNameArry[0]?.trim()
  return (
    <div className={props.styles?.hotel_search_wid_inputfield}>
     {!props.isSrp &&
      <label>{props.label}</label>}
      <div className={props.styles?.hotel_search_wid_inputfield_main}>
        {/* <img src={props.leftIcon} className={props.styles.hotel_search_left_icon} /> */}

        <span
          className={props.styles?.hotel_search_left_icon}
          onClick={() => {
            props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
            props.onChangeHotelName("");
            props.onClickHotelInput();
          }}
        >
          {/* {props.leftIcon}         */}
        </span>
        <div className={props.styles?.search_widget_img_flex}>
      
        {props.isSrp &&
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 width="200px" height="200px" viewBox="0 0 200 200" enable-background="new 0 0 200 200" >
<g>
	<path fill="#30C8CA" d="M83.541,150.468c-4.986,0.8-9.511,1.971-13.341,3.47c-3.731,1.459-6.829,3.206-9.096,5.303
		c-2.268,2.096-3.751,4.667-3.751,7.501s1.483,5.405,3.751,7.502c2.267,2.097,5.365,3.844,9.096,5.302
		c7.462,2.919,17.516,4.655,28.619,4.655c11.102,0,21.154-1.736,28.616-4.655c3.732-1.458,6.829-3.205,9.097-5.302
		s3.751-4.668,3.751-7.502s-1.483-5.405-3.751-7.501c-2.268-2.097-5.364-3.844-9.097-5.303c-3.829-1.499-8.354-2.67-13.341-3.47
		v4.144c4.486,0.758,8.524,1.825,11.858,3.128c3.403,1.331,6.086,2.909,7.81,4.501c1.722,1.593,2.438,3.077,2.438,4.501
		c0,1.423-0.716,2.909-2.438,4.502c-1.724,1.593-4.406,3.169-7.81,4.501c-6.806,2.662-16.464,4.373-27.134,4.373
		c-10.671,0-20.329-1.711-27.135-4.373c-3.404-1.332-6.086-2.908-7.809-4.501s-2.438-3.079-2.438-4.502
		c0-1.424,0.715-2.908,2.438-4.501c1.723-1.592,4.405-3.17,7.809-4.501c3.333-1.303,7.372-2.37,11.857-3.128V150.468z"/>
	<path fill="#30C8CA" d="M98.818,22.285c-27.043,0-49.055,22.003-49.055,49.055c0,39.002,43.819,89.957,45.683,92.115l3.372,3.88
		l3.37-3.889c1.864-2.149,45.684-53.104,45.684-92.106C147.872,44.288,125.86,22.285,98.818,22.285L98.818,22.285z M98.818,153.527
		c-10.222-12.7-40.136-52.436-40.136-82.188c0-22.128,18.007-40.135,40.136-40.135c22.127,0,40.135,18.007,40.135,40.135
		C138.953,101.066,109.039,140.827,98.818,153.527z M98.818,45.822c-14.752,0-26.758,12.005-26.758,26.757
		c0,14.751,12.005,26.756,26.758,26.756c14.752,0,26.756-12.004,26.756-26.756C125.574,57.827,113.57,45.822,98.818,45.822z
		 M98.818,90.417c-9.839,0-17.839-8.001-17.839-17.838c0-9.838,8-17.838,17.839-17.838c9.837,0,17.836,8,17.836,17.838
		C116.654,82.416,108.655,90.417,98.818,90.417z"/>
</g>
</svg>
        }
        {!props.isSrp &&
  <h1  onClick={() => {
            props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
            props.onChangeHotelName("");
            props.onClickHotelInput();
          }}> {region} </h1>}
        <input
          type="text"
          autoComplete={"off"}
          id="myTextField"
          tabindex="1"
          onFocus={props.onInputFocus}
          onKeyDown={props.onKeyDownArrow}
          placeholder={
            !commonFunction.isValueEmpty(props.placholder)
              ? props.placholder
              : ""
          }
          className={props.styles?.search_widget_input_new}
          value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
          onChange={(event) => {
            props.onChangeHotelName(event.target.value);
          }}
          onClick={() => {
            props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
            props.onChangeHotelName("");
            props.onClickHotelInput();
          }}
        />
        
        </div>
        {!commonFunction.isValueEmpty(props.isError) &&
        props.isError === true ? (
          <div className={props.styles?.hotel_search_error_message}>
            <span>
              {!commonFunction.isValueEmpty(props.errorMessage)
                ? props.errorMessage
                : "Invalid field"}
            </span>
          </div>
        ) : null}
        {props.expandAutoCompleteBool === true ? (
          <div className={props.styles?.search_section_arrow}>
          <svg className={props.styles?.hotel_arrow_pax} width="151" height="151" viewBox="0 0 151 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M75.5 1.3811L140.885 76.0953H10.1151L75.5 1.3811Z" fill="white"></path><line y1="-1.5" x2="100.715" y2="-1.5" transform="matrix(0.666347 -0.745642 0.939264 0.343196 9.5873 76.4784)" stroke="#2FC7C9" stroke-width="3"></line><path d="M76.0269 1.19861L140.214 76.0953" stroke="#2FC7C9" stroke-width="3"></path></svg>
          <div
            className={props.styles?.hotel_autocomplete_value}
            id="hotelDataArray"
          >
          
            {!commonFunction.isValueEmpty(props.autoCompleteData) &&
              props.autoCompleteData.length > 0 &&
              props.autoCompleteData.map((element) => {
                return (
                  <div
                    id="hotelData"
                    className={props.styles?.hotel_autocomplete_element}
                    onClick={() => {
                      props.onClickHotelName(element);
                      props.isAutoCompleteExpand(false);
                    }}
                  >
                    {/* <img
                      src={
                        element.property_count === 0
                          ? "/images/hotels.svg"
                          : "/images/location-01.svg"
                      }
                      alt=""
                    /> */}
                    <div>
                      <div className={props.styles?.hotel_autocomplete_name}>
                        {element.search_text}
                      </div>
                      {element.property_count === 0 ? (
                        ""
                      ) : (
                        <div className={props.styles?.hotel_autocomplete_tag}>
                          {element.property_count} Properties
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
