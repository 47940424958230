import React, { Component, createRef } from 'react';
import { addDays, differenceInCalendarDays, format } from 'date-fns';

import commonFunction from '../../../../lib/utils/common';
import DesktopCalender from "../../../calendar/flightCalender/theme1/modifySearch/DesktopCalender";
import FlightAutoComplete from './FlightAutoComplete';
import EconomyPopup from './EconomyPopup';
import PaxConfig from "./PaxConfig";
import cf from "../../../../lib/utils/common";
import ModifySearchForm from "./ModifySearchForm"
const cabinClass = [
    { id: 1, value: 'Economy', label: 'Economy' },
    { id: 2, value: 'Business', label: 'Business' },
    { id: 3, value: 'First Class', label: 'First Class' },
    { id: 4, value: 'Premium Economy', label: 'Premium Economy' }
];

const todaysDate = format(new Date(), 'dd-LLL-yyyy');
let search_type = ""
class ModifySearch extends Component {

    constructor(props) {
        super(props);
        this.setState = this.setState.bind(this);
        this.travellerDivRef = createRef();
        this.economyPopUpWrapper = createRef()
        this.departureListWrapper = createRef()
        this.returnListWrapper = createRef()
        this.multicityDepartureWrapper = createRef();
    this.multicityReturnWrapper = createRef();
        let returnDate = format(addDays(new Date(props?.queryParams?.departure), 7), 'dd-LLL-yyyy')
        if (props?.queryParams?.arrival) {
            let dayDifference = differenceInCalendarDays(new Date(props?.queryParams?.arrival), new Date(props?.queryParams?.departure));
            if (dayDifference >= 0) {
                //     returnDate = format(addDays(new Date(props?.queryParams?.departure), 7), 'dd-LLL-yyyy')
                // } else {
                returnDate = format(new Date(props?.queryParams?.arrival), 'dd-LLL-yyyy')
            }
        }
    //   if ( props?.queryParams?.multicity) {
    //     props?.queryParams?.multicity.forEach((element) => {
    //         element.odt = format(new Date(element.odt), "dd-LLL-yyyy")
    //        }) ;
    //   }
       
        this.state = {
            departureDate: props?.queryParams?.departure ? format(new Date(props?.queryParams?.departure), 'dd-LLL-yyyy') : format(new Date(), 'dd-LLL-yyyy'),
            returnDate: returnDate,
            expandDepartureDateBoolean: false,
            expandReturnDateBoolean: false,
            isOnewaySearch: !props?.queryParams?.arrival,
            departureValue: props?.queryParams?.fromCity ? `${props?.queryParams?.fromCity} - (${props?.queryParams?.from})` : "",
            goingToValue: props?.queryParams?.toCity ? `${props?.queryParams?.toCity} - (${props?.queryParams?.to})` : "",
            expandDepartureBoolean: false,
            expandGoingToBoolean: false,
            paxAdultValue: +props?.queryParams?.adults,
            paxChildrenValue: +props?.queryParams?.child,
            paxInfantValue: +props?.queryParams?.infants,
            SelectedClassValue: cabinClass.find(cls => cls.id == props?.queryParams?.class),
            openCabinClassList: false,
            getDepartureSuggestionList: this.props.homeData,
            getGoingToSuggestionList: this.props.homeData,
            departureAirportCode: props?.queryParams?.from,
            goingToAirportCode: props?.queryParams?.to,
            isError: { departure: false, goingTo: false, departureDate: false, goingToDate: false, },
            returnCity: props?.queryParams?.toCity || "",
            departureCity: props?.queryParams?.fromCity || "",
            isTravelersPopupVisible: false,
            travellersObjOrigin: {
                adults: +props?.queryParams?.adults,
                child: +props?.queryParams?.child,
                infants: +props?.queryParams?.infants,
                originalSelectedClass: cabinClass.find(cls => cls.id == props?.queryParams?.class)
            },
            code: "",
            name: "",
            isMultiCitySearch:false,
            isValid: false,
            selectedGoingToIndex: null,
            selectedDepartureIndex: null,
            indexDropdown: -1,
            multicityFlightData: props?.queryParams?.multicity
        }
    }

    componentDidMount() {
        if (this.props?.queryParams?.type === "3") {
            this.setState({ isMultiCitySearch: true })
        }
        document.addEventListener("mousedown", this.handleClickOutside, false);
        document.addEventListener('keydown', this.handleTabInside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
        document.removeEventListener('keydown', this.handleTabInside);
    }

    handleClickOutside = event => {
        if (this.travellerDivRef.current && !this.travellerDivRef.current.contains(event.target)) {
            this.setState({
                isTravelersPopupVisible: false,
                openCabinClassList: false,
                paxAdultValue: this.state.travellersObjOrigin.adults,
                paxChildrenValue: this.state.travellersObjOrigin.child,
                paxInfantValue: this.state.travellersObjOrigin.infants
            })
        }
        if (this.economyPopUpWrapper.current && !this.economyPopUpWrapper.current.contains(event.target)) {
            this.setState({
                openCabinClassList: false, SelectedClassValue: { ...this.state.travellersObjOrigin.originalSelectedClass }
            })
        }
        if (this.departureListWrapper.current && !this.departureListWrapper.current.contains(event.target)) {
            this.setState({ expandDepartureBoolean: false })
        }
        if (this.returnListWrapper.current && !this.returnListWrapper.current.contains(event.target)) {
            this.setState({ expandGoingToBoolean: false })
        }

        if (this.multicityDepartureWrapper.current && !this.multicityDepartureWrapper.current.contains(event.target)) {
            const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
            multicityFlightData[this.state.selectedDepartureIndex].isDeparture = false
            this.setState((prevState) => {
              return {
                ...prevState,
                multicityFlightData,
                selectedDepartureIndex:null
              }
            })
          }
      
          if (this.multicityReturnWrapper.current && !this.multicityReturnWrapper.current.contains(event.target)) {
            const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
            multicityFlightData[this.state.selectedGoingToIndex].isGoingTo = false
            this.setState((prevState) => {
              return {
                ...prevState,
                multicityFlightData,
                selectedGoingToIndex: null,
              }
            })
          }
    }

    handleTabInside = e => {
        if (e.key !== "Tab") {
            return
        }
        this.handleClickOutside(e)
    };

    onChangeSetDepartureGoingToValue = (value, type,index) => {
        if (type === "departure") {
            if (index !== undefined) {
                const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
                multicityFlightData[index]["org"]= value
                this.setState((prevState)=>{
                  return {
                    ...prevState,
                    multicityFlightData
                  }
                })
                
              } else {
                this.setState({ departureValue: value, departureAirportCode: "" }, () => {
                    if (this.state.isError.departure === true) {
                        this.validateErrorMsg();
                    }
                    if (value.length === 0) {
                        this.setState({ getDepartureSuggestionList: [] });
                    }
                });
              }
         
        } else {
            if (index !== undefined) {
                const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
                multicityFlightData[index]["dest"]= value
                this.setState((prevState)=>{
                  return {
                    ...prevState,
                    multicityFlightData
                  }
                })
                
              }else {
                this.setState({ goingToValue: value, goingToAirportCode: "" }, () => {
                    if (this.state.isError.goingTo === true) {
                        this.validateErrorMsg()
                        if (value.length === 0) this.setState({ getGoingToSuggestionList: [] });
                    }
                });
              } 
        }

        if (type === "departure") {
            this.props.getAutoSuggestData(value, type, "change")
            this.setState({ expandDepartureBoolean: true });
        } else {
            this.props.getAutoSuggestData(value, type, "change")
            this.setState({ expandGoingToBoolean: true });
        }
    }

    onchangeVisibility = (bool, type,index) => {
        if (type === "goingTo") {
            this.setState({ expandReturnDateBoolean: bool, expandDepartureDateBoolean: false }, () => {
                if (this.state.isOnewaySearch === true) {
                    this.setState({ isOnewaySearch: false });
                }
            });
        } else {
            if (index !== undefined) {
    
                const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
                multicityFlightData[index]["expandDepartureDateBoolean"] = bool
                this.setState((prevState) => {
                  return {
                  ...prevState,
                  multicityFlightData,
                  expandGoingToDateBoolean: false,
                }})
             
              
              } else{
                this.setState({ expandReturnDateBoolean: false, expandDepartureDateBoolean: bool });
              }
            
        }
    }



    validateErrorMsg = () => {
        const mapDispatch = {
            departure: commonFunction.isValueEmpty(this.state.departureValue) ? true : false,
            goingTo: commonFunction.isValueEmpty(this.state.goingToValue) ? true : (this.state.departureValue === this.state.goingToValue) ? true : false,
            departureDate: commonFunction.isValueEmpty(this.state.departureDate) ? true : false,
            goingToDate: commonFunction.isValueEmpty(this.state.returnDate) ? true : false,
        }
        this.setState({ isError: mapDispatch });
    }

    onClickSetDepartureGoingToValue = (city, airPortCode, type,index) => {
        if (type === "departure") {
            if (this?.state?.isMultiCitySearch) {
                const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
                multicityFlightData[index]["org"] = airPortCode
                multicityFlightData[index]["departureValue"] = city + " - (" + airPortCode + ")"
                  this.setState((prevState) => {
                return {
                ...prevState,
                multicityFlightData,
                selectedDepartureIndex: null,
              }
              })
              } else{
                this.setState({ departureValue: city + ' - (' + airPortCode + ')', departureAirportCode: airPortCode, departureCity: city },
                () => {
                    if (this.state.isError.departure === true) {
                        this.validateErrorMsg();
                    }
                });
              }
         
        } else {
            if (this?.state?.isMultiCitySearch) {
                const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
                multicityFlightData[index]["dest"] = airPortCode
                multicityFlightData[index + 1] ?  multicityFlightData[index + 1]["org"]= airPortCode : "",
                multicityFlightData[index]["goingToValue"] = city + " - (" + airPortCode + ")",
          multicityFlightData[index + 1] ?  multicityFlightData[index + 1]["departureValue"]= city + " - (" + airPortCode + ")" : "",
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    multicityFlightData,
                    selectedGoingToIndex: null,
                  }
                })
                
              } else{
                this.setState({ goingToValue: city + ' - (' + airPortCode + ')', goingToAirportCode: airPortCode, returnCity: city },
                () => {
                    if (this.state.isError.goingTo === true) {
                        this.validateErrorMsg()
                    }
                });
        }
              }
        
    }

    onClickSwap = () => {
        if (this.state.departureValue || this.state.goingToValue) {
            let departureValue = this.state.departureValue
            let departureAirportCode = this.state.departureAirportCode
            let departureCity = this.state.departureCity
            this.setState({
                departureValue: this.state.goingToValue, goingToValue: departureValue,
                departureAirportCode: this.state.goingToAirportCode, goingToAirportCode: departureAirportCode,
                departureCity: this.state.returnCity, returnCity: departureCity
            });
        }
    }
    onChangeDepartureGoingDateValue = (value, type,index) => {
        if (type === "goingTo") {
            let dayDifference = differenceInCalendarDays(new Date(value), new Date(this.state.departureDate));
            if (dayDifference < 0) {
                this.setState({ isOnewaySearch: true, returnDate: format(addDays(new Date(), 7), 'dd-LLL-yyyy') });
            } else {
                this.setState({ returnDate: format(new Date(value), 'dd-LLL-yyyy') });
            }
        } else {
            if (index !== undefined) {
                const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
                
                let returnDate = this.state.returnDate
               
                let dayDifference = differenceInCalendarDays(new Date(this.state.returnDate), new Date(value));
                if ( dayDifference < 0) {
                    returnDate = format(addDays(new Date(value), 7), 'dd-LLL-yyyy')
                }
                 multicityFlightData[index]["odt"] =  format(new Date(value), 'dd-LLL-yyyy')
                 multicityFlightData[index]["expandDepartureDateBoolean"] = false
                setTimeout(() => {
                   this.setState((prevState) => {
                    return {
                    ...prevState,
                    multicityFlightData,
                    returnDate
                  }})
                }, 10);
              } else{
                let returnDate = this.state.returnDate
                let dayDifference = differenceInCalendarDays(new Date(this.state.returnDate), new Date(value));
                if (/* this.state.isOnewaySearch && */ dayDifference < 0) {
                    returnDate = format(addDays(new Date(value), 7), 'dd-LLL-yyyy')
                }
                this.setState({ departureDate: format(new Date(value), 'dd-LLL-yyyy'), returnDate });
              }
        }
    }

    onclickFlightAutoComplete =  (type,key,i) => {
        if (type === "departure") {
            if (i !== undefined && key !== undefined) {
                const multicityFlightData = JSON.parse(JSON.stringify(this.state?.multicityFlightData))
                multicityFlightData[i][key] = true
                multicityFlightData[i]["org"] = ""
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    multicityFlightData,
                    selectedDepartureIndex: i,
                    selectedGoingToIndex:null
                  }
                })
                
              } else {
                this.setState({ departureValue: "" }, () => {
                    this.props.getAutoSuggestData(this.state.departureValue, type, "click")
                })
              }
         
        } else {
            if (i !== undefined && key !== undefined) {
                const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
                multicityFlightData[i][key] = true
                multicityFlightData[i]["dest"] = ""
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    multicityFlightData,
                    selectedGoingToIndex: i,
                    selectedDepartureIndex:null
                  }
                })
                
              } else {
                this.setState({ goingToValue: "" }, () => {
                    this.props.getAutoSuggestData(this.state.goingToValue, type, "click")
                })
              }
         
        }
    }

    onTravellersClickHandler = () => {
        this.setState({ isTravelersPopupVisible: true })
    }

    onClickChangePaxNumber = (pax, value, type) => {
        if (pax === "adult") {
            if ((+value) > 0 && ((+this.state.paxChildrenValue) + value) <= 9 && ((+this.state.paxChildrenValue) + value) > 0) {
                this.setState({ paxAdultValue: value }, () => {
                    if (type === "minus") {
                        if ((+this.state.paxInfantValue) > value) {
                            let paxInfantValue = +this.state.paxInfantValue
                            this.setState({ paxInfantValue: paxInfantValue - 1 });
                        }
                    }
                });
            }
        } else if (pax === "children") {
            if (((+value) + (+this.state.paxAdultValue)) <= 9 && (+value + (+this.state.paxAdultValue)) > 0) {
                this.setState({ paxChildrenValue: +value });
            } else if ((+value) + this.state.paxAdultValue > 9 && type === "minus") {
                this.setState({ paxChildrenValue: 9 - this.state.paxAdultValue });
            }
        } else if (pax === "infant") {
            // if (value > (+this.state.paxAdultValue)) {
            //     this.setState({ paxInfantValue: +(--value) });
            // } else {
            //     this.setState({ paxInfantValue: +value });
            // }
            if (+value >= 0 && +value <= this.state.paxAdultValue) {
                this.setState({ paxInfantValue: +value });
            } else if (+value > this.state.paxAdultValue && type === "minus") {
                this.setState({ paxInfantValue: +this.state.paxAdultValue })
            }
        }
    }

    checkValidation = (item)=>{
        let isValid = true
        const { multicityFlightData = [] } = item
        multicityFlightData.map((data,index)=>{
        let errorObj = {}
        if ( data['org'] === '') {
          isValid = false
          errorObj['org'] = 'Enter Origin City'
        }
        if ( data['dest'] === '') {
          isValid = false
          errorObj['dest'] = 'Enter Destination City'
        }
    
        if (data['org'] === data['dest'] &&  data['dest'] !== "" ) {
          isValid = false
          errorObj['dest'] = 'Origin and destination cannot be same'
          
        }
        data.error = errorObj
        })
        return {isValid, data: multicityFlightData }
      }

    onClickSearchFlight = () => {
        if (this.state.isMultiCitySearch) {
            let {isValid ,data} = this.checkValidation(this.state)
              this.setState((prevState) => {
                return {
                ...prevState,
                multicityFlightData : data,
                isValid
             
               
              }})
          }
        const mapDispatch = {
            departure: commonFunction.isValueEmpty(this.state.departureValue) ? true : !this.state.departureAirportCode ? true : false,
            goingTo: commonFunction.isValueEmpty(this.state.goingToValue) ? true : !this.state.goingToAirportCode ? true : (this.state.departureValue === this.state.goingToValue) ? true : false,
            departureDate: commonFunction.isValueEmpty(this.state.departureDate) ? true : false,
            goingToDate: commonFunction.isValueEmpty(this.state.returnDate) ? true : false,
        }
        this.setState({ isError: mapDispatch }, () => {
            let Validate = true;
            for (const [key, value] of Object.entries(this.state.isError)) {
                if (value === true) {
                    Validate = false;
                    break;
                }
            }
            let arrivalDate = ''
            let departureDateArr = !commonFunction.isValueEmpty(this.state.departureDate) ? this.state.departureDate.split('/') : '';
            let departureDate = !commonFunction.isValueEmpty(departureDateArr) ? format(new Date(this.state.departureDate), 'yyyy-MM-dd') : '';
            if (this.state.isOnewaySearch === false) {
                let arrivalDateArr = !commonFunction.isValueEmpty(this.state.returnDate) ? this.state.returnDate.split('/') : '';
                arrivalDate = !commonFunction.isValueEmpty(arrivalDateArr) ? format(new Date(this.state.returnDate), 'yyyy-MM-dd') : '';
            }
            this.state.multicityFlightData.forEach((element) => {
                element.odt = format(new Date(element.odt), "yyyy-MM-dd")
              });
              let multicity_data = this.state.multicityFlightData
            let urlQuery = {
                from: this.state.departureAirportCode, to: this.state.goingToAirportCode, type:  this.state.isOnewaySearch ? 1 : this.state.isMultiCitySearch ? 3 :  2, 
                class: this.state.isMultiCitySearch ? this.state.SelectedClassValue.id: this.state.travellersObjOrigin.originalSelectedClass.id,
                adults: this.state.paxAdultValue, child: this.state.paxChildrenValue, infants: this.state.paxInfantValue,
                departure: departureDate, arrival: arrivalDate, fromCity: this.state.departureCity, toCity: this.state.returnCity,
                multicity: this.state.isMultiCitySearch ? JSON.stringify( multicity_data ) : [],
            }
         
            if (this.state.isValid) {
                cf.redirectTo("flight/search", urlQuery);
            } else {
                if (Validate === true) commonFunction.redirectTo("flight/search", urlQuery)
            }
            
        });
    }

    onDoneBtnClickHandler = () => {
        this.setState({
            travellersObjOrigin: {
                adults: this.state.paxAdultValue,
                child: this.state.paxChildrenValue,
                infants: this.state.paxInfantValue,
                originalSelectedClass: {
                    ...this.state.SelectedClassValue
                }
            },
            isTravelersPopupVisible: false
        })
    }

    onClickOpenCabinClassList = () => {
        if (this.state.openCabinClassList === false) {
            this.setState({ openCabinClassList: true });
        } else if (this.state.openCabinClassList === true) {
            this.setState({ openCabinClassList: false });
        }
    }

    // onClickSwap = () => {
    //     if (!commonFunction.isValueEmpty(this.state.departureValue) && !commonFunction.isValueEmpty(this.state.goingToValue)) {
    //         this.setState({ departureValue: this.state.goingToValue, goingToValue: this.state.departureValue });
    //     }
    // }

    cabinClassSelectHandler = (element) => {
        this.setState({ SelectedClassValue: { label: element.label, id: element.id }, openCabinClassList: false })
    }

    deleteReturnDateHandler = () => {
        this.setState({ isOnewaySearch: true, expandReturnDateBoolean: false });
    }

    removeReturnDateHandler = (e) => {
        if (e.key !== "Enter") {
            return
        }
        this.deleteReturnDateHandler()
    }

    highlightData(id, parentElement) {
        let innerContent = document.getElementById(parentElement).children
        for (let i = 0; i < innerContent.length; i++) {
            let selectedElement = innerContent[i];
            selectedElement.style.backgroundColor = (i == id) ? "#ccc" : "#fff";
        }
        innerContent[id].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }

    onKeyDownArrow = (event, parentClassId, list, type) => {
        let code = ""
        let name = ""
        let indexDropdown = this.state.indexDropdown
        switch (event.which) {

            case 38: // up
                indexDropdown--;
                if (indexDropdown < 0) { indexDropdown = 0 }
                this.highlightData(indexDropdown, parentClassId)
                code = (list.length >= indexDropdown) ? `${list[indexDropdown].AIRPORT_CODE}` : "";
                name = (list.length >= indexDropdown) ? `${list[indexDropdown].CITY_NAME}` : "";
                search_type = (list.length >= indexDropdown) ? `${list[indexDropdown].search_type ? list[indexDropdown].search_type : 1}` : "";
                break;
            case 40: // down
                indexDropdown++;
                if (indexDropdown >= list.length - 1) { indexDropdown = list.length - 1 }
                this.highlightData(indexDropdown, parentClassId)
                code = (list.length >= indexDropdown) ? `${list[indexDropdown].AIRPORT_CODE}` : "";
                name = (list.length >= indexDropdown) ? `${list[indexDropdown].CITY_NAME}` : "";
                search_type = (list.length >= indexDropdown) ? `${list[indexDropdown].search_type ? list[indexDropdown].search_type : 1}` : "";
                break;

            case 13: //enter
                if (this.state.code && this.state.name) {
                    this.onClickSetDepartureGoingToValue(this.state.name, this.state.code, type)
                }
                if (type === "departure") {
                    this.setState({ expandDepartureBoolean: false })
                }
                if (type === "goingTo") {
                    this.setState({ expandGoingToBoolean: false })
                }
                indexDropdown = -1;
                break;
            case 9: //enter
                if (this.state.code && this.state.name) {
                    this.onClickSetDepartureGoingToValue(this.state.name, this.state.code, type)
                }
                if (type === "departure") {
                    this.setState({ expandDepartureBoolean: false })
                }
                if (type === "goingTo") {
                    this.setState({ expandGoingToBoolean: false })
                }
                indexDropdown = -1;
                break;
            default:
        }
        this.setState({ code: code, name: name, indexDropdown: indexDropdown })
    }

    modifySearchKeyDownHandler = (e) => {
        if (e.key !== "Enter") {
            return
        }
        this.onClickSearchFlight()
    }

    onSwapKeyDownHandler = (e) => {
        if (e.key !== "Enter") {
            return
        }
        this.onClickSwap()
    }

    render() {
        return <div className="flex flex-row w-full container mx-auto xl:px-8 gray_color flex-col">
            

            {/* MODIFY SEARCH MULTICITY */}
            {this.props?.queryParams && this.props?.queryParams.type === "3" ? 
            <div className={this.props.styles.flightsearchpage_widget_multicity} >
            <div className={this.props.styles.modifySearchWidget_divider}>
            {this.state?.multicityFlightData.map((data,i)=>{
                    return  (
                      <div>
                           <ModifySearchForm style={this.props.styles}
                           state={this.state}
                           data={data}
                           index={i}
                           onclickFlightAutoComplete={this.onclickFlightAutoComplete}
                           setState={this.setState}
                           onClickSetDepartureGoingToValue={this.onClickSetDepartureGoingToValue}
                           getGoingToSuggestionList={this.props.getGoingToSuggestionList}
                           getDepartureSuggestionList={this.props.getDepartureSuggestionList}
                           onChangeSetDepartureGoingToValue={this.onChangeSetDepartureGoingToValue}
                           onchangeVisibility={this.onchangeVisibility}
                           dateFormat={this.props.dateFormat}
                           onChangeDepartureGoingDateValue={this.onChangeDepartureGoingDateValue}
                           todaysDate={todaysDate}
                           onSwapKeyDownHandler={this.onSwapKeyDownHandler}
                           onClickSwap={this.onClickSwap}
                           onKeyDownArrow={this.onKeyDownArrow}
                           multicityReturnWrapper={this.multicityReturnWrapper}
                             multicityDepartureWrapper ={this. multicityDepartureWrapper}
                           />
                    </div>
                    )
                  })}

            </div>
             
                <div className={this.props.styles.flightsearchpage_widget_flex_multicity_non_rep} >
                    <div
                        className={
                            this.props.styles.flight_search_column +
                            " " +
                            this.props.styles.flight_search_pax_multicity_modify
                        }
                    >
                        <div className={this.props.styles.label_text}>
                            <label>Adult</label>
                            <span>(+12 Years)</span>
                        </div>
                        <PaxConfig
                            value={!commonFunction.isValueEmpty(this.state.paxAdultValue) ? this.state.paxAdultValue : 0}
                            onChangePaxValue={(value, type) => this.onClickChangePaxNumber("adult", value, type)}
                            maxLimit={9}
                            minLimit={1}
                            totalPax={(+this.state.paxChildrenValue) + (+this.state.paxAdultValue)}
                            styles={this.props.styles}
                        />
                    </div>

                    <div
                        className={
                            this.props.styles.flight_search_column +
                            " " +
                            this.props.styles.flight_search_pax_multicity_modify
                        }
                    >
                        <div className={this.props.styles.label_text}>
                            <label>Children</label>
                            <span>(2-11 Years)</span>
                        </div>
                        <PaxConfig
                    value={!commonFunction.isValueEmpty(this.state.paxChildrenValue) ? this.state.paxChildrenValue : 0}
                    onChangePaxValue={(value, type) => this.onClickChangePaxNumber("children", value, type)}
                    maxLimit={9}
                    minLimit={0}
                    totalPax={(+this.state.paxChildrenValue) + (+this.state.paxAdultValue)}
                    styles={this.props.styles}
                />
                    </div>


                    <div
                        className={
                            this.props.styles.flight_search_column +
                            " " +
                            this.props.styles.flight_search_pax_multicity_modify
                        }
                    >
                        <div className={this.props.styles.label_text}>
                            <label>Infant</label>
                            <span>(Below 2 Years)</span>
                        </div>
                        <PaxConfig
                    value={!commonFunction.isValueEmpty(this.state.paxInfantValue) ? this.state.paxInfantValue : 0}
                    onChangePaxValue={(value, type) => this.onClickChangePaxNumber("infant", value, type)}
                    maxLimit={+this.state.paxAdultValue}
                    minLimit={0}
                    totalPax={this.state.paxInfantValue}
                    styles={this.props.styles}
                />
                    </div>
                    
                    <div
                        className={
                            this.props.styles.flight_search_column +
                            " " +
                            this.props.styles.flight_search_class_modify_multicity
                        }
                    >
                        <div
                            className={this.props.styles.flight_cabin_class_cont}
                          onClick={() => this.onClickOpenCabinClassList()}
                        >
                            <div
                                className={this.props.styles.flight_cabin_class_icon}
                            >
                                <svg
                                    version="1.1"
                                    id="Layer_1"
                                    x="0px"
                                    y="0px"
                                    className={
                                        this.props.styles.flight_cabin_class_icon_img
                                    }
                                    width="30px"
                                    height="30px"
                                    viewBox="0 0 30 30"
                                    enableBackground="new 0 0 30 30"
                                >
                                    <g>
                                        <path
                                            fill="currentColor"
                                            d="M15.018,1.971c-3.493,0-6.843,1.388-9.312,3.858c-2.471,2.469-3.858,5.819-3.858,9.312
		c0,3.494,1.388,6.842,3.858,9.313c2.469,2.47,5.819,3.856,9.312,3.856c3.493,0,6.841-1.387,9.313-3.856
		c2.47-2.47,3.856-5.819,3.856-9.313c-0.004-3.492-1.393-6.84-3.861-9.309C21.857,3.363,18.511,1.975,15.018,1.971L15.018,1.971z
		 M15.018,26.432c-2.994,0-5.865-1.189-7.982-3.308c-2.117-2.118-3.307-4.989-3.307-7.983c0-2.994,1.19-5.865,3.307-7.982
		c2.117-2.117,4.988-3.307,7.982-3.307c2.994,0,5.866,1.19,7.983,3.307c2.116,2.117,3.306,4.988,3.306,7.982
		c-0.002,2.994-1.192,5.862-3.311,7.978C20.88,25.237,18.011,26.428,15.018,26.432L15.018,26.432z"
                                        />
                                    </g>
                                    <rect
                                        x="7.623"
                                        y="7.496"
                                        fill="none"
                                        width="14.792"
                                        height="15.306"
                                    />
                                    <g>
                                        <path
                                            fill="currentColor"
                                            d="M13.789,18.303l6.39,0.064c0.762,0.008,1.372,0.626,1.372,1.387v1.356c0,0.438-0.354,0.792-0.791,0.792
		c-0.023,0-0.048,0-0.072-0.003l-9.27-0.852c-0.204-0.018-0.36-0.188-0.36-0.394v-1.93L8.498,8.874
		C8.432,8.624,8.622,8.379,8.88,8.379h1.434c1.303,0,2.4,0.974,2.556,2.267L13.789,18.303z M9.392,9.171l2.369,9.106h1.227
		l-0.905-7.537c-0.107-0.896-0.867-1.57-1.769-1.57L9.392,9.171z M11.85,20.292l8.91,0.818v-1.356c0-0.326-0.262-0.592-0.588-0.595
		l-8.322-0.085V20.292z M14.854,12.301c-0.088-0.2,0.001-0.434,0.201-0.522c0.2-0.089,0.434,0.001,0.522,0.201
		c0.312,0.696,0.312,1.408,0,2.104c-0.218,0.492-0.218,0.969,0,1.461c0.09,0.198,0,0.433-0.201,0.521c-0.2,0.09-0.434,0-0.522-0.2
		c-0.309-0.696-0.309-1.407,0-2.104C15.072,13.27,15.072,12.793,14.854,12.301z M19.211,12.301c-0.091-0.2,0.001-0.434,0.199-0.522
		c0.201-0.089,0.436,0.001,0.523,0.201c0.31,0.696,0.31,1.408,0,2.104c-0.218,0.492-0.218,0.969,0,1.461
		c0.09,0.198-0.001,0.433-0.201,0.521c-0.199,0.09-0.434,0-0.521-0.2c-0.31-0.696-0.31-1.407,0-2.104
		C19.429,13.27,19.429,12.793,19.211,12.301z M17.033,10.519c-0.09-0.2,0-0.434,0.2-0.522s0.434,0.001,0.522,0.201
		c0.31,0.696,0.31,1.407,0,2.104c-0.218,0.492-0.218,0.969,0,1.46c0.089,0.2-0.002,0.434-0.201,0.523
		c-0.2,0.089-0.435-0.001-0.521-0.201c-0.312-0.696-0.312-1.407,0-2.104C17.251,11.488,17.251,11.011,17.033,10.519L17.033,10.519z"
                                        />
                                    </g>
                                </svg>
                            </div>
                            <div
                                className={this.props.styles.flight_cabin_class_name}
                            >
                                {!cf.isValueEmpty(this.state.SelectedClassValue)
                          ? this.state.SelectedClassValue.label
                          : ""}
                                
                            </div>
                            <div
                                className={this.props.styles.flight_cabin_class_arrow}
                            >
                                <svg
                                    version="1.1"
                                    id="Layer_1"
                                    x="0px"
                                    y="0px"
                                    width="30px"
                                    height="30px"
                                    viewBox="0 0 30 30"
                                    enableBackground="new 0 0 30 30"
                                >
                                    <path
                                        id="XMLID_224_"
                                        fill="currentColor"
                                        d="M1.869,11.074l12.576,12.575c0.236,0.236,0.556,0.368,0.889,0.368
	c0.334,0,0.654-0.132,0.889-0.368l12.576-12.575c0.489-0.492,0.489-1.288,0-1.779c-0.492-0.491-1.288-0.491-1.779,0L15.333,20.98
	L3.647,9.295C3.401,9.049,3.08,8.926,2.757,8.926c-0.322,0-0.643,0.123-0.889,0.369C1.377,9.786,1.377,10.582,1.869,11.074z"
                                    />
                                </svg>
                            </div>
                            {this.state.openCabinClassList === true ? (
                                <div
                                      ref={this.economyPopUpWrapper}
                                    className={this.props.styles.flight_cabin_class_list}
                                >
                                    {cabinClass.map((element, index) => {
                                        return (
                                            <div
                                                key={"cabinClass" + index}
                                                className={
                                                    this.props.styles.flight_cabin_class_ele
                                                }
                                                onClick={() => { this.cabinClassSelectHandler(element) }}
                                            >
                                                {element.label}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </div>
                    </div>
    

                    <div className={this.props.styles.flightautocomplemodify}>
                        <button tabIndex="2" onClick={this.onClickSearchFlight} onKeyDown={this.modifySearchKeyDownHandler}>Modify</button>
                    </div>
                </div>
         
            </div>
            : 
            <div className={this.props.styles.flightsearchpage_widget} >
                <div className={this.props.styles.flightsearchpage_widget_flex} >
                    <div className={this.props.styles.flightautocomplete_main}>
                        <FlightAutoComplete
                            label="From"
                            value={this.state.departureValue}
                            styles={this.props.styles}
                            state = {this.state}
                            onclickFlightAutoComplete={() => this.onclickFlightAutoComplete("departure")}
                            placholder="City or Airport"
                            onClickSourceAndDestination={(city, airPortCode) => this.onClickSetDepartureGoingToValue(city, airPortCode, "departure")}
                            onChangeSourceAndDestination={(value) => this.onChangeSetDepartureGoingToValue(value, "departure")}
                            isAutoCompleteExpand={(bool) => this.setState({ expandDepartureBoolean: bool })}
                            autoCompleteData={!commonFunction.isValueEmpty(this.props.getDepartureSuggestionList) ? this.props.getDepartureSuggestionList : []}
                            expandAutoCompleteBool={!commonFunction.isValueEmpty(this.state.expandDepartureBoolean) ? this.state.expandDepartureBoolean : false}
                            errorMessage={"Please select your Origin City"}
                            isError={this.state.isError.departure}
                            listWrapper={this.departureListWrapper}
                            onKeyDownArrow={(event) => { this.onKeyDownArrow(event, 'flightDataArray', this.props.getDepartureSuggestionList, "departure") }}
                        />
                        <span className={this.props.styles.flight_swap} tabIndex="2" onKeyDown={this.onSwapKeyDownHandler} onClick={this.onClickSwap}>
                            <svg version="1.1" id="Layer_1" x="0px" y="0px"
                                width="30px" height="22px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">
                                <g>
                                    <path fill="currentColor" d="M14.719,28.813C7.103,28.813,0.906,22.616,0.906,15S7.103,1.188,14.719,1.188S28.531,7.384,28.531,15
		                        S22.335,28.813,14.719,28.813z M14.719,2.188C7.654,2.188,1.906,7.935,1.906,15c0,7.064,5.748,12.813,12.813,12.813
		                        c7.064,0,12.813-5.748,12.813-12.813C27.531,7.935,21.783,2.188,14.719,2.188z"/>
                                </g>
                                <g>
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" x="6.145" y="9.9" width="17.148" height="12.891" />
                                        </defs>
                                        <g clipPath="url(#SVGID_2_)">
                                            <g>
                                                <path fill="currentColor" d="M5.689,15.855l6.875,6.92l0.729-0.713L8.09,16.855h15.203v-1.02H8.09" />
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <defs>
                                            <rect id="SVGID_3_" x="6.145" y="7.208" width="17.148" height="12.891" />
                                        </defs>
                                        <g clipPath="url(#SVGID_4_)">
                                            <g>
                                                <path fill="currentColor" d="M23.747,14.145l-6.874-6.922l-0.729,0.713l5.203,5.208H6.145v1.019h15.203" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>

                    <div className={this.props.styles.flightautocomplete_main}>
                        <FlightAutoComplete
                            label="To"
                            value={this.state.goingToValue}
                            styles={this.props.styles}
                            state = {this.state}
                            onclickFlightAutoComplete={() => this.onclickFlightAutoComplete("goingTo")}
                            placholder="City or Airport"
                            onClickSourceAndDestination={(city, airPortCode) => this.onClickSetDepartureGoingToValue(city, airPortCode, "goingTo")}
                            onChangeSourceAndDestination={(value) => this.onChangeSetDepartureGoingToValue(value, "goingTo")}
                            isAutoCompleteExpand={(bool) => this.setState({ expandGoingToBoolean: bool })}
                            autoCompleteData={!commonFunction.isValueEmpty(this.props.getGoingToSuggestionList) ? this.props.getGoingToSuggestionList : []}
                            expandAutoCompleteBool={this.state.expandGoingToBoolean}
                            errorMessage={(this.state.departureValue === this.state.goingToValue) ? "Origin and destination cannot be same" : "Please select your Destination City"}
                            isError={this.state.isError.goingTo}
                            listWrapper={this.returnListWrapper}
                            onKeyDownArrow={(event) => { this.onKeyDownArrow(event, 'flightDataArray', this.props.getGoingToSuggestionList, "goingTo") }}
                        />
                    </div>
                    <div className={this.props.styles.flightautocompletecalender}>
                        <DesktopCalender
                            label="Departure Date"
                            styles={this.props.styles}
                            value={!commonFunction.isValueEmpty(this.state.departureDate) ? this.state.departureDate : ""}
                            placholder="Departure Date"
                            onChangeDate={(value) => { this.onChangeDepartureGoingDateValue(value, "goindeparturegTo") }}
                            iconImage={<svg version="1.1" id="Layer_1" x="0px" y="0px"
                                width="17px" height="16px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">
                                <g>
                                    <path fill="currentColor" d="M7.258,17.91H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.002,1.002,1.002h0.083
                                               c0.558,0,1.003-0.445,1.003-1.002v-0.111C8.262,18.355,7.816,17.91,7.258,17.91z"/>
                                    <path fill="currentColor" d="M11.322,17.91h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.084
                                               c0.556,0,1.002-0.445,1.002-1.002v-0.111C12.324,18.355,11.878,17.91,11.322,17.91z"/>
                                    <path fill="currentColor" d="M15.414,17.91h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.002,1.002,1.002h0.112
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C16.415,18.355,15.97,17.91,15.414,17.91L15.414,17.91z"/>
                                    <path fill="currentColor" d="M7.258,13.792H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.001,1.002,1.001h0.083
                                               c0.558,0,1.003-0.445,1.003-1.001v-0.111C8.262,14.237,7.816,13.792,7.258,13.792z"/>
                                    <path fill="currentColor" d="M11.322,13.792h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.084
                                               c0.556,0,1.002-0.445,1.002-1.001v-0.111C12.324,14.237,11.878,13.792,11.322,13.792z"/>
                                    <path fill="currentColor" d="M15.414,13.792h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.001,1.002,1.001h0.112
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C16.415,14.237,15.97,13.792,15.414,13.792z"/>
                                    <path fill="currentColor" d="M19.478,17.91h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.002,1.003,1.002h0.111
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C20.506,18.355,20.033,17.91,19.478,17.91z"/>
                                    <path fill="currentColor" d="M23.568,17.91h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.112
                                               c0.557,0,1.002-0.445,1.002-1.002v-0.111C24.57,18.355,24.125,17.91,23.568,17.91z"/>
                                    <path fill="currentColor" d="M19.478,13.792h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.001,1.003,1.001h0.111
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C20.506,14.237,20.033,13.792,19.478,13.792z"/>
                                    <path fill="currentColor" d="M23.568,13.792h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.112
                                               c0.557,0,1.002-0.445,1.002-1.001v-0.111C24.57,14.237,24.125,13.792,23.568,13.792z"/>
                                    <path fill="currentColor" d="M7.258,22.002H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.445,1.004,1.002,1.004h0.083
                                               c0.558,0,1.003-0.445,1.003-1.004v-0.109C8.262,22.473,7.816,22.002,7.258,22.002z"/>
                                    <path fill="currentColor" d="M11.322,22.002h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.084
                                               c0.556,0,1.002-0.445,1.002-1.004v-0.109C12.324,22.473,11.878,22.002,11.322,22.002z"/>
                                    <path fill="currentColor" d="M15.414,22.002h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.446,1.004,1.002,1.004h0.112
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C16.415,22.473,15.97,22.002,15.414,22.002z"/>
                                    <path fill="currentColor" d="M19.478,22.002h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.109c0,0.559,0.445,1.004,1.003,1.004h0.111
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C20.506,22.473,20.033,22.002,19.478,22.002z"/>
                                    <path fill="currentColor" d="M23.568,22.002h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.112
                                               c0.557,0,1.002-0.445,1.002-1.004v-0.109C24.57,22.473,24.125,22.002,23.568,22.002z"/>
                                    <path fill="currentColor" d="M28.577,10.535L28.577,10.535V8.281c0-1.726-1.392-3.118-3.089-3.118h-1.643V4.189
                                               c0-1.196-0.973-2.171-2.171-2.171c-1.196,0-2.17,0.975-2.17,2.171v0.974l-8.238,0V4.19c0-1.197-0.974-2.171-2.17-2.171
                                               c-1.197,0-2.17,0.974-2.17,2.171v0.974H5.283c-1.725,0-3.117,1.392-3.117,3.118v16.643c0,1.697,1.392,3.088,3.117,3.088h20.232
                                               c1.698,0,3.09-1.391,3.09-3.088L28.577,10.535L28.577,10.535L28.577,10.535z M20.785,4.189c0-0.473,0.39-0.863,0.861-0.863
                                               c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863c-0.472,0-0.861-0.39-0.861-0.863V4.189z M8.206,4.189
                                               c0-0.473,0.389-0.863,0.862-0.863c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863
                                               c-0.473,0-0.862-0.39-0.862-0.863V4.189z M5.254,6.472h1.67c0.112,1.085,1.03,1.948,2.17,1.948c1.113,0,2.031-0.863,2.143-1.948
                                               h8.266c0.111,1.085,1.03,1.948,2.143,1.948c1.113,0,2.031-0.863,2.144-1.948h1.67c0.974,0,1.781,0.807,1.781,1.809v1.615H3.446
                                               V8.28C3.446,7.279,4.253,6.472,5.254,6.472L5.254,6.472z M25.488,26.703H5.254c-1.002,0-1.808-0.807-1.808-1.779V11.175H27.27
                                               v13.749C27.27,25.896,26.462,26.703,25.488,26.703z"/>
                                </g>
                            </svg>}
                            startDate={!commonFunction.isValueEmpty(this.state.departureDate) ? this.state.departureDate : ""}
                            endDate={!commonFunction.isValueEmpty(this.state.returnDate) ? this.state.returnDate : ""}
                            startDateLimit={!commonFunction.isValueEmpty(todaysDate) ? todaysDate : ""}
                            dateFormat={this.props.dateFormat}
                            changeVisibility={(bool) => this.onchangeVisibility(bool, "departure")}
                            isVisible={this.state.expandDepartureDateBoolean}
                            type={"double"}
                            doubleSelection={false}
                        />
                    </div>
                    <div className={this.props.styles.flightautocompletecalender}>
                        <DesktopCalender
                            label="Return Date"
                            class={this.props.styles.left}
                            styles={this.props.calendarStyles}
                            value={this.state.isOnewaySearch === false ? !commonFunction.isValueEmpty(this.state.returnDate) ? this.state.returnDate : "" : ""}
                            placholder="Arrival Date"
                            onChangeDate={(value) => { this.onChangeDepartureGoingDateValue(value, "goingTo") }}
                            iconImage={<svg version="1.1" id="Layer_1" x="0px" y="0px"
                                width="17px" height="16px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">
                                <g>
                                    <path fill="currentColor" d="M7.258,17.91H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.002,1.002,1.002h0.083
                                               c0.558,0,1.003-0.445,1.003-1.002v-0.111C8.262,18.355,7.816,17.91,7.258,17.91z"/>
                                    <path fill="currentColor" d="M11.322,17.91h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.084
                                               c0.556,0,1.002-0.445,1.002-1.002v-0.111C12.324,18.355,11.878,17.91,11.322,17.91z"/>
                                    <path fill="currentColor" d="M15.414,17.91h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.002,1.002,1.002h0.112
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C16.415,18.355,15.97,17.91,15.414,17.91L15.414,17.91z"/>
                                    <path fill="currentColor" d="M7.258,13.792H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.001,1.002,1.001h0.083
                                               c0.558,0,1.003-0.445,1.003-1.001v-0.111C8.262,14.237,7.816,13.792,7.258,13.792z"/>
                                    <path fill="currentColor" d="M11.322,13.792h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.084
                                               c0.556,0,1.002-0.445,1.002-1.001v-0.111C12.324,14.237,11.878,13.792,11.322,13.792z"/>
                                    <path fill="currentColor" d="M15.414,13.792h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.001,1.002,1.001h0.112
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C16.415,14.237,15.97,13.792,15.414,13.792z"/>
                                    <path fill="currentColor" d="M19.478,17.91h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.002,1.003,1.002h0.111
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C20.506,18.355,20.033,17.91,19.478,17.91z"/>
                                    <path fill="currentColor" d="M23.568,17.91h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.112
                                               c0.557,0,1.002-0.445,1.002-1.002v-0.111C24.57,18.355,24.125,17.91,23.568,17.91z"/>
                                    <path fill="currentColor" d="M19.478,13.792h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.001,1.003,1.001h0.111
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C20.506,14.237,20.033,13.792,19.478,13.792z"/>
                                    <path fill="currentColor" d="M23.568,13.792h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.112
                                               c0.557,0,1.002-0.445,1.002-1.001v-0.111C24.57,14.237,24.125,13.792,23.568,13.792z"/>
                                    <path fill="currentColor" d="M7.258,22.002H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.445,1.004,1.002,1.004h0.083
                                               c0.558,0,1.003-0.445,1.003-1.004v-0.109C8.262,22.473,7.816,22.002,7.258,22.002z"/>
                                    <path fill="currentColor" d="M11.322,22.002h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.084
                                               c0.556,0,1.002-0.445,1.002-1.004v-0.109C12.324,22.473,11.878,22.002,11.322,22.002z"/>
                                    <path fill="currentColor" d="M15.414,22.002h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.446,1.004,1.002,1.004h0.112
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C16.415,22.473,15.97,22.002,15.414,22.002z"/>
                                    <path fill="currentColor" d="M19.478,22.002h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.109c0,0.559,0.445,1.004,1.003,1.004h0.111
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C20.506,22.473,20.033,22.002,19.478,22.002z"/>
                                    <path fill="currentColor" d="M23.568,22.002h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.112
                                               c0.557,0,1.002-0.445,1.002-1.004v-0.109C24.57,22.473,24.125,22.002,23.568,22.002z"/>
                                    <path fill="currentColor" d="M28.577,10.535L28.577,10.535V8.281c0-1.726-1.392-3.118-3.089-3.118h-1.643V4.189
                                               c0-1.196-0.973-2.171-2.171-2.171c-1.196,0-2.17,0.975-2.17,2.171v0.974l-8.238,0V4.19c0-1.197-0.974-2.171-2.17-2.171
                                               c-1.197,0-2.17,0.974-2.17,2.171v0.974H5.283c-1.725,0-3.117,1.392-3.117,3.118v16.643c0,1.697,1.392,3.088,3.117,3.088h20.232
                                               c1.698,0,3.09-1.391,3.09-3.088L28.577,10.535L28.577,10.535L28.577,10.535z M20.785,4.189c0-0.473,0.39-0.863,0.861-0.863
                                               c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863c-0.472,0-0.861-0.39-0.861-0.863V4.189z M8.206,4.189
                                               c0-0.473,0.389-0.863,0.862-0.863c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863
                                               c-0.473,0-0.862-0.39-0.862-0.863V4.189z M5.254,6.472h1.67c0.112,1.085,1.03,1.948,2.17,1.948c1.113,0,2.031-0.863,2.143-1.948
                                               h8.266c0.111,1.085,1.03,1.948,2.143,1.948c1.113,0,2.031-0.863,2.144-1.948h1.67c0.974,0,1.781,0.807,1.781,1.809v1.615H3.446
                                               V8.28C3.446,7.279,4.253,6.472,5.254,6.472L5.254,6.472z M25.488,26.703H5.254c-1.002,0-1.808-0.807-1.808-1.779V11.175H27.27
                                               v13.749C27.27,25.896,26.462,26.703,25.488,26.703z"/>
                                </g>
                            </svg>
                            }
                            startDate={!commonFunction.isValueEmpty(this.state.departureDate) ? this.state.departureDate : ""}
                            endDate={!commonFunction.isValueEmpty(this.state.returnDate) ? this.state.returnDate : ""}
                            startDateLimit={!commonFunction.isValueEmpty(this.state.departureDate) ? this.state.departureDate : ""}
                            dateFormat={this.props.dateFormat}
                            changeVisibility={(bool) => this.onchangeVisibility(bool, "goingTo")}
                            isVisible={this.state.expandReturnDateBoolean}
                            type={"double"}
                            doubleSelection={false}
                        />
                        {!this.state.isOnewaySearch && <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                            <img src='images/download.png' tabIndex="2" style={{ width: "30px", height: "28px", marginTop: "10px" }} onKeyDown={this.removeReturnDateHandler} onClick={this.deleteReturnDateHandler} alt="" />
                        </div>}
                    </div>
                    <div className={this.props.styles.flightautocompletetraveller}>
                        <label>Travellers</label>
                        <input type={"text"}
                            value={this.state.travellersObjOrigin.adults + this.state.travellersObjOrigin.child + this.state.travellersObjOrigin.infants}
                            readOnly /* tabIndex="2" */ onClick={this.onTravellersClickHandler} /* onFocusCapture={this.onTravellersClickHandler} */ />
                        {this.state.isTravelersPopupVisible &&
                            <EconomyPopup
                                travellerDivRef={this.travellerDivRef}
                                economyPopUpWrapper={this.economyPopUpWrapper}
                                state={this.state}
                                styles={this.props.styles}
                                cabinClass={cabinClass}
                                cabinClassSelectHandler={this.cabinClassSelectHandler}
                                onClickChangePaxNumber={this.onClickChangePaxNumber}
                                onClickOpenCabinClassList={this.onClickOpenCabinClassList}
                                onDoneBtnClickHandler={this.onDoneBtnClickHandler} />}
                    </div>
                    <div className={this.props.styles.flightautocomplemodify}>
                        <button tabIndex="2" onClick={this.onClickSearchFlight} onKeyDown={this.modifySearchKeyDownHandler}>Modify</button>
                    </div>
                </div>
            </div>
}

            {/* MODIFY SEARCH MULTICITY END */}


        </div>
    }
}


export default ModifySearch