import React, { Component, createRef } from "react";
import { addDays, differenceInCalendarDays, format } from "date-fns";

import B2BWidgets from "../../../b2bwidgets/index";
// import DesktopCalender from "../../../calendar/DesktopCalender";
import DesktopCalender from "../../../calendar/flightCalender/theme2/DesktopCalenderNew"
import FlightAutoComplete from "./FlightAutoComplete";
import PaxConfig from "./PaxConfig";
import cf from "../../../../lib/utils/common";
import { getAirlines } from "../../../../lib/getAirlines";

const cabinClassOne = [
  { id: 1, value: "Economy", label: "Economy" },
  { id: 2, value: "Business", label: "Business" },
  { id: 3, value: "First Class", label: "First Class" },
  { id: 4, value: "Premium Economy", label: "Premium Economy" },
];
// const cabinClassTwo = [
//   { id: 1, value: "Economy", label: "Economy" },
//   { id: 2, value: "Business", label: "Business" },
//   { id: 3, value: "First Class", label: "First Class" },
//   { id: 4, value: "Premium Economy", label: "Premium Economy" },
// ];

let search_type = "";
export default class Theme2 extends Component {
  constructor(props) {
    super(props);
    this.economydeparturePopUpWrapper = createRef();
    this.economyreturnPopUpWrapper = createRef();
    this.departureListWrapper = createRef();
    this.returnListWrapper = createRef();
    this.advanceSearchWrapper = createRef();
    this.guestDetailWrapper = createRef();
    this.passengerDetailWrapper = createRef();

    this.setDropdownWrapperRef = createRef();
    this.state = {
      isOnewaySearch: true,
      departureValue: "",
      departureAirPortName: "",
      goingToValue: "",
      goingToAirPortName: "",
      expandDepartureBoolean: false,
      expandGoingToBoolean: false,
      dateLimit: cf.getDateWithFormat(new Date(), this?.props?.dateFormat),
      departureDateValue: cf.getDateWithFormat(
        new Date(),
        this?.props?.dateFormat
      ),
      goingToDateValue: cf.getDateWithFormat(
        addDays(new Date(), 7),
        this?.props?.dateFormat
      ),
      expandDepartureDateBoolean: false,
      expandGoingToDateBoolean: false,
      paxAdultValue: 1,
      paxChildrenValue: 0,
      paxInfantValue: 0,
      SelectedClassValue: { label: "Economy", id: 1 },
      SelectedClassValueTwo: { label: "Economy", id: 1 },
      TotalPassenger: 1,
      openCabinClassListOne: false,
      openCabinClassListTwo: false,
      openPaxList: false,
      getDepartureSuggestionList: [],
      getGoingToSuggestionList: [],
      getDepartureAirportCode: "",
      getGoingToAirportCode: "",
      isError: {
        departure: false,
        goingTo: false,
        departureDate: false,
        goingToDate: false,
        selectedGuest: false,
      },
      returnCity: "",
      departureCity: "",
      code: "",
      name: "",
      airportName: "",
      indexDropdown: -1,
      type: "flight",
      openTraveler: false,
      openAirlines: false,
      isAirlinesChecked: false,
      selectedAirlines: [],
      // airlineList:[],
      stops: [],
      isZeroStop: false,
      isOneStop: false,
      isTwoStop: false,
      guest_id: "",
      isCalenderVisible: false
    };
  }
  componentDidMount() {
    // this.setDropdownWrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    let srpCabinClass = this.props.isSRP
      ? new URLSearchParams(window.location.search).get("class") === "2"
        ? { label: "Business", id: 2 }
        : new URLSearchParams(window.location.search).get("class") === "3"
          ? { label: "First Class", id: 3 }
          : new URLSearchParams(window.location.search).get("class") === "4"
            ? { label: "Premium Economy", id: 4 }
            : ""
      : this.state.SelectedClassValue;

    this.setState(
      {
        getDepartureSuggestionList: this.props.airports,
        getGoingToSuggestionList: this.props.airports,
        departureValue: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("from")
          : "",
        goingToValue: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("to")
          : new URLSearchParams(window.location.search).get("to") &&
            new URLSearchParams(window.location.search).get("toCity")
            ? new URLSearchParams(window.location.search).get("toCity") +
            " - (" +
            new URLSearchParams(window.location.search).get("to") +
            ")"
            : "",
        departureCity: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("fromCity")
          : "",
        returnCity: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("toCity")
          : new URLSearchParams(window.location.search).get("toCity")
            ? new URLSearchParams(window.location.search).get("toCity")
            : "",
        getGoingToAirportCode: new URLSearchParams(window.location.search).get(
          "to"
        )
          ? new URLSearchParams(window.location.search).get("to")
          : "",
        departureDateValue: this.props.isSRP
          ? !cf.isValueEmpty(
            cf.getDateWithFormat(
              new Date(
                new URLSearchParams(window.location.search).get("departure")
              ),
              this?.props?.dateFormat
            )
          ) &&
          cf.getDateWithFormat(
            new Date(
              new URLSearchParams(window.location.search).get("departure")
            ),
            this?.props?.dateFormat
          )
          : cf.getDateWithFormat(new Date(), this?.props?.dateFormat),
        goingToDateValue: this.props.isSRP
          ? !cf.isValueEmpty(
            new URLSearchParams(window.location.search).get("arrival")
          ) &&
            !cf.isValueEmpty(
              cf.getDateWithFormat(
                new Date(
                  new URLSearchParams(window.location.search).get("arrival")
                ),
                this?.props?.dateFormat
              )
            )
            ? cf.getDateWithFormat(
              new Date(
                new URLSearchParams(window.location.search).get("arrival")
              ),
              this?.props?.dateFormat
            )
            : cf.getDateWithFormat(
              addDays(new Date(), 7),
              this?.props?.dateFormat
            )
          : cf.getDateWithFormat(
            addDays(new Date(), 7),
            this?.props?.dateFormat
          ),
        paxAdultValue: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("adults")
          : this.state.paxAdultValue,
        paxChildrenValue: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("child")
          : this.state.paxChildrenValue,
        paxInfantValue: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("infants")
          : this.state.paxInfantValue,
        SelectedClassValue: srpCabinClass,
        isOnewaySearch: !cf.isValueEmpty(
          new URLSearchParams(window.location.search).get("arrival")
        )
          ? false
          : true,
      },
      () => {
        this.props.isSRP &&
          this.onClickSetDepartureGoingToValue(
            this.state.departureCity,
            this.state.departureValue,
            "departure"
          );
        this.props.isSRP &&
          this.onClickSetDepartureGoingToValue(
            this.state.returnCity,
            this.state.goingToValue,
            "goingTo"
          );
      }
    );
    // getAirlines().then(res => {
    //     // console.log("getAirlines===>res===>", res);
    //     if (res && res.status && res.data && res.data.data && res.data.data.length > 0) {
    //       this.setState({
    //         airlineList:res.data.data
    //       })
    //     }

    // })

    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleTabInside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleTabInside);
  }
  //Add client
  adGuestId = (id) => {
    this.setState({ guest_id: id }, () => {
      if (this.state.isError.selectedGuest === true) {
        this.validateErrorMsg();
      }
      this.setState({ guest_id: id });
    });
  };

  rmGuestId = () => {
    this.setState({ guest_id: "" });
  };

  handleClickOutside = (e) => {
    if (
      this.economydeparturePopUpWrapper.current &&
      !this.economydeparturePopUpWrapper.current.contains(event.target)
    ) {
      this.setState({ openCabinClassListOne: false });
    }
    if (
      this.economyreturnPopUpWrapper.current &&
      !this.economyreturnPopUpWrapper.current.contains(event.target)
    ) {
      this.setState({ openCabinClassListTwo: false });
    }
    if (
      this.passengerDetailWrapper.current &&
      !this.passengerDetailWrapper.current.contains(event.target)
    ) {
      this.setState({ openPaxList: false });
    }
    if (
      this.departureListWrapper.current &&
      !this.departureListWrapper.current.contains(event.target)
    ) {
      this.setState({ expandDepartureBoolean: false });
    }
    if (
      this.returnListWrapper.current &&
      !this.returnListWrapper.current.contains(event.target)
    ) {
      this.setState({ expandGoingToBoolean: false });
    }
    if (
      this.advanceSearchWrapper.current &&
      !this.advanceSearchWrapper.current.contains(event.target)
    ) {
      this.setState({ openAirlines: false });
    }
    if (
      this.guestDetailWrapper.current &&
      !this.guestDetailWrapper.current.contains(event.target)
    ) {
      this.setState({ openTraveler: false });
    }
  };

  handleTabInside = (e) => {
    if (e.key !== "Tab") {
      return;
    }
    this.handleClickOutside(e);
  };

  onClickOpenCabinClassListOne = () => {
    if (this.state.openCabinClassListOne === false) {
      this.setState({ openCabinClassListOne: true });
    } else if (this.state.openCabinClassListOne === true) {
      this.setState({ openCabinClassListOne: false });
    }
  };

  onClickOpenCabinClassListTwo = () => {
    if (this.state.openCabinClassListTwo === false) {
      this.setState({ openCabinClassListTwo: true });
    } else if (this.state.openCabinClassListTwo === true) {
      this.setState({ openCabinClassListTwo: false });
    }
  };

  onClickOpenPassangerList = () => {
    if (this.state.openPaxList === false) {
      this.setState({ openPaxList: true });
    } else if (this.state.openPaxList === true) {
      this.setState({ openPaxList: false });
    }
  }

  validateErrorMsg = () => {
    const mapDispatch = {
      departure: cf.isValueEmpty(this.state.departureValue)
        ? true
        : !this.state.getDepartureAirportCode
          ? true
          : false,
      goingTo: cf.isValueEmpty(this.state.goingToValue)
        ? true
        : !this.state.getGoingToAirportCode
          ? true
          : this.state.departureValue === this.state.goingToValue
            ? true
            : false,
      departureDate: cf.isValueEmpty(this.state.departureDateValue)
        ? true
        : false,
      goingToDate: cf.isValueEmpty(this.state.goingToDateValue) ? true : false,
      selectedGuest: cf.isValueEmpty(this.state.guest_id) ? true : false,
    };
    this.setState({ isError: mapDispatch });
  };

  onClickSearchFlight = () => {
    const mapDispatch = {
      departure: cf.isValueEmpty(this.state.departureValue)
        ? true
        : !this.state.getDepartureAirportCode
          ? true
          : false,
      goingTo: cf.isValueEmpty(this.state.goingToValue)
        ? true
        : !this.state.getGoingToAirportCode
          ? true
          : this.state.departureValue === this.state.goingToValue
            ? true
            : false,
      departureDate: cf.isValueEmpty(this.state.departureDateValue)
        ? true
        : false,
      goingToDate: !this.state.isOnewaySearch && cf.isValueEmpty(this.state.goingToDateValue) ? true : false,
      selectedGuest: cf.isValueEmpty(this.state.guest_id) ? true : false,
    };
    console.log("departureValue:", this.state.departureValue);
    console.log("goingToValue:", this.state.goingToValue);
    console.log("Comparison result:", this.state.departureValue == this.state.goingToValue);
    console.log("mapDispatch", mapDispatch);
    if (this.props.isOffline !== true) {
      mapDispatch.selectedGuest = cf.isValueEmpty(this.state.selectedGuest)
        ? false
        : true;
    }
    if (!this.props.isOffline) {
      mapDispatch.selectedGuest = cf.isValueEmpty(this.state.selectedGuest)
        ? false
        : true;
    }
    this.setState({ isError: mapDispatch }, () => {
      let Validate = true;
      for (const [key, value] of Object.entries(this.state.isError)) {
        if (value === true) {
          Validate = false;
          break;
        }
      }
      let arrivalDate = "";
      let departureDateArr = !cf.isValueEmpty(this.state.departureDateValue)
        ? this.state.departureDateValue.split("/")
        : "";
      let departureDate = !cf.isValueEmpty(departureDateArr)
        ? format(new Date(this.state.departureDateValue), "yyyy-MM-dd")
        : "";
      if (this.state.isOnewaySearch === false) {
        let arrivalDateArr = !cf.isValueEmpty(this.state.goingToDateValue)
          ? this.state.goingToDateValue.split("/")
          : "";
        arrivalDate = !cf.isValueEmpty(arrivalDateArr)
          ? format(new Date(this.state.goingToDateValue), "yyyy-MM-dd")
          : "";
      }
      let airline_code = [];
      if (this.props.isOffline === false) {
        this.props?.selectedAirlines?.forEach((element) => {
          airline_code.push(element.airline_code);
        });
      }

      let fromValue = this.state.departureValue.split("-");
      let toValue = this.state.goingToValue.split("-");
      // console.log(airline_code,"airline_code")
      let urlQuery = {
        from: this.state.getDepartureAirportCode,
        to: this.state.getGoingToAirportCode,
        type: this.state.isOnewaySearch ? 1 : 2,
        class: this.state.SelectedClassValue?.id,
        returnclass: this.state.SelectedClassValueTwo?.id,
        adults: this.state.paxAdultValue,
        child: this.state.paxChildrenValue,
        infants: this.state.paxInfantValue,
        airlines: JSON.stringify(airline_code),
        stops: this.state.stops,
        guests: this.state.guest_id,
        departureValue: this.props.isOffline ? fromValue[0] : "",
        goingToValue: this.props.isOffline ? toValue[0] : "",
        // airlines:this.props.selectedAirlines,
        departure: departureDate,
        arrival:
          arrivalDate, fromCity: this.state.departureCity, toCity: this.state.returnCity,
        departureAirPortName: this.state.departureAirPortName,
        goingToAirPortName: this.state.goingToAirPortName
      };
      console.log("urlQuery", urlQuery)

      console.log("Validate", Validate)

      if (Validate === true) {
        if (this.props.isOffline === false) {
          cf.redirectTo("flight/search", urlQuery);
        } else if (this.props.isOffline === true) {
          const searchParams = new URLSearchParams(urlQuery);
          const queryString = searchParams.toString();
          window.location = "/offlinesearch/flight?" + queryString;
          // cf.redirectTo("/offlinesearch/flight", urlQuery)
          // window.location.href = `/offlinesearch/flight=${urlQuery}`;
        }
      }
    });
  };

  onchangeVisibility = (bool, type) => {
    // console.log("bool, type",bool, type)
    this.setDropdownWrapperRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    // if (type === "goingTo") {
    //   this.setState(
    //     { expandGoingToDateBoolean: bool, expandDepartureDateBoolean: false },
    //     () => {
    //       if (this.state.isOnewaySearch === true) {
    //         this.setState({ isOnewaySearch: false });
    //       }
    //     }
    //   );
    // } else {
    //   this.setState({
    //     expandGoingToDateBoolean: false,
    //     expandDepartureDateBoolean: bool,
    //   });
    // }

    this.setState({
      isCalenderVisible: bool
    }, () => {
      if (type == "return") {
        if (this.state.isOnewaySearch === true) {
          this.setState({ isOnewaySearch: false });
        }
      }
    })

  };

  onChangeSetDepartureGoingToValue = (value, type) => {
    if (type === "departure") {
      this.setState(
        { departureValue: value, getDepartureAirportCode: "" },
        () => {
          if (this.state.isError.departure === true) {
            this.validateErrorMsg();
          }
          if (value.length === 0) {
            this.setState({ getDepartureSuggestionList: [] });
          }
        }
      );
    } else {
      this.setState({ goingToValue: value, getGoingToAirportCode: "" }, () => {
        if (this.state.isError.goingTo === true) {
          this.validateErrorMsg();
          if (value.length === 0)
            this.setState({ getGoingToSuggestionList: [] });
        }
      });
    }
    if (type === "departure") {
      this.props.getAutoSuggestData(value, type, "change");
      this.setState({ expandDepartureBoolean: true });
    } else {
      this.props.getAutoSuggestData(value, type, "change");
      this.setState({ expandGoingToBoolean: true });
    }
  };

  onClickSetDepartureGoingToValue = (city, airPortCode, airPortName, type) => {
    if (type === "departure") {
      this.setState(
        {
          departureValue: city,
          departureAirPortName: airPortCode + " - " + airPortName + " ",
          getDepartureAirportCode: airPortCode,
          departureCity: city,
        },
        () => {
          if (this.state.isError.departure === true) {
            this.validateErrorMsg();
          }
        }
      );
    } else {
      this.setState(
        {
          goingToValue: city,
          getGoingToAirportCode: airPortCode,
          goingToAirPortName: airPortCode + " - " + airPortName + " ",
          returnCity: city,
        },
        () => {
          if (this.state.isError.goingTo === true) {
            this.validateErrorMsg();
          }
        }
      );
    }
  };

  onClickChangePaxNumber = (pax, value, type) => {
    if (pax === "adult") {
      if (
        +this.state.paxChildrenValue + value <= 9 &&
        +this.state.paxChildrenValue + value > 0
      ) {
        this.setState({ paxAdultValue: value }, () => {
          if (type === "minus") {
            if (+this.state.paxInfantValue > value) {
              let paxInfantValue = +this.state.paxInfantValue;
              this.setState({ paxInfantValue: --paxInfantValue });
            }
          }
        });
      }
    } else if (pax === "children") {
      if (
        +value + +this.state.paxAdultValue <= 9 &&
        +value + +this.state.paxAdultValue > 0
      ) {
        this.setState({ paxChildrenValue: +value });
      }
    } else if (pax === "infant") {
      if (value > +this.state.paxAdultValue) {
        this.setState({ paxInfantValue: +--value });
      } else {
        this.setState({ paxInfantValue: +value });
      }
    }
  };


  validateErrorMsg = () => {
    const mapDispatch = {
      departure: cf.isValueEmpty(this.state.departureValue) ? true : false,
      goingTo: cf.isValueEmpty(this.state.goingToValue)
        ? true
        : this.state.departureValue === this.state.goingToValue
          ? true
          : false,
      departureDate: cf.isValueEmpty(this.state.departureDateValue)
        ? true
        : false,
      goingToDate: cf.isValueEmpty(this.state.goingToDateValue) ? true : false,
    };
    this.setState({ isError: mapDispatch });
  };

  onClickSwap = () => {
    if (
      !cf.isValueEmpty(this.state.departureValue) ||
      !cf.isValueEmpty(this.state.goingToValue)
    ) {
      let departureValue = this.state.departureValue;
      let departureAirportCode = this.state.getDepartureAirportCode;
      let departureCity = this.state.departureCity;
      let departureAirPortName = this.state.departureAirPortName
      this.setState({
        departureValue: this.state.goingToValue,
        goingToValue: departureValue,
        getDepartureAirportCode: this.state.getGoingToAirportCode,
        getGoingToAirportCode: departureAirportCode,
        departureCity: this.state.returnCity,
        returnCity: departureCity,
        departureAirPortName: this.state.goingToAirPortName,
        goingToAirPortName: departureAirPortName
      });
    }
  };


  onChangeDepartureGoingDateValue = (value, type) => {
    console.log("typeaabc", type)
    if (type === "endDate") {
      let dayDifference = differenceInCalendarDays(
        new Date(value),
        new Date(this.state.departureDateValue)
      );
      if (dayDifference < 0) {
        // this.setState({
        //   isOnewaySearch: true,
        //   goingToDateValue: cf.getDateWithFormat(
        //     addDays(new Date(), 7),
        //     this?.props?.dateFormat
        //   ),
        // });
      } else {
        this.setState({
          goingToDateValue: cf.getDateWithFormat(
            value,
            this?.props?.dateFormat
          ),
        }, () => {
          console.log("this.state.goingToDateValue", this.state.goingToDateValue)

        });
      }
    } else {
      console.log("value", value)
      let goingToDateValue = this.state.goingToDateValue;
      let dayDifference = differenceInCalendarDays(
        new Date(this.state.goingToDateValue),
        new Date(value)
      );
      if (dayDifference < 0) {
        goingToDateValue = cf.getDateWithFormat(
          addDays(new Date(value), 7),
          this?.props?.dateFormat
        );
      }
      this.setState({
        departureDateValue: cf.getDateWithFormat(
          value,
          this?.props?.dateFormat
        ),
        goingToDateValue,
      }, () => {
        console.log("this.state.departureDateValue", this.state.departureDateValue)
      });
    }
  };

  onclickFlightAutoComplete(type) {
    this.setDropdownWrapperRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    if (type === "departure") {
      this.setState({ departureValue: "", departureAirPortName: "", }, () => {
        this.props.getAutoSuggestData(this.state.departureValue, type, "click");
      });
    } else {
      this.setState({ goingToValue: "", goingToAirPortName: "", }, () => {
        this.props.getAutoSuggestData(this.state.goingToValue, type, "click");
      });
    }
  }

  highlightData(id, parentElement) {
    let innerContent = document.getElementById(parentElement).children;
    for (let i = 0; i < innerContent.length; i++) {
      let selectedElement = innerContent[i];
      selectedElement.style.backgroundColor = i == id ? "#ccc" : "#fff";
    }
    innerContent[id].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }

  onKeyDownArrow = (event, parentClassId, list, type) => {
    // console.log("key up key doun")
    let code = "";
    let name = "";
    let airportName = "";
    let indexDropdown = this.state.indexDropdown;
    switch (event.which) {
      case 38: // up
        indexDropdown--;
        if (indexDropdown < 0) {
          indexDropdown = 0;
        }
        this.highlightData(indexDropdown, parentClassId);
        code =
          list.length >= indexDropdown
            ? `${list[indexDropdown].AIRPORT_CODE}`
            : "";
        name =
          list.length >= indexDropdown
            ? `${list[indexDropdown].CITY_NAME}`
            : "";
        airportName =
          list.length >= indexDropdown
            ? `${list[indexDropdown].AIRPORT_NAME}`
            : "";
        search_type =
          list.length >= indexDropdown
            ? `${list[indexDropdown].search_type
              ? list[indexDropdown].search_type
              : 1
            }`
            : "";
        break;
      case 40: // down
        indexDropdown++;
        if (indexDropdown >= list.length - 1) {
          indexDropdown = list.length - 1;
        }
        this.highlightData(indexDropdown, parentClassId);
        code =
          list.length >= indexDropdown
            ? `${list[indexDropdown].AIRPORT_CODE}`
            : "";
        name =
          list.length >= indexDropdown
            ? `${list[indexDropdown].CITY_NAME}`
            : "";
        airportName =
          list.length >= indexDropdown
            ? `${list[indexDropdown].AIRPORT_NAME}`
            : "";
        search_type =
          list.length >= indexDropdown
            ? `${list[indexDropdown].search_type
              ? list[indexDropdown].search_type
              : 1
            }`
            : "";
        break;

      case 13: //enter
        if (this.state.code && this.state.name) {
          this.onClickSetDepartureGoingToValue(
            this.state.name,
            this.state.code, this.state.airportName,
            type
          );
        }
        if (type === "departure") {
          this.setState({ expandDepartureBoolean: false });
        }
        if (type === "goingTo") {
          this.setState({ expandGoingToBoolean: false });
        }
        indexDropdown = -1;
        break;
      case 9: //enter
        if (this.state.code && this.state.name) {
          this.onClickSetDepartureGoingToValue(
            this.state.name,
            this.state.code, this.state.airportName,
            type
          );
        }
        if (type === "departure") {
          this.setState({ expandDepartureBoolean: false });
        }
        if (type === "goingTo") {
          this.setState({ expandGoingToBoolean: false });
        }
        indexDropdown = -1;
        break;
      default:
    }
    this.setState({ code: code, name: name, airportName: airportName, indexDropdown: indexDropdown });
  };

  modifySearchKeyDownHandler = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    this.onClickSearchFlight();
  };

  onSwapKeyDownHandler = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    this.onClickSwap();
  };

  onClickTraveller = () => {
    if (!this.state.openTraveler) {
      this.setState({ openTraveler: true });
    } else {
      this.setState({ openTraveler: false });
    }
  };

  onClickAirlines = () => {
    if (!this.state.openAirlines) {
      this.setState((prevState) => {
        return {
          ...prevState,
          openAirlines: true,
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          openAirlines: false,
        };
      });
    }
  };

  handleOnChangeAirlines = () => {
    if (!this.state.isAirlinesChecked) {
      this.setState({ isAirlinesChecked: true });
    } else {
      this.setState({ isAirlinesChecked: false });
    }
  };

  onAirlineSelect = (event) => {
    if (
      event.target.checked &&
      !this.state.selectedAirlines.includes(event.target.value)
    ) {
      this.setState({
        selectedAirlines: [...this.state.selectedAirlines, event.target.value],
      });
    } else {
      this.setState({
        selectedAirlines: this.state.selectedAirlines.filter((value) => {
          // console.log(event.target.value);
          return value !== event.target.value;
        }),
      });
    }
    // console.log(event.target.value,event.target.checked,this.state.selectedAirlines)
  };

  deleteReturnDateHandler = () => {
    this.setState({ isOnewaySearch: true, expandReturnDateBoolean: false });
  }

  removeReturnDateHandler = (e) => {
    if (e.key !== "Enter") {
      return
    }
    this.deleteReturnDateHandler()
  }

  handleStopCheck = (event) => {
    // console.log(event.target.name,"name");
    this.setState({ [event.target.name]: event.target.checked });
  };

  applyb2bFilters = (filterName, event, checkbox) => {
    switch (filterName) {
      case "stops":
        // console.log('this', event)
        const { value, checked } = event.target;
        // console.log(`${value} is ${checked}`);
        if (checked && !this.state.stops.includes(event.target.value)) {
          this.setState({
            stops: [...this.state.stops, value],
          });
        } else {
          this.setState({
            stops: this.state.stops.filter((event) => event !== value),
          });
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div
        className="container mx-auto xl:px-8"
        ref={this.setDropdownWrapperRef}
      >
        <div className={this.props.styles.flight_search_wid_container}>
          <div className="flight_search_wid_main">
            <div className={this.props.styles.flight_way_tab}>
              <div className={this.props.styles.flight_way_btngrp}>
                <button
                  className={
                    this.state.isOnewaySearch
                      ? this.props.styles.active_way
                      : this.props.styles.button_style
                  }
                  onClick={() => {
                    this.setState({
                      isOnewaySearch: true,
                      expandGoingToDateBoolean: false,
                      expandDepartureDateBoolean: false,
                    });
                  }}
                >
                  One-Way
                </button>
                <button
                  className={
                    this.state.isOnewaySearch === false
                      ? this.props.styles.active_way
                      : this.props.styles.button_style
                  }
                  onClick={() => {
                    this.setState({
                      isOnewaySearch: false,
                      goingToDateValue: cf.getDateWithFormat(
                        addDays(new Date(this.state.departureDateValue), 7),
                        this?.props?.dateFormat
                      ),
                    });
                  }}
                >
                  Round-Way
                </button>
                {/* <div>{this.props.multiSelectDropdown()}</div> */}
                {this.props?.userProfile?.userType === "B2B" && (
                  <B2BWidgets
                    styles={this.props.b2bStyles}
                    {...this.state}
                    onClickTraveller={this.onClickTraveller}
                    onClickAirlines={this.onClickAirlines}
                    removeGuestId={this.rmGuestId}
                    handleOnChangeAirlines={this.handleOnChangeAirlines}
                    applyb2bFilters={this.applyb2bFilters}
                    addGuestId={this.adGuestId}
                    listWrapper={this.advanceSearchWrapper}
                    guestWrapper={this.guestDetailWrapper}
                    // multiSelectDropdown={
                    //   this.props.isOffline === false
                    //     ? this.props.multiSelectDropdown()
                    //     : ""
                    // }
                    handleStopCheck={this.handleStopCheck}
                    isOffline={this.props.isOffline}
                    errorMessage={"Select Traveler"}
                    isError={this.state.isError.selectedGuest}
                  />
                )}
              </div>
            </div>
            <div className={this.props.styles.flight_search_details_container}>
              <div className={this.props.styles.flight_search_details_main}>
                <div className={this.props.styles.flight_search_details_row}>
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_depart
                    }
                  >
                    <FlightAutoComplete
                      label="Origin"
                      value={this.state.departureValue}
                      styles={this.props.styles}
                      onclickFlightAutoComplete={() =>
                        this.onclickFlightAutoComplete("departure")
                      }
                      placholder="City or Airport"
                      onClickSourceAndDestination={(city, airPortCode, airPortName) =>
                        this.onClickSetDepartureGoingToValue(
                          city,
                          airPortCode,
                          airPortName,
                          "departure"
                        )
                      }
                      onChangeSourceAndDestination={(value) =>
                        this.onChangeSetDepartureGoingToValue(
                          value,
                          "departure"
                        )
                      }
                      isAutoCompleteExpand={(bool) =>
                        this.setState({ expandDepartureBoolean: bool })
                      }
                      autoCompleteData={
                        !cf.isValueEmpty(this.props.getDepartureSuggestionList)
                          ? this.props.getDepartureSuggestionList
                          : []
                      }
                      expandAutoCompleteBool={
                        !cf.isValueEmpty(this.state.expandDepartureBoolean)
                          ? this.state.expandDepartureBoolean
                          : false
                      }
                      leftIcon={
                        <svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30"><g id="Group_1400" data-name="Group 1400" transform="translate(-9574.354 -279.94)"><path id="Path_726" data-name="Path 726" d="M88.156,98.443l13.937-4.429a1.536,1.536,0,0,0,1-.975,1.647,1.647,0,0,0-.528-1.767,3.682,3.682,0,0,0-3.738-.67l-3.251,1.239L91.1,89.078a.374.374,0,0,0-.325-.041l-1.93.61a.392.392,0,0,0-.163.65L91.671,93.3l-4.124,1.564L85.8,93.73a.374.374,0,0,0-.325-.041l-1.32.427a.4.4,0,0,0-.2.589L86.1,97.753a1.821,1.821,0,0,0,2.052.691Z" transform="translate(9490.469 190.924)" fill="#616161"></path><path id="Path_727" data-name="Path 727" d="M121.631,412.16H105.215a1.056,1.056,0,1,0,0,2.113h16.415a1.056,1.056,0,1,0,0-2.113Z" transform="translate(9470.931 -120.497)" fill="#616161"></path></g></svg>
                      }
                      errorMessage={"Enter Origin City"}
                      isError={this.state.isError.departure}
                      listWrapper={this.departureListWrapper}
                      onKeyDownArrow={(event) => {
                        this.onKeyDownArrow(
                          event,
                          "flightDataArray",
                          this.props.getDepartureSuggestionList,
                          "departure"
                        );
                      }}
                      airPortName={this.state.departureAirPortName}
                    />
                    <div className={this.props.styles.flight_ptext}>
                    </div>
                  </div>
                  {/* <img src="/images/home-fgt.png" class={this.props.styles.flight_swap} alt="flight arrow" onClick={() => { this.onClickSwap() }}></img> */}
                  <span
                    className={this.props.styles.flight_swap}
                    tabIndex="2"
                    onKeyDown={this.onSwapKeyDownHandler}
                    onClick={() => {
                      this.onClickSwap();
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      width="30px"
                      height="30px"
                      viewBox="0 0 30 30"
                      enableBackground="new 0 0 30 30"
                    >
                      <g id="Group_1402" data-name="Group 1402" transform="translate(-200.5 -195.5)">
                        <rect id="Rectangle_9" data-name="Rectangle 9" width="28" height="28" rx="8" transform="translate(201 196)" fill="#fff" stroke="#e72643" stroke-width="1" />
                        <path id="Path_90" data-name="Path 90" d="M543.9,906.055h15.23l-4.035-4.494" transform="translate(-336.163 -697.263)" fill="none" stroke="#707070" stroke-width="1" />
                        <path id="Path_91" data-name="Path 91" d="M0,4.494H15.23L11.194,0" transform="translate(222.97 215.702) rotate(180)" fill="none" stroke="#707070" stroke-width="1" />
                      </g>
                    </svg>
                  </span>
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_depart
                    }
                  >
                    <FlightAutoComplete
                      label="Destination"
                      value={this.state.goingToValue}
                      styles={this.props.styles}
                      onclickFlightAutoComplete={() =>
                        this.onclickFlightAutoComplete("goingTo")
                      }
                      placholder="City or Airport"
                      onClickSourceAndDestination={(city, airPortCode, airPortName) =>
                        this.onClickSetDepartureGoingToValue(
                          city,
                          airPortCode,
                          airPortName,
                          "goingTo"
                        )
                      }
                      onChangeSourceAndDestination={(value) =>
                        this.onChangeSetDepartureGoingToValue(value, "goingTo")
                      }
                      isAutoCompleteExpand={(bool) =>
                        this.setState({ expandGoingToBoolean: bool })
                      }
                      autoCompleteData={
                        !cf.isValueEmpty(this.props.getGoingToSuggestionList)
                          ? this.props.getGoingToSuggestionList
                          : []
                      }
                      expandAutoCompleteBool={
                        !cf.isValueEmpty(this.state.expandGoingToBoolean)
                          ? this.state.expandGoingToBoolean
                          : false
                      }
                      leftIcon={
                        <svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30"><g id="Group_1401" data-name="Group 1401" transform="translate(-245.5 -158.334)"><g id="Group_660" data-name="Group 660" transform="translate(-9327.768 -116.501)"><path id="Path_726" data-name="Path 726" d="M88.156,98.443l13.937-4.429a1.536,1.536,0,0,0,1-.975,1.647,1.647,0,0,0-.528-1.767,3.682,3.682,0,0,0-3.738-.67l-3.251,1.239L91.1,89.078a.374.374,0,0,0-.325-.041l-1.93.61a.392.392,0,0,0-.163.65L91.671,93.3l-4.124,1.564L85.8,93.73a.374.374,0,0,0-.325-.041l-1.32.427a.4.4,0,0,0-.2.589L86.1,97.753a1.821,1.821,0,0,0,2.052.691Z" transform="translate(9549.887 155.802) rotate(30)" fill="#616161"></path><path id="Path_727" data-name="Path 727" d="M121.631,412.16H105.215a1.056,1.056,0,1,0,0,2.113h16.415a1.056,1.056,0,1,0,0-2.113Z" transform="translate(9470.931 -120.497)" fill="#616161"></path></g></g></svg>
                      }
                      errorMessage={
                        this.state.goingToValue !== "" &&
                          this.state.departureValue === this.state.goingToValue
                          ? "Origin and destination cannot be same"
                          : "Enter Destination City"
                      }
                      isError={this.state.isError.goingTo}
                      listWrapper={this.returnListWrapper}
                      onKeyDownArrow={(event) => {
                        this.onKeyDownArrow(
                          event,
                          "flightDataArray",
                          this.props.getGoingToSuggestionList,
                          "goingTo"
                        );
                      }}
                      airPortName={this.state.goingToAirPortName}
                    />
                    <div className={this.props.styles.flight_ptext}>
                    </div>
                  </div>

                  {/* Calender One */}
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_depart_going_date
                    }
                  >


                    {/* newcalender */}

                
                    <DesktopCalender
                      styles={this.props.styles}
                      value={
                        !cf.isValueEmpty(this.state.departureDateValue)
                          ? this.state.departureDateValue + this.state.goingToDateValue
                          : ""
                      }
                      departureValue={!cf.isValueEmpty(this.state.departureDateValue) ? this.state.departureDateValue : ""}
                      returnValue={!cf.isValueEmpty(this.state.goingToDateValue) && this.state.goingToDateValue !== "NA" ? this.state.goingToDateValue : "Tap to add return"}
                      departureDate={this.state.departureDateValue}
                      onChangeDate={(value, type) => {
                        this.onChangeDepartureGoingDateValue(
                          value,
                          type
                        );
                      }}
                      iconImage={
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="35px"
                          height="35px"
                          viewBox="0 0 35 35"
                          enableBackground="new 0 0 35 35"
                        >
                          <g id="Group_741" data-name="Group 741" transform="translate(-1010 -888.5)">
                            <path id="calendar_icn" d="M28.583,0H3.707A3.6,3.6,0,0,0,0,3.744V28.7a3.63,3.63,0,0,0,3.707,3.8H28.583A3.818,3.818,0,0,0,32.5,28.7V3.744A3.749,3.749,0,0,0,28.583,0ZM3.707,1.354H28.583a2.426,2.426,0,0,1,2.562,2.39V9.479H1.354V3.744A2.245,2.245,0,0,1,3.707,1.354Zm9.835,18.958V16.25h5.417v4.063Zm5.417,1.354v4.062H13.542V21.667Zm-6.771-1.354H6.771V16.25h5.417Zm8.125-4.063h5.416v4.063H20.312Zm0-1.354V10.834h5.416V14.9Zm-1.354,0H13.542V10.834h5.417Zm-6.771,0H6.771V10.834h5.417Zm-6.771,0H1.354V10.834H5.417Zm0,1.354v4.063H1.354V16.25Zm0,5.417v4.062H1.354V21.667Zm1.354,0h5.417v4.062H6.771Zm5.417,5.417v4.063H6.771V27.083Zm1.354,0h5.417v4.063H13.542Zm6.771,0h5.416v4.063H20.312Zm0-1.354V21.667h5.416v4.062Zm6.771-4.062h4.063v4.062H27.083Zm0-1.354V16.25h4.063v4.063Zm0-5.417V10.834h4.063V14.9ZM1.354,28.7V27.083H5.417v4.063H3.707A2.3,2.3,0,0,1,1.354,28.7Zm27.229,2.445h-1.5V27.083h4.063V28.7A2.45,2.45,0,0,1,28.583,31.146Z" transform="translate(1010 888.5)" fill="#3479c5" />
                            <path id="Rectangle_22" data-name="Rectangle 22" d="M3,0H29a3,3,0,0,1,3,3V15a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(1010 889)" fill="#3479c5" />
                            <path id="Path_161" data-name="Path 161" d="M14.3,0H0" transform="translate(1032.78 897.708) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
                            <path id="Path_163" data-name="Path 163" d="M2.475,0,0,2.715,2.475,5.19h0" transform="translate(1033.864 900.303) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
                          </g>
                        </svg>
                      }
                      startDate={
                        !cf.isValueEmpty(this.state.departureDateValue)
                          ? this.state.departureDateValue
                          : ""
                      }
                      endDate={
                        !cf.isValueEmpty(this.state.goingToDateValue)
                          ? this.state.goingToDateValue
                          : ""
                      }
                      startDateLimit={
                        !cf.isValueEmpty(this.state.dateLimit)
                          ? this.state.dateLimit
                          : ""
                      }
                      dateFormat={"DD-MMM-YYYY"}
                      changeVisibility={(bool,type) =>
                        this.onchangeVisibility(bool, type)
                      }
                      isVisible={this.state.isCalenderVisible}
                      type={"double"}
                      searchType={this.state.isOnewaySearch ? "oneWay" : "roundTrip"}
                      oneWayReturnClick={() => { this.setState({ isOnewaySearch: false, isCalenderVisible: true }) }}
                      roundWayReturnClick= {()=>{  this.setState({ isOnewaySearch: true})}}
                      showCrossIcon ={true}
                    />
                    {/* new calender end */}


                    {/* cabin slection dropdown */}
                    <div
                      className={this.props.styles.flight_cabin_class_cont}
                      onClick={() => this.onClickOpenCabinClassListOne()}
                    >
                      <div
                        className={this.props.styles.flight_cabin_class_name}
                      >
                        {!cf.isValueEmpty(this.state.SelectedClassValue)
                          ? this.state.SelectedClassValue.label
                          : "Economy"}
                      </div>
                      <div
                        className={
                          this.props.styles.flight_cabin_class_arrow_pass
                        }
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <path id="Path_1101" data-name="Path 1101" d="M339.293,85.293l1.414,1.414L334,93.414l-6.707-6.707,1.414-1.414L334,90.586Z" transform="translate(-327.293 -85.293)" fill-rule="evenodd" />
                        </svg>
                      </div>
                      {this.state.openCabinClassListOne === true ? (
                        <div
                          ref={this.economydeparturePopUpWrapper}
                          className={this.props.styles.flight_cabin_class_list}
                        >
                          {cabinClassOne.map((element, index) => {
                            return (
                              <div
                                key={"cabinClass" + index}
                                className={
                                  this.props.styles.flight_cabin_class_ele
                                }
                                onClick={() => {
                                  this.setState({
                                    SelectedClassValue: {
                                      label: element.label,
                                      id: element.id,
                                    },
                                    openCabinClassListOne: false,
                                  });
                                }}
                              >
                                {element.label}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>

                    {/* cabin selection label */}

                    {!this.state.isOnewaySearch ?
                      // <div
                      //       className={this.props.styles.flight_cabin_class_name}
                      //     >
                      //     {!cf.isValueEmpty(this.state.SelectedClassValue)
                      //         ? this.state.SelectedClassValue.label
                      //         : "Economy"}
                      //     </div>
                      <div
                        className={this.props.styles.flight_cabin_class_cont}
                      // onClick={() => this.onClickOpenCabinClassListOne()}
                      >
                        <div
                          className={this.props.styles.flight_cabin_class_name}
                        >
                          {!cf.isValueEmpty(this.state.SelectedClassValue)
                            ? this.state.SelectedClassValue.label
                            : "Economy"}
                        </div>
                        <div
                          className={
                            this.props.styles.flight_cabin_class_arrow_pass
                          }
                        >
                          {/* <svg
                                  version="1.1"
                                  id="Layer_1"
                                  x="0px"
                                  y="0px"
                                  width="30px"
                                  height="30px"
                                  viewBox="0 0 30 30"
                                  enableBackground="new 0 0 30 30"
                                >
                                  <path id="Path_1101" data-name="Path 1101" d="M339.293,85.293l1.414,1.414L334,93.414l-6.707-6.707,1.414-1.414L334,90.586Z" transform="translate(-327.293 -85.293)" fill-rule="evenodd"/>
                                </svg> */}
                        </div>
                        {this.state.openCabinClassListOne === true ? (
                          <div
                            ref={this.economydeparturePopUpWrapper}
                            className={this.props.styles.flight_cabin_class_list}
                          >
                            {cabinClassOne.map((element, index) => {
                              return (
                                <div
                                  key={"cabinClass" + index}
                                  className={
                                    this.props.styles.flight_cabin_class_ele
                                  }
                                  onClick={() => {
                                    this.setState({
                                      SelectedClassValue: {
                                        label: element.label,
                                        id: element.id,
                                      },
                                      openCabinClassListOne: false,
                                    });
                                  }}
                                >
                                  {element.label}
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                      :
                      null
                    }
                  </div>




                  {/* Pax Select */}
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_class
                    }
                  >
                    <div
                      className={this.props.styles.flight_cabin_class_cont}
                      onClick={() => this.onClickOpenPassangerList()}
                    >
                      <div
                        className={this.props.styles.flight_cabin_class_name}
                      >
                        {`${this.state.paxAdultValue + this.state.paxChildrenValue + this.state.paxInfantValue}`}&nbsp;Passenger
                      </div>
                      <div
                        className={
                          this.props.styles.flight_cabin_class_arrow_pass_last
                        }
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="20px"
                          height="20px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <path id="Path_1101" data-name="Path 1101" d="M339.293,85.293l1.414,1.414L334,93.414l-6.707-6.707,1.414-1.414L334,90.586Z" transform="translate(-327.293 -85.293)" fill-rule="evenodd" />
                        </svg>
                      </div>
                    </div>
                    {this.state.openPaxList === true ? (
                      <div
                        className={
                          this.props.styles.flight_search_pax_select_main
                        }
                        ref={this.passengerDetailWrapper}
                      >
                        <div
                          className={
                            this.props.styles.flight_search_column +
                            " " +
                            this.props.styles.flight_search_pax
                          }
                        >
                          <div className={this.props.styles.flight_search_pax_newleft}>
                            <label>Adult</label>
                            <span>(+12 Years)</span>
                          </div>
                          <PaxConfig
                            value={
                              !cf.isValueEmpty(this.state.paxAdultValue)
                                ? this.state.paxAdultValue
                                : 0
                            }
                            onChangePaxValue={(value, type) =>
                              this.onClickChangePaxNumber("adult", value, type)
                            }
                            maxLimit={9}
                            minLimit={1}
                            totalPax={
                              +this.state.paxChildrenValue +
                              +this.state.paxAdultValue
                            }
                            styles={this.props.styles}
                          />

                        </div>

                        <div
                          className={
                            this.props.styles.flight_search_column +
                            " " +
                            this.props.styles.flight_search_pax
                          }
                        >
                          <div className={this.props.styles.flight_search_pax_newleft}>
                            <label>Children</label>
                            <span>(2-11 Years)</span>
                          </div>
                          <PaxConfig
                            value={
                              !cf.isValueEmpty(this.state.paxChildrenValue)
                                ? this.state.paxChildrenValue
                                : 0
                            }
                            onChangePaxValue={(value, type) =>
                              this.onClickChangePaxNumber(
                                "children",
                                value,
                                type
                              )
                            }
                            maxLimit={9}
                            minLimit={0}
                            totalPax={
                              +this.state.paxChildrenValue +
                              +this.state.paxAdultValue
                            }
                            styles={this.props.styles}
                          />
                        </div>

                        <div
                          className={
                            this.props.styles.flight_search_column +
                            " " +
                            this.props.styles.flight_search_pax
                          }
                        >
                          <div className={this.props.styles.flight_search_pax_newleft}>
                            <label>Infant</label>
                            <span>(Below 2 Years)</span>
                          </div>
                          <PaxConfig
                            value={
                              !cf.isValueEmpty(this.state.paxInfantValue)
                                ? this.state.paxInfantValue
                                : 0
                            }
                            onChangePaxValue={(value, type) =>
                              this.onClickChangePaxNumber("infant", value, type)
                            }
                            maxLimit={+this.state.paxAdultValue}
                            minLimit={0}
                            totalPax={this.state.paxInfantValue}
                            styles={this.props.styles}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* Search Button*/}
                  <div className={this.props.styles.flight_search_class_new}>
                    <button
                      className={this.props.styles.flight_search_widget_btn}
                      tabIndex="2"
                      onKeyDown={this.modifySearchKeyDownHandler}
                      onClick={() => this.onClickSearchFlight()}
                    >

                      <svg width="50px" height="50px" viewBox="0,0,256,256">
                        <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none">
                          <g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                          </g></g>
                      </svg>
                      {this.props.isSRP ? "Modify" : "Search"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}