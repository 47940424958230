import React, { useEffect, useState, useRef } from 'react'
import style from './multiSelectDropDown.module.css'
function multiSelectDropDown(props) {
    const box = useRef(null);
    const dropdownRef = useRef(null);
    const { inputPlaceHolder, dropdownInputPlaceHolder, options, setoptions } = props

    const [dropdownVisible, setdropdownVisible] = useState(false)
    const [dropdownOptions, setdropdownOptions] = useState(options)
    const [inputValue, setinputValue] = useState("")
    const [subInputVal, setsubInputVal] = useState("")
    const [dropdownDirection, setdropdownDirection] = useState('down')

    useEffect(() => {
        if (dropdownVisible) {
            updateDropdownDirection();
        }
    }, [dropdownVisible]);

    useEffect(() => {
        filterOptions(subInputVal)
        let checkedOptArr = options.filter(option => option.isChecked)
        let inputVal = checkedOptArr.reduce(
            (accumulator, currentValue) => accumulator + "," + currentValue.label,
            "");
        if (inputVal.indexOf("All") > -1) {
            inputVal = inputVal.replace('All,', '')
        }
        setinputValue(inputVal.replace(',', ''))
    }, [options])

    useEffect(() => {
        function handleOutsideClick(event) {
            if (box.current && !box.current.contains(event.target)) {
                setdropdownVisible(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateDropdownDirection);
        updateDropdownDirection();

        return () => window.removeEventListener('resize', updateDropdownDirection);
    }, [])

    function updateDropdownDirection() {
        const dropdown = dropdownRef.current;
        if (dropdown && props.isMobile) {
            const rect = dropdown.getBoundingClientRect();
            const spaceBelow = window.innerHeight - rect.bottom;
            const spaceAbove = rect.top;

            if (spaceBelow < rect.height) {
                setdropdownDirection('up');
            } else {
                setdropdownDirection('down');
            }
        }
    }

    function filterOptions(value) {
        setsubInputVal(value)
        let tempOptions = JSON.parse(JSON.stringify(options))
        if (value) {
            tempOptions = dropdownOptions.filter((option) => option.label.toLowerCase().startsWith(value.toLowerCase()))
        }
    
       setdropdownOptions(tempOptions)
    }

    function selectionHandler(value) {

        let tempOptions = JSON.parse(JSON.stringify(dropdownOptions));
        let optionIndex = tempOptions.findIndex(option => option.value == value);
        let tempOptionsCopy = JSON.parse(JSON.stringify(options));
        let optionIndexCopy = tempOptionsCopy.findIndex(option => option.value == value);
        tempOptions[optionIndex].isChecked = !tempOptions[optionIndex].isChecked;
        tempOptionsCopy[optionIndexCopy].isChecked = !tempOptionsCopy[optionIndexCopy].isChecked;
        if (value == "*") {
            for (let i = 0; i < tempOptions.length; i++) {
                tempOptions[i].isChecked = tempOptions[optionIndex].isChecked
            }
            for (let i = 0; i < tempOptionsCopy.length; i++) {
                tempOptionsCopy[i].isChecked = tempOptions[optionIndex].isChecked
            }
        }
        else{
            if(tempOptions.some(op => op.value == "*")){
                tempOptions[0].isChecked = false
                tempOptionsCopy[0].isChecked = false
            }
       
        }

        let checkedOptArr = tempOptionsCopy.filter(option => option.isChecked)
        let inputVal = checkedOptArr.reduce(
            (accumulator, currentValue) => accumulator + "," + currentValue.label,
            "");
        if (inputVal.indexOf("All") > -1) {
            inputVal = inputVal.replace('All,', '')
        }
        setinputValue(inputVal.replace(',', ''))
        setdropdownOptions(tempOptions)
        setoptions(tempOptionsCopy)
    }

    const dropdownStyle = {
        position: 'absolute',
        [dropdownDirection]: '100%',
        top: dropdownDirection === 'up' ? 'auto' : '100%',
        bottom: dropdownDirection === 'down' ? 'auto' : '100%',
    };

    return (
        <div className={style.dropdown_container} ref={box}>
            <span className={style.dropdown_Flx}>
                <input type='text' placeholder={inputPlaceHolder} value={inputValue || ""} onClick={() => { setdropdownVisible(!dropdownVisible)  ; filterOptions("") } } style={{caretColor: 'transparent', cursor: 'pointer'}} />
                <span style={{cursor:"pointer"}} onClick={() => { setdropdownVisible(!dropdownVisible)  ; filterOptions("") } }><svg xmlns="http://www.w3.org/2000/svg" width="14" height="8"><path fill-rule="evenodd" fill="#01445F" d="M11.606.458c.704-.008 1.103.082 1.515.021a1.073 1.073 0 0 1-.021 1.517L7.746 7.198l-.747-.769.748.772a1.072 1.072 0 0 1-1.554-.061L.942 1.996A1.074 1.074 0 0 1 .921.479C1.334.54 2.12.45 2.437.458L7.2 4.931 11.606.458z"></path></svg></span>
                {/* <img
                          src="/arrow-down-wid.svg"
                          alt=""
                        /> */}
            </span>

            {dropdownVisible ?
                          <div className={`${style.dropDownlistmain} ${dropdownDirection === 'up' ? style.dropdown_above : ''}`} ref={dropdownRef}>
                            <ul>
                                {props.inputPlaceHolder !== "Supplier" &&
                            <li className='adv-type-here'><input type="text" id={'name-input'} placeholder={dropdownInputPlaceHolder} value={subInputVal} onChange={(e) => { filterOptions(e.target.value);  }} /></li>}
                            </ul>
                    <ul className={style.dropdown_value_container}>

                        {dropdownOptions.length ? dropdownOptions.map((option) => {
                            return (
                                <li>
                                    {/* <input type='checkbox' className={style.pointer} id={option.value} checked={option?.isChecked ? true : false} onChange={(e) => { selectionHandler(option.value); }} />
                                    <span>{option.label}</span> */}
                                    <label className={style.checkbox_label}>
                                        <span className={style.bagCheckbox}>
                                            <input type="checkbox" className={style.pointer} id={option.value} checked={option?.isChecked ? true : false} onChange={(e) => { selectionHandler(option.value); }} />
                                            <span className={style.bagBox}>
                                            <span className={style.bagCheck}></span>
                                            </span>
                                        </span>
                                        <span className={style.advTitle}>{option.label}</span>
                                    </label>
                                </li>
                                
                            )
                        }) :
                        <p className={style.no_result_found}> No Results Found !!</p>}
                    </ul>
                </div>
                : null
            }
        </div>
    )
}

export default multiSelectDropDown