

export const getRecentSearchHotel = (req)=>{
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    console.log('POST!!!!!!!', requestOptions)
    return fetch('/shared/api/hotelRecentSearch',requestOptions).then((response)=>response.json())
}
