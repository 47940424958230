import React, { Component } from 'react';

// import this.props.style from './multiSelectDropDown.module.css';

class multiSelectDropDownMobile extends Component {
  constructor(props) {
    super(props);
    console.log(props,"optiofgfgfdgfdns");
    this.box = React.createRef();
    this.state = {
      dropdownVisible: false,
      dropdownOptions: props.options,
      inputValue: "",
      reRender:false,
      subInputVal:""
    };
  }

  componentDidMount() {
    const { options } = this.props;
    this.setState({ dropdownOptions: options });
    this.updateInputValue(options);
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillReceiveProps(nextProps){

          this.setState({   reRender:!this.state.reRender , dropdownOptions: nextProps.options} ,()=>{
            this.updateInputValue()
          })
          
  }
  handleOutsideClick = (event) => {
    if (this.box.current && !this.box.current.contains(event.target)) {
      this.setState({ dropdownVisible: false });
    }
  };

  updateInputValue = () => {
    const { dropdownOptions } = this.state;
    this.filterOptions(this.state.subInputVal)
    // console.log("dropdownOptionsdrty",dropdownOptions)
    let checkedOptArr = dropdownOptions?.filter((option) => option.isChecked);
    let inputVal = checkedOptArr.reduce(
      (accumulator, currentValue) => accumulator + "," + currentValue.label,
      ""
    );
    if (inputVal.indexOf("All") > -1) {
      inputVal = inputVal.replace('All,', '');
    }
    this.setState({ inputValue: inputVal.replace(/^,|,$/g,'')  },()=>{
      // console.log("state.inputValue",this.state.inputValue)
    });
  };
  
  

  filterOptions = (value) => {
    this.setState({ subInputVal: value });

    const { options } = this.props;
    let tempOptions = JSON.parse(JSON.stringify(options));
    if (value) {
      tempOptions = tempOptions.filter((option) =>
        option.label.toLowerCase().startsWith(value.toLowerCase())
      );
    }
    this.setState({ dropdownOptions: tempOptions });
  };

  // selectionHandler = (value) => {
  //   const { options, setoptions } = this.props;
  //   let tempOptions = JSON.parse(JSON.stringify(this.state.dropdownOptions));
  //   let optionIndex = tempOptions.findIndex((option) => option.value === value);
  //   let tempOptionsCopy = JSON.parse(JSON.stringify(options));
  //   let optionIndexCopy = tempOptionsCopy.findIndex(
  //     (option) => option.value === value
  //   );
  //   tempOptions[optionIndex].isChecked = !tempOptions[optionIndex].isChecked;
  //   tempOptionsCopy[optionIndexCopy].isChecked = !tempOptionsCopy[
  //     optionIndexCopy
  //   ].isChecked;
  //   if (value === "*") {
  //     for (let i = 0; i < tempOptions.length; i++) {
  //       tempOptions[i].isChecked = tempOptions[optionIndex].isChecked;
  //     }
  //     for (let i = 0; i < tempOptionsCopy.length; i++) {
  //       tempOptionsCopy[i].isChecked = tempOptions[optionIndex].isChecked;
  //     }
  //   }

  //   let checkedOptArr = tempOptionsCopy.filter((option) => option.isChecked);
  //   let inputVal = checkedOptArr.reduce(
  //     (accumulator, currentValue) => accumulator + "," + currentValue.label,
  //     ""
  //   );
  //   if (inputVal.indexOf("All") > -1) {
  //     inputVal = inputVal.replace('All,', '');
  //   }
  //   this.setState({ inputValue: inputVal.replace(',', '') });
  //   this.setState({ dropdownOptions: tempOptions });
  //   setoptions(tempOptionsCopy);
  // };

  selectionHandler = (value) => {
    const { options, setoptions } = this.props;
    let tempOptions = JSON.parse(JSON.stringify(this.state.dropdownOptions));
    let optionIndex = tempOptions.findIndex((option) => option.value === value);
    let tempOptionsCopy = JSON.parse(JSON.stringify(options));
    let optionIndexCopy = tempOptionsCopy.findIndex(
      (option) => option.value === value
    );
  
    tempOptions[optionIndex].isChecked = !tempOptions[optionIndex].isChecked;
  
    // if (Array.isArray(tempOptionsCopy)) {
      tempOptionsCopy[optionIndexCopy].isChecked = !tempOptionsCopy[optionIndexCopy].isChecked;
  
      if (value === "*") {
        for (let i = 0; i < tempOptions.length; i++) {
          tempOptions[i].isChecked = tempOptions[optionIndex].isChecked;
        }
        for (let i = 0; i < tempOptionsCopy.length; i++) {
          tempOptionsCopy[i].isChecked = tempOptions[optionIndex].isChecked;
        }
      }
      else{
        if(tempOptions.some(op => op.value == "*")){
            tempOptions[0].isChecked = false
            tempOptionsCopy[0].isChecked = false
        }
   
    }
  
      let checkedOptArr = tempOptionsCopy.filter((option) => option.isChecked);
      let inputVal = checkedOptArr
        .reduce(
          (accumulator, currentValue) => accumulator + "," + currentValue.label,
          ""
        )
        .replace(/,+/g, "");
      if (inputVal.indexOf("All") > -1) {
        inputVal = inputVal.replace("All", "");
      }
  
      this.setState({ inputValue: inputVal });
      this.setState({ dropdownOptions: tempOptions });
      setoptions(tempOptionsCopy);
    // }
  };
  

  render() {
    const { inputPlaceHolder, dropdownInputPlaceHolder } = this.props;
    const { dropdownVisible, dropdownOptions, inputValue } = this.state;

    return (
      <div ref={this.box} className={this.props.style?.drop_wd}>
        <span className={this.props.style?.dropdown_Flx}>
          <input
            type='text'
            placeholder={inputPlaceHolder}
            value={inputValue || ""}
            onClick={() => {
              this.setState((prevState) => ({
                dropdownVisible: !prevState.dropdownVisible,
              }));
               this.filterOptions("") 
            }}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8"><path fill-rule="evenodd" fill="#01445F" d="M11.606.458c.704-.008 1.103.082 1.515.021a1.073 1.073 0 0 1-.021 1.517L7.746 7.198l-.747-.769.748.772a1.072 1.072 0 0 1-1.554-.061L.942 1.996A1.074 1.074 0 0 1 .921.479C1.334.54 2.12.45 2.437.458L7.2 4.931 11.606.458z"></path></svg>
          {/* <img src="/arrow-down-wid.svg" alt='' /> */}
        </span>

        {dropdownVisible && (
          <div className={this.props.style.dropDownlistmain}>
            <ul>
              {inputPlaceHolder !== "Supplier" && (
                <li className='adv-type-here'>
                  <input
                    type="text"
                    id={'name-input'}
                    placeholder={dropdownInputPlaceHolder}
                    onChange={(e) => {
                      this.filterOptions(e.target.value);
                    }}
                  />
                </li>
              )}
            </ul>
            <ul className={this.props.style.dropdown_value_container}>
              { dropdownOptions.length ? dropdownOptions.map((option) => (
                <li key={option.value}>
                  <label className={this.props.style.checkbox_label}>
                    <span className={this.props.style.bagCheckbox}>
                      <input
                        type="checkbox"
                        className={this.props.style.pointer}
                        id={option.value}
                        value={this.state.subInputVal}
                        checked={option.isChecked}
                        onChange={() => {
                          this.selectionHandler(option.value);
                        }}
                      />
                      <span className={this.props.style.bagBox}>
                        <span className={this.props.style.bagCheck}></span>
                      </span>
                    </span>
                    <span className={this.props.style.advTitle}>{option.label}</span>
                  </label>
                </li>
              )) : 
              <p> No Results Found !!</p>}

            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default multiSelectDropDownMobile;