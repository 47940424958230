
export const getStorage = (key) => {
    // console.log("getStorage==>",key)
    let storageValue = localStorage.getItem(key)
    return storageValue
}

export const setStorage = (key,value) => {
    // console.log("setStorage===>",key,value )
    localStorage.setItem(key, value)
    // console.log("set Local storage===>");
}

