import React, { Component } from "react";
import { add, format } from "date-fns";

import B2BWidgets from '../../../b2bwidgets/index';
import DesktopCalender from "../../../calendar/DesktopCalender";
import autoSuggest from "../../../../lib/autoSuggest";
import cf from "../../../../lib/utils/common";
import { es } from "date-fns/locale";

// static 5 cities
const popularCities = [
  {
    destination_id: "11663",
    search_text: "Mumbai",
    location_type: 1,
  },
  {
    destination_id: "11667",
    search_text: "New Delhi",
    location_type: 1,
  },
  {
    destination_id: "11634",
    search_text: "Chennai",
    location_type: 1,
  },
  {
    destination_id: "12633",
    search_text: "Dubai",
    location_type: 1,
  },
  {
    destination_id: "10516",
    search_text: "Paris",
    location_type: 1,
  },
];

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};
const fetchData = async (query, callBackFunc) => {
  if (query != "") {
    const res = await autoSuggest({ searchText: query });
    callBackFunc(res);
  } else {
    callBackFunc(null);
  }
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 400);

export default class Theme1 extends Component {
  constructor(props) {
    super(props);
    this.searchBoxRef = React.createRef();
    this.advanceSearchWrapper = React.createRef()
    this.guestDetailWrapper = React.createRef()
    this.state = {
      activityName: "",
      isAutoSuggestVisible: false,
      checkInOutValue: "",
      checkInValue: null,
      checkOutValue: null,
      isCalendarVisible: false,
      autoSuggestCountryData: [],
      autoSuggestCityData: popularCities,
      selectedAutoSuggestData: null,
      isError: false,
      isDateError: false,
      lastArrowAction: "",
      selectedAutoSuggestIndex: -1,
      advSearch: false,
      isOneStar: false,
      isTwoStar: false,
      isThreeStar: false,
      isFourStar: false,
      isFiveStar: false,
      ratingSelected: [],
      guest_id: "",
      guestError: false,
    };
  }
  onClickAdvSearch = () => {
    if (!this.state.advSearch) {
      this.setState({ advSearch: true })
    } else {
      this.setState({ advSearch: false })
    }
  }
  componentDidMount() {
    const activityname = new URLSearchParams(window.location.search).get(
      "activityname"
    );
    this.onChangeCheckInOutValue(new Date());
    console.log("activityname from query param", activityname);
    if (activityname != null) {
      debouncedFetchData(activityname, (data) => {
        console.log("dataa**********", data);
        if (
          data &&
          data.data &&
          data.data.searchBycity &&
          typeof data.data.searchBycity === "object"
        ) {
          const cityData = [...data.data.searchBycity.result];
          const selectedCity = cityData.filter((city) => {
            return city.search_text == activityname;
          })[0];
          console.log("selectedCity", selectedCity);
          this.setState({
            autoSuggestCityData: cityData,
            isAutoSuggestVisible: false,
            activityName: selectedCity.search_text,
            selectedAutoSuggestData: selectedCity,
            isError: false,
          });
        } else if (data && data.data && data.data.status == false) {
          this.setState({
            autoSuggestCityData: popularCities,
            isAutoSuggestVisible: true,
          });
        }
      });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //Add client
  adGuestId = (id) => {
    this.setState({ guest_id: id }, () => {
      if (this.state.guestError === true) {
        this.setState({ guestError: cf.isValueEmpty(id) ? true : false })
      }
      this.setState({ guest_id: id });
    });
  }
  rmGuestId = () => {
    this.setState({ guest_id: "" });
  };
  onChangeCheckInOutValue = (dateValue) => {
    console.log("date=value", dateValue);
    this.setState({
      checkInValue: cf.getDateWithFormat(
        new Date(dateValue),
        this?.props?.dateFormat
      ),
      checkOutValue: cf.getDateWithFormat(
        add(new Date(dateValue), { days: 1 }),
        this?.props?.dateFormat
      ),
      checkInOutValue: cf.getDateWithFormat(dateValue, this?.props?.dateFormat),
      isDateError: false,
    });
  };

  onChanegeSetActivityName = (value) => {
    this.setState({ activityName: value, isError: false });
    if (value.trim() == "") {
      this.setState({
        autoSuggestCityData: popularCities,
        isAutoSuggestVisible: false,
        selectedAutoSuggestData: null,
      });
    } else {
      debouncedFetchData(value, (data) => {
        if (
          data &&
          data.data &&
          data.data.searchBycity &&
          typeof data.data.searchBycity === "object"
        ) {
          this.setState({
            autoSuggestCityData: [...data.data.searchBycity.result],
            isAutoSuggestVisible: true,
          });
        } else if (data && data.data && data.data.status == false) {
          this.setState({
            autoSuggestCityData: popularCities,
            isAutoSuggestVisible: true,
          });
        }
      });
    }
  };

  onClickSearchActivity = () => {
    if (
      !(
        this.state.selectedAutoSuggestData &&
        this.state.selectedAutoSuggestData.search_text
      )
    ) {
      this.setState({ isError: true });
    }
    if (this.state.checkInOutValue == "") {
      this.setState({ isDateError: true });
    }
    if (this.props.isOffline === true && this.state.guest_id === '') {
      this.setState({ guestError: true })
    } else {
      this.setState({ guestError: false })
    }
    if (
      this.state.selectedAutoSuggestData &&
      this.state.selectedAutoSuggestData.search_text &&
      this.state.checkInOutValue
    ) {
      let urlQuery = {
        location: this.state.selectedAutoSuggestData.search_text,
        checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
        checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
        from: 1,
        to: 30,
        // search_type: this.state.selectedAutoSuggestData.location_type,
        search_type: 1,
        search_text: this.state.selectedAutoSuggestData.destination_id,
        currency: "AED",
        rating: [...new Set(this.state.ratingSelected)].join(','),
        guestId: this.state.guest_id
      };
      if (this.props.isOffline === true) {
        if (this.state.guest_id !== '') {
          const searchParams = new URLSearchParams(urlQuery)
          const queryString = searchParams.toString()
          window.location = "/offlinesearch/activity?" + queryString
        }
        // window.open(`${window.location.origin}` + "/offlinesearch/activity" , "_self");
        // cf.redirectTo("/offlinesearch/activity", urlQuery)

      } else {
        cf.redirectTo("/activity/search", urlQuery);
      }
    } else {
      console.log('please select guest id')
    }
  };
  onClickSetActivityName = (element) => {
    this.setState({
      activityName: element.search_text,
      selectedAutoSuggestData: element,
      isError: false,
    });
  };
  onInputFocus = (fieldName) => {
    if (fieldName == "autocomplete") {
      if (this.state.isAutoSuggestVisible === false) {
        this.setState({ isAutoSuggestVisible: true, isCalendarVisible: false });
      }
    } else if (fieldName == "search") {
      this.setState({ isAutoSuggestVisible: false, isCalendarVisible: false });
    }
  };

  keyPress = (action) => {
    switch (action) {
      case "ArrowDown":
        console.log("keyPress fn down called ", action);
        let children = document.getElementById(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        ).children;

        let selectIndex = 0;
        selectIndex =
          this.state.selectedAutoSuggestIndex == children.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex + 1;
        console.log("children of auto-suggest", children);
        for (let index = 0; index < children.length; index++) {
          let childElement = children[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndex === index ? "#ccc" : "#fff";
          }
        }
        this.setState({
          selectedAutoSuggestIndex: selectIndex,
          lastArrowAction: "ArrowDown",
        });
        break;
      case "ArrowUp":
        console.log("keyPress fn up called ", action);
        let childrens = document.getElementById(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        ).children;
        let selectIndexUp = 0;
        selectIndexUp =
          this.state.selectedAutoSuggestIndex == childrens.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex == -1
              ? 0
              : this.state.selectedAutoSuggestIndex - 1;
        console.log("children of auto-suggest", childrens);
        for (let index = 0; index < childrens.length; index++) {
          let childElement = childrens[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndexUp === index ? "#ccc" : "#fff";
          }
        }
        // this.setState((prevState) => ({
        //   selectedAutoSuggestIndex:
        //     prevState.selectedAutoSuggestIndex > 0
        //       ? prevState.lastArrowAction=="ArrowDown"?prevState.selectedAutoSuggestIndex - 1:prevState.selectedAutoSuggestIndex
        //       : prevState.selectedAutoSuggestIndex+1,
        //   lastArrowAction: "ArrowUp",
        // }));
        this.setState({
          selectedAutoSuggestIndex: selectIndexUp,
          lastArrowAction: "ArrowUp",
        });
        break;
      case "Enter":
      case "Tab":
        let indexToSelect = 0;
        if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowUp"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        } else if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowDown"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        }
        // const indexToSelect = this.state.selectedAutoSuggestIndex===0?0:this.state.lastArrowAction?"ArrowUp"?this.state.selectedAutoSuggestIndex+1:this.state.selectedAutoSuggestIndex-1:0;
        const selectedItem = this.state.autoSuggestCityData[indexToSelect];
        this.setState({
          activityName: selectedItem.search_text,
          selectedAutoSuggestData: selectedItem,
          isError: false,
          isAutoSuggestVisible: false,
          selectedAutoSuggestIndex: indexToSelect,
          lastArrowAction: "",
        });
        break;

      default:
        console.log("default", action);
        break;
    }
  };

  scrollToMyRef = () => {
    this.searchBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  onClickTraveller = () => {
    if (!this.state.openTraveler) {
      this.setState({ openTraveler: true })
    } else {
      this.setState({ openTraveler: false })
    }
  }

  handleStarCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  ratingHandler = (e) => {
    console.log('rating selected array =-->>', this.state.ratingSelected)
    const { value, checked } = e.target
    const { ratingSelected } = this.state

    if (checked) {
      this.setState({
        ratingSelected: [...ratingSelected, value]
      })
    } else {
      this.setState({
        ratingSelected: ratingSelected.filter(e => e !== value)
      })
    }
  }

  handleClickOutside = e => {
    if (this.advanceSearchWrapper.current && !this.advanceSearchWrapper.current.contains(e.target)) {
      this.setState({ advSearch: false })
    }
    if (this.guestDetailWrapper.current && !this.guestDetailWrapper.current.contains(e.target)) {
      this.setState({ openTraveler: false })
    }
  }

  render() {
    return (
      <div
        className="container mx-auto xl:px-8"
        ref={this.searchBoxRef}
        onClick={(e) => {
          e.stopPropagation();
          if (this.state.isAutoSuggestVisible) {
            this.setState({
              isAutoSuggestVisible: false,
            });
          }
          if (this.state.isCalendarVisible) {
            this.setState({
              isCalendarVisible: false,
            });
          }
        }}
      >
        <div className={this.props.cssfile.activity_search_wid_container}>
          <div className="activity_search_wid_main">
            <div className={this.props.cssfile.activity_way_title}>
              {/* <h2>Activities</h2> */}
            </div>
            {this.props?.sessionData?.type === 'B2B' &&

              <B2BWidgets {...this.state}
                styles={this.props.b2bStyles}
                type={'activity'}
                onClickTraveller={this.onClickTraveller}
                onClickAdvSearch={this.onClickAdvSearch}
                addGuestId={this.adGuestId}
                removeGuestId={this.rmGuestId}
                ratingHandler={this.ratingHandler}
                handleStarCheck={this.handleStarCheck}
                isOffline={this.props.isOffline}
                listWrapper={this.advanceSearchWrapper}
                guestWrapper={this.guestDetailWrapper}
                errorMessage={"Select Traveler"}
                isError={this.state.guestError}
              />}
            <div
              className={this.props.cssfile.activity_search_details_container}
            >
              <div className={this.props.cssfile.activity_search_details_main}>
                <div className={this.props.cssfile.activity_search_details_row}>
                  <div
                    className={
                      this.props.cssfile.activity_search_column +
                      " " +
                      this.props.cssfile.activity_search_name
                    }
                  >
                    <div
                      id="search-widget-main"
                      className={
                        this.props.cssfile.activity_search_wid_inputfield
                      }
                    >
                      <label>{`Enter Destination`}</label>
                      <div
                        className={
                          this.props.cssfile.activity_search_wid_inputfield_main
                        }
                      // onClick={this.scrollToMyRef}
                      >
                        {/* <span><img src="images/location-map-01.svg" /></span> */}
                        <span>
                          <small></small>
                        </span>
                        <input
                          type="text"
                          tabIndex={1}
                          autoComplete={"off"}
                          id="myTextField"
                          placeholder={`City`}
                          className={this.props.cssfile.search_widget_input_new}
                          value={
                            !cf.isValueEmpty(this.state.activityName)
                              ? this.state.activityName
                              : ""
                          }
                          onChange={(event) => {
                            event.stopPropagation();
                            this.onChanegeSetActivityName(event.target.value);
                          }}
                          onClick={(e) => {
                            this.scrollToMyRef();
                            e.stopPropagation();
                            this.setState((prevState) => {
                              return {
                                isAutoSuggestVisible:
                                  !prevState.isAutoSuggestVisible,
                                isCalendarVisible: false,
                                activityName: '',
                              };
                            });
                            // this.scrollToMyRef();
                          }}
                          // onFocus={() => {
                          //   // e.stopPropagation();
                          //   // console.log("event on focus pressed", e.key);
                          //   this.onInputFocus("autocomplete");
                          // }}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                            this.keyPress(e.key);
                          }}
                        />
                        {this.state.isError && (
                          <div className={this.props.cssfile.error}>
                            {"Enter Destination"}
                          </div>
                        )}

                        {this.state.isAutoSuggestVisible === true ? (
                          <div
                            className={
                              this.props.cssfile.activity_autocomplete_value
                            }
                            id="ACTIVITY-AUTO-SUGGEST-DATA-LIST"
                          >
                            {!cf.isValueEmpty(
                              this.state.autoSuggestCountryData
                            ) &&
                              this.state.autoSuggestCountryData.length > 0 &&
                              this.state.countryData.map((element, index) => {
                                return (
                                  <div
                                    id={"COUNTRY-" + index}
                                    key={element.search_text}
                                    className={
                                      this.props.cssfile
                                        .activity_autocomplete_element
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        activityName: element.search_text,
                                        isAutoSuggestVisible: false,
                                      });
                                      this.onClickSetActivityName(element);
                                    }}
                                  >
                                    <img src="images/location-01.svg" alt=""/>
                                    <div>
                                      <div
                                        className={
                                          this.props.cssfile
                                            .activity_autocomplete_name
                                        }
                                      >
                                        {element.search_text}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {!cf.isValueEmpty(this.state.autoSuggestCityData) &&
                              this.state.autoSuggestCityData.length > 0 ? (
                              this.state.autoSuggestCityData.map(
                                (element, index) => {
                                  return (
                                    <div
                                      id={"ACTIVITY_CITY-" + index}
                                      key={`city-${element.search_text}`}
                                      className={
                                        this.props.cssfile
                                          .activity_autocomplete_element
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          activityName: element.search_text,
                                          isAutoSuggestVisible: false,
                                        });
                                        this.onClickSetActivityName(element);
                                      }}
                                    >
                                      {/* <img src="images/location-01.svg" /> */}
                                      <span></span>
                                      <div>
                                        <div
                                          className={
                                            this.props.cssfile
                                              .activity_autocomplete_name
                                          }
                                        >
                                          {element.search_text}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div
                                key={`noresults`}
                                className={
                                  this.props.cssfile
                                    .activity_autocomplete_element
                                }
                              >
                                {/* <img src="images/location-01.svg" /> */}
                                <span></span>
                                <div>
                                  <div
                                    className={
                                      this.props.cssfile
                                        .activity_autocomplete_name
                                    }
                                  >
                                    No Results Found
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${this.props.cssfile.activity_search_column} ${this.props.cssfile.activity_search_date}`}
                  >
                    <DesktopCalender
                      tabIndex={2}
                      label="Date"
                      styles={this.props.calendarStyles}
                      value={
                        !cf.isValueEmpty(this.state.checkInOutValue)
                          ? this.state.checkInOutValue
                          : ""
                      }
                      placholder="Select the date"
                      onChangeDate={(dateVal) => {
                        this.onChangeCheckInOutValue(dateVal);
                      }}
                      // iconImage={<img src="images/calendar-01.svg" />}
                      startDate={cf.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      startDateLimit={cf.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      dateFormat={this?.props?.dateFormat}
                      changeVisibility={(bool) => {
                        this.scrollToMyRef();
                        this.setState({
                          isCalendarVisible: bool,
                          isAutoSuggestVisible: false,
                          isDateError: false,
                        });
                      }}
                      isVisible={this.state.isCalendarVisible}
                      doubleSelection={false}
                    />
                    <span>
                      <small></small>
                    </span>
                    {this.state.isDateError && (
                      <div className={this.props.cssfile.errordate}>
                        {"Please select Date"}
                      </div>
                    )}
                  </div>

                  {/* <div
                    className={`${this.props.cssfile.activity_search_details_row} ${this.props.cssfile.activity_nw}`}
                  > */}
                    <div className={this.props.cssfile.activity_search_class}>
                      <button
                        tabIndex={3}
                        className={
                          this.props.cssfile.activity_search_widget_btn
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          this.onClickSearchActivity();
                        }}
                        onFocus={(e) => {
                          e.stopPropagation();
                          this.onInputFocus("search");
                        }}
                      >
                        Search
                      </button>
                    </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
