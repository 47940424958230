export const addColleague = (req) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('/shared/api/colleagues/addcolleagues', requestOptions).then((response) => response.json())
}



export const updateColleague = (req) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('/shared/api/colleagues/updatecolleagues', requestOptions).then((response) => response.json())
}

export const getColleague = (req) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('/shared/api/colleagues/getcolleagues', requestOptions).then((response) => response.json())
}