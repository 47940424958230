import React, { useEffect, useState } from "react";
import cf from "../../../../lib/utils/common";
import DesktopCalender from "../../../calendar/DesktopCalender";
import FlightAutoComplete from "./FlightAutoComplete";
import { format } from 'date-fns';

export default function ModifySearchForm({style,state,data,index,onclickFlightAutoComplete,setState,onClickSetDepartureGoingToValue,multicityReturnWrapper,multicityDepartureWrapper,
    getGoingToSuggestionList,getDepartureSuggestionList,onChangeSetDepartureGoingToValue,onchangeVisibility,dateFormat,onChangeDepartureGoingDateValue,
    todaysDate,onClickSwap,onSwapKeyDownHandler,onKeyDownArrow}) {
    // console.log(getDepartureSuggestionList,"456");

 return (
   <div className={style.flightsearchpage_widget_flex_multicity_modify} >
                    <div className={style.flightautocomplete_main_multicity}>
                        <FlightAutoComplete
                            label="Departure From"
                            value={data.departureValue}
                            state={state}
                            data={data}
                            index={index}
                            styles={style}
                            onclickFlightAutoComplete={() => onclickFlightAutoComplete("departure","isDeparture",index)}
                            placholder="City or Airport"
                            onClickSourceAndDestination={(city, airPortCode) => onClickSetDepartureGoingToValue(city, airPortCode, "departure",index)}
                            onChangeSourceAndDestination={(value) => onChangeSetDepartureGoingToValue(value, "departure",index)}
                            isAutoCompleteExpand={(bool) => setState({ expandDepartureBoolean: bool })}
                            autoCompleteData={!cf.isValueEmpty(getDepartureSuggestionList) ? getDepartureSuggestionList : []}
                            expandAutoCompleteBool={!cf.isValueEmpty(state.expandDepartureBoolean) ? state.expandDepartureBoolean : false}
                            errorMessage={data?.error?.org}
                            isError={data?.error?.org && (data.org === "" ? true : false)}
                            listWrapper={multicityDepartureWrapper}
                            onKeyDownArrow={(event) => { onKeyDownArrow(event, 'flightDataArray', getDepartureSuggestionList, "departure") }}
                        />
                        <span className={style.flight_swap} tabIndex="2"
                         onKeyDown={onSwapKeyDownHandler} onClick={onClickSwap}
                         >
                            <svg version="1.1" id="Layer_1" x="0px" y="0px"
                                width="30px" height="22px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">
                                <g>
                                    <path fill="currentColor" d="M14.719,28.813C7.103,28.813,0.906,22.616,0.906,15S7.103,1.188,14.719,1.188S28.531,7.384,28.531,15
		                        S22.335,28.813,14.719,28.813z M14.719,2.188C7.654,2.188,1.906,7.935,1.906,15c0,7.064,5.748,12.813,12.813,12.813
		                        c7.064,0,12.813-5.748,12.813-12.813C27.531,7.935,21.783,2.188,14.719,2.188z"/>
                                </g>
                                <g>
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" x="6.145" y="9.9" width="17.148" height="12.891" />
                                        </defs>
                                        <g clipPath="url(#SVGID_2_)">
                                            <g>
                                                <path fill="currentColor" d="M5.689,15.855l6.875,6.92l0.729-0.713L8.09,16.855h15.203v-1.02H8.09" />
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <defs>
                                            <rect id="SVGID_3_" x="6.145" y="7.208" width="17.148" height="12.891" />
                                        </defs>
                                        <g clipPath="url(#SVGID_4_)">
                                            <g>
                                                <path fill="currentColor" d="M23.747,14.145l-6.874-6.922l-0.729,0.713l5.203,5.208H6.145v1.019h15.203" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>

                    <div className={style.flightautocomplete_main_multicity}>
                        <FlightAutoComplete
                            label="Going To"
                            value={data.goingToValue}
                            data={data}
                            index={index}
                            state={state}
                            styles={style}
                            onclickFlightAutoComplete={() => onclickFlightAutoComplete("goingTo","isGoingTo",index)}
                            placholder="City or Airport"
                            onClickSourceAndDestination={(city, airPortCode) => onClickSetDepartureGoingToValue(city, airPortCode, "goingTo",index)}
                            onChangeSourceAndDestination={(value) => onChangeSetDepartureGoingToValue(value, "goingTo",index)}
                            isAutoCompleteExpand={(bool) => setState({ expandGoingToBoolean: bool })}
                            autoCompleteData={!cf.isValueEmpty(getGoingToSuggestionList) ? getGoingToSuggestionList : []}
                            expandAutoCompleteBool={state.expandGoingToBoolean}
                            errorMessage={data?.error?.dest}
                            isError={data?.error?.dest && ((data.dest === "" || data.dest === data.org) ? true : false)}
                            listWrapper={multicityReturnWrapper}
                            onKeyDownArrow={(event) => {onKeyDownArrow(event, 'flightDataArray', getGoingToSuggestionList, "goingTo") }}
                        />
                    </div>
                    <div className={style.flightautocompletecalender_multicity}>
                        <DesktopCalender
                            label="Departure Date"
                            styles={style}
                            value={!cf.isValueEmpty(data.odt) ? format(new Date(data.odt), "dd-LLL-yyyy")  : ""}
                            placholder="Departure Date"
                            onChangeDate={(value) => {onChangeDepartureGoingDateValue(value, "goindeparturegTo",index) }}
                            iconImage={<svg version="1.1" id="Layer_1" x="0px" y="0px"
                                width="17px" height="16px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">
                                <g>
                                    <path fill="currentColor" d="M7.258,17.91H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.002,1.002,1.002h0.083
                                               c0.558,0,1.003-0.445,1.003-1.002v-0.111C8.262,18.355,7.816,17.91,7.258,17.91z"/>
                                    <path fill="currentColor" d="M11.322,17.91h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.084
                                               c0.556,0,1.002-0.445,1.002-1.002v-0.111C12.324,18.355,11.878,17.91,11.322,17.91z"/>
                                    <path fill="currentColor" d="M15.414,17.91h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.002,1.002,1.002h0.112
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C16.415,18.355,15.97,17.91,15.414,17.91L15.414,17.91z"/>
                                    <path fill="currentColor" d="M7.258,13.792H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.001,1.002,1.001h0.083
                                               c0.558,0,1.003-0.445,1.003-1.001v-0.111C8.262,14.237,7.816,13.792,7.258,13.792z"/>
                                    <path fill="currentColor" d="M11.322,13.792h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.084
                                               c0.556,0,1.002-0.445,1.002-1.001v-0.111C12.324,14.237,11.878,13.792,11.322,13.792z"/>
                                    <path fill="currentColor" d="M15.414,13.792h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.001,1.002,1.001h0.112
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C16.415,14.237,15.97,13.792,15.414,13.792z"/>
                                    <path fill="currentColor" d="M19.478,17.91h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.002,1.003,1.002h0.111
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C20.506,18.355,20.033,17.91,19.478,17.91z"/>
                                    <path fill="currentColor" d="M23.568,17.91h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.112
                                               c0.557,0,1.002-0.445,1.002-1.002v-0.111C24.57,18.355,24.125,17.91,23.568,17.91z"/>
                                    <path fill="currentColor" d="M19.478,13.792h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.001,1.003,1.001h0.111
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C20.506,14.237,20.033,13.792,19.478,13.792z"/>
                                    <path fill="currentColor" d="M23.568,13.792h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.112
                                               c0.557,0,1.002-0.445,1.002-1.001v-0.111C24.57,14.237,24.125,13.792,23.568,13.792z"/>
                                    <path fill="currentColor" d="M7.258,22.002H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.445,1.004,1.002,1.004h0.083
                                               c0.558,0,1.003-0.445,1.003-1.004v-0.109C8.262,22.473,7.816,22.002,7.258,22.002z"/>
                                    <path fill="currentColor" d="M11.322,22.002h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.084
                                               c0.556,0,1.002-0.445,1.002-1.004v-0.109C12.324,22.473,11.878,22.002,11.322,22.002z"/>
                                    <path fill="currentColor" d="M15.414,22.002h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.446,1.004,1.002,1.004h0.112
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C16.415,22.473,15.97,22.002,15.414,22.002z"/>
                                    <path fill="currentColor" d="M19.478,22.002h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.109c0,0.559,0.445,1.004,1.003,1.004h0.111
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C20.506,22.473,20.033,22.002,19.478,22.002z"/>
                                    <path fill="currentColor" d="M23.568,22.002h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.112
                                               c0.557,0,1.002-0.445,1.002-1.004v-0.109C24.57,22.473,24.125,22.002,23.568,22.002z"/>
                                    <path fill="currentColor" d="M28.577,10.535L28.577,10.535V8.281c0-1.726-1.392-3.118-3.089-3.118h-1.643V4.189
                                               c0-1.196-0.973-2.171-2.171-2.171c-1.196,0-2.17,0.975-2.17,2.171v0.974l-8.238,0V4.19c0-1.197-0.974-2.171-2.17-2.171
                                               c-1.197,0-2.17,0.974-2.17,2.171v0.974H5.283c-1.725,0-3.117,1.392-3.117,3.118v16.643c0,1.697,1.392,3.088,3.117,3.088h20.232
                                               c1.698,0,3.09-1.391,3.09-3.088L28.577,10.535L28.577,10.535L28.577,10.535z M20.785,4.189c0-0.473,0.39-0.863,0.861-0.863
                                               c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863c-0.472,0-0.861-0.39-0.861-0.863V4.189z M8.206,4.189
                                               c0-0.473,0.389-0.863,0.862-0.863c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863
                                               c-0.473,0-0.862-0.39-0.862-0.863V4.189z M5.254,6.472h1.67c0.112,1.085,1.03,1.948,2.17,1.948c1.113,0,2.031-0.863,2.143-1.948
                                               h8.266c0.111,1.085,1.03,1.948,2.143,1.948c1.113,0,2.031-0.863,2.144-1.948h1.67c0.974,0,1.781,0.807,1.781,1.809v1.615H3.446
                                               V8.28C3.446,7.279,4.253,6.472,5.254,6.472L5.254,6.472z M25.488,26.703H5.254c-1.002,0-1.808-0.807-1.808-1.779V11.175H27.27
                                               v13.749C27.27,25.896,26.462,26.703,25.488,26.703z"/>
                                </g>
                            </svg>}
                            startDate={!cf.isValueEmpty(data.odt) ? data.odt : ""}
                            endDate={!cf.isValueEmpty(state.returnDate) ? state.returnDate : ""}
                            startDateLimit={!cf.isValueEmpty(todaysDate) ? todaysDate : ""}
                            dateFormat={dateFormat}
                            changeVisibility={(bool) => onchangeVisibility(bool, "departure",index)}
                            isVisible={state.multicityFlightData[index].expandDepartureDateBoolean}
                            type={"double"}
                            doubleSelection={false}
                        />
                    </div>
                </div> 
 )
  
 
}