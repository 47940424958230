import React, { Component } from "react";
import { add, format } from "date-fns";
import DesktopCalender from "../../../calendar/activityCalendar/theme7/DesktopCalender";
import autoSuggest from "../../../../lib/autoSuggest";
import cf from "../../../../lib/utils/common";
import ModalPopupComponent from "@/commonComponents/ModalPopupComponent/theme5mobile/ModalPopupComponent";
import MobileCalender from "../../../calendar/activityCalendar/theme7/MobileCalender";
import recentSearch from "../../../../lib/getRecentSearchActivity";
import moment from "moment";
import { getStorage, setStorage } from "../../../../lib/utils/localStorage";

// static 5 cities
const popularCities = [
  {
    destination_id: "12390",
    search_text: "Singapore",
    location_type: 1,
  },
  {
    destination_id: "12633",
    search_text: "Dubai",
    location_type: 1,
  },
  {
    destination_id: "11896",
    search_text: "Petra",
    location_type: 2,
  },
  {
    destination_id: "10220",
    search_text: "Orlando",
    location_type: 2,
  },
  {
    destination_id: "11370",
    search_text: "Cairo",
    location_type: 2,
  }
];

export default class Theme7 extends Component {
  constructor(props) {
    console.log("theme 2 act search widget...", props);
    super(props);
    this.searchBoxRef = React.createRef();
    this.advanceSearchWrapper = React.createRef();
    this.guestDetailWrapper = React.createRef();
    this.state = {
      activityName: this.props?.seoDestination
        ? this.props?.seoDestination
        : new URLSearchParams(window.location.search).has("location")
        ? new URLSearchParams(window.location.search).get("location").toString()
        : "",
      isAutoSuggestVisible: false,
      checkInOutValue: this.props.isSrp
        ? new URLSearchParams(window.location.search).has("checkin")
          ? cf.getDateWithFormat(
              new URLSearchParams(window.location.search)
                .get("checkin")
                .toString(),
              "DD MMM YY"
            )
          : moment().add(this.props?.seoDays, "days").format("DD MMM YY")
        : "",
      checkInValue: null,
      checkOutValue: null,
      isCalendarVisible: false,
      autoSuggestCountryData: [],
      autoSuggestCityData: popularCities,
      selectedAutoSuggestData: {
        search_text: this.props.isSrp
          ? new URLSearchParams(window.location.search).has("location")
            ? new URLSearchParams(window.location.search)
                .get("location")
                .toString()
            : ""
          : "",
        location_type: this.props.isSrp
          ? new URLSearchParams(window.location.search).has("search_type")
            ? new URLSearchParams(window.location.search).get("search_type")
            : ""
          : "",
        destination_id: this.props.isSrp
          ? new URLSearchParams(window.location.search).has("search_text")
            ? new URLSearchParams(window.location.search)
                .get("search_text")
                .toString()
            : ""
          : "",
      },
      isError: false,
      isDateError: false,
      lastArrowAction: "",
      selectedAutoSuggestIndex: -1,
      advSearch: false,
      isOneStar: false,
      isTwoStar: false,
      isThreeStar: false,
      isFourStar: false,
      isFiveStar: false,
      ratingSelected: [],
      guest_id: "",
      guestError: false,
      showCalendar: false,
      userProfileData: "",
      recentSearchData: [],
      destinationDropdown: false,
      // getrecentSearchList:null,
      // getrecentSearchListLocal:null,
      search_key: "activitySearchKey",
      search_key_all: "activitySearchKeyAll",
    };
  }
  onClickAdvSearch = () => {
    if (!this.state.advSearch) {
      this.setState({ advSearch: true });
    } else {
      this.setState({ advSearch: false });
    }
  };
  componentDidMount() {
    // Local storage recent search ========>

    if (true) {
      let LocalSearch = getStorage(this.state.search_key);

      let LocalSearchAll = getStorage(this.state.search_key_all);

      if (LocalSearchAll != null) {
        this.setState({ recentSearchData: JSON.parse(LocalSearchAll) });
      }
      if (LocalSearch != null && LocalSearchAll == null) {
        this.setState({ recentSearchData: [JSON.parse(LocalSearch)] });
      }
    }

    let userToken = cf.getCookieTocken("userToken");
    cf.getUserProfile(userToken, this.props.site_id).then((res) => {
      if (res.status === true) {
        console.log("user profile status true...");
        this.setState({ userProfileData: res });
      } else {
        this.setState({ userProfileData: res });
      }
    });
    // console.log('user profile ==', this.props.userProfile);
    // new URLSearchParams(window.location.search).get("location");
    // if(!this.props.isSrp) {
    //   this.onChangeCheckInOutValue(new Date());
    // }
    //   const activityname = this.state.activityName
    // console.log("activityname from query param", activityname);
    // if (activityname != null) {
    //   debouncedFetchData(activityname, (data) => {
    //     console.log("dataa**********", data);
    //     if (
    //       data &&
    //       data.data &&
    //       data.data.searchBycity &&
    //       typeof data.data.searchBycity === "object"
    //     ) {
    //       const cityData = [...data.data.searchBycity.result];
    //       const selectedCity = cityData.filter((city) => {
    //         return city.search_text == activityname;
    //       })[0];
    //       console.log("selectedCity", selectedCity);
    //       this.setState({
    //         autoSuggestCityData: cityData,
    //         isAutoSuggestVisible: false,
    //         activityName: selectedCity.search_text,
    //         selectedAutoSuggestData: selectedCity,
    //         isError: false,
    //         recentSearchData : data.data.recent_search?.length > 0 ? data.data.recent_search : []
    //       });
    //     } else if (data && data.data && data.data.status == false) {
    //       this.setState({
    //         autoSuggestCityData: [],
    //         isAutoSuggestVisible: true,
    //         recentSearchData : data.data.recent_search?.length > 0 ? data.data.recent_search : []
    //       });
    //     }
    //   });
    // }

    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //Add client
  adGuestId = (id) => {
    this.setState({ guest_id: id }, () => {
      if (this.state.guestError === true) {
        this.setState({ guestError: cf.isValueEmpty(id) ? true : false });
      }
      this.setState({ guest_id: id });
    });
  };
  rmGuestId = () => {
    this.setState({ guest_id: "" });
  };
  onChangeCheckInOutValue = (dateValue) => {
    console.log("date=value", dateValue);
    this.setState({
      checkInValue: cf.getDateWithFormat(
        // new Date(dateValue),
        dateValue,
        this?.props?.dateFormat
      ),
      checkOutValue: cf.getDateWithFormat(
        add(new Date(dateValue), { days: 8 }),
        this?.props?.dateFormat
      ),
      checkInOutValue: cf.getDateWithFormat(dateValue, "DD MMM YY"),
      isDateError: false,
      // isCalendarVisible: false
    });
  };

  onChanegeSetActivityName = (value) => {
    let recentSearchArray = [];
    this.setState({ activityName: value, isError: false });
    if (value === "") {
      this.setState({
        autoSuggestCityData: popularCities,
        // isAutoSuggestVisible: false,
        selectedAutoSuggestData: null,
      });
    } else {
      this.debouncedFetchData(value, (data) => {
        if (this.state.userProfileData?.status) {
          recentSearchArray =
            data.data.recent_search?.length > 0 ? data.data.recent_search : [];
        } else {
          let LocalSearch = getStorage(this.state.search_key);

          let LocalSearchAll = getStorage(this.state.search_key_all);

          if (LocalSearchAll != null) {
            recentSearchArray = JSON.parse(LocalSearchAll);
            // this.setState({ recentSearchData: JSON.parse(LocalSearchAll) });
          }
          if (LocalSearch != null && LocalSearchAll == null) {
            recentSearchArray = [JSON.parse(LocalSearch)];
            // this.setState({ recentSearchData: [JSON.parse(LocalSearch)] });
          }
        }
        if (
          data &&
          data.data &&
          data.data.searchBycity &&
          typeof data.data.searchBycity === "object"
        ) {
          this.setState({
            autoSuggestCityData: [...data.data.searchBycity.result],
            isAutoSuggestVisible: true,
            recentSearchData: recentSearchArray,
          });
        } else if (data && data.data && data.data.status == false) {
          this.setState({
            autoSuggestCityData: [],
            isAutoSuggestVisible: true,
            recentSearchData: recentSearchArray,
          });
        }
      });
    }
  };

  debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };

  debouncedFetchData = this.debounce((query, cb) => {
    this.fetchData(query, cb);
  }, 400);

  fetchData = async (query, callBackFunc) => {
    if (query != "" && query.length > 2) {
      const res = await autoSuggest({
        searchText: query,
        isSrp: this.props.isSrp,
      });
      callBackFunc(res);
    } else {
      callBackFunc(null);
    }
  };

  onClickSearchActivity = () => {
    // Local storage recent search ========>

    if (
      this.state.selectedAutoSuggestData.search_text &&
      this.state.userProfileData?.status == false
    ) {
      let LocalSearch = getStorage(this.state.search_key);
      let LocalSearchAll = JSON.parse(getStorage(this.state.search_key_all));
      const searchItem = JSON.parse(LocalSearch);

      if (LocalSearch) {
        if (LocalSearchAll == null) {
          var searchData = getStorage(this.state.search_key);
          var searchDataArr = [JSON.parse(searchData)];

          let myObject = {
            destination_id: this.state.selectedAutoSuggestData.destination_id,
            location_type: this.state.selectedAutoSuggestData.location_type,
            search_text: this.state.selectedAutoSuggestData.search_text,
          };
          if (searchItem.destination_id !== myObject.destination_id) {
            searchDataArr.unshift(myObject);
            var searchDataStrig = JSON.stringify(searchDataArr);
            setStorage(this.state.search_key, searchDataStrig);
            setStorage(
              this.state.search_key_all,
              JSON.stringify(searchDataArr)
            );
          }
        }

        if (LocalSearchAll) {
          var searchData = getStorage(this.state.search_key);
          var searchDataAll = JSON.parse(getStorage(this.state.search_key_all));
          var searchDataArr = [JSON.parse(searchData)];
          var indexArr = [];
          searchDataAll.forEach((x, i) => {
            indexArr.push(x.destination_id);
          });

          let myObject = {
            destination_id: this.state.selectedAutoSuggestData.destination_id,
            location_type: this.state.selectedAutoSuggestData.location_type,
            search_text: this.state.selectedAutoSuggestData.search_text,
          };

          if (!indexArr.includes(myObject.destination_id)) {
            searchDataAll.unshift(myObject);
            var searchDataStrig = JSON.stringify(searchDataArr);
            setStorage(
              this.state.search_key_all,
              JSON.stringify(searchDataAll)
            );
          }
          if (searchDataAll.length > 5) {
            searchDataAll.pop();
            setStorage(
              this.state.search_key_all,
              JSON.stringify(searchDataAll)
            );
          }
        }
      } else {
        var searchData = [];
        let data = {
          destination_id: this.state.selectedAutoSuggestData.destination_id,
          location_type: this.state.selectedAutoSuggestData.location_type,
          search_text: this.state.selectedAutoSuggestData.search_text,
        };
        // searchData.push(data); // add the object to the array
        searchData.unshift(data);
        var searchDartaStrig = JSON.stringify(searchData);
        setStorage(this.state.search_key, searchDartaStrig);
      }

      let data = {
        destination_id: this.state.selectedAutoSuggestData.destination_id,
        location_type: this.state.selectedAutoSuggestData.location_type,
        search_text: this.state.selectedAutoSuggestData.search_text,
      };

      setStorage(this.state.search_key, JSON.stringify(data));
    }
    if (
      !(
        this.state.selectedAutoSuggestData &&
        this.state.selectedAutoSuggestData.search_text
      )
    ) {
      this.setState({ isError: true });
    }
    if (this.state.checkInOutValue == "") {
      this.setState({ isDateError: true });
    }
    if (this.props.isOffline === true && this.state.guest_id === "") {
      this.setState({ guestError: true });
    } else {
      this.setState({ guestError: false });
    }
    if (
      this.state.selectedAutoSuggestData &&
      this.state.selectedAutoSuggestData.search_text &&
      this.state.checkInOutValue
    ) {
      let urlQuery = {
        location: this.state.selectedAutoSuggestData.search_text,
        checkin: format(new Date(this.state.checkInOutValue), "yyyy-MM-dd"),
        checkout: cf.getDateWithFormat(
          add(new Date(this.state.checkInOutValue), { days: 1 }),
          "yyyy-MM-DD"
        ),
        from: 1,
        to: 30,
        search_type: this.state.selectedAutoSuggestData.location_type,
        search_text: this.state.selectedAutoSuggestData.destination_id,
        currency: this.props.home?.data?.site?.base_currency || "INR",
        rating: [...new Set(this.state.ratingSelected)].join(","),
        guestId: this.state.guest_id,
      };
      if (this.state.userProfileData?.status) {
        if (true) {
          let data = {
            destination_id: this.state.selectedAutoSuggestData.destination_id,
            location_type: this.state.selectedAutoSuggestData.location_type,
            search_text: this.state.selectedAutoSuggestData.search_text,
          };
          // console.log(this.state.hotelName, this.state.getDestinationId,this.state.getSearchType,
          //   this.state.property_count,this.state.dest_type);

          recentSearch(data).then((res) => {
            console.log("rrespppppp", res);
          });
        }
      }
      if (this.props.isOffline === true) {
        if (this.state.guest_id !== "") {
          const searchParams = new URLSearchParams(urlQuery);
          const queryString = searchParams.toString();
          window.location = "/offlinesearch/activity?" + queryString;
        }
        // window.open(`${window.location.origin}` + "/offlinesearch/activity" , "_self");
        // cf.redirectTo("/offlinesearch/activity", urlQuery)
      } else {
        cf.redirectTo("/activity/search", urlQuery);
      }
    } else {
      console.log("please select guest id");
    }
  };
  onClickSetActivityName = (element) => {
    console.log("on click ==>>", element);
    this.setState({
      activityName: element.search_text,
      selectedAutoSuggestData: element,
      isError: false,
    });
  };
  onInputFocus = (fieldName) => {
    if (fieldName == "autocomplete") {
      if (this.state.isAutoSuggestVisible === false) {
        this.setState({ isAutoSuggestVisible: true, isCalendarVisible: false });
      }
    } else if (fieldName == "search") {
      this.setState({ isAutoSuggestVisible: false, isCalendarVisible: false });
    }
  };

  keyPress = (action) => {
    switch (action) {
      case "ArrowDown":
        console.log("keyPress fn down called ", action);
        let children = document.getElementById(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        ).children;

        let selectIndex = 0;
        selectIndex =
          this.state.selectedAutoSuggestIndex == children.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex + 1;
        console.log("children of auto-suggest", children);
        for (let index = 0; index < children.length; index++) {
          let childElement = children[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndex === index ? "#ccc" : "#fff";
          }
        }
        this.setState({
          selectedAutoSuggestIndex: selectIndex,
          lastArrowAction: "ArrowDown",
        });
        break;
      case "ArrowUp":
        console.log("keyPress fn up called ", action);
        let childrens = document.getElementById(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        ).children;
        let selectIndexUp = 0;
        selectIndexUp =
          this.state.selectedAutoSuggestIndex == childrens.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex == -1
            ? 0
            : this.state.selectedAutoSuggestIndex - 1;
        console.log("children of auto-suggest", childrens);
        for (let index = 0; index < childrens.length; index++) {
          let childElement = childrens[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndexUp === index ? "#ccc" : "#fff";
          }
        }
        // this.setState((prevState) => ({
        //   selectedAutoSuggestIndex:
        //     prevState.selectedAutoSuggestIndex > 0
        //       ? prevState.lastArrowAction=="ArrowDown"?prevState.selectedAutoSuggestIndex - 1:prevState.selectedAutoSuggestIndex
        //       : prevState.selectedAutoSuggestIndex+1,
        //   lastArrowAction: "ArrowUp",
        // }));
        this.setState({
          selectedAutoSuggestIndex: selectIndexUp,
          lastArrowAction: "ArrowUp",
        });
        break;
      case "Enter":
      case "Tab":
        let indexToSelect = 0;
        if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowUp"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        } else if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowDown"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        }
        // const indexToSelect = this.state.selectedAutoSuggestIndex===0?0:this.state.lastArrowAction?"ArrowUp"?this.state.selectedAutoSuggestIndex+1:this.state.selectedAutoSuggestIndex-1:0;
        const selectedItem = this.state.autoSuggestCityData[indexToSelect - 1];
        this.setState({
          activityName: selectedItem.search_text,
          selectedAutoSuggestData: selectedItem,
          isError: false,
          isAutoSuggestVisible: false,
          selectedAutoSuggestIndex: indexToSelect,
          lastArrowAction: "",
        });
        break;

      default:
        console.log("default", action);
        break;
    }
  };

  scrollToMyRef = () => {
    this.searchBoxRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  onClickTraveller = () => {
    if (!this.state.openTraveler) {
      this.setState({ openTraveler: true });
    } else {
      this.setState({ openTraveler: false });
    }
  };

  handleStarCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  ratingHandler = (e) => {
    console.log("rating selected array =-->>", this.state.ratingSelected);
    const { value, checked } = e.target;
    const { ratingSelected } = this.state;

    if (checked) {
      this.setState({
        ratingSelected: [...ratingSelected, value],
      });
    } else {
      this.setState({
        ratingSelected: ratingSelected.filter((e) => e !== value),
      });
    }
  };

  handleClickOutside = (e) => {
    if (
      this.advanceSearchWrapper.current &&
      !this.advanceSearchWrapper.current.contains(e.target)
    ) {
      this.setState({ advSearch: false });
    }
    if (
      this.guestDetailWrapper.current &&
      !this.guestDetailWrapper.current.contains(e.target)
    ) {
      this.setState({ isCalendarVisible: false });
    }
  };

  onChangeVisibility = (bool) => {
    this.setState({
      isCalendarVisible: bool,
      isAutoSuggestVisible: false,
      isDateError: false,
    });
  };
  closeDestinationPopup = () => {
    this.setState({ destinationDropdown: false });
  };

  render() {
    return (
      <>
        {this.props.isMobile ? (
          // mobile section starts
          <div
            className="container mx-auto xl:px-8"
            ref={this.searchBoxRef}
            onClick={(e) => {
              e.stopPropagation();
              if (this.state.isAutoSuggestVisible) {
                this.setState({
                  isAutoSuggestVisible: false,
                });
              }
              if (this.state.isCalendarVisible) {
                this.setState({
                  isCalendarVisible: false,
                });
              }
            }}
          >
            <div className={this.props.cssfile.activity_search_wid_container}>
              <div className="activity_search_wid_main">
                <div className={this.props.cssfile.activity_way_title}>
                  {/* <h2>Activities</h2> */}
                </div>
                <div
                  className={
                    this.props.cssfile.activity_search_details_container
                  }
                >
                  <div
                    className={this.props.cssfile.activity_search_details_main}
                  >
                    <div
                      className={this.props.cssfile.activity_search_details_row}
                    >
                      <div
                        className={
                          this.props.cssfile.activity_search_column +
                          " " +
                          this.props.cssfile.activity_search_name
                        }
                      >
                        <div
                          id="search-widget-main"
                          className={
                            this.props.cssfile.activity_search_wid_inputfield
                          }
                        >
                          <label>{"Where to?"}</label>
                          <div
                            className={
                              this.props.cssfile
                                .activity_search_wid_inputfield_main
                            }

                            // onClick={this.scrollToMyRef}
                          >
                            <input
                              type="text"
                              readOnly
                              tabIndex={1}
                              placeholder={`Search for a place or activity`}
                              autoComplete={"off"}
                              id="myTextField"
                              className={
                                this.props.cssfile.search_widget_input_new
                              }
                              value={
                                !cf.isValueEmpty(this.state.activityName) &&
                                !this.state.isAutoSuggestVisible
                                  ? this.state.activityName
                                  : ""
                              }
                              onClick={(e) => {
                                this.scrollToMyRef();
                                e.stopPropagation();
                                this.setState((prevState) => {
                                  return {
                                    ...prevState,
                                    destinationDropdown: true,
                                    isCalendarVisible: false,
                                    activityName: "",
                                    autoSuggestCityData: popularCities,
                                  };
                                });
                              }}
                            />

                            {this.state.isError && (
                              <div className={this.props.cssfile.error}>
                                {"Enter Destination"}
                              </div>
                            )}

                            {/* {this.state.isAutoSuggestVisible === true ? ( */}
                            <>
                              <ModalPopupComponent
                                isPopup={this.state.destinationDropdown}
                                modalCss={
                                  this.props.isSrp
                                    ? this.props.modalCss
                                    : this.props.popupcss
                                }
                                closePopup={this.closeDestinationPopup}
                                showBtn={false}
                                heading={"Destination"}
                              >
                                <div
                                  className={
                                    this.props.cssfile
                                      .activity_autocomplete_value
                                  }
                                  id="ACTIVITY-AUTO-SUGGEST-DATA-LIST"
                                >
                                  <div
                                    className={
                                      this.props.cssfile
                                        .input_search_destination
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="14"
                                    >
                                      <defs>
                                        <filter id="ve52ept4ca">
                                          <feFlood
                                            flood-color="#979797"
                                            flood-opacity="1"
                                            result="floodOut"
                                          ></feFlood>
                                          <feComposite
                                            operator="atop"
                                            in="floodOut"
                                            in2="SourceGraphic"
                                            result="compOut"
                                          ></feComposite>
                                          <feBlend
                                            in="compOut"
                                            in2="SourceGraphic"
                                          ></feBlend>
                                        </filter>
                                      </defs>
                                      <g filter="url(#ve52ept4ca)">
                                        <path
                                          fill-rule="evenodd"
                                          fill="#97144D"
                                          d="M10.928 9.513A6.022 6.022 0 0 0 1.116 2.53C-.812 5.24-.179 8.1 2.531 10.928a6.021 6.021 0 0 0 6.982 0l2.83 2.83a1.004 1.004 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.031a4.016 4.016 0 0 1 8.029.006 4.015 4.015 0 0 1-8.029-.006z"
                                        ></path>
                                      </g>
                                    </svg>
                                    <input
                                      type="text"
                                      tabIndex={1}
                                      autoFocus
                                      autoComplete={"off"}
                                      id="myTextField"
                                      placeholder={`Search for a place or activity`}
                                      maxLength={24}
                                      className={
                                        this.props.cssfile
                                          .search_widget_input_new
                                      }
                                      value={
                                        !cf.isValueEmpty(
                                          this.state.activityName
                                        )
                                          ? this.state.activityName
                                          : ""
                                      }
                                      onChange={(event) => {
                                        event.stopPropagation();
                                        this.onChanegeSetActivityName(
                                          event.target.value
                                        );
                                      }}
                                      onClick={(e) => {
                                        this.scrollToMyRef();
                                        e.stopPropagation();
                                        this.setState((prevState) => {
                                          return {
                                            ...prevState,
                                            isAutoSuggestVisible: true,
                                            isCalendarVisible: false,
                                            activityName: "",
                                          };
                                        });
                                      }}
                                      onKeyDown={(e) => {
                                        e.stopPropagation();
                                        this.keyPress(e.key);
                                      }}
                                    />
                                  </div>
                                  <div
                                    class={
                                      this.props.cssfile.activity_suggestionLbl
                                    }
                                  >
                                    Suggestions
                                  </div>

                                  {!cf.isValueEmpty(
                                    this.state.autoSuggestCountryData
                                  ) &&
                                    this.state.autoSuggestCountryData.length >
                                      0 &&
                                    this.state.countryData.map(
                                      (element, index) => {
                                        return (
                                          <div
                                            id={"COUNTRY-" + index}
                                            key={element.search_text}
                                            className={
                                              this.props.cssfile
                                                .activity_autocomplete_element
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              this.setState({
                                                activityName:
                                                  element.search_text,
                                                isAutoSuggestVisible: false,
                                                destinationDropdown: false,
                                              });
                                              this.onClickSetActivityName(
                                                element
                                              );
                                            }}
                                          >
                                            <img src="images/location-01.svg" />
                                            <div>
                                              <div
                                                className={
                                                  this.props.cssfile
                                                    .activity_autocomplete_name
                                                }
                                              >
                                                {element.search_text}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  {!cf.isValueEmpty(
                                    this.state.autoSuggestCityData
                                  ) &&
                                  this.state.autoSuggestCityData.length > 0 ? (
                                    this.state.autoSuggestCityData.map(
                                      (element, index) => {
                                        return (
                                          <div
                                            // id={"ACTIVITY_CITY-" + index}
                                            // key={`city-${element.search_text}`}
                                            className={
                                              this.props.cssfile
                                                .activity_autocomplete_element
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              this.setState({
                                                activityName:
                                                  element.search_text,
                                                isAutoSuggestVisible: false,
                                                destinationDropdown: false,
                                              });
                                              this.onClickSetActivityName(
                                                element
                                              );
                                            }}
                                          >
                                            {/* <img src="images/location-01.svg" /> */}
                                            <div
                                              className={
                                                this.props.cssfile
                                                  .activity_autocompIcon
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="17"
                                                height="22"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  d="m14.769 13.53-6.19 8.098c-.061.06-.152.12-.242.12a.276.276 0 0 1-.242-.12l-6.19-8.098a8.116 8.116 0 0 1 0-9.82C3.445 1.685 5.8.537 8.337.537c2.536 0 4.892 1.148 6.432 3.173a8.119 8.119 0 0 1 0 9.82zm-.483-9.458a7.482 7.482 0 0 0-5.949-2.931c-2.355 0-4.53 1.058-5.949 2.931a7.485 7.485 0 0 0 0 9.095l5.949 7.766 5.949-7.766a7.485 7.485 0 0 0 0-9.095zM8.337 13.47c-2.657 0-4.832-2.176-4.832-4.835S5.68 3.8 8.337 3.8s4.831 2.176 4.831 4.835-2.174 4.835-4.831 4.835zm0-9.066a4.242 4.242 0 0 0-4.228 4.231 4.242 4.242 0 0 0 4.228 4.23 4.242 4.242 0 0 0 4.228-4.23 4.242 4.242 0 0 0-4.228-4.231z"
                                                ></path>
                                              </svg>
                                            </div>
                                            <div>
                                              <div
                                                className={
                                                  this.props.cssfile
                                                    .activity_autocomplete_name
                                                }
                                              >
                                                {element.search_text}
                                              </div>
                                              {/* <div className={this.props.cssfile.activity_autonwLabel}>United Arab Emirates</div> */}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <div
                                      key={`noresults`}
                                      className={
                                        this.props.cssfile
                                          .activity_autocomplete_element
                                      }
                                    >
                                      {/* <img src="images/location-01.svg" /> */}
                                      {/* <span></span> */}
                                      <div
                                        className={
                                          this.props.cssfile.noresult_icon
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="17"
                                          height="22"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="m14.769 13.53-6.19 8.098c-.061.06-.152.12-.242.12a.276.276 0 0 1-.242-.12l-6.19-8.098a8.116 8.116 0 0 1 0-9.82C3.445 1.685 5.8.537 8.337.537c2.536 0 4.892 1.148 6.432 3.173a8.119 8.119 0 0 1 0 9.82zm-.483-9.458a7.482 7.482 0 0 0-5.949-2.931c-2.355 0-4.53 1.058-5.949 2.931a7.485 7.485 0 0 0 0 9.095l5.949 7.766 5.949-7.766a7.485 7.485 0 0 0 0-9.095zM8.337 13.47c-2.657 0-4.832-2.176-4.832-4.835S5.68 3.8 8.337 3.8s4.831 2.176 4.831 4.835-2.174 4.835-4.831 4.835zm0-9.066a4.242 4.242 0 0 0-4.228 4.231 4.242 4.242 0 0 0 4.228 4.23 4.242 4.242 0 0 0 4.228-4.23 4.242 4.242 0 0 0-4.228-4.231z"
                                          ></path>
                                        </svg>
                                      </div>
                                      <div>
                                        <div
                                          className={
                                            this.props.cssfile
                                              .activity_autocomplete_noresultnw
                                          }
                                        >
                                          No Results Found
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {this.state.recentSearchData.length > 0 && (
                                    <>
                                      <div
                                        className={
                                          this.props.cssfile.activity_RecentLbl
                                        }
                                      >
                                        Recent Search
                                      </div>

                                      {this.state.recentSearchData.map(
                                        (ele) => {
                                          return (
                                            <div
                                              className={
                                                this.props.cssfile
                                                  .activity_autocomplete_element
                                              }
                                            >
                                              <div
                                                className={
                                                  this.props.cssfile
                                                    .activity_autocompIcon
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="19px"
                                                  height="18px"
                                                >
                                                  <path
                                                    fill-rule="evenodd"
                                                    fill="rgb(0, 0, 0)"
                                                    d="M9.969,3.403 L9.969,8.830 C9.969,8.954 10.019,9.072 10.107,9.160 L13.970,12.996 C14.058,13.084 14.108,13.203 14.108,13.327 C14.108,13.584 13.898,13.792 13.640,13.792 C13.515,13.792 13.395,13.742 13.307,13.655 L9.445,9.818 C9.181,9.556 9.033,9.200 9.033,8.830 L9.033,3.403 C9.033,3.146 9.243,2.938 9.501,2.938 C9.760,2.938 9.969,3.146 9.969,3.403 ZM10.716,17.733 C10.314,17.790 9.908,17.819 9.501,17.819 C8.255,17.820 7.023,17.553 5.892,17.035 C5.878,17.030 5.865,17.024 5.852,17.017 C5.623,16.899 5.533,16.619 5.652,16.391 C5.770,16.162 6.053,16.073 6.282,16.191 C9.084,17.454 12.372,16.942 14.650,14.888 C17.802,12.045 18.037,7.203 15.175,4.072 C12.314,0.941 7.439,0.707 4.287,3.550 C1.135,6.393 0.900,11.235 3.761,14.366 L3.761,13.357 C3.761,13.100 3.971,12.892 4.230,12.892 C4.488,12.892 4.698,13.100 4.698,13.357 L4.698,15.449 C4.698,15.706 4.488,15.915 4.230,15.915 L2.123,15.915 C1.864,15.915 1.655,15.706 1.655,15.449 C1.655,15.193 1.864,14.984 2.123,14.984 L3.076,14.984 C1.916,13.709 1.168,12.119 0.926,10.417 C0.260,5.712 3.559,1.361 8.296,0.699 C13.031,0.039 17.408,3.315 18.075,8.018 C18.740,12.719 15.447,17.066 10.716,17.733 Z"
                                                  />
                                                </svg>
                                              </div>
                                              <div className="">
                                                <div
                                                  className={
                                                    this.props.cssfile
                                                      .activity_autocomplete_name
                                                  }
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState({
                                                      activityName:
                                                        ele.search_text,
                                                      isAutoSuggestVisible: false,
                                                      destinationDropdown: false,
                                                    });
                                                    this.onClickSetActivityName(
                                                      ele
                                                    );
                                                  }}
                                                >
                                                  {ele.search_text}
                                                </div>
                                                {/* <div className={this.props.cssfile.activity_autonwLabel}>United Arab Emirates</div> */}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </div>
                              </ModalPopupComponent>
                            </>

                            {/* // ) : null} */}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${this.props.cssfile.activity_search_column} ${this.props.cssfile.activity_search_date}`}
                      >
                        <div className={this.props.cssfile.flex_calwp}>
                          <MobileCalender
                            tabIndex={2}
                            label="When"
                            styles={this.props.calendarStyles}
                            value={
                              !cf.isValueEmpty(this.state.checkInOutValue)
                                ? this.state.checkInOutValue
                                : ""
                            }
                            placholder="Select Date"
                            onChangeDate={(dateVal) => {
                              this.onChangeCheckInOutValue(dateVal);
                            }}
                            // iconImage={<img src="images/calendar-01.svg" />}
                            startDate={cf.getDateWithFormat(
                              new Date(),
                              this?.props?.dateFormat
                            )}
                            startDateLimit={cf.getDateWithFormat(
                              new Date(),
                              this?.props?.dateFormat
                            )}
                            dateFormat={this?.props?.dateFormat}
                            changeVisibility={(bool) => {
                              this.scrollToMyRef();
                              this.setState({
                                isCalendarVisible: bool,
                                isAutoSuggestVisible: false,
                                isDateError: false,
                              });
                            }}
                            isVisible={this.state.isCalendarVisible}
                            doubleSelection={false}
                          />
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16px"
                              height="10px"
                            >
                              <path
                                fill-rule="evenodd"
                                fill="rgb(38, 106, 172)"
                                d="M15.368,3.032 L8.938,9.516 C8.817,9.638 8.677,9.727 8.528,9.787 C8.089,9.967 7.568,9.877 7.212,9.518 L0.782,3.033 C0.308,2.555 0.308,1.780 0.782,1.302 C1.256,0.823 2.025,0.823 2.499,1.302 L8.074,6.924 L13.651,1.300 C14.125,0.822 14.894,0.822 15.368,1.300 C15.842,1.778 15.842,2.554 15.368,3.032 Z"
                              />
                            </svg>
                          </span>
                        </div>
                        {/* <span>
                      <small></small>
                    </span> */}
                        {this.state.isDateError && (
                          <div className={this.props.cssfile.errordate}>
                            {"Please select Date"}
                          </div>
                        )}
                      </div>

                      {/* <div
                    className={`${this.props.cssfile.activity_search_details_row} ${this.props.cssfile.activity_nw}`}
                  > */}
                      <div className={this.props.cssfile.activity_search_class}>
                        <button
                          tabIndex={3}
                          className={
                            this.props.cssfile.activity_search_widget_btn
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            this.onClickSearchActivity();
                          }}
                          onFocus={(e) => {
                            e.stopPropagation();
                            this.onInputFocus("search");
                          }}
                        >
                          {/* <svg width="50px" height="50px" viewBox="0,0,256,256">
                        <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none">
                        <g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                        </g></g>
                      </svg> */}
                          Search
                        </button>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // mobile section ends

          // desktop section starts
          <div
            className={this.props.isSrp ? this.props.cssfile.parentWidFour : ""}
          >
            <div
              className="container-1242 mx-auto"
              ref={this.searchBoxRef}
              onClick={(e) => {
                e.stopPropagation();
                if (this.state.isAutoSuggestVisible) {
                  this.setState({
                    isAutoSuggestVisible: false,
                  });
                }
                // if (this.state.isCalendarVisible) {
                //   this.setState({
                //     isCalendarVisible: false,
                //   });
                // }
              }}
            >
              <div
                className={
                  this.props.isSrp
                    ? this.props.cssfile.srp_search_wid_container
                    : this.props.cssfile.activity_search_wid_container
                }
              >
                <div className="activity_search_wid_main">
                  <div className={this.props.cssfile.activity_way_title}>
                    {/* <h2>Activities</h2> */}
                  </div>
                  <div
                    className={
                      this.props.isSrp
                        ? this.props.cssfile.srp_search_details_container
                        : this.props.cssfile.activity_search_details_container
                    }
                  >
                    <div
                      className={
                        this.props.cssfile.activity_search_details_main
                      }
                    >
                      <div
                        className={
                          this.props.cssfile.activity_search_details_row
                        }
                      >
                        <div
                          className={
                            this.props.cssfile.activity_search_column +
                            " " +
                            this.props.cssfile.activity_search_name
                          }
                        >
                          <div
                            id="search-widget-main"
                            className={
                              this.props.cssfile.activity_search_wid_inputfield
                            }
                          >
                            <label>Where to?</label>
                            <div
                              className={
                                this.props.cssfile
                                  .activity_search_wid_inputfield_main
                              }

                              // onClick={this.scrollToMyRef}
                            >
                              {/* <span>
                        <svg version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="200px"
                          height="200px"
                          viewBox="0 0 200 200"
                          enableBackground="new 0 0 200 200"
                          >
<g>
	<path fill="currentColor" d="M101,184.511c-0.031,0-0.062,0-0.094-0.001c-1.113-0.026-2.164-0.515-2.902-1.349
		c-1.537-1.736-3.586-4.031-6.147-6.885c-2.659-2.961-7.256-8.648-14.055-17.387c-6.738-8.66-12.719-17.192-17.779-25.359
		c-5.106-8.243-9.814-17.68-13.991-28.049c-4.284-10.633-6.457-20.179-6.457-28.372c0-16.481,5.985-30.635,17.79-42.068
		C69.39,23.395,84.07,17.489,101,17.489s31.546,5.842,43.442,17.365c11.932,11.556,17.982,25.772,17.982,42.255
		c0,8.19-2.106,17.607-6.26,27.989c-4.062,10.15-9.045,19.803-14.812,28.688c-5.665,8.729-11.346,17.015-16.885,24.627
		c-5.553,7.63-10.354,13.807-14.27,18.359c-0.053,0.062-0.107,0.121-0.164,0.18l-6.167,6.346
		C103.114,184.074,102.079,184.511,101,184.511z M101,25.489c-14.988,0-27.441,5.004-38.069,15.298
		C52.599,50.795,47.576,62.676,47.576,77.109c0,7.164,1.977,15.704,5.876,25.382c4.007,9.945,8.506,18.972,13.372,26.826
		c4.91,7.927,10.729,16.224,17.292,24.66c6.589,8.469,11.196,14.174,13.693,16.955c1.222,1.361,2.329,2.597,3.319,3.706l3.084-3.174
		c3.762-4.381,8.399-10.354,13.787-17.758c5.456-7.498,11.056-15.665,16.643-24.275c5.483-8.45,10.226-17.637,14.095-27.305
		c3.774-9.433,5.688-17.85,5.688-25.018c0-14.434-5.086-26.376-15.549-36.508C128.378,30.432,115.988,25.489,101,25.489z"/>
</g>
<g>
	<path fill="currentColor" d="M101,91.748c-7.077,0-12.834-5.757-12.834-12.834S93.923,66.079,101,66.079s12.835,5.757,12.835,12.834
		S108.077,91.748,101,91.748z M101,74.079c-2.666,0-4.834,2.169-4.834,4.834s2.169,4.834,4.834,4.834
		c2.666,0,4.835-2.169,4.835-4.834S103.666,74.079,101,74.079z"/>
</g>
</svg>
                        </span> */}
                              <input
                                type="text"
                                readOnly
                                tabIndex={1}
                                placeholder={`Search for a place or activity`}
                                autoComplete={"off"}
                                id="myTextField"
                                className={
                                  this.props.cssfile.search_widget_input_new
                                }
                                value={
                                  !cf.isValueEmpty(this.state.activityName) &&
                                  !this.state.isAutoSuggestVisible
                                    ? this.state.activityName
                                    : ""
                                }
                                onClick={(e) => {
                                  this.scrollToMyRef();
                                  e.stopPropagation();
                                  this.setState((prevState) => {
                                    return {
                                      ...prevState,
                                      isAutoSuggestVisible:
                                        !prevState.isAutoSuggestVisible,
                                      isCalendarVisible: false,
                                      activityName: "",
                                      autoSuggestCityData: popularCities,
                                    };
                                  });
                                }}
                              />

                              {this.state.isError && (
                                <div className={this.props.cssfile.error}>
                                  {"Enter Destination"}
                                </div>
                              )}

                              {this.state.isAutoSuggestVisible === true ? (
                                <div
                                  className={
                                    this.props.cssfile
                                      .activity_autocompContainer
                                  }
                                >
                                  <div
                                    className={
                                      this.props.cssfile
                                        .input_search_destination
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="14"
                                    >
                                      <defs>
                                        <filter id="ve52ept4ca">
                                          <feFlood
                                            flood-color="#979797"
                                            flood-opacity="1"
                                            result="floodOut"
                                          ></feFlood>
                                          <feComposite
                                            operator="atop"
                                            in="floodOut"
                                            in2="SourceGraphic"
                                            result="compOut"
                                          ></feComposite>
                                          <feBlend
                                            in="compOut"
                                            in2="SourceGraphic"
                                          ></feBlend>
                                        </filter>
                                      </defs>
                                      <g filter="url(#ve52ept4ca)">
                                        <path
                                          fill-rule="evenodd"
                                          fill="#97144D"
                                          d="M10.928 9.513A6.022 6.022 0 0 0 1.116 2.53C-.812 5.24-.179 8.1 2.531 10.928a6.021 6.021 0 0 0 6.982 0l2.83 2.83a1.004 1.004 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.031a4.016 4.016 0 0 1 8.029.006 4.015 4.015 0 0 1-8.029-.006z"
                                        ></path>
                                      </g>
                                    </svg>
                                    <input
                                      type="text"
                                      tabIndex={1}
                                      autoFocus
                                      autoComplete={"off"}
                                      id="myTextField"
                                      placeholder={`Search for a place or activity`}
                                      maxLength={24}
                                      className={
                                        this.props.cssfile
                                          .search_widget_input_new
                                      }
                                      value={
                                        !cf.isValueEmpty(
                                          this.state.activityName
                                        )
                                          ? this.state.activityName
                                          : ""
                                      }
                                      onChange={(event) => {
                                        event.stopPropagation();
                                        this.onChanegeSetActivityName(
                                          event.target.value
                                        );
                                      }}
                                      onClick={(e) => {
                                        this.scrollToMyRef();
                                        e.stopPropagation();
                                        this.setState((prevState) => {
                                          return {
                                            ...prevState,
                                            isAutoSuggestVisible: true,
                                            isCalendarVisible: false,
                                            // activityName: '',
                                          };
                                        });
                                      }}
                                      onKeyDown={(e) => {
                                        e.stopPropagation();
                                        this.keyPress(e.key);
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      this.props.cssfile.activity_autocompWrap
                                    }
                                  >
                                    <div
                                      className={
                                        this.props.cssfile
                                          .activity_autocomplete_value
                                      }
                                      id="ACTIVITY-AUTO-SUGGEST-DATA-LIST"
                                    >
                                      <div
                                        className={
                                          this.props.cssfile
                                            .activity_suggestionLbl
                                        }
                                      >
                                        Suggestions
                                      </div>
                                      {/* <div className={this.props.cssfile.input_search_destination}>
                                            <svg width="50px" height="50px" viewBox="0,0,256,256"><g fill="#3479c5" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path></g></g></svg>  
                                            <input
                          type="text"
                          tabIndex={1}
                          autoFocus
                          autoComplete={"off"}
                          id="myTextField"
                          placeholder={`Search for a place or activity`}
                          className={this.props.cssfile.search_widget_input_new}
                          value={
                            !cf.isValueEmpty(this.state.activityName)
                              ? this.state.activityName
                              : ""
                          }
                          onChange={(event) => {
                            event.stopPropagation();
                            this.onChanegeSetActivityName(event.target.value);
                          }}
                          onClick={(e) => {
                            this.scrollToMyRef();
                            e.stopPropagation();
                            this.setState((prevState) => {
                              return {...prevState,
                                isAutoSuggestVisible: true,
                                isCalendarVisible: false,
                                activityName: '',
                              };
                            });
                          }}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                            this.keyPress(e.key);
                          }}
                        />
                                                </div> */}

                                      {/* {!cf.isValueEmpty(
                                        this.state.autoSuggestCountryData
                                      ) &&
                                        this.state.autoSuggestCountryData
                                          .length > 0 &&
                                        this.state.countryData.map(
                                          (element, index) => {
                                            return (
                                              <div
                                                id={index}
                                                key={element.search_text}
                                                className={
                                                  this.props.cssfile
                                                    .activity_autocomplete_element
                                                }
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.setState({
                                                    activityName:
                                                      element.search_text,
                                                    isAutoSuggestVisible: false,
                                                    destinationDropdown: false,
                                                  });
                                                  this.onClickSetActivityName(
                                                    element
                                                  );
                                                }}
                                              >
                                                <img src="images/location-01.svg" />
                                                <div>
                                                  <div
                                                    className={
                                                      this.props.cssfile
                                                        .activity_autocomplete_name
                                                    }
                                                  >
                                                    {element.search_text}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )} */}
                                      {!cf.isValueEmpty(
                                        this.state.autoSuggestCityData
                                      ) &&
                                      this.state.autoSuggestCityData.length >
                                        0 ? (
                                        this.state.autoSuggestCityData.map(
                                          (element, index) => {
                                            return (
                                              <div
                                                // id={index}
                                                // key={`city-${element.search_text}`}
                                                className={
                                                  this.props.cssfile
                                                    .activity_autocomplete_element
                                                }
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.setState({
                                                    activityName:
                                                      element.search_text,
                                                    isAutoSuggestVisible: false,
                                                    destinationDropdown: false,
                                                  });
                                                  this.onClickSetActivityName(
                                                    element
                                                  );
                                                }}
                                              >
                                                {/* <img src="images/location-01.svg" /> */}
                                                {/* <span></span> */}
                                                <div
                                                  className={
                                                    this.props.cssfile
                                                      .activity_autocompIcon
                                                  }
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="17"
                                                    height="22"
                                                  >
                                                    <path
                                                      fill-rule="evenodd"
                                                      d="m14.769 13.53-6.19 8.098c-.061.06-.152.12-.242.12a.276.276 0 0 1-.242-.12l-6.19-8.098a8.116 8.116 0 0 1 0-9.82C3.445 1.685 5.8.537 8.337.537c2.536 0 4.892 1.148 6.432 3.173a8.119 8.119 0 0 1 0 9.82zm-.483-9.458a7.482 7.482 0 0 0-5.949-2.931c-2.355 0-4.53 1.058-5.949 2.931a7.485 7.485 0 0 0 0 9.095l5.949 7.766 5.949-7.766a7.485 7.485 0 0 0 0-9.095zM8.337 13.47c-2.657 0-4.832-2.176-4.832-4.835S5.68 3.8 8.337 3.8s4.831 2.176 4.831 4.835-2.174 4.835-4.831 4.835zm0-9.066a4.242 4.242 0 0 0-4.228 4.231 4.242 4.242 0 0 0 4.228 4.23 4.242 4.242 0 0 0 4.228-4.23 4.242 4.242 0 0 0-4.228-4.231z"
                                                    ></path>
                                                  </svg>
                                                </div>
                                                <div>
                                                  <div
                                                    className={
                                                      this.props.cssfile
                                                        .activity_autocomplete_name
                                                    }
                                                  >
                                                    {element.search_text}
                                                  </div>
                                                  {/* <div className={this.props.cssfile.activity_autonwLabel}>United Arab Emirates</div> */}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                      ) : (
                                        <div
                                          key={`noresults`}
                                          className={
                                            this.props.cssfile
                                              .activity_autocomplete_noresult
                                          }
                                        >
                                          {/* <img src="images/location-01.svg" /> */}
                                          {/* <span></span> */}
                                          <div
                                            className={
                                              this.props.cssfile.noresult_icon
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="17"
                                              height="22"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                d="m14.769 13.53-6.19 8.098c-.061.06-.152.12-.242.12a.276.276 0 0 1-.242-.12l-6.19-8.098a8.116 8.116 0 0 1 0-9.82C3.445 1.685 5.8.537 8.337.537c2.536 0 4.892 1.148 6.432 3.173a8.119 8.119 0 0 1 0 9.82zm-.483-9.458a7.482 7.482 0 0 0-5.949-2.931c-2.355 0-4.53 1.058-5.949 2.931a7.485 7.485 0 0 0 0 9.095l5.949 7.766 5.949-7.766a7.485 7.485 0 0 0 0-9.095zM8.337 13.47c-2.657 0-4.832-2.176-4.832-4.835S5.68 3.8 8.337 3.8s4.831 2.176 4.831 4.835-2.174 4.835-4.831 4.835zm0-9.066a4.242 4.242 0 0 0-4.228 4.231 4.242 4.242 0 0 0 4.228 4.23 4.242 4.242 0 0 0 4.228-4.23 4.242 4.242 0 0 0-4.228-4.231z"
                                              ></path>
                                            </svg>
                                          </div>
                                          <div>
                                            <div
                                              className={
                                                this.props.cssfile
                                                  .activity_autocomplete_noresultnw
                                              }
                                            >
                                              No Results Found
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {this.state.recentSearchData.length >
                                        0 && (
                                        <>
                                          <div
                                            className={
                                              this.props.cssfile
                                                .activity_RecentLbl
                                            }
                                          >
                                            Recent Search
                                          </div>
                                          {this.state.recentSearchData.map(
                                            (ele) => {
                                              return (
                                                <div
                                                  className={
                                                    this.props.cssfile
                                                      .activity_autocomplete_element
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      this.props.cssfile
                                                        .activity_autocompIcon
                                                    }
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="19px"
                                                      height="18px"
                                                    >
                                                      <path
                                                        fill-rule="evenodd"
                                                        fill="rgb(0, 0, 0)"
                                                        d="M9.969,3.403 L9.969,8.830 C9.969,8.954 10.019,9.072 10.107,9.160 L13.970,12.996 C14.058,13.084 14.108,13.203 14.108,13.327 C14.108,13.584 13.898,13.792 13.640,13.792 C13.515,13.792 13.395,13.742 13.307,13.655 L9.445,9.818 C9.181,9.556 9.033,9.200 9.033,8.830 L9.033,3.403 C9.033,3.146 9.243,2.938 9.501,2.938 C9.760,2.938 9.969,3.146 9.969,3.403 ZM10.716,17.733 C10.314,17.790 9.908,17.819 9.501,17.819 C8.255,17.820 7.023,17.553 5.892,17.035 C5.878,17.030 5.865,17.024 5.852,17.017 C5.623,16.899 5.533,16.619 5.652,16.391 C5.770,16.162 6.053,16.073 6.282,16.191 C9.084,17.454 12.372,16.942 14.650,14.888 C17.802,12.045 18.037,7.203 15.175,4.072 C12.314,0.941 7.439,0.707 4.287,3.550 C1.135,6.393 0.900,11.235 3.761,14.366 L3.761,13.357 C3.761,13.100 3.971,12.892 4.230,12.892 C4.488,12.892 4.698,13.100 4.698,13.357 L4.698,15.449 C4.698,15.706 4.488,15.915 4.230,15.915 L2.123,15.915 C1.864,15.915 1.655,15.706 1.655,15.449 C1.655,15.193 1.864,14.984 2.123,14.984 L3.076,14.984 C1.916,13.709 1.168,12.119 0.926,10.417 C0.260,5.712 3.559,1.361 8.296,0.699 C13.031,0.039 17.408,3.315 18.075,8.018 C18.740,12.719 15.447,17.066 10.716,17.733 Z"
                                                      />
                                                    </svg>
                                                  </div>
                                                  <div className="">
                                                    <div
                                                      className={
                                                        this.props.cssfile
                                                          .activity_autocomplete_name
                                                      }
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.setState({
                                                          activityName:
                                                            ele.search_text,
                                                          isAutoSuggestVisible: false,
                                                        });
                                                        this.onClickSetActivityName(
                                                          ele
                                                        );
                                                      }}
                                                    >
                                                      {ele.search_text}
                                                    </div>
                                                    {/* <div className={this.props.cssfile.activity_autonwLabel}>United Arab Emirates</div> */}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${this.props.cssfile.activity_search_column} ${this.props.cssfile.activity_search_date}`}
                          tabIndex={2}
                          ref={this.guestDetailWrapper}
                          onFocus={(e) => {
                            e.stopPropagation();
                            this.setState({ isCalendarVisible: true });
                          }}
                        >
                          <DesktopCalender
                            label="When"
                            styles={this.props.calendarStyles}
                            value={this.state.checkInOutValue}
                            placholder="Select Date"
                            onChangeDate={(dateVal) => {
                              this.onChangeCheckInOutValue(dateVal);
                            }}
                            // iconImage={<img src="images/calendar-01.svg" />}
                            startDate={cf.getDateWithFormat(
                              new Date(),
                              this?.props?.dateFormat
                            )}
                            startDateLimit={cf.getDateWithFormat(
                              new Date(),
                              this?.props?.dateFormat
                            )}
                            dateFormat={this?.props?.dateFormat}
                            changeVisibility={(bool) => {
                              // this.scrollToMyRef();
                              this.onChangeVisibility(bool);
                            }}
                            isVisible={this.state.isCalendarVisible}
                            doubleSelection={false}
                            type={"double"}
                          />
                          <span
                            className={this.props.cssfile.date_dropdown_icon}
                            onClick={() =>
                              this.setState({ isCalendarVisible: true })
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18px"
                              height="11px"
                              className={
                                this.state.isCalendarVisible
                                  ? this.props.cssfile.arrow_rotate
                                  : ""
                              }
                            >
                              <path
                                fill-rule="evenodd"
                                fill="rgb(38, 106, 172)"
                                d="M17.373,2.754 L9.979,10.211 C9.840,10.351 9.679,10.454 9.508,10.522 C9.003,10.729 8.403,10.626 7.994,10.213 L0.599,2.756 C0.054,2.206 0.054,1.315 0.599,0.765 C1.145,0.215 2.029,0.215 2.574,0.765 L8.985,7.230 L15.399,0.763 C15.944,0.213 16.828,0.213 17.373,0.763 C17.919,1.313 17.919,2.204 17.373,2.754 Z"
                              />
                            </svg>
                          </span>
                          {/* <span>
                      <small></small>
                    </span> */}
                          {this.state.isDateError && (
                            <div className={this.props.cssfile.errordate}>
                              {"Please select Date"}
                            </div>
                          )}
                        </div>

                        {/* <div
                    className={`${this.props.cssfile.activity_search_details_row} ${this.props.cssfile.activity_nw}`}
                  > */}
                        <div
                          className={this.props.cssfile.activity_search_class}
                        >
                          <button
                            tabIndex={3}
                            className={
                              this.props.isSrp
                                ? this.props.cssfile.srp_search_widget_btn
                                : this.props.cssfile.activity_search_widget_btn
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              this.onClickSearchActivity();
                            }}
                            onFocus={(e) => {
                              e.stopPropagation();
                              this.onInputFocus("search");
                            }}
                          >
                            {/* <svg width="50px" height="50px" viewBox="0,0,256,256">
                        <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none">
                        <g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                        </g></g>
                      </svg> */}
                            Activity Search
                          </button>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
