import React,{Component} from 'react';


class FareType  extends Component {
    constructor(props) {
        super(props);
        this.state = {
          hoverIndex: -1
      };
    }
    // state = {  }

    handleMouseEnter = (index) => {
      this.setState({ hoverIndex: index });
    };

    handleMouseLeave = () => {
        this.setState({ hoverIndex: -1 });
    };
    render() { 
        return ( 
            <div className={this.props.disabled?`${this.props.styles?.popular_destination1} ${this.props.styles?.specialfare_disable}`:this.props.styles?.popular_destination1}>
            <div className={this.props.styles?.populardest_title}>
              {this.props.title}
            </div>
            <div className={this.props.styles?.destination_container}>
              {this.props.faretypelist.map((resfare, resind) => {
                return (
                  <div
                    key={resind}
                    className={
                        resfare?.isChecked
                        ? this.props.styles?.destination_active
                        : this.props.styles?.destination_item
                    }
                    onClick={() => this.props.handleOnClick(resfare, resind)}
                    onMouseEnter={() => this.handleMouseEnter(resind)}
                    onMouseLeave={this.handleMouseLeave}
                  >
                    <div className={this.props.styles?.property_count}>
                      {resfare?.label} 
                    </div>
                    {this.state.hoverIndex === resind && this.props.show && resfare.msg && (
                        <div className={this.props.styles?.fare_tooltip}>{resfare.msg}</div>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
         );
    }
}
 
export default FareType;