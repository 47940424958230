import React, { useEffect, useState } from "react";

import commonFunction from "../../../../lib/utils/common";
import search_type_const from "../../../../lib/utils/hotel/constants.json";
// import styles from "./index.module.css";

export default function HotelAutoComplete(props) {
  // console.log("propsprops",props)
  let hotelNameArry = props?.value?.split(",");
  const region = hotelNameArry[0]?.trim();
  return (
    <div className={props.styles?.hotel_search_wid_inputfield}>
      <label>{props.label}</label>
      <div className={props.styles?.hotel_search_wid_inputfield_main}>
        {/* <img src={props.leftIcon} className={props.styles.hotel_search_left_icon} /> */}
        <div className={props.styles?.hotel_srchCont}>
          <div className={props.styles?.search_auto_flx}>
            <span
              className={props.styles?.hotel_search_left_icon}
              onClick={() => {
                props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
                props.onChangeHotelName("");
                props.onClickHotelInput();
              }}
            >
              {props.leftIcon}
            </span>
            <div className={`${props.styles?.search_widget_img_flex} ${props.styles?.search_widSRp_flx}`}>
              {props.isSrp && (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20">
                  <path
                    fill-rule="evenodd"
                    fill="#01445F"
                    d="M13.74 14.634c-2.441 2.48-3.93 4.946-4.04 5.046-1.397.06-2.544.06-3.312 0-.011-.1-2.229-2.566-4.003-5.046C1.33 12.746 0 10.549 0 8.594 0 4.33 3.463.868 7.729.868s7.729 3.462 7.729 7.726c0 1.955-1.033 4.152-1.718 6.04zM7.729 1.682A6.917 6.917 0 0 0 .813 8.594c0 1.806.985 3.823 2.647 5.567 1.31 2.408 3.464 4.296 3.464 4.296.461.403 1.806.403 1.61 0 0 0 2.155-1.888 3.878-4.296 1.248-1.744 2.233-3.761 2.233-5.567a6.917 6.917 0 0 0-6.916-6.912zm0 10.571c-2.021 0-3.049-1.64-3.049-3.66 0-2.019 1.028-3.659 3.049-3.659 2.02 0 3.661 1.64 3.661 3.659 0 2.02-1.641 3.66-3.661 3.66zm0-6.506c-1.572 0-2.848 1.483-2.848 2.846a2.848 2.848 0 0 0 5.695 0c0-1.363-1.275-2.846-2.847-2.846z"
                  />
                </svg>
              )}
              {!props.isSrp && (
                <h1
                  onClick={() => {
                    props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
                    props.onChangeHotelName("");
                    props.onClickHotelInput();
                  }}
                >
                  {" "}
                  {region}{" "}
                </h1>
              )}
              <input
                type="text"
                autoComplete={"off"}
                id="myTextField"
                tabindex="1"
                onFocus={props.onInputFocus}
                onKeyDown={props.onKeyDownArrow}
                placeholder={
                  !commonFunction.isValueEmpty(props.placholder)
                    ? props.placholder
                    : ""
                }
                className={props.styles?.search_widget_input_new}
                value={
                  !commonFunction.isValueEmpty(props.value) ? props.value : ""
                }
                onChange={(event) => {
                  props.onChangeHotelName(event.target.value);
                }}
                onClick={() => {
                  props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
                  props.onChangeHotelName("");
                  props.onClickHotelInput();
                }}
              />
            </div>
          </div>

          {!commonFunction.isValueEmpty(props.isError) &&
          props.isError === true ? (
            <div className={props.styles?.hotel_search_error_message}>
              <span>
                {!commonFunction.isValueEmpty(props.errorMessage)
                  ? props.errorMessage
                  : "Enter Destination"}
              </span>
            </div>
          ) : null}
        </div>
        {props.expandAutoCompleteBool === true ? (
          <div className={props.styles?.search_section_arrow}>
            <div
              className={props.styles?.hotel_autocomplete_value}
              id="hotelDataArray"
            >
              {!commonFunction.isValueEmpty(props.autoCompleteData) &&
                props.autoCompleteData.length > 0 &&
                props.autoCompleteData.map((element) => {
                  // console.log("element",element)
                  return (
                    <div>
                      <div
                        id="hotelData"
                        className={props.styles?.hotel_autocomplete_element}
                        onClick={() => {
                          props.onClickHotelName(element);
                          props.isAutoCompleteExpand(false);
                        }}
                      >
                        <img
                          src={
                            element.property_count === 0
                              ? "/images/hotels.svg"
                              : "/theme4/locationIcon.svg"
                          }
                          alt=""
                        />
                        <div className={props.styles.par_div}>
                          <div
                            className={props.styles?.hotel_autocomplete_name}
                          >
                            {element.search_text}{" "}
                            <span>
                              {" "}
                              {element?.search_type
                                ? search_type_const[element?.search_type]?.text
                                : " "}
                            </span>
                          </div>
                          {/* {element.search_type==search_type_const[element.search_type]? search_type_const[element.search_type].text:element?.dest_type} */}
                          {/* {element.property_count === 0 ? (
                        ""
                      ) : (
                        <div className={props.styles?.hotel_autocomplete_tag}>
                          {element.property_count} Properties
                        </div>
                      )} */}
                        </div>
                      </div>
                      <hr className={props.styles?.border_hrBottom} />
                    </div>
                  );
                })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
