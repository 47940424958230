import React, { useEffect, useState } from "react";
import cf from "../../../../lib/utils/common";
import DesktopCalender from "../../../calendar/DesktopCalender";
import FlightAutoComplete from "./FlightAutoComplete";

export default function MulticityForm({props,index,data}) {
    // console.log(props,"propsnew");
   
 return (
  <div className={props.styles.repeat_comp}>
    <div className={props.styles.flight_search_details_container}>
    <div className={props.styles.flight_search_details_main}>
      <div className={props.styles.flight_search_details_row}> 
        <div
          className={
           props.styles.flight_search_column +
            " " +
            props.styles.flight_search_depart_multicity
          }
        >
          <FlightAutoComplete
            label="Departure From"
            value={props.state.multicityFlightData[index].departureValue}
            state={props.state}
            data={data}
            index={index}
            styles={props.styles}
            onclickFlightAutoComplete={() =>
              props.onclickFlightAutoComplete("departure","isDeparture",index)
            }
            placholder="City or Airport"
            onClickSourceAndDestination={(city, airPortCode) =>
             props.onClickSetDepartureGoingToValue(
                city,
                airPortCode,
                "departure",
                index,
              )
            }
            onChangeSourceAndDestination={(value) =>
              props.onChangeSetDepartureGoingToValue(
                value,
                "departure",
                index
              )
            }
            isAutoCompleteExpand={(bool) =>
              props.setState({ expandDepartureBoolean: bool })
            }
            autoCompleteData={
              !cf.isValueEmpty(props.getDepartureSuggestionList)
                ? props.getDepartureSuggestionList
                : []
            }
            expandAutoCompleteBool={
              !cf.isValueEmpty(props.state.expandDepartureBoolean)
                ? props.state.expandDepartureBoolean
                : false
            }
            leftIcon={
              <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="30px"
                height="30px"
                viewBox="0 0 30 30"
                enableBackground="new 0 0 30 30"
              >
                <path
                  fill="currentColor"
                  d="M4.108,11.369l-2.693,1.089l5.507,4.903l8.443-2.754l-2.087,6.658l3.571-1.454l3.358-6.778l6.325-2.058l0,0
                                 c0.702-0.193,1.292-0.669,1.631-1.314c0.34-0.645,0.396-1.401,0.157-2.089c-0.239-0.688-0.753-1.246-1.419-1.542
                                 c-0.665-0.295-1.424-0.302-2.094-0.018l-6.174,2.27L10.01,4.621L6.409,6.073l6.778,4.54l-5.628,2.452L4.108,11.369z M16.425,19.358
                                 l-2.179,0.876l1.846-5.839l3.328-1.089L16.425,19.358z M7.709,6.194l2.3-0.938l8.595,3.692l6.415-2.361l0,0
                                 c0.519-0.235,1.112-0.241,1.635-0.017c0.524,0.224,0.929,0.658,1.116,1.195c0.188,0.538,0.141,1.129-0.129,1.63
                                 c-0.271,0.501-0.739,0.866-1.291,1.004L7.075,16.694L2.536,12.67l1.543-0.636l3.45,1.725l6.929-3.025L7.709,6.194z"
                />
                <rect
                  x="9.625"
                  y="23.5"
                  fill="currentColor"
                  width="18.841"
                  height="0.75"
                />
              </svg>
            }
            errorMessage={data?.error?.org}
            isError={data?.error?.org && (data.org === "" ? true : false)}
            listWrapper={props.multicityDepartureWrapper}
            // multicityWrapper={props.multicityDepartureWrapper}
            onKeyDownArrow={(event) => {
              props.onKeyDownArrow(
                event,
                "flightDataArray",
                props.getDepartureSuggestionList,
                "departure"
              );
            }}
          />
        </div>
        {/* <img src="/images/home-fgt.png" class={this.props.styles.flight_swap} alt="flight arrow" onClick={() => { this.onClickSwap() }}></img> */}
        <span
          className={props.styles.flight_swap_multicity}
          tabIndex="2"
          onKeyDown={props.onSwapKeyDownHandler}
          onClick={() => {
            props.onClickSwap();
          }}
        >
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            width="30px"
            height="30px"
            viewBox="0 0 30 30"
            enableBackground="new 0 0 30 30"
          >
            <g>
              <path
                fill="currentColor"
                d="M14.719,28.813C7.103,28.813,0.906,22.616,0.906,15S7.103,1.188,14.719,1.188S28.531,7.384,28.531,15
S22.335,28.813,14.719,28.813z M14.719,2.188C7.654,2.188,1.906,7.935,1.906,15c0,7.064,5.748,12.813,12.813,12.813
c7.064,0,12.813-5.748,12.813-12.813C27.531,7.935,21.783,2.188,14.719,2.188z"
              />
            </g>
            <g>
              <g>
                <defs>
                  <rect
                    id="SVGID_1_"
                    x="6.145"
                    y="9.9"
                    width="17.148"
                    height="12.891"
                  />
                </defs>
                <g clipPath="url(#SVGID_2_)">
                  <g>
                    <path
                      fill="currentColor"
                      d="M5.689,15.855l6.875,6.92l0.729-0.713L8.09,16.855h15.203v-1.02H8.09"
                    />
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <rect
                    id="SVGID_3_"
                    x="6.145"
                    y="7.208"
                    width="17.148"
                    height="12.891"
                  />
                </defs>
                <g clipPath="url(#SVGID_4_)">
                  <g>
                    <path
                      fill="currentColor"
                      d="M23.747,14.145l-6.874-6.922l-0.729,0.713l5.203,5.208H6.145v1.019h15.203"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <div
          className={
            props.styles.flight_search_column +
            " " +
            props.styles.flight_search_depart_multicity
          }
        >
          <FlightAutoComplete
            label="Going To"
            value={props.state.multicityFlightData[index].goingToValue}
            state={props.state}
            data={data}
            index={index}
            styles={props.styles}
            onclickFlightAutoComplete={() =>
              props.onclickFlightAutoComplete("goingTo","isGoingTo",index)
            }
            placholder="City or Airport"
            onClickSourceAndDestination={(city, airPortCode) =>
              props.onClickSetDepartureGoingToValue(
                city,
                airPortCode,
                "goingTo",
                index,
              )
            }
            onChangeSourceAndDestination={(value) =>
              props.onChangeSetDepartureGoingToValue(value, "goingTo",index)
            }
            isAutoCompleteExpand={(bool) =>
              props.setState({ expandGoingToBoolean: bool })
            }
            autoCompleteData={
              !cf.isValueEmpty(props.getGoingToSuggestionList)
                ? props.getGoingToSuggestionList
                : []
            }
            expandAutoCompleteBool={
              !cf.isValueEmpty(props.state.expandGoingToBoolean)
                ? props.state.expandGoingToBoolean
                : false
            }
            leftIcon={
              <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="30px"
                height="30px"
                viewBox="0 0 30 30"
                enableBackground="new 0 0 30 30"
              >
                <path
                  fill="currentColor"
                  d="M5.727,6.708l-2.85-0.559l1.935,7.115l8.576,2.304l-5.385,4.438l3.785,0.732l6.517-3.843l6.421,1.732l0,0
                                 c0.694,0.221,1.448,0.146,2.084-0.21c0.637-0.355,1.098-0.959,1.272-1.664c0.176-0.708,0.05-1.455-0.348-2.067
                                 c-0.395-0.61-1.026-1.031-1.743-1.159l-6.41-1.471l-5.224-7.778l-3.81-0.75l3.198,7.505l-6.053-1.021L5.727,6.708z M11.68,20.126
                                 l-2.304-0.455l4.735-3.883l3.382,0.905L11.68,20.126z M11.57,4.34l2.44,0.471l5.182,7.788l6.663,1.526l0,0
                                 c0.563,0.086,1.063,0.406,1.378,0.879c0.318,0.474,0.42,1.059,0.283,1.61c-0.137,0.553-0.499,1.022-0.999,1.295
                                 c-0.501,0.272-1.092,0.322-1.63,0.137L5.303,12.788L3.699,6.938l1.641,0.311l1.947,3.33l7.457,1.25L11.57,4.34z"
                />
                <rect
                  x="8.625"
                  y="24.5"
                  fill="currentColor"
                  width="18.841"
                  height="0.75"
                />
              </svg>
            }
            // errorMessage={
            //   props.state.goingToValue !== "" &&
            //   props.state.departureValue === props.state.goingToValue
            //     ? "Origin and destination cannot be same"
            //     : "Enter Destination City"
            // }
            errorMessage={data?.error?.dest}
            isError={data?.error?.dest && ((data.dest === "" || data.dest === data.org) ? true : false)}
            listWrapper={props.multicityReturnWrapper}
            // multicityWrapper={props.multicityReturnWrapper}
            onKeyDownArrow={(event) => {
              props.onKeyDownArrow(
                event,
                "flightDataArray",
                props.getGoingToSuggestionList,
                "goingTo"
              );
            }}
          />
        </div>
        <div
          className={
            props.styles.flight_search_column +
            " " +
            props.styles.flight_search_depart_going_date_multicity
          }
        >
          <DesktopCalender
            label="Departure Date"
            styles={props.styles}
            value={
              !cf.isValueEmpty(props.state.multicityFlightData[index].odt)
                ? props.state.multicityFlightData[index].odt
                : ""
            }
            placholder="Departure Date"
            onChangeDate={(value) => {
              props.onChangeDepartureGoingDateValue(
                value,
                "goindeparturegTo",
                index
              );
            }}
            iconImage={
              <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="30px"
                height="30px"
                viewBox="0 0 30 30"
                enableBackground="new 0 0 30 30"
              >
                <g>
                  <path
                    fill="currentColor"
                    d="M7.258,17.91H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.002,1.002,1.002h0.083
                                     c0.558,0,1.003-0.445,1.003-1.002v-0.111C8.262,18.355,7.816,17.91,7.258,17.91z"
                  />
                  <path
                    fill="currentColor"
                    d="M11.322,17.91h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.084
                                     c0.556,0,1.002-0.445,1.002-1.002v-0.111C12.324,18.355,11.878,17.91,11.322,17.91z"
                  />
                  <path
                    fill="currentColor"
                    d="M15.414,17.91h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.002,1.002,1.002h0.112
                                     c0.556,0,1.001-0.445,1.001-1.002v-0.111C16.415,18.355,15.97,17.91,15.414,17.91L15.414,17.91z"
                  />
                  <path
                    fill="currentColor"
                    d="M7.258,13.792H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.001,1.002,1.001h0.083
                                     c0.558,0,1.003-0.445,1.003-1.001v-0.111C8.262,14.237,7.816,13.792,7.258,13.792z"
                  />
                  <path
                    fill="currentColor"
                    d="M11.322,13.792h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.084
                                     c0.556,0,1.002-0.445,1.002-1.001v-0.111C12.324,14.237,11.878,13.792,11.322,13.792z"
                  />
                  <path
                    fill="currentColor"
                    d="M15.414,13.792h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.001,1.002,1.001h0.112
                                     c0.556,0,1.001-0.445,1.001-1.001v-0.111C16.415,14.237,15.97,13.792,15.414,13.792z"
                  />
                  <path
                    fill="currentColor"
                    d="M19.478,17.91h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.002,1.003,1.002h0.111
                                     c0.556,0,1.001-0.445,1.001-1.002v-0.111C20.506,18.355,20.033,17.91,19.478,17.91z"
                  />
                  <path
                    fill="currentColor"
                    d="M23.568,17.91h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.112
                                     c0.557,0,1.002-0.445,1.002-1.002v-0.111C24.57,18.355,24.125,17.91,23.568,17.91z"
                  />
                  <path
                    fill="currentColor"
                    d="M19.478,13.792h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.001,1.003,1.001h0.111
                                     c0.556,0,1.001-0.445,1.001-1.001v-0.111C20.506,14.237,20.033,13.792,19.478,13.792z"
                  />
                  <path
                    fill="currentColor"
                    d="M23.568,13.792h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.112
                                     c0.557,0,1.002-0.445,1.002-1.001v-0.111C24.57,14.237,24.125,13.792,23.568,13.792z"
                  />
                  <path
                    fill="currentColor"
                    d="M7.258,22.002H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.445,1.004,1.002,1.004h0.083
                                     c0.558,0,1.003-0.445,1.003-1.004v-0.109C8.262,22.473,7.816,22.002,7.258,22.002z"
                  />
                  <path
                    fill="currentColor"
                    d="M11.322,22.002h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.084
                                     c0.556,0,1.002-0.445,1.002-1.004v-0.109C12.324,22.473,11.878,22.002,11.322,22.002z"
                  />
                  <path
                    fill="currentColor"
                    d="M15.414,22.002h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.446,1.004,1.002,1.004h0.112
                                     c0.556,0,1.001-0.445,1.001-1.004v-0.109C16.415,22.473,15.97,22.002,15.414,22.002z"
                  />
                  <path
                    fill="currentColor"
                    d="M19.478,22.002h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.109c0,0.559,0.445,1.004,1.003,1.004h0.111
                                     c0.556,0,1.001-0.445,1.001-1.004v-0.109C20.506,22.473,20.033,22.002,19.478,22.002z"
                  />
                  <path
                    fill="currentColor"
                    d="M23.568,22.002h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.112
                                     c0.557,0,1.002-0.445,1.002-1.004v-0.109C24.57,22.473,24.125,22.002,23.568,22.002z"
                  />
                  <path
                    fill="currentColor"
                    d="M28.577,10.535L28.577,10.535V8.281c0-1.726-1.392-3.118-3.089-3.118h-1.643V4.189
                                     c0-1.196-0.973-2.171-2.171-2.171c-1.196,0-2.17,0.975-2.17,2.171v0.974l-8.238,0V4.19c0-1.197-0.974-2.171-2.17-2.171
                                     c-1.197,0-2.17,0.974-2.17,2.171v0.974H5.283c-1.725,0-3.117,1.392-3.117,3.118v16.643c0,1.697,1.392,3.088,3.117,3.088h20.232
                                     c1.698,0,3.09-1.391,3.09-3.088L28.577,10.535L28.577,10.535L28.577,10.535z M20.785,4.189c0-0.473,0.39-0.863,0.861-0.863
                                     c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863c-0.472,0-0.861-0.39-0.861-0.863V4.189z M8.206,4.189
                                     c0-0.473,0.389-0.863,0.862-0.863c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863
                                     c-0.473,0-0.862-0.39-0.862-0.863V4.189z M5.254,6.472h1.67c0.112,1.085,1.03,1.948,2.17,1.948c1.113,0,2.031-0.863,2.143-1.948
                                     h8.266c0.111,1.085,1.03,1.948,2.143,1.948c1.113,0,2.031-0.863,2.144-1.948h1.67c0.974,0,1.781,0.807,1.781,1.809v1.615H3.446
                                     V8.28C3.446,7.279,4.253,6.472,5.254,6.472L5.254,6.472z M25.488,26.703H5.254c-1.002,0-1.808-0.807-1.808-1.779V11.175H27.27
                                     v13.749C27.27,25.896,26.462,26.703,25.488,26.703z"
                  />
                </g>
              </svg>
            }
            startDate={
              !cf.isValueEmpty(props.state.multicityFlightData[index].odt)
                ? props.state.multicityFlightData[index].odt
                : ""
            }
            endDate={
              !cf.isValueEmpty(props.state.goingToDateValue)
                ? props.state.goingToDateValue
                : ""
            }
            startDateLimit={
              !cf.isValueEmpty(props.state.dateLimit)
                ? props.state.dateLimit
                : ""
            }
            dateFormat={"DD-MMM-YYYY"}
            changeVisibility={(bool) =>
              props.onchangeVisibility(bool, "departure",index)
            }
            isVisible={props.state.multicityFlightData[index].expandDepartureDateBoolean}
            type={"double"}
            id={index}
            doubleSelection={false}
          />
        </div>
      </div>
      {index !==0 && index !==1 &&
      <div
        className={props.styles.remove_flight_button }
        id="decrease"
        onClick={() => props.RemoveFlightObject(index) }
        // onFocusCapture={onFocusHandler}
        // tabIndex="2"
        // onKeyDown={keyDownHandler}
      >
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className={props.styles.minus_pax}
          width="30px"
          height="30px"
          viewBox="0 0 30 30"
          enableBackground="new 0 0 30 30"
        >
          <g>
            <path
              fill="currentColor"
              d="M15.401,29.146c-7.616,0-13.813-6.196-13.813-13.813S7.785,1.521,15.401,1.521
		c7.617,0,13.813,6.196,13.813,13.813S23.018,29.146,15.401,29.146z M15.401,2.521c-7.065,0-12.813,5.747-12.813,12.813
		c0,7.064,5.748,12.813,12.813,12.813s12.813-5.748,12.813-12.813C28.214,8.268,22.466,2.521,15.401,2.521z"
            />
          </g>
          <path
            fill="currentColor"
            d="M24.65,15.333c0,0.368-0.299,0.666-0.667,0.666H6.818c-0.368,0-0.667-0.298-0.667-0.666l0,0
	c0-0.368,0.298-0.667,0.667-0.667h17.166C24.352,14.666,24.65,14.965,24.65,15.333L24.65,15.333z"
          />
        </svg>
      </div>
}
      </div>
      </div>  
      </div>     
 )
  
 
}