import React from "react";
import FlightAutoComplete from "../../widgets/flightSearchWidget/theme4/FlightAutoComplete";
import DesktopCalender from "../../calendar/flightCalender/theme4/DesktopCalender"
import cf from "../../../lib/utils/common";
import PaxConfig from "../../widgets/flightSearchWidget/theme4/PaxConfig";
import ModifyAdvanceSearchMulticityForm from "./ModifyAdvanceSearchMulticityForm"

const ModifyAdvanceSearchMulticity = ( props ) => {
  return (
    <>
   {props?.state?.multicityFlightData.map((data,i)=>{
                    // console.log(i,">>>>");
                    return  (
                      <div className="">
                    <ModifyAdvanceSearchMulticityForm props={props} 
                    data={data}
                    index={i}
                    />
                    </div>
                    )
                  })}

                                 {/* common Section */}
    <div className={props.styles.main_adv_multicity}>
     
     <div className={props.styles.advanc_addition_tabs}>
   
  
   {/* Pax Select */}
<div
            className={
              props.styles1.flight_search_column +
              " " +
              props.styles1.flight_search_class
            }


            ref={props.passengerDropdownWrapper}

          >
            <div
              className={props.styles1.flight_travel_cont}
              onClick={() => props.onClickOpenPassangerList()}
            >
              <label>Traveler</label>
              <div className={props.styles1.flight_travIconMulti}>
              <svg width="15px" height="15px" viewBox="0 0 200 200"><g><path fill="#30C8CA" d="M100,188.716c-48.918,0-88.716-39.798-88.716-88.716S51.082,11.284,100,11.284S188.716,51.082,188.716,100 S148.918,188.716,100,188.716z M45.883,162.507c14.516,12.586,33.442,20.209,54.117,20.209c20.675,0,39.602-7.624,54.118-20.209 c-1.134-27.888-38.089-30.935-54.118-30.935S47.016,134.62,45.883,162.507z M100,17.284c-45.61,0-82.716,37.106-82.716,82.716 c0,22.237,8.82,42.452,23.146,57.331c1.983-10.823,9.079-19.279,20.815-24.682c12.708-5.85,28.09-7.077,38.755-7.077 c10.666,0,26.048,1.228,38.756,7.077c11.736,5.402,18.832,13.858,20.814,24.682c14.326-14.88,23.146-35.094,23.146-57.331 C182.716,54.39,145.609,17.284,100,17.284z M100,103c-17.409,0-31.572-14.163-31.572-31.572c0-17.409,14.163-31.572,31.572-31.572 c17.409,0,31.572,14.163,31.572,31.572C131.572,88.837,117.409,103,100,103z M100,45.856c-14.1,0-25.572,11.472-25.572,25.572 C74.428,85.528,85.9,97,100,97c14.101,0,25.572-11.472,25.572-25.572C125.572,57.328,114.101,45.856,100,45.856z"></path></g></svg>
              </div>
              <div
                className={`${props.styles1.flight_cabin_class_nameAdvance} ${props.styles1.flight_travler}`}
              >
                {`${props.state.paxAdultValue+props.state.paxChildrenValue+props.state.paxInfantValue}`}&nbsp;Passenger
              </div>
              {/* <div
                className={
                  props.styles1.flight_cabin_class_arrow_pass
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 30 30"
                  enableBackground="new 0 0 30 30"
                >
                  <path id="Path_1101" data-name="Path 1101" d="M339.293,85.293l1.414,1.414L334,93.414l-6.707-6.707,1.414-1.414L334,90.586Z" transform="translate(-327.293 -85.293)" fill-rule="evenodd"/>
                </svg>
              </div> */}
            </div>
            {props.state.openPaxList === true ? (
              <div
                className={
                  props.styles1.flight_search_pax_select_main
                }
               
              >
                <div
                  className={
                    props.styles1.flight_search_column +
                    " " +
                    props.styles1.flight_search_pax+
                    " "+
                    props.styles1.wd_100
                  }
                >
                  <div className={props.styles1.flight_search_pax_newleft}>
                  <label>Adult</label>
                  <span>(+12 Years)</span>
                  </div>
                  <PaxConfig
                    value={
                      !cf.isValueEmpty(props.state.paxAdultValue)
                        ? props.state.paxAdultValue
                        : 0
                    }
                    onChangePaxValue={(value, type) =>
                      props.onClickChangePaxNumber("adult", value, type)
                    }
                    maxLimit={9}
                    minLimit={1}
                    totalPax={
                      +props.state.paxChildrenValue +
                      +props.state.paxAdultValue
                    }
                    styles={props.styles1}
                  />
                  
                </div>

                <div
                  className={
                    props.styles1.flight_search_column +
                    " " +
                    props.styles1.flight_search_pax+
                    " "+
                    props.styles1.wd_100
                  }
                >
                  <div className={props.styles1.flight_search_pax_newleft}>
                  <label>Children</label>
                  <span>(2-11 Years)</span>
                  </div>
                  <PaxConfig
                    value={
                      !cf.isValueEmpty(props.state.paxChildrenValue)
                        ? props.state.paxChildrenValue
                        : 0
                    }
                    onChangePaxValue={(value, type) =>
                      props.onClickChangePaxNumber(
                        "children",
                        value,
                        type
                      )
                    }
                    maxLimit={9}
                    minLimit={0}
                    totalPax={
                      +props.state.paxChildrenValue +
                      +props.state.paxAdultValue
                    }
                    styles={props.styles1}
                  />
                </div>

                <div
                  className={
                    props.styles1.flight_search_column +
                    " " +
                    props.styles1.flight_search_pax+
                    " "+
                    props.styles1.wd_100
                  }
                >
                  <div className={props.styles1.flight_search_pax_newleft}>
                  <label>Infant</label>
                  <span>(Below 2 Years)</span>
                  </div>
                  <PaxConfig
                    value={
                      !cf.isValueEmpty(props.state.paxInfantValue)
                        ? props.state.paxInfantValue
                        : 0
                    }
                    onChangePaxValue={(value, type) =>
                      props.onClickChangePaxNumber("infant", value, type)
                    }
                    maxLimit={+props.state.paxAdultValue}
                    minLimit={0}
                    totalPax={props.state.paxInfantValue}
                    styles={props.styles1}
                  />
                </div>
              </div>
            ) : null}
          </div>
{/*    
             <div className={props.styles.adv_tabs_split}>
             <div className={props.styles.icon_box_adv}>
             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="13"><path fill-rule="evenodd" fill="#01445F" d="m.924 8.43 6.713 4.373a.93.93 0 0 0 .474.152 1.02 1.02 0 0 0 .331-.07l15.377-6.762c1.709-.712 1.705-3.328-.045-4.004a2.296 2.296 0 0 0-1.807 0L19.64 3.401 9.526.212a.79.79 0 0 0-.589.029l-2.253.99c-.413.183.166.667-.417 1.08a.822.822 0 0 0 .376.398l6.46 3.581-4.689 2.67-4.768-3.161a.82.82 0 0 0-.682 0l-1.294.819C.652 6.797.46 7.278.639 7.694c.06.14.159.261.285.736zm2.354-.989L8.45 9.733c-.277.06-.136.085.004.074a.866.866 0 0 0 .266-.061l6.607-2.915a1.02 1.02 0 0 0 .266-.184.82.82 0 0 0-.221-1.294L8.916 2.29l.389-.433 9.542 3.603c.633-.353.418.46.609.04l3.148-1.882a.67.67 0 0 1 .52 0c.274.127.393 1.102.265.728a.54.54 0 0 1-.265.266L8.205 11.199 3.33 7.539l-.052-.098z"></path></svg>
      </div>
    <div className={props.styles.departure_main_box}>
      <div className={props.styles.label_fname}>
        <label htmlFor="fname" className="lablename-htl">
          Airlines
        </label>
      </div>

      <div>{props.multiSelectDropdown}</div>
    </div>
    </div> */}

  
             <div className={`${props.styles.adv_tabs_split} ${props.styles.adv_tabsSupplier} ${props.styles.wd_20}`}>
             <div className={props.styles.icon_box_adv}>
             <svg width="15px" height="15px" viewBox="0 0 200 200"><g><path fill="#30C8CA" d="M100,188.716c-48.918,0-88.716-39.798-88.716-88.716S51.082,11.284,100,11.284S188.716,51.082,188.716,100 S148.918,188.716,100,188.716z M45.883,162.507c14.516,12.586,33.442,20.209,54.117,20.209c20.675,0,39.602-7.624,54.118-20.209 c-1.134-27.888-38.089-30.935-54.118-30.935S47.016,134.62,45.883,162.507z M100,17.284c-45.61,0-82.716,37.106-82.716,82.716 c0,22.237,8.82,42.452,23.146,57.331c1.983-10.823,9.079-19.279,20.815-24.682c12.708-5.85,28.09-7.077,38.755-7.077 c10.666,0,26.048,1.228,38.756,7.077c11.736,5.402,18.832,13.858,20.814,24.682c14.326-14.88,23.146-35.094,23.146-57.331 C182.716,54.39,145.609,17.284,100,17.284z M100,103c-17.409,0-31.572-14.163-31.572-31.572c0-17.409,14.163-31.572,31.572-31.572 c17.409,0,31.572,14.163,31.572,31.572C131.572,88.837,117.409,103,100,103z M100,45.856c-14.1,0-25.572,11.472-25.572,25.572 C74.428,85.528,85.9,97,100,97c14.101,0,25.572-11.472,25.572-25.572C125.572,57.328,114.101,45.856,100,45.856z"></path></g></svg>
      </div>
    <div className={props.styles.departure_main_box}>
      <div className={props.styles.label_fname}>
        <label htmlFor="fname" className="lablename-htl">
          Supplier
        </label>
      </div>
      <div className={props.styles.Multicity_supplier_advInput}>{props.multiSelectSupplierDropdown}</div>
    </div>
    </div>
  
  </div>
</div>
        <div className={props.styles.multicity_main}>
          {props?.state?.multicityFlightData.length <= 5 &&
          <div className={props.styles.search_button_section}>
          <button
                    onClick={props.AddFlightButton}
                    > 
                     Add More flight({props?.state?.multicityFlightData.length <= 2 ? 4 : props?.state?.multicityFlightData.length === 3 ? 3 : props?.state?.multicityFlightData.length === 4 ? 2 : 1}Max) +
                    </button>
            
        </div>
            }
              <div className={props.styles.flight_Adv_wrapMU}>
              <button
                className={props.styles.flight_AdvBtnMU}
                onClick={props.onClickSearchFlight}
              >Search</button>
      
              </div>
        </div>
    </>
  );
};
export default ModifyAdvanceSearchMulticity;
