import React, { useEffect, useState } from "react";
import cf from "../../../../lib/utils/common";
import DesktopCalender from "../../../calendar/DesktopCalender";
import FlightAutoComplete from "./FlightAutoComplete";
import { format } from 'date-fns';

export default function ModifySearchForm({style,state,data,index,onclickFlightAutoComplete,setState,onClickSetDepartureGoingToValue,multicityReturnWrapper,multicityDepartureWrapper,
    getGoingToSuggestionList,getDepartureSuggestionList,onChangeSetDepartureGoingToValue,onchangeVisibility,dateFormat,onChangeDepartureGoingDateValue,
    todaysDate,onClickSwap,onSwapKeyDownHandler,onKeyDownArrow,RemoveFlightObject}) {
    // console.log(getDepartureSuggestionList,"456");

 return (
   <div className={style.flightsearchpage_widget_flex_multicity_modify} >
                    <div className={style.flightautocomplete_main_multicity}>
                        <FlightAutoComplete
                            // label="From"
                            leftIcon={
                                <svg width="25px" height="25px" viewBox="0 0 200 200"><g><path fill="#30C8CA" d="M83.541,150.468c-4.986,0.8-9.511,1.971-13.341,3.47c-3.731,1.459-6.829,3.206-9.096,5.303 c-2.268,2.096-3.751,4.667-3.751,7.501s1.483,5.405,3.751,7.502c2.267,2.097,5.365,3.844,9.096,5.302 c7.462,2.919,17.516,4.655,28.619,4.655c11.102,0,21.154-1.736,28.616-4.655c3.732-1.458,6.829-3.205,9.097-5.302 s3.751-4.668,3.751-7.502s-1.483-5.405-3.751-7.501c-2.268-2.097-5.364-3.844-9.097-5.303c-3.829-1.499-8.354-2.67-13.341-3.47 v4.144c4.486,0.758,8.524,1.825,11.858,3.128c3.403,1.331,6.086,2.909,7.81,4.501c1.722,1.593,2.438,3.077,2.438,4.501 c0,1.423-0.716,2.909-2.438,4.502c-1.724,1.593-4.406,3.169-7.81,4.501c-6.806,2.662-16.464,4.373-27.134,4.373 c-10.671,0-20.329-1.711-27.135-4.373c-3.404-1.332-6.086-2.908-7.809-4.501s-2.438-3.079-2.438-4.502 c0-1.424,0.715-2.908,2.438-4.501c1.723-1.592,4.405-3.17,7.809-4.501c3.333-1.303,7.372-2.37,11.857-3.128V150.468z"></path><path fill="#30C8CA" d="M98.818,22.285c-27.043,0-49.055,22.003-49.055,49.055c0,39.002,43.819,89.957,45.683,92.115l3.372,3.88 l3.37-3.889c1.864-2.149,45.684-53.104,45.684-92.106C147.872,44.288,125.86,22.285,98.818,22.285L98.818,22.285z M98.818,153.527 c-10.222-12.7-40.136-52.436-40.136-82.188c0-22.128,18.007-40.135,40.136-40.135c22.127,0,40.135,18.007,40.135,40.135 C138.953,101.066,109.039,140.827,98.818,153.527z M98.818,45.822c-14.752,0-26.758,12.005-26.758,26.757 c0,14.751,12.005,26.756,26.758,26.756c14.752,0,26.756-12.004,26.756-26.756C125.574,57.827,113.57,45.822,98.818,45.822z M98.818,90.417c-9.839,0-17.839-8.001-17.839-17.838c0-9.838,8-17.838,17.839-17.838c9.837,0,17.836,8,17.836,17.838 C116.654,82.416,108.655,90.417,98.818,90.417z"></path></g></svg>
                            }
                            value={data.departureValue}
                            state={state}
                            data={data}
                            index={index}
                            styles={style}
                            onclickFlightAutoComplete={() => onclickFlightAutoComplete("departure","isDeparture",index)}
                            placholder="City or Airport"
                            onClickSourceAndDestination={(city, airPortCode) => onClickSetDepartureGoingToValue(city, airPortCode, "departure",index)}
                            onChangeSourceAndDestination={(value) => onChangeSetDepartureGoingToValue(value, "departure",index)}
                            isAutoCompleteExpand={(bool) => setState({ expandDepartureBoolean: bool })}
                            autoCompleteData={!cf.isValueEmpty(getDepartureSuggestionList) ? getDepartureSuggestionList : []}
                            expandAutoCompleteBool={!cf.isValueEmpty(state.expandDepartureBoolean) ? state.expandDepartureBoolean : false}
                            errorMessage={data?.error?.org}
                            isError={data?.error?.org && (data.org === "" ? true : false)}
                            listWrapper={multicityDepartureWrapper}
                            onKeyDownArrow={(event) => { onKeyDownArrow(event, 'flightDataArray', getDepartureSuggestionList, "departure") }}
                        />
                        <span className={style.flight_swap_modify} tabIndex="2"
                         onKeyDown={onSwapKeyDownHandler} onClick={onClickSwap}
                         >
                           <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 33 33">
                            <g>
                              <path class="st0" d="M16.5,32.2c-8.7,0-15.7-7-15.7-15.7s7-15.7,15.7-15.7s15.7,7,15.7,15.7S25.1,32.2,16.5,32.2z M16.5,1.8   C8.4,1.8,1.8,8.4,1.8,16.5c0,8.1,6.6,14.7,14.7,14.7c8.1,0,14.7-6.6,14.7-14.7C31.2,8.4,24.6,1.8,16.5,1.8z"></path>
                            </g>
                            <polygon class="st1" points="25.6,22.3 25.6,22.3 25.6,22.3 "></polygon>
                            <g>
                              <path class="st0" d="M26.9,15.9c0.3-0.3,0.4-1.2,0-1.5l-5.5-5.5c-0.3-0.3-0.9-0.3-1.2,0C20.1,9,20,9.2,20,9.4   c0,0.2,0.1,0.4,0.2,0.6l4.2,4.2l-17.8,0c-0.5,0-0.8,0.4-0.8,0.8l0,0c0,0.5,0.4,0.8,0.8,0.8h17.6"></path>
                              <path class="st0" d="M6.1,17.1c-0.3,0.3-0.4,1.2,0,1.5l5.5,5.5c0.3,0.3,0.9,0.3,1.2,0c0.2-0.2,0.2-0.4,0.2-0.6   c0-0.2-0.1-0.4-0.2-0.6l-4.2-4.2l17.8,0c0.5,0,0.8-0.4,0.8-0.8l0,0c0-0.5-0.4-0.8-0.8-0.8H8.8"></path>
                            </g>
                          </svg>
                        </span>
                    </div>

                    <div className={style.flightautocomplete_main_multicity}>
                        <FlightAutoComplete
                            // label="To"
                            leftIcon={
                                <svg width="25px" height="25px" viewBox="0 0 200 200"><g><path fill="#30C8CA" d="M83.541,150.468c-4.986,0.8-9.511,1.971-13.341,3.47c-3.731,1.459-6.829,3.206-9.096,5.303 c-2.268,2.096-3.751,4.667-3.751,7.501s1.483,5.405,3.751,7.502c2.267,2.097,5.365,3.844,9.096,5.302 c7.462,2.919,17.516,4.655,28.619,4.655c11.102,0,21.154-1.736,28.616-4.655c3.732-1.458,6.829-3.205,9.097-5.302 s3.751-4.668,3.751-7.502s-1.483-5.405-3.751-7.501c-2.268-2.097-5.364-3.844-9.097-5.303c-3.829-1.499-8.354-2.67-13.341-3.47 v4.144c4.486,0.758,8.524,1.825,11.858,3.128c3.403,1.331,6.086,2.909,7.81,4.501c1.722,1.593,2.438,3.077,2.438,4.501 c0,1.423-0.716,2.909-2.438,4.502c-1.724,1.593-4.406,3.169-7.81,4.501c-6.806,2.662-16.464,4.373-27.134,4.373 c-10.671,0-20.329-1.711-27.135-4.373c-3.404-1.332-6.086-2.908-7.809-4.501s-2.438-3.079-2.438-4.502 c0-1.424,0.715-2.908,2.438-4.501c1.723-1.592,4.405-3.17,7.809-4.501c3.333-1.303,7.372-2.37,11.857-3.128V150.468z"></path><path fill="#30C8CA" d="M98.818,22.285c-27.043,0-49.055,22.003-49.055,49.055c0,39.002,43.819,89.957,45.683,92.115l3.372,3.88 l3.37-3.889c1.864-2.149,45.684-53.104,45.684-92.106C147.872,44.288,125.86,22.285,98.818,22.285L98.818,22.285z M98.818,153.527 c-10.222-12.7-40.136-52.436-40.136-82.188c0-22.128,18.007-40.135,40.136-40.135c22.127,0,40.135,18.007,40.135,40.135 C138.953,101.066,109.039,140.827,98.818,153.527z M98.818,45.822c-14.752,0-26.758,12.005-26.758,26.757 c0,14.751,12.005,26.756,26.758,26.756c14.752,0,26.756-12.004,26.756-26.756C125.574,57.827,113.57,45.822,98.818,45.822z M98.818,90.417c-9.839,0-17.839-8.001-17.839-17.838c0-9.838,8-17.838,17.839-17.838c9.837,0,17.836,8,17.836,17.838 C116.654,82.416,108.655,90.417,98.818,90.417z"></path></g></svg>
                            }
                            value={data.goingToValue}
                            data={data}
                            index={index}
                            state={state}
                            styles={style}
                            onclickFlightAutoComplete={() => onclickFlightAutoComplete("goingTo","isGoingTo",index)}
                            placholder="City or Airport"
                            onClickSourceAndDestination={(city, airPortCode) => onClickSetDepartureGoingToValue(city, airPortCode, "goingTo",index)}
                            onChangeSourceAndDestination={(value) => onChangeSetDepartureGoingToValue(value, "goingTo",index)}
                            isAutoCompleteExpand={(bool) => setState({ expandGoingToBoolean: bool })}
                            autoCompleteData={!cf.isValueEmpty(getGoingToSuggestionList) ? getGoingToSuggestionList : []}
                            expandAutoCompleteBool={state.expandGoingToBoolean}
                            errorMessage={data?.error?.dest}
                            isError={data?.error?.dest && ((data.dest === "" || data.dest === data.org) ? true : false)}
                            listWrapper={multicityReturnWrapper}
                            onKeyDownArrow={(event) => {onKeyDownArrow(event, 'flightDataArray', getGoingToSuggestionList, "goingTo") }}
                        />
                    </div>
                    <div className={style.flightautocompletecalender_multicity}>
                        <DesktopCalender
                            // label="Departure Date"
                            styles={style}
                            value={!cf.isValueEmpty(data.odt) ? format(new Date(data.odt), "dd-LLL-yyyy")  : ""}
                            placholder="Departure Date"
                            onChangeDate={(value) => {onChangeDepartureGoingDateValue(value, "goindeparturegTo",index) }}
                            iconImage={
                                <svg width="25px" height="25px" viewBox="0 0 200 200"><g><path fill="#30C8CA" d="M166.5,180.657h-133c-5.239,0-9.5-4.261-9.5-9.5V52.407c0-5.239,4.261-9.5,9.5-9.5h38v-9.5 c0-2.622,2.128-4.75,4.75-4.75S81,30.785,81,33.407v9.5h38v-9.5c0-2.622,2.128-4.75,4.75-4.75s4.75,2.128,4.75,4.75v9.5h38 c5.239,0,9.5,4.261,9.5,9.5v118.75C176,176.396,171.739,180.657,166.5,180.657z M128.5,52.407v4.75c0,2.622-2.128,4.75-4.75,4.75 s-4.75-2.128-4.75-4.75v-4.75H81v4.75c0,2.622-2.128,4.75-4.75,4.75s-4.75-2.128-4.75-4.75v-4.75h-38v118.75h132.985l0.015-118.75 H128.5z M142.75,142.657h-9.5c-2.622,0-4.75-2.128-4.75-4.75v-9.5c0-2.622,2.128-4.75,4.75-4.75h9.5c2.622,0,4.75,2.128,4.75,4.75 v9.5C147.5,140.529,145.372,142.657,142.75,142.657z M142.75,104.657h-9.5c-2.622,0-4.75-2.128-4.75-4.75v-9.5 c0-2.622,2.128-4.75,4.75-4.75h9.5c2.622,0,4.75,2.128,4.75,4.75v9.5C147.5,102.529,145.372,104.657,142.75,104.657z M104.75,142.657h-9.5c-2.622,0-4.75-2.128-4.75-4.75v-9.5c0-2.622,2.128-4.75,4.75-4.75h9.5c2.622,0,4.75,2.128,4.75,4.75v9.5 C109.5,140.529,107.372,142.657,104.75,142.657z M104.75,104.657h-9.5c-2.622,0-4.75-2.128-4.75-4.75v-9.5 c0-2.622,2.128-4.75,4.75-4.75h9.5c2.622,0,4.75,2.128,4.75,4.75v9.5C109.5,102.529,107.372,104.657,104.75,104.657z M66.75,142.657h-9.5c-2.622,0-4.75-2.128-4.75-4.75v-9.5c0-2.622,2.128-4.75,4.75-4.75h9.5c2.622,0,4.75,2.128,4.75,4.75v9.5 C71.5,140.529,69.372,142.657,66.75,142.657z M66.75,104.657h-9.5c-2.622,0-4.75-2.128-4.75-4.75v-9.5 c0-2.622,2.128-4.75,4.75-4.75h9.5c2.622,0,4.75,2.128,4.75,4.75v9.5C71.5,102.529,69.372,104.657,66.75,104.657z"></path></g></svg>
                            }
                            startDate={!cf.isValueEmpty(data.odt) ? data.odt : ""}
                            endDate={!cf.isValueEmpty(state.returnDate) ? state.returnDate : ""}
                            startDateLimit={!cf.isValueEmpty(todaysDate) ? todaysDate : ""}
                            dateFormat={dateFormat}
                            changeVisibility={(bool) => onchangeVisibility(bool, "departure",index)}
                            isVisible={state.multicityFlightData[index].expandDepartureDateBoolean}
                            type={"double"}
                            doubleSelection={false}
                        />
                    </div>
                    {index !==0 && index !==1 &&
      <div
        className={style.remove_flight_button }
        id="decrease"
        onClick={() =>RemoveFlightObject(index) }
      >
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className={style.minus_pax}
          width="30px"
          height="30px"
          viewBox="0 0 30 30"
          enableBackground="new 0 0 30 30"
        >
          <g>
            <path
              fill="currentColor"
              d="M15.401,29.146c-7.616,0-13.813-6.196-13.813-13.813S7.785,1.521,15.401,1.521
		c7.617,0,13.813,6.196,13.813,13.813S23.018,29.146,15.401,29.146z M15.401,2.521c-7.065,0-12.813,5.747-12.813,12.813
		c0,7.064,5.748,12.813,12.813,12.813s12.813-5.748,12.813-12.813C28.214,8.268,22.466,2.521,15.401,2.521z"
            />
          </g>
          <path
            fill="currentColor"
            d="M24.65,15.333c0,0.368-0.299,0.666-0.667,0.666H6.818c-0.368,0-0.667-0.298-0.667-0.666l0,0
	c0-0.368,0.298-0.667,0.667-0.667h17.166C24.352,14.666,24.65,14.965,24.65,15.333L24.65,15.333z"
          />
        </svg>
      </div>
}
                </div> 
 )
  
 
}