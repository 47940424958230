import GuestDetails from "../../GuestDetails/index"
import AdvanceSearchMulticity from "./AdvanceSearchMulticity"

export default function Theme1(props) {
  console.log(props,">>>>>>");
  let styles = props?.styles;
  switch (props?.type) {
    case "flight":
      return (
        <>
          <GuestDetails props={props} />
          {props.isOffline === false && (
            <>
             <AdvanceFlightSearch props={props}/>
            {/* {props.isMultiCitySearch ? <div className={props.styles.adv_multicity_imp}><AdvanceSearchMulticity props={props}/> </div> :  <AdvanceFlightSearch props={props}/>} */}
            </>
 
          )
        
         }
        </>
      );
    case 'hotel':
      return (
        <>
          <div className={props.styles.adv_search_container}>
            <div className={props.styles.adv_search_wrap}>
              {/* Guest Details */}
              <GuestDetails props={props} />

              {/* Advance Search */}

              {props.isOffline === false && 
                <AdvanceHotelSearch props={props} />
              }
            </div>
          </div>
        </>
      );
    case "activity":
      return (
        <div className={props.styles.adv_search_container}>
          <div className={props.styles.adv_search_wrap}>
            <GuestDetails props={props} />
            {/* Advance Search */}
            {props.isOffline === true ? null : <ActivityAdvanceSearch props={props} />
            }
          </div>
        </div>
      );
      case "experience":
        return (
          <div className={props.styles.adv_search_container}>
          <div className={props.styles.adv_search_wrap}>
            <GuestDetails props={props} />
            {/* Advance Search */}

            <ExpAdvanceSearch props={props} />
          </div>
        </div>
        )


      case "holiday":
      return (
        <div className={props.styles.adv_search_container}>
          <div className={props.styles.adv_search_wrap}>
            <GuestDetails props={props} />
          </div>
        </div>
      )

     
    default:
      return <div>Theme2 Holiday cookies </div>;
  }
}



const AdvanceHotelSearch = ({ props }) => {
  return <div ref={props.listWrapper} className={props.styles.collapsible_title}>
    <div className={{}}>
      <div className="form-input-width-htl advanced_search" id="htladvserch">
        <div className="advbtn">
          {/* <button type="button" className="advbtnn addbtn guestdropbtn advancedbtn advancedbtn-htl" id="advanceSearchButton" /> */}
          <button type="button" onClick={() => props?.onClickAdvSearch()} className={props?.styles?.traveller_button} id="">{props.advSearch ? "-" : "+"}</button>
          {/* <span className="guesttext">  Advanced Search</span> */}
          <span className={props.styles.guesttext}>{props?.type === "flight" ? "  Advanced Search" : "  Advanced Search"} </span>
        </div>
        {props?.advSearch &&
          <div className={props.styles.advmodal_dropcontent} id="advanceSearchForm">
            <div className="advsearch-modal-content">
              {/* <span className="closem" id="closem" onclick="closeAdvanceSearchModal()">×</span> */}
              <div className="adv-fm-pref adv-fm-pref-htl">
                <div className="advance-form content">
                  <div className="adv-check ">
                    <div className={props.styles.adv_text}>Hotel Star</div>
                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="1" name="isOneStar" onChange={(e) => { props.applyb2bFilters('star', e, this,0);props.handleStarCheck(e)}} checked={props.isOneStar} value="1" className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="star"> 1 Star </label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="2" name="isTwoStar" onChange={(e) => { props.applyb2bFilters('star', e, this,1);props.handleStarCheck(e)}} checked={props.isTwoStar}  value="2" className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="star"> 2 Star </label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="3" name="isThreeStar" onChange={(e) => { props.applyb2bFilters('star', e, this,2);props.handleStarCheck(e)}} checked={props.isThreeStar}  value="3" className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="star"> 3 Star </label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="4" name="isFourStar" onChange={(e) => { props.applyb2bFilters('star', e, this,3);props.handleStarCheck(e)}} checked={props.isFourStar}  value="4" className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="star"> 4 Star </label><br />
                      </div>
                      <div className="col-3-adv">
                        <input type="checkbox" id="5" name="isFiveStar" onChange={(e) => { props.applyb2bFilters('star', e, this,4);props.handleStarCheck(e)}} checked={props.isFiveStar}  value="5" className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="star"> 5 Star </label><br />
                      </div>
                    </div>
                  </div>
                  <div className="adv-check">
                    <div className={props.styles.adv_text}>Room Type</div>
                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="refundable" value="Refundable" name="room_type" onChange={(e) => {  props.handleCheckboxSelection(e,0) }} checked={props.selectedCheckBoxes["room_type"]?.at(0) } className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="refundable"> Refundable</label><br />
                      </div>
                      {/* <div className="col-3-adv ">
                        <input type="checkbox" id="non_refundable" value="Non Refundable" name="room_type" onChange={(e) => {  props.handleCheckboxSelection(e,1) }} checked={props.selectedCheckBoxes["room_type"]?.at(1) } className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="non_refundable"> Non Refundable</label><br />
                      </div> */}
                    </div>
                  </div>
                  <div className="adv-check">
                    <div className={props.styles.adv_text}>Breakfast</div>

                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">

                        <input type="checkbox" id="breakfast" value="withBreakfast" name="breakfast" onChange={(e) => { props.handleCheckboxSelection(e,0) }} checked={props.selectedCheckBoxes["breakfast"]?.at(0) } className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="breakfast"> With Breakfast</label><br />
                      </div>
                      {/* <div className="col-3-adv ">
                        <input type="checkbox" id="without_breakfast" value="withoutBreakfast" name="breakfast" onChange={(e) => {  props.handleCheckboxSelection(e,1) }} checked={props.selectedCheckBoxes["breakfast"]?.at(1) } className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="without_breakfast"> Without Breakfast</label><br />
                      </div> */}

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>}
      </div>
    </div>
  </div>

}

const ActivityAdvanceSearch = ({ props }) => {
  return <div ref={props.listWrapper} className={props.styles.collapsible_title}>
    <div className={{}}>
      <div className="form-input-width-htl advanced_search" id="htladvserch">
        <div className="advbtn">
          {/* <button type="button" className="advbtnn addbtn guestdropbtn advancedbtn advancedbtn-htl" id="advanceSearchButton" /> */}
          <button type="button" onClick={() => props?.onClickAdvSearch()} className={props?.styles?.traveller_button} id="">{props.advSearch ? "-" : "+"}</button>
          {/* <span className="guesttext">  Advanced Search</span> */}
          <span className={props.styles.guesttext}>{props?.type === "flight" ? "  Advanced Search" : "  Advanced Search"} </span>
        </div>
        {props?.advSearch &&
          <div className={props.styles.advmodal_dropcontent} id="advanceSearchForm">
            <div className="advsearch-modal-content">
              {/* <span className="closem" id="closem" onclick="closeAdvanceSearchModal()">×</span> */}
              <div className="adv-fm-pref adv-fm-pref-htl">
                <div className="advance-form content">
                  <div className="adv-check ">
                    <div className={props.styles.adv_text}>Activity Star</div>
                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">
                      <input type="checkbox" id="res" name="isOneStar" value={1} onChange={(e) => {props.ratingHandler(e); props.handleStarCheck(e)}} checked={props.isOneStar} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="res"> 1 Star</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="isTwoStar" value={2} onChange={(e) => {props.ratingHandler(e); props.handleStarCheck(e)}} checked={props.isTwoStar} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar"> 2 Star</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="breakfast" name="isThreeStar" value={3} onChange={(e) => {props.ratingHandler(e); props.handleStarCheck(e)}} checked={props.isThreeStar} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor> 3 Star</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="pool" name="isFourStar" value={4} onChange={(e) => {props.ratingHandler(e); props.handleStarCheck(e)}} checked={props.isFourStar} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="pool"> 4 Star</label><br />
                      </div>
                      <div className="col-3-adv">
                        <input type="checkbox" id name="isFiveStar" value={5} onChange={(e) => {props.ratingHandler(e); props.handleStarCheck(e)}} checked={props.isFiveStar} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor> 5 Star</label><br />
                      </div>
                    </div>
                  </div>
                  {/* <div className="adv-check">
                    <div className={props.styles.adv_text}>Activity Type</div>
                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="res" name="qc" defaultValue={119} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="res"> Refundable</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="qc" defaultValue="Free Cancelation" className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar"> Non Refundable</label><br />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="adv-check">
                    <div className={props.styles.adv_text}>Breakfast</div>

                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="res" name="qc" onClick={props.onTickAdvSearch} defaultValue={119} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="res"> With Breakfast</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="qc" defaultValue="Free Cancelation" className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar">Without Breakfast</label><br />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
             
            </div>
          </div>}
      </div>
    </div>
  </div>
}

const ExpAdvanceSearch = ({ props }) => {
  console.log('exp adv search props =>', props)
  return <div className={props.styles.collapsible_title}>
    <div className={{}}>
      <div className="form-input-width-htl advanced_search" id="htladvserch">
        <div className="advbtn">
          {/* <button type="button" className="advbtnn addbtn guestdropbtn advancedbtn advancedbtn-htl" id="advanceSearchButton" /> */}
          <button type="button" onClick={() => props?.onClickAdvSearch()} className={props?.styles?.traveller_button} id="">{props.advSearch ? "-" : "+"}</button>
          {/* <span className="guesttext">  Advanced Search</span> */}
          <span className={props.styles.guesttext}>{props?.type === "experience" ? "  Advanced Search" : "  Advanced Search"} </span>
        </div>
        {props?.advSearch &&
          <div className={props.styles.advmodal_dropcontent} id="advanceSearchForm">
            <div className="advsearch-modal-content">
              {/* <span className="closem" id="closem" onclick="closeAdvanceSearchModal()">×</span> */}
              <div className="adv-fm-pref adv-fm-pref-htl">
                <div className="advance-form content">
                  <div className="adv-check ">
                    <div className={props.styles.adv_text}>Experiences Star</div>
                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="res" name="isOneStar" value={1} onChange={(e) => {props.ratingHandler(e); props.handleStarCheck(e)}} checked={props.isOneStar} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="res"> 1 Star or Less</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="isTwoStar" value={2} onChange={(e) => {props.ratingHandler(e); props.handleStarCheck(e)}} checked={props.isTwoStar} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar"> 2 Star  or Less </label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="breakfast" name="isThreeStar" value={3} onChange={(e) => {props.ratingHandler(e); props.handleStarCheck(e)}} checked={props.isThreeStar} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor> 3 Star  or Less</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="pool" name="isFourStar" value={4} onChange={(e) => {props.ratingHandler(e); props.handleStarCheck(e)}} checked={props.isFourStar} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="pool"> 4 Star  or Less </label><br />
                      </div>
                      <div className="col-3-adv">
                        <input type="checkbox" id name="isFiveStar" value={5} onChange={(e) => {props.ratingHandler(e); props.handleStarCheck(e)}} checked={props.isFiveStar} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor> 5 Star  or Less </label><br />
                      </div>
                    </div>
                  </div>
                  {/* <div className="adv-check">
                    <div className={props.styles.adv_text}>Time</div>
                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="res" name="is2Day" value={'2 DAYS'} onChange={(e) => {props.timeHandler(e); props.handleTimeCheck(e)}} checked={props.is2Day} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="res"> 2 Day</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="is1Day" value={'1 DAYS'} onChange={(e) => {props.timeHandler(e); props.handleTimeCheck(e)}} checked={props.is1Day} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar"> 1 Day</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="is1Hour" value={'Approx 1 Hour'} onChange={(e) => {props.timeHandler(e); props.handleTimeCheck(e)}} checked={props.is1Hour} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar"> 1 Hour Approx</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="is10Hour" value={'10 Hours (Approx)'} onChange={(e) => {props.timeHandler(e); props.handleTimeCheck(e)}} checked={props.is10Hour} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar"> 10 Hour Approx</label><br />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="adv-check">
                    <div className={props.styles.adv_text}>Price</div>
                      {props.openSlider()}
                  </div> */}
                </div>
              </div>
             
            </div>
          </div>}
      </div>
    </div>
  </div>
}

const AdvanceFlightSearch = ({ props }) => {
  return  <div ref={props.listWrapper} className={props.styles.advance_add}>
  <div className={props.styles.advance_search_card_container}>
    <button
      type="button"
      onClick={() => props?.onClickAirlines()}
      className={props?.styles?.traveller_button}
      id="advanceSearchButton"
    >
      {props.openAirlines ? "-" : "+"}
    </button>
    <span className={props.styles.guesttext}> Advanced Search</span>
  </div>
   {props.openAirlines &&
    <>
      <div className={props.styles.advance_search_card}>
        <div className={props.styles.leftadvancesearch}>
          <div className={props.styles.adv_text}>Flight Type</div>
          <div className={props.styles.form_checkbox_adv}>
            <div className="col-3-adv ">
              <input
                type="checkbox"
                id="res"
                name="isZeroStop"
                onChange={(e) => { props.applyb2bFilters('stops', e, this);props.handleStopCheck(e) }} 
                checked={props.isZeroStop}
                value="0" 
                className="check-ticks-adv"
              />
              <label className={props.styles.check_advance_labels} htmlFor="res">
                {" "}
                Non Stop 
              </label>
              <br />
            </div>
            <div className="col-3-adv ">
              <input
                type="checkbox"
                id="bar"
                name="isOneStop"
                onChange={(e) => { props.applyb2bFilters('stops', e, this); props.handleStopCheck(e)}} 
                checked={props.isOneStop}
                value="1" 
                className="check-ticks-adv"
              />
              <label className={props.styles.check_advance_labels} htmlFor="bar">
                {" "}
                1 Stop{" "}
              </label>
              <br />
            </div>
            <div className="col-3-adv ">
              <input
                type="checkbox"
                id="breakfast"
                name="isTwoStop"
                onChange={(e) => { props.applyb2bFilters('stops', e, this); props.handleStopCheck(e)}} 
                checked={props.isTwoStop}
                value="2" 
                defaultValue={3}
                className="check-ticks-adv"
              />
              <label className={props.styles.check_advance_labels} htmlFor>
                {" "}
                2 Stop
              </label>
              <br />
            </div>
          </div>
          {/* <div>
            <div className={props.styles.adv_text}>Meals</div>
            <div className={props.styles.form_checkbox_adv}>
              <div className="col-3-adv ">
                <input
                  type="checkbox"
                  id="res"
                  name="amenities"
                  defaultValue={1}
                  className="check-ticks-adv"
                />
                <label className={props.styles.check_advance_labels} htmlFor="res">
                  {" "}
                  Non Veg
                </label>
                <br />
              </div>
              <div className="col-3-adv ">
                <input
                  type="checkbox"
                  id="bar"
                  name="amenities"
                  defaultValue={2}
                  className="check-ticks-adv"
                />
                <label className={props.styles.check_advance_labels} htmlFor="bar">
                  {" "}
                  Veg{" "}
                </label>
                <br />
              </div>
            </div>
            <div>
            </div>
            <div className={props.styles.adv_text}>Extra Baggage</div>
            <div className={props.styles.form_checkbox_adv}>
              <div className="col-3-adv ">
                <input
                  type="checkbox"
                  id="res"
                  name="amenities"
                  defaultValue={1}
                  className="check-ticks-adv"
                />
                <label className={props.styles.check_advance_labels} htmlFor="res">
                  {" "}
                  yes
                </label>
                <br />
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className={props.styles.dropdownfilter}>
        <div className='fname form-inputs addguest form-inputs-nationality-mobile'>
          <div className={props.styles.mystyletext}>
          <div
            className='form-input-width-htl'
            onClick={() => props.handleOnChangeAirlines()}
          >
            <div className={props.styles.label_fname}>
            <label htmlFor='fname' className='lablename-htl'>
              Select Prefered Airlines
            </label>
            </div>
          </div>
          </div>
        </div>
        <div>{props.multiSelectDropdown}</div>
        </div> */}
             <div className={props.styles.advanc_addition_tabs}>
            <div className={props.styles.adv_tabs_split}>
              <div className={props.styles.icon_box_adv}>
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                    stroke="none">
                    <path d="M245 631 c-92 -24 -173 -90 -215 -176 -19 -41 -24 -66 -24 -135 -1
                    -78 2 -90 33 -148 38 -70 70 -100 145 -140 43 -22 64 -26 136 -26 72 0 93 4
                    136 26 75 40 107 70 145 140 31 58 34 70 33 148 0 72 -4 93 -26 136 -39 75
                    -70 107 -137 143 -65 34 -164 49 -226 32z m165 -47 c39 -12 66 -29 104 -67
                    206 -207 3 -544 -279 -463 -79 23 -156 100 -179 176 -34 113 -11 206 68 286
                    80 79 173 102 286 68z"/>
                    <path d="M300 417 l0 -103 63 -62 c52 -51 66 -61 75 -49 9 10 0 25 -43 67
                    l-55 54 0 98 c0 91 -1 98 -20 98 -19 0 -20 -7 -20 -103z"/>
                    </g>
                    </svg>
              </div>
            <div className={props.styles.departure_main_box}>
              <div className={props.styles.label_fname}>
                <label htmlFor="fname" className="lablename-htl">
                  Departure Time
                </label>
              </div>
              <div className={props.styles.imp_select_dep}>
                {props.multiSelectDepartureTimeDropdown}
                {/* <select id="DepartureTime"
                                value={props.dtym}
                                onChange={(e) => {
                                    props.updateDepartureTime(e.target.value);
                                }}
                            >
                                <option value="" disabled selected hidden>Select Supplier</option>
                                <option value="00000600">Before 6 AM</option>
                                <option value="06011159 ">6 AM - 12 PM</option>
                                <option value="12001759 ">12 PM - 6 PM</option>
                                <option value="18002359">After 6 PM</option>
                            </select> */}
              </div>
            </div>
            </div>
          
              <div className={props.styles.adv_tabs_split}>
              <div className={props.styles.icon_box_adv}>
              <svg
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  width="30px"
                  height="30px"
                  viewBox="0 0 30 30"
                  enable-background="new 0 0 30 30"
                >
                  <rect
                    x="3.833"
                    y="1.033"
                    fill="none"
                    width="22.334"
                    height="27.964"
                  />
                  <g>
                    <path
                      fill="currentColor"
                      d="M9.683,7.213c0-2.827,2.366-5.146,5.317-5.146c2.952,0,5.316,2.318,5.316,5.146
		c0,2.828-2.364,5.146-5.316,5.146C12.048,12.359,9.683,10.042,9.683,7.213L9.683,7.213z M15,1.038c-3.491,0-6.346,2.75-6.346,6.175
		c0,3.425,2.855,6.175,6.346,6.175c3.49,0,6.346-2.75,6.346-6.175C21.346,3.789,18.49,1.038,15,1.038z M7.674,17.063
		c4.801-2.187,10.323-2.137,15.083,0.138c1.444,0.689,2.363,2.146,2.363,3.746v6.355l-5.047,0.428
		c-3.208,0.271-6.435,0.277-9.644,0.018L4.88,27.3V21.11c0-1.691,0.987-3.224,2.525-3.924L7.674,17.063z M23.202,16.272
		c-5.035-2.405-10.877-2.458-15.955-0.146l-0.269,0.122c-1.905,0.868-3.127,2.768-3.127,4.862v7.138l6.496,0.526
		c3.266,0.264,6.549,0.256,9.814-0.021l5.989-0.507v-7.301C26.149,18.952,25.002,17.134,23.202,16.272L23.202,16.272z"
                    />
                  </g>
                </svg>
              </div>
            <div className={props.styles.departure_main_box}>
              <div className={props.styles.label_fname}>
                <label htmlFor="fname" className="lablename-htl">
                  RBD_Selector
                </label>
              </div>
              <div>{props.multiSelectRBDClassDropdown}</div>
            </div>
            </div>
           
                     <div className={props.styles.adv_tabs_split}>
                     <div className={props.styles.icon_box_adv}>
                     <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <path
                            fill="currentColor"
                            d="M4.108,11.369l-2.693,1.089l5.507,4.903l8.443-2.754l-2.087,6.658l3.571-1.454l3.358-6.778l6.325-2.058l0,0
                                           c0.702-0.193,1.292-0.669,1.631-1.314c0.34-0.645,0.396-1.401,0.157-2.089c-0.239-0.688-0.753-1.246-1.419-1.542
                                           c-0.665-0.295-1.424-0.302-2.094-0.018l-6.174,2.27L10.01,4.621L6.409,6.073l6.778,4.54l-5.628,2.452L4.108,11.369z M16.425,19.358
                                           l-2.179,0.876l1.846-5.839l3.328-1.089L16.425,19.358z M7.709,6.194l2.3-0.938l8.595,3.692l6.415-2.361l0,0
                                           c0.519-0.235,1.112-0.241,1.635-0.017c0.524,0.224,0.929,0.658,1.116,1.195c0.188,0.538,0.141,1.129-0.129,1.63
                                           c-0.271,0.501-0.739,0.866-1.291,1.004L7.075,16.694L2.536,12.67l1.543-0.636l3.45,1.725l6.929-3.025L7.709,6.194z"
                          />
                          <rect
                            x="9.625"
                            y="23.5"
                            fill="currentColor"
                            width="18.841"
                            height="0.75"
                          />
                        </svg>
              </div>
            <div className={props.styles.departure_main_box}>
              <div className={props.styles.label_fname}>
                <label htmlFor="fname" className="lablename-htl">
                  Airlines
                </label>
              </div>

              <div>{props.multiSelectDropdown}</div>
            </div>
            </div>

          
                     <div className={props.styles.adv_tabs_split}>
                     <div className={props.styles.icon_box_adv}>
              <svg
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  width="30px"
                  height="30px"
                  viewBox="0 0 30 30"
                  enable-background="new 0 0 30 30"
                >
                  <rect
                    x="3.833"
                    y="1.033"
                    fill="none"
                    width="22.334"
                    height="27.964"
                  />
                  <g>
                    <path
                      fill="currentColor"
                      d="M9.683,7.213c0-2.827,2.366-5.146,5.317-5.146c2.952,0,5.316,2.318,5.316,5.146
		c0,2.828-2.364,5.146-5.316,5.146C12.048,12.359,9.683,10.042,9.683,7.213L9.683,7.213z M15,1.038c-3.491,0-6.346,2.75-6.346,6.175
		c0,3.425,2.855,6.175,6.346,6.175c3.49,0,6.346-2.75,6.346-6.175C21.346,3.789,18.49,1.038,15,1.038z M7.674,17.063
		c4.801-2.187,10.323-2.137,15.083,0.138c1.444,0.689,2.363,2.146,2.363,3.746v6.355l-5.047,0.428
		c-3.208,0.271-6.435,0.277-9.644,0.018L4.88,27.3V21.11c0-1.691,0.987-3.224,2.525-3.924L7.674,17.063z M23.202,16.272
		c-5.035-2.405-10.877-2.458-15.955-0.146l-0.269,0.122c-1.905,0.868-3.127,2.768-3.127,4.862v7.138l6.496,0.526
		c3.266,0.264,6.549,0.256,9.814-0.021l5.989-0.507v-7.301C26.149,18.952,25.002,17.134,23.202,16.272L23.202,16.272z"
                    />
                  </g>
                </svg>
              </div>
            <div className={props.styles.departure_main_box}>
              <div className={props.styles.label_fname}>
                <label htmlFor="fname" className="lablename-htl">
                  Supplier
                </label>
              </div>
              <div>{props.multiSelectSupplierDropdown}</div>
            </div>
            </div>
          
          </div>
      </div>

    </>
    
  }
  </div>
}