import React, { Component, createRef } from "react";
import { addDays, differenceInCalendarDays, format } from "date-fns";

import B2BWidgets from "../../../b2bwidgets/index";
import DesktopCalender from "../../../calendar/flightCalender/theme1/DesktopCalender";
import FlightAutoComplete from "./FlightAutoComplete";
import PaxConfig from "./PaxConfig";
import cf from "../../../../lib/utils/common";
import { getAirlines } from "../../../../lib/getAirlines";
import Multicity from './Multicity'

const cabinClass = [
  { id: 1, value: "Economy", label: "Economy" },
  { id: 2, value: "Business", label: "Business" },
  { id: 3, value: "First Class", label: "First Class" },
  { id: 4, value: "Premium Economy", label: "Premium Economy" },
];

let search_type = "";
export default class Theme1 extends Component {
  constructor(props) {
    super(props);
    this.setState = this.setState.bind(this);
    this.economyPopUpWrapper = createRef();
    this.departureListWrapper = createRef();
    this.returnListWrapper = createRef();
    this.advanceSearchWrapper = createRef();
    this.guestDetailWrapper = createRef();
    this.multicityDepartureWrapper = createRef();
    this.multicityReturnWrapper = createRef();

    this.setDropdownWrapperRef = createRef();
    this.state = {
      isOnewaySearch: true,
      isMultiCitySearch:false,
      departureValue: "",
      goingToValue: "",
      expandDepartureBoolean: false,
      expandGoingToBoolean: false,
      dateLimit: cf.getDateWithFormat(new Date(), this?.props?.dateFormat),
      departureDateValue: cf.getDateWithFormat(
        new Date(),
        this?.props?.dateFormat
      ),
      goingToDateValue: cf.getDateWithFormat(
        addDays(new Date(), 7),
        this?.props?.dateFormat
      ),
      expandDepartureDateBoolean: false,
      expandGoingToDateBoolean: false,
      paxAdultValue: 1,
      paxChildrenValue: 0,
      paxInfantValue: 0,
      SelectedClassValue: this.props.SelectedClassValue,
      openCabinClassList: false,
      getDepartureSuggestionList: [],
      getGoingToSuggestionList: [],
      getDepartureAirportCode: "",
      getGoingToAirportCode: "",
      isError: {
        departure: false,
        goingTo: false,
        departureDate: false,
        goingToDate: false,
        selectedGuest: false,
      },
      returnCity: "",
      departureCity: "",
      code: "",
      name: "",
      indexDropdown: -1,
      type: "flight",
      openTraveler: false,
      openAirlines: false,
      isAirlinesChecked: false,
      selectedAirlines: [],
      // airlineList:[],
      stops: [],
      isZeroStop: false,
      isOneStop: false,
      isTwoStop: false,
      guest_id: "",
      addFlight:2,
      selectedGoingToIndex: null,
      selectedDepartureIndex: null,
      isValid: false,
      dtym: "",
      multicityFlightData:[ {
        org: "",
        dest: "",
        odt: cf.getDateWithFormat(
          new Date(),
          this?.props?.dateFormat
        ),
    },
    {
      org: "",
      dest: "",
      odt: cf.getDateWithFormat(
        new Date(),
        this?.props?.dateFormat
      ),
  },]
    };
  }
  componentDidMount() {
    // this.setDropdownWrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    let srpCabinClass = this.props.isSRP
      ? new URLSearchParams(window.location.search).get("class") === "2"
        ? { label: "Business", id: 2 }
        : new URLSearchParams(window.location.search).get("class") === "3"
        ? { label: "First Class", id: 3 }
        : new URLSearchParams(window.location.search).get("class") === "4"
        ? { label: "Premium Economy", id: 4 }
        : ""
      : this.props.SelectedClassValue
      // this.state.SelectedClassValue;
      this.state.SelectedClassValue = srpCabinClass
    this.setState(
      {
        getDepartureSuggestionList: this.props.airports,
        getGoingToSuggestionList: this.props.airports,
        departureValue: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("from")
          : "",
        goingToValue: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("to")
          : new URLSearchParams(window.location.search).get("to") &&
            new URLSearchParams(window.location.search).get("toCity")
          ? new URLSearchParams(window.location.search).get("toCity") +
            " - (" +
            new URLSearchParams(window.location.search).get("to") +
            ")"
          : "",
        departureCity: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("fromCity")
          : "",
        returnCity: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("toCity")
          : new URLSearchParams(window.location.search).get("toCity")
          ? new URLSearchParams(window.location.search).get("toCity")
          : "",
        getGoingToAirportCode: new URLSearchParams(window.location.search).get(
          "to"
        )
          ? new URLSearchParams(window.location.search).get("to")
          : "",
        departureDateValue: this.props.isSRP
          ? !cf.isValueEmpty(
              cf.getDateWithFormat(
                new Date(
                  new URLSearchParams(window.location.search).get("departure")
                ),
                this?.props?.dateFormat
              )
            ) &&
            cf.getDateWithFormat(
              new Date(
                new URLSearchParams(window.location.search).get("departure")
              ),
              this?.props?.dateFormat
            )
          : cf.getDateWithFormat(new Date(), this?.props?.dateFormat),
        goingToDateValue: this.props.isSRP
          ? !cf.isValueEmpty(
              new URLSearchParams(window.location.search).get("arrival")
            ) &&
            !cf.isValueEmpty(
              cf.getDateWithFormat(
                new Date(
                  new URLSearchParams(window.location.search).get("arrival")
                ),
                this?.props?.dateFormat
              )
            )
            ? cf.getDateWithFormat(
                new Date(
                  new URLSearchParams(window.location.search).get("arrival")
                ),
                this?.props?.dateFormat
              )
            : cf.getDateWithFormat(
                addDays(new Date(), 7),
                this?.props?.dateFormat
              )
          : cf.getDateWithFormat(
              addDays(new Date(), 7),
              this?.props?.dateFormat
            ),
        paxAdultValue: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("adults")
          : this.state.paxAdultValue,
        paxChildrenValue: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("child")
          : this.state.paxChildrenValue,
        paxInfantValue: this.props.isSRP
          ? new URLSearchParams(window.location.search).get("infants")
          : this.state.paxInfantValue,
        SelectedClassValue: srpCabinClass,
        isOnewaySearch: !cf.isValueEmpty(
          new URLSearchParams(window.location.search).get("arrival")
        )
          ? false
          : true,
      },
      () => {
        this.props.isSRP &&
          this.onClickSetDepartureGoingToValue(
            this.state.departureCity,
            this.state.departureValue,
            "departure"
          );
        this.props.isSRP &&
          this.onClickSetDepartureGoingToValue(
            this.state.returnCity,
            this.state.goingToValue,
            "goingTo"
          );
      }
    );
    // getAirlines().then(res => {
    //     // console.log("getAirlines===>res===>", res);
    //     if (res && res.status && res.data && res.data.data && res.data.data.length > 0) {
    //       this.setState({
    //         airlineList:res.data.data
    //       })
    //     }

    // })

    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleTabInside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleTabInside);
  }
  //Add client
  adGuestId = (id) => {
    this.setState({ guest_id: id }, () => {
      if (this.state.isError.selectedGuest === true) {
        this.validateErrorMsg();
      }
      this.setState({ guest_id: id });
    });
  };

  rmGuestId = () => {
    this.setState({ guest_id: "" });
  };

  handleClickOutside = (e) => {
    if (
      this.economyPopUpWrapper.current &&
      !this.economyPopUpWrapper.current.contains(event.target)
    ) {
      this.setState({ openCabinClassList: false });
    }
    if (
      this.departureListWrapper.current &&
      !this.departureListWrapper.current.contains(event.target)
    ) {
      this.setState({ expandDepartureBoolean: false });
    }
    if (
      this.returnListWrapper.current &&
      !this.returnListWrapper.current.contains(event.target)
    ) {
      this.setState({ expandGoingToBoolean: false });
    }
    if (
      this.advanceSearchWrapper.current &&
      !this.advanceSearchWrapper.current.contains(event.target)
    ) {
      this.setState({ openAirlines: false });
    }
    if (
      this.guestDetailWrapper.current &&
      !this.guestDetailWrapper.current.contains(event.target)
    ) {
      this.setState({ openTraveler: false });
    }
    if (this.multicityDepartureWrapper.current && !this.multicityDepartureWrapper.current.contains(event.target)) {
      const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
      multicityFlightData[this.state.selectedDepartureIndex].isDeparture = false
      this.setState((prevState) => {
        return {
          ...prevState,
          multicityFlightData,
          expandGoingToBoolean: false,
          selectedDepartureIndex:null
        }
      })
    }

    if (this.multicityReturnWrapper.current && !this.multicityReturnWrapper.current.contains(event.target)) {
      const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
      multicityFlightData[this.state.selectedGoingToIndex].isGoingTo = false
      this.setState((prevState) => {
        return {
          ...prevState,
          multicityFlightData,
          expandGoingToBoolean: false,
          selectedGoingToIndex: null,
        }
      })
    }
  };

  handleTabInside = (e) => {
    if (e.key !== "Tab") {
      return;
    }
    this.handleClickOutside(e);
  };

  onClickOpenCabinClassList = () => {
    if (this.state.openCabinClassList === false) {
      this.setState({ openCabinClassList: true });
    } else if (this.state.openCabinClassList === true) {
      this.setState({ openCabinClassList: false });
    }
  };
  validateErrorMsg = () => {
    const mapDispatch = {
      departure: cf.isValueEmpty(this.state.departureValue)
        ? true
        : !this.state.getDepartureAirportCode
        ? true
        : false,
      goingTo: cf.isValueEmpty(this.state.goingToValue)
        ? true
        : !this.state.getGoingToAirportCode
        ? true
        : this.state.departureValue === this.state.goingToValue
        ? true
        : false,
      departureDate: cf.isValueEmpty(this.state.departureDateValue)
        ? true
        : false,
      goingToDate: cf.isValueEmpty(this.state.goingToDateValue) ? true : false,
      selectedGuest: cf.isValueEmpty(this.state.guest_id) ? true : false,
    };
    this.setState({ isError: mapDispatch });
  };
  checkValidation = (item)=>{
    let isValid = true
    const { multicityFlightData = [] } = item
    multicityFlightData.map((data,index)=>{
    let errorObj = {}
    if ( data['org'] === '') {
      isValid = false
      errorObj['org'] = 'Enter Origin City'
    }
    if ( data['dest'] === '') {
      isValid = false
      errorObj['dest'] = 'Enter Destination City'
    }

    if (data['org'] === data['dest'] &&  data['dest'] !== "" ) {
      isValid = false
      errorObj['dest'] = 'Origin and destination cannot be same'
      
    }
    data.error = errorObj
    })
    return {isValid, data: multicityFlightData }
  }
  onClickSearchFlight = () => {
    if (this.state.isMultiCitySearch) {
      let {isValid ,data} = this.checkValidation(this.state)
    
        this.setState((prevState) => {
          return {
          ...prevState,
          multicityFlightData : data,
          isValid
       
         
        }})
    }

    const mapDispatch = {
      departure: cf.isValueEmpty(this.state.departureValue)
        ? true
        : !this.state.getDepartureAirportCode
        ? true
        : false,
      goingTo: cf.isValueEmpty(this.state.goingToValue)
        ? true
        : !this.state.getGoingToAirportCode
        ? true
        : this.state.departureValue === this.state.goingToValue
        ? true
        : false,
      departureDate: cf.isValueEmpty(this.state.departureDateValue)
        ? true
        : false,
      goingToDate: cf.isValueEmpty(this.state.goingToDateValue) ? true : false,
      selectedGuest: cf.isValueEmpty(this.state.guest_id) ? true : false,
    };
    if (this.props.isOffline !== true) {
      mapDispatch.selectedGuest = cf.isValueEmpty(this.state.selectedGuest)
        ? false
        : true;
    }
    if (!this.props.isOffline) {
      mapDispatch.selectedGuest = cf.isValueEmpty(this.state.selectedGuest)
        ? false
        : true;
    }
    this.setState({ isError: mapDispatch }, () => {
      let Validate = true;
      for (const [key, value] of Object.entries(this.state.isError)) {
        if (value === true) {
          Validate = false;
          break;
        }
      }
      let arrivalDate = "";
      let departureDateArr = !cf.isValueEmpty(this.state.departureDateValue)
        ? this.state.departureDateValue.split("/")
        : "";
      let departureDate = !cf.isValueEmpty(departureDateArr)
        ? format(new Date(this.state.departureDateValue), "yyyy-MM-dd")
        : "";
      if (this.state.isOnewaySearch === false) {
        let arrivalDateArr = !cf.isValueEmpty(this.state.goingToDateValue)
          ? this.state.goingToDateValue.split("/")
          : "";
        arrivalDate = !cf.isValueEmpty(arrivalDateArr)
          ? format(new Date(this.state.goingToDateValue), "yyyy-MM-dd")
          : "";
      }
      let airline_code = [];
      if (this.props.isOffline === false) {
        this.props.selectedAirlines.forEach((element) => {
          airline_code.push(element.airline_code);
        });
      }

      let supplier_Id = []
      if (this.props.isOffline === false) {
        this.props.selectedSupplier.forEach((element) => {
          supplier_Id.push(element.id);
        });
        
      }
      let multicityData = this.state.multicityFlightData
      multicityData.forEach((element) => {
        element.odt = format(new Date(element.odt), "yyyy-MM-dd")
      });
      let multicity_data = multicityData
      let fromValue = this.state.departureValue.split("-");
      let toValue = this.state.goingToValue.split("-");
      let urlQuery = {
        from: this.state.getDepartureAirportCode,
        to: this.state.getGoingToAirportCode,
        type: this.state.isOnewaySearch ? 1 : this.state.isMultiCitySearch ? 3 :  2,
        class: this.props?.SelectedClassValue?.id,
        adults: this.state.paxAdultValue,
        child: this.state.paxChildrenValue,
        infants: this.state.paxInfantValue,
        airlines: JSON.stringify(airline_code),
        departure_time: this.state.dtym,
        supplier:JSON.stringify(supplier_Id ),
        rbd_class:JSON.stringify(this.props.selectedRbdClass),
        splfare:
        this.props.FareType ?
        this.props.FareType.filter((option) => option.isChecked).map(
          (item) => item.value
        ):'',

        stops: this.state.stops,
        guests: this.state.guest_id,
        departureValue: this.props.isOffline ? fromValue[0] : "",
        goingToValue: this.props.isOffline ? toValue[0] : "",
        multicity: this.state.isMultiCitySearch ? JSON.stringify( multicity_data ) : [],
        // airlines:this.props.selectedAirlines,
        departure: departureDate,
        arrival:
          arrivalDate  , fromCity: this.state.departureCity, toCity: this.state.returnCity ,
          };
         
      console.log(urlQuery,"urlQuery");
     if (this.state.isValid) {
      cf.redirectTo("flight/search", urlQuery);
     } else {
      if (Validate === true) {
        if (this.props.isOffline === false) {
          cf.redirectTo("flight/search", urlQuery);
        } else if (this.props.isOffline === true) {
          const searchParams = new URLSearchParams(urlQuery);
          const queryString = searchParams.toString();
          window.location = "/offlinesearch/flight?" + queryString;
          // cf.redirectTo("/offlinesearch/flight", urlQuery)
          // window.location.href = `/offlinesearch/flight=${urlQuery}`;
        }
      }
     }
    });
  };
  onchangeVisibility = (bool, type,index) => {
    this.setDropdownWrapperRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    if (type === "goingTo") {
      this.setState(
        { expandGoingToDateBoolean: bool, expandDepartureDateBoolean: false },
        () => {
          if (this.state.isOnewaySearch === true) {
            this.setState({ isOnewaySearch: false });
          }
        }
      );
    } else {
      if (index !== undefined) {
      
        const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
        multicityFlightData[index]["expandDepartureDateBoolean"] = bool
        this.setState((prevState) => {
          return {
          ...prevState,
          multicityFlightData,
          expandGoingToDateBoolean: false,
        }})
     
      
      } else{
        this.setState({
          expandGoingToDateBoolean: false,
          expandDepartureDateBoolean: bool,
        });
      }
   
    }
  };
  onChangeSetDepartureGoingToValue = (value, type,index) => {
    if (type === "departure") {
      if (index !== undefined) {
        const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
        multicityFlightData[index]["org"]= value
        multicityFlightData[index]["departureValue"]= value
        this.setState((prevState)=>{
          return {
            ...prevState,
            multicityFlightData
          }
        })
        
      } else {
        this.setState(
          { departureValue: value, getDepartureAirportCode: "" },
          () => {
            if (this.state.isError.departure === true) {
              this.validateErrorMsg();
            }
            if (value.length === 0) {
              this.setState({ getDepartureSuggestionList: [] });
            }
          }
        );
      }
    
    } else {
      if (index !== undefined) {
        const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
        multicityFlightData[index]["dest"]= value
        multicityFlightData[index]["goingToValue"]= value
        this.setState((prevState)=>{
          return {
            ...prevState,
            multicityFlightData
          }
        })
        
      } else {
        this.setState({ goingToValue: value, getGoingToAirportCode: "" }, () => {
          if (this.state.isError.goingTo === true) {
            this.validateErrorMsg();
            if (value.length === 0)
              this.setState({ getGoingToSuggestionList: [] });
          }
        });
      }
   
    }
    if (type === "departure") {
      this.props.getAutoSuggestData(value, type, "change");
      this.setState({ expandDepartureBoolean: true });
    } else {
      this.props.getAutoSuggestData(value, type, "change");
      this.setState({ expandGoingToBoolean: true });
    }
  };
  onClickSetDepartureGoingToValue = (city, airPortCode,type,index) => {
    if (type === "departure") {
      if (this?.state?.isMultiCitySearch) {
        const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
        multicityFlightData[index]["org"] = airPortCode
        multicityFlightData[index]["departureValue"] = city + " - (" + airPortCode + ")"
          this.setState((prevState) => {
        return {
        ...prevState,
        multicityFlightData,
        selectedDepartureIndex: null,
      }
      }, () => {
        if (multicityFlightData.isValid === true) {
          this.checkValidation(this.state);
        }
      })
      } else {
        this.setState(
          {
            departureValue: city + " - (" + airPortCode + ")",
            getDepartureAirportCode: airPortCode,
            departureCity: city,
          },
          () => {
            if (this.state.isError.departure === true) {
              this.validateErrorMsg();
            }
          }
        );
      }
    
    } else {
     
        if (this?.state?.isMultiCitySearch) {
          const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
          multicityFlightData[index]["dest"] = airPortCode
          multicityFlightData[index + 1] ?  multicityFlightData[index + 1]["org"]= airPortCode : "",
          multicityFlightData[index]["goingToValue"] = city + " - (" + airPortCode + ")",
          multicityFlightData[index + 1] ?  multicityFlightData[index + 1]["departureValue"]= city + " - (" + airPortCode + ")" : "",
          this.setState((prevState) => {
            return {
              ...prevState,
              multicityFlightData,
              selectedGoingToIndex: null,
            }
          })
          
        }
       else {
        this.setState(
          {
            goingToValue: city + " - (" + airPortCode + ")",
            getGoingToAirportCode: airPortCode,
            returnCity: city,
          },
          () => {
            if (this.state.isError.goingTo === true) {
              this.validateErrorMsg();
            }
          }
        );
      }
     
    }
  };
  onClickChangePaxNumber = (pax, value, type) => {
    if (pax === "adult") {
      if (
        +this.state.paxChildrenValue + value <= 9 &&
        +this.state.paxChildrenValue + value > 0
      ) {
        this.setState({ paxAdultValue: value }, () => {
          if (type === "minus") {
            if (+this.state.paxInfantValue > value) {
              let paxInfantValue = +this.state.paxInfantValue;
              this.setState({ paxInfantValue: --paxInfantValue });
            }
          }
        });
      }
    } else if (pax === "children") {
      if (
        +value + +this.state.paxAdultValue <= 9 &&
        +value + +this.state.paxAdultValue > 0
      ) {
        this.setState({ paxChildrenValue: +value });
      }
    } else if (pax === "infant") {
      if (value > +this.state.paxAdultValue) {
        this.setState({ paxInfantValue: +--value });
      } else {
        this.setState({ paxInfantValue: +value });
      }
    }
  };
  validateErrorMsg = () => {
    const mapDispatch = {
      departure: cf.isValueEmpty(this.state.departureValue) ? true : false,
      goingTo: cf.isValueEmpty(this.state.goingToValue)
        ? true
        : this.state.departureValue === this.state.goingToValue
        ? true
        : false,
      departureDate: cf.isValueEmpty(this.state.departureDateValue)
        ? true
        : false,
      goingToDate: cf.isValueEmpty(this.state.goingToDateValue) ? true : false,
    };
    this.setState({ isError: mapDispatch });
  };
  onClickSwap = () => {
    if (
      !cf.isValueEmpty(this.state.departureValue) ||
      !cf.isValueEmpty(this.state.goingToValue)
    ) {
      let departureValue = this.state.departureValue;
      let departureAirportCode = this.state.getDepartureAirportCode;
      let departureCity = this.state.departureCity;
      this.setState({
        departureValue: this.state.goingToValue,
        goingToValue: departureValue,
        getDepartureAirportCode: this.state.getGoingToAirportCode,
        getGoingToAirportCode: departureAirportCode,
        departureCity: this.state.returnCity,
        returnCity: departureCity,
      });
    }
  };
  onChangeDepartureGoingDateValue = (value, type,index) => {
    if (type === "goingTo") {
      let dayDifference = differenceInCalendarDays(
        new Date(value),
        new Date(this.state.departureDateValue)
      );
      if (dayDifference < 0) {
        this.setState({
          isOnewaySearch: true,
          goingToDateValue: cf.getDateWithFormat(
            addDays(new Date(), 7),
            this?.props?.dateFormat
          ),
        });
      } else {
        this.setState({
          goingToDateValue: cf.getDateWithFormat(
            value,
            this?.props?.dateFormat
          ),
        });
      }
    } else {
      if (index !== undefined) {
        const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
        
        let goingToDateValue = this.state.goingToDateValue;
       
        let dayDifference = differenceInCalendarDays(
          new Date(this.state.goingToDateValue),
          new Date(value)
        );
        if (dayDifference < 0) {
          goingToDateValue = cf.getDateWithFormat(
            addDays(new Date(value), 7),
            this?.props?.dateFormat
          );
        }
         multicityFlightData[index]["odt"] =  cf.getDateWithFormat(value,this?.props?.dateFormat)
         multicityFlightData[index]["expandDepartureDateBoolean"] = false
        setTimeout(() => {
           this.setState((prevState) => {
            return {
            ...prevState,
            multicityFlightData,
            goingToDateValue
          }})
        }, 10);
      } else {
        let goingToDateValue = this.state.goingToDateValue;
        let dayDifference = differenceInCalendarDays(
          new Date(this.state.goingToDateValue),
          new Date(value)
        );
        if (dayDifference < 0) {
          goingToDateValue = cf.getDateWithFormat(
            addDays(new Date(value), 7),
            this?.props?.dateFormat
          );
        }
        this.setState({
          departureDateValue: cf.getDateWithFormat(
            value,
            this?.props?.dateFormat
          ),
          goingToDateValue,
        });
      }
     
    }
  };
  onclickFlightAutoComplete(type,key,i) {
    this.setDropdownWrapperRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
      if (type === "departure") {
        if (i !== undefined && key !== undefined) {
          const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
          multicityFlightData[i][key] = true
          multicityFlightData[i]["org"] = ""
          this.setState((prevState) => {
            return {
              ...prevState,
              multicityFlightData,
              selectedDepartureIndex: i,
              selectedGoingToIndex:null
            }
          })
          
        } 
        this.setState({ departureValue: "" }, () => {
          this.props?.getAutoSuggestData(this.state.departureValue, type, "click");
        });
      } else {
        if (i !== undefined && key !== undefined) {
          const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
          multicityFlightData[i][key] = true
          multicityFlightData[i]["dest"] = ""
          this.setState((prevState) => {
            return {
              ...prevState,
              multicityFlightData,
              selectedGoingToIndex: i,
              selectedDepartureIndex:null
            }
          })
          
        } 
        this.setState({ goingToValue: "" }, () => {
          this.props?.getAutoSuggestData(this.state.goingToValue, type, "click");
        });
      }
 
  }

  highlightData(id, parentElement) {
    let innerContent = document.getElementById(parentElement).children;
    for (let i = 0; i < innerContent.length; i++) {
      let selectedElement = innerContent[i];
      selectedElement.style.backgroundColor = i == id ? "#ccc" : "#fff";
    }
    innerContent[id].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }

  onKeyDownArrow = (event, parentClassId, list, type) => {
    let code = "";
    let name = "";
    let indexDropdown = this.state.indexDropdown;
    switch (event.which) {
      case 38: // up
        indexDropdown--;
        if (indexDropdown < 0) {
          indexDropdown = 0;
        }
        this.highlightData(indexDropdown, parentClassId);
        code =
          list.length >= indexDropdown
            ? `${list[indexDropdown].AIRPORT_CODE}`
            : "";
        name =
          list.length >= indexDropdown
            ? `${list[indexDropdown].CITY_NAME}`
            : "";
        search_type =
          list.length >= indexDropdown
            ? `${
                list[indexDropdown].search_type
                  ? list[indexDropdown].search_type
                  : 1
              }`
            : "";
        break;
      case 40: // down
        indexDropdown++;
        if (indexDropdown >= list.length - 1) {
          indexDropdown = list.length - 1;
        }
        this.highlightData(indexDropdown, parentClassId);
        code =
          list.length >= indexDropdown
            ? `${list[indexDropdown].AIRPORT_CODE}`
            : "";
        name =
          list.length >= indexDropdown
            ? `${list[indexDropdown].CITY_NAME}`
            : "";
        search_type =
          list.length >= indexDropdown
            ? `${
                list[indexDropdown].search_type
                  ? list[indexDropdown].search_type
                  : 1
              }`
            : "";
        break;

      case 13: //enter
        if (this.state.code && this.state.name) {
          this.onClickSetDepartureGoingToValue(
            this.state.name,
            this.state.code,
            type
          );
        }
        if (type === "departure") {
          this.setState({ expandDepartureBoolean: false });
        }
        if (type === "goingTo") {
          this.setState({ expandGoingToBoolean: false });
        }
        indexDropdown = -1;
        break;
      case 9: //enter
        if (this.state.code && this.state.name) {
          this.onClickSetDepartureGoingToValue(
            this.state.name,
            this.state.code,
            type
          );
        }
        if (type === "departure") {
          this.setState({ expandDepartureBoolean: false });
        }
        if (type === "goingTo") {
          this.setState({ expandGoingToBoolean: false });
        }
        indexDropdown = -1;
        break;
      default:
    }
    this.setState({ code: code, name: name, indexDropdown: indexDropdown });
  };

  modifySearchKeyDownHandler = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    this.onClickSearchFlight();
  };

  onSwapKeyDownHandler = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    this.onClickSwap();
  };

  onClickTraveller = () => {
    if (!this.state.openTraveler) {
      this.setState({ openTraveler: true });
    } else {
      this.setState({ openTraveler: false });
    }
  };

  onClickAirlines = () => {
    if (!this.state.openAirlines) {
      this.setState((prevState) => {
        return {
          ...prevState,
          openAirlines: true,
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          openAirlines: false
        };
      });
    }
  };

  handleOnChangeAirlines = () => {
    if (!this.state.isAirlinesChecked) {
      this.setState({ isAirlinesChecked: true });
    } else {
      this.setState({ isAirlinesChecked: false });
    }
  };

  onAirlineSelect = (event) => {
    if (
      event.target.checked &&
      !this.state.selectedAirlines.includes(event.target.value)
    ) {
      this.setState({
        selectedAirlines: [...this.state.selectedAirlines, event.target.value],
      });
    } else {
      this.setState({
        selectedAirlines: this.state.selectedAirlines.filter((value) => {
          // console.log(event.target.value);
          return value !== event.target.value;
        }),
      });
    }
    // console.log(event.target.value,event.target.checked,this.state.selectedAirlines)
  };

  handleStopCheck = (event) => {
    // console.log(event.target.name,"name");
    this.setState({ [event.target.name]: event.target.checked });
  };

  applyb2bFilters = (filterName, event, checkbox) => {
    switch (filterName) {
      case "stops":
        // console.log('this', event)
        const { value, checked } = event.target;
        // console.log(`${value} is ${checked}`);
        if (checked && !this.state.stops.includes(event.target.value)) {
          this.setState({
            stops: [...this.state.stops, value],
          });
        } else {
          this.setState({
            stops: this.state.stops.filter((event) => event !== value),
          });
        }
        break;
      default:
        break;
    }
  };

  multicityTab = () => {
    this.setState({ 
      isOnewaySearch: false,
    isMultiCitySearch:true,
    });
  };

   AddFlightButton = ()=>{
    
    const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
    let index = multicityFlightData.length - 1
    let org = multicityFlightData[index].dest
    let departureValue = multicityFlightData[index].goingToValue
      this.setState(prevState => ({
        multicityFlightData: [...prevState.multicityFlightData,
          {
            org: org ? org : "" ,
            dest: "",
            departureValue:departureValue ? departureValue : "",
            odt: cf.getDateWithFormat(
              new Date(),
              this?.props?.dateFormat
            ),
          }
        ]
        }))
  }

  RemoveFlightObject = (index)=>{
    const items = this.state.multicityFlightData.filter((item,i) => i !== index);
    this.setState((prevState) => {
      return {
        ...prevState,
        multicityFlightData: items
      };
    });


  }

  updateDepartureTime = (value) => {
this.setState((prevState) => {
  return {
    ...prevState,
    dtym: value
  };
});
  }
    

  render() {
    return (
      <div
        className="container mx-auto xl:px-8"
        ref={this.setDropdownWrapperRef}
      >
        <div className={this.props.styles.flight_search_wid_container}>
          <div className="flight_search_wid_main">
            <div className={this.props.styles.flight_way_tab}>
              <div className={this.props.styles.flight_way_btngrp}>
                <button
                  className={
                    (this.state.isOnewaySearch &&  this?.state?.isMultiCitySearch === false)
                      ? this.props.styles.active_way
                      : this.props.styles.button_style
                  }
                  onClick={() => {
                    this.setState({
                      isOnewaySearch: true,
                      isMultiCitySearch:false,
                      expandGoingToDateBoolean: false,
                      expandDepartureDateBoolean: false,
                    });
                  }}
                >
                  One Way
                </button>
                <button
                  className={
                    (this.state.isOnewaySearch === false && this?.state?.isMultiCitySearch === false)
                      ? this.props.styles.active_way
                      : this.props.styles.button_style
                  }
                  onClick={() => {
                    this.setState({
                      isOnewaySearch: false,
                      isMultiCitySearch:false,
                      goingToDateValue: cf.getDateWithFormat(
                        addDays(new Date(this.state.departureDateValue), 7),
                        this?.props?.dateFormat
                      ),
                    });
                  }}
                >
                  Round-Trip
                </button>
                {this.props?.userProfile?.userType === "B2B" && !this.props.isOffline &&(
                <button
                  className={
                    (this.state.isOnewaySearch === false && this?.state?.isMultiCitySearch === true)
                      ? this.props.styles.active_way
                      : this.props.styles.button_style
                  }
                  onClick={() => {
                   this.multicityTab()
                  }}
                >
                  Multi-city
                </button>
 ) }
                {/* <div>{this.props.multiSelectDropdown()}</div> */}
                <div className={this.props.styles.flight_adj_flex}>
                {this.props?.userProfile?.userType === "B2B" && (
                  <B2BWidgets
                    styles={this.props.b2bStyles}
                    {...this.state}
                    onClickTraveller={this.onClickTraveller}
                    onClickAirlines={this.onClickAirlines}
                    removeGuestId={this.rmGuestId}
                    handleOnChangeAirlines={this.handleOnChangeAirlines}
                    applyb2bFilters={this.applyb2bFilters}
                    addGuestId={this.adGuestId}
                    listWrapper={this.advanceSearchWrapper}
                    guestWrapper={this.guestDetailWrapper}
                    multiSelectDropdown={
                      this.props.isOffline === false
                        ? this.props.multiSelectDropdown()
                        : ""
                    }
                    multiSelectSupplierDropdown={
                      this.props.isOffline === false ? this.props.multiSelectSupplierDropdown() : ""
                    }
                    multiSelectDepartureTimeDropdown={
                      this.props.isOffline === false ? this.props.multiSelectDepartureTimeDropdown() : ""
                    }
                    multiSelectRBDClassDropdown={
                      this.props.isOffline === false ? this.props.multiSelectRBDClassDropdown() : ""
                    }
                    handleStopCheck={this.handleStopCheck}
                    isOffline={this.props.isOffline}
                    updateDepartureTime={this.updateDepartureTime}
                    errorMessage={"Select Traveler"}
                    isError={this.state.isError.selectedGuest}
                  />
                )}
                </div>

              </div>
            </div>
            <div className={this.props.styles.flight_search_details_container}>
              <div className={this.props.styles.flight_search_details_main}>
              {this.state.isMultiCitySearch   ? 
              <>
             
            <Multicity styles={this.props.styles} 
            cabinClass={cabinClass}
            state={this.state}
            onClickSetDepartureGoingToValue={this.onClickSetDepartureGoingToValue}
            onclickFlightAutoComplete={this.onclickFlightAutoComplete}
            setDropdownWrapperRef={this.setDropdownWrapperRef}
            AddFlightButton={this.AddFlightButton}
            onChangeSetDepartureGoingToValue={this.onChangeSetDepartureGoingToValue}
            departureListWrapper={this.departureListWrapper}
            onKeyDownArrow={this.onKeyDownArrow}
            getDepartureSuggestionList={this.props.getDepartureSuggestionList}
            setState={this.setState}
            onSwapKeyDownHandler={this.onSwapKeyDownHandler}
            onClickSwap={this.onClickSwap}
            returnListWrapper={this.returnListWrapper}
            getGoingToSuggestionList={this.props.getGoingToSuggestionList}
            onChangeDepartureGoingDateValue={this.onChangeDepartureGoingDateValue}
            onchangeVisibility={this.onchangeVisibility}
            onClickChangePaxNumber={this.onClickChangePaxNumber}
            onClickOpenCabinClassList={this.onClickOpenCabinClassList}
            economyPopUpWrapper={this.economyPopUpWrapper}
            multicityReturnWrapper={this.multicityReturnWrapper}
            multicityDepartureWrapper ={this. multicityDepartureWrapper}
            selectedRbdClass = {this.props.selectedRbdClass}
            RemoveFlightObject={this.RemoveFlightObject}
            onChangeClassValue={this.props.onChangeClassValue}
            SelectedClassValue={this.props.SelectedClassValue}

            />
           
            </>
            :
                <>
                <div className={this.props.styles.flight_search_details_row}>
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_depart
                    }
                  >
                    <FlightAutoComplete
                      label="Departure From"
                      value={this.state.departureValue}
                      state={this.state}
                      styles={this.props.styles}
                      onclickFlightAutoComplete={() =>
                        this.onclickFlightAutoComplete("departure")
                      }
                      placholder="City or Airport"
                      onClickSourceAndDestination={(city, airPortCode) =>
                        this.onClickSetDepartureGoingToValue(
                          city,
                          airPortCode,
                          "departure"
                        )
                      }
                      onChangeSourceAndDestination={(value) =>
                        this.onChangeSetDepartureGoingToValue(
                          value,
                          "departure"
                        )
                      }
                      isAutoCompleteExpand={(bool) =>
                        this.setState({ expandDepartureBoolean: bool })
                      }
                      autoCompleteData={
                        !cf.isValueEmpty(this.props.getDepartureSuggestionList)
                          ? this.props.getDepartureSuggestionList
                          : []
                      }
                      expandAutoCompleteBool={
                        !cf.isValueEmpty(this.state.expandDepartureBoolean)
                          ? this.state.expandDepartureBoolean
                          : false
                      }
                      leftIcon={
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <path
                            fill="currentColor"
                            d="M4.108,11.369l-2.693,1.089l5.507,4.903l8.443-2.754l-2.087,6.658l3.571-1.454l3.358-6.778l6.325-2.058l0,0
                                           c0.702-0.193,1.292-0.669,1.631-1.314c0.34-0.645,0.396-1.401,0.157-2.089c-0.239-0.688-0.753-1.246-1.419-1.542
                                           c-0.665-0.295-1.424-0.302-2.094-0.018l-6.174,2.27L10.01,4.621L6.409,6.073l6.778,4.54l-5.628,2.452L4.108,11.369z M16.425,19.358
                                           l-2.179,0.876l1.846-5.839l3.328-1.089L16.425,19.358z M7.709,6.194l2.3-0.938l8.595,3.692l6.415-2.361l0,0
                                           c0.519-0.235,1.112-0.241,1.635-0.017c0.524,0.224,0.929,0.658,1.116,1.195c0.188,0.538,0.141,1.129-0.129,1.63
                                           c-0.271,0.501-0.739,0.866-1.291,1.004L7.075,16.694L2.536,12.67l1.543-0.636l3.45,1.725l6.929-3.025L7.709,6.194z"
                          />
                          <rect
                            x="9.625"
                            y="23.5"
                            fill="currentColor"
                            width="18.841"
                            height="0.75"
                          />
                        </svg>
                      }
                      errorMessage={"Enter Origin City"}
                      isError={this.state.isError.departure}
                      listWrapper={this.departureListWrapper}
                      // multicityWrapper={this.multicityWrapper}
                      onKeyDownArrow={(event) => {
                        this.onKeyDownArrow(
                          event,
                          "flightDataArray",
                          this.props.getDepartureSuggestionList,
                          "departure"
                        );
                      }}
                    />
                  </div>
                  {/* <img src="/images/home-fgt.png" class={this.props.styles.flight_swap} alt="flight arrow" onClick={() => { this.onClickSwap() }}></img> */}
                  <span
                    className={this.props.styles.flight_swap}
                    tabIndex="2"
                    onKeyDown={this.onSwapKeyDownHandler}
                    onClick={() => {
                      this.onClickSwap();
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      width="30px"
                      height="30px"
                      viewBox="0 0 30 30"
                      enableBackground="new 0 0 30 30"
                    >
                      <g>
                        <path
                          fill="currentColor"
                          d="M14.719,28.813C7.103,28.813,0.906,22.616,0.906,15S7.103,1.188,14.719,1.188S28.531,7.384,28.531,15
		S22.335,28.813,14.719,28.813z M14.719,2.188C7.654,2.188,1.906,7.935,1.906,15c0,7.064,5.748,12.813,12.813,12.813
		c7.064,0,12.813-5.748,12.813-12.813C27.531,7.935,21.783,2.188,14.719,2.188z"
                        />
                      </g>
                      <g>
                        <g>
                          <defs>
                            <rect
                              id="SVGID_1_"
                              x="6.145"
                              y="9.9"
                              width="17.148"
                              height="12.891"
                            />
                          </defs>
                          <g clipPath="url(#SVGID_2_)">
                            <g>
                              <path
                                fill="currentColor"
                                d="M5.689,15.855l6.875,6.92l0.729-0.713L8.09,16.855h15.203v-1.02H8.09"
                              />
                            </g>
                          </g>
                        </g>
                        <g>
                          <defs>
                            <rect
                              id="SVGID_3_"
                              x="6.145"
                              y="7.208"
                              width="17.148"
                              height="12.891"
                            />
                          </defs>
                          <g clipPath="url(#SVGID_4_)">
                            <g>
                              <path
                                fill="currentColor"
                                d="M23.747,14.145l-6.874-6.922l-0.729,0.713l5.203,5.208H6.145v1.019h15.203"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_depart
                    }
                  >
                    <FlightAutoComplete
                      label="Going To"
                      value={this.state.goingToValue}
                      styles={this.props.styles}
                      state={this.state}
                      onclickFlightAutoComplete={() =>
                        this.onclickFlightAutoComplete("goingTo")
                      }
                      placholder="City or Airport"
                      onClickSourceAndDestination={(city, airPortCode) =>
                        this.onClickSetDepartureGoingToValue(
                          city,
                          airPortCode,
                          "goingTo"
                        )
                      }
                      onChangeSourceAndDestination={(value) =>
                        this.onChangeSetDepartureGoingToValue(value, "goingTo")
                      }
                      isAutoCompleteExpand={(bool) =>
                        this.setState({ expandGoingToBoolean: bool })
                      }
                      autoCompleteData={
                        !cf.isValueEmpty(this.props.getGoingToSuggestionList)
                          ? this.props.getGoingToSuggestionList
                          : []
                      }
                      expandAutoCompleteBool={
                        !cf.isValueEmpty(this.state.expandGoingToBoolean)
                          ? this.state.expandGoingToBoolean
                          : false
                      }
                      leftIcon={
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <path
                            fill="currentColor"
                            d="M5.727,6.708l-2.85-0.559l1.935,7.115l8.576,2.304l-5.385,4.438l3.785,0.732l6.517-3.843l6.421,1.732l0,0
                                           c0.694,0.221,1.448,0.146,2.084-0.21c0.637-0.355,1.098-0.959,1.272-1.664c0.176-0.708,0.05-1.455-0.348-2.067
                                           c-0.395-0.61-1.026-1.031-1.743-1.159l-6.41-1.471l-5.224-7.778l-3.81-0.75l3.198,7.505l-6.053-1.021L5.727,6.708z M11.68,20.126
                                           l-2.304-0.455l4.735-3.883l3.382,0.905L11.68,20.126z M11.57,4.34l2.44,0.471l5.182,7.788l6.663,1.526l0,0
                                           c0.563,0.086,1.063,0.406,1.378,0.879c0.318,0.474,0.42,1.059,0.283,1.61c-0.137,0.553-0.499,1.022-0.999,1.295
                                           c-0.501,0.272-1.092,0.322-1.63,0.137L5.303,12.788L3.699,6.938l1.641,0.311l1.947,3.33l7.457,1.25L11.57,4.34z"
                          />
                          <rect
                            x="8.625"
                            y="24.5"
                            fill="currentColor"
                            width="18.841"
                            height="0.75"
                          />
                        </svg>
                      }
                      errorMessage={
                        this.state.goingToValue !== "" &&
                        this.state.departureValue === this.state.goingToValue
                          ? "Origin and destination cannot be same"
                          : "Enter Destination City"
                      }
                      isError={this.state.isError.goingTo}
                      listWrapper={this.returnListWrapper}
                      // multicityWrapper={this.multicityWrapper}
                      onKeyDownArrow={(event) => {
                        this.onKeyDownArrow(
                          event,
                          "flightDataArray",
                          this.props.getGoingToSuggestionList,
                          "goingTo"
                        );
                      }}
                    />
                  </div>
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_depart_going_date
                    }
                  >
                    <DesktopCalender
                      label="Departure Date"
                      styles={this.props.styles}
                      value={
                        !cf.isValueEmpty(this.state.departureDateValue)
                          ? this.state.departureDateValue
                          : ""
                      }
                      placholder="Departure Date"
                      onChangeDate={(value) => {
                        this.onChangeDepartureGoingDateValue(
                          value,
                          "goindeparturegTo"
                        );
                      }}
                      iconImage={
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <g>
                            <path
                              fill="currentColor"
                              d="M7.258,17.91H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.002,1.002,1.002h0.083
                                               c0.558,0,1.003-0.445,1.003-1.002v-0.111C8.262,18.355,7.816,17.91,7.258,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,17.91h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.084
                                               c0.556,0,1.002-0.445,1.002-1.002v-0.111C12.324,18.355,11.878,17.91,11.322,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,17.91h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.002,1.002,1.002h0.112
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C16.415,18.355,15.97,17.91,15.414,17.91L15.414,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M7.258,13.792H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.001,1.002,1.001h0.083
                                               c0.558,0,1.003-0.445,1.003-1.001v-0.111C8.262,14.237,7.816,13.792,7.258,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,13.792h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.084
                                               c0.556,0,1.002-0.445,1.002-1.001v-0.111C12.324,14.237,11.878,13.792,11.322,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,13.792h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.001,1.002,1.001h0.112
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C16.415,14.237,15.97,13.792,15.414,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,17.91h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.002,1.003,1.002h0.111
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C20.506,18.355,20.033,17.91,19.478,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,17.91h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.112
                                               c0.557,0,1.002-0.445,1.002-1.002v-0.111C24.57,18.355,24.125,17.91,23.568,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,13.792h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.001,1.003,1.001h0.111
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C20.506,14.237,20.033,13.792,19.478,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,13.792h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.112
                                               c0.557,0,1.002-0.445,1.002-1.001v-0.111C24.57,14.237,24.125,13.792,23.568,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M7.258,22.002H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.445,1.004,1.002,1.004h0.083
                                               c0.558,0,1.003-0.445,1.003-1.004v-0.109C8.262,22.473,7.816,22.002,7.258,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,22.002h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.084
                                               c0.556,0,1.002-0.445,1.002-1.004v-0.109C12.324,22.473,11.878,22.002,11.322,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,22.002h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.446,1.004,1.002,1.004h0.112
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C16.415,22.473,15.97,22.002,15.414,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,22.002h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.109c0,0.559,0.445,1.004,1.003,1.004h0.111
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C20.506,22.473,20.033,22.002,19.478,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,22.002h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.112
                                               c0.557,0,1.002-0.445,1.002-1.004v-0.109C24.57,22.473,24.125,22.002,23.568,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M28.577,10.535L28.577,10.535V8.281c0-1.726-1.392-3.118-3.089-3.118h-1.643V4.189
                                               c0-1.196-0.973-2.171-2.171-2.171c-1.196,0-2.17,0.975-2.17,2.171v0.974l-8.238,0V4.19c0-1.197-0.974-2.171-2.17-2.171
                                               c-1.197,0-2.17,0.974-2.17,2.171v0.974H5.283c-1.725,0-3.117,1.392-3.117,3.118v16.643c0,1.697,1.392,3.088,3.117,3.088h20.232
                                               c1.698,0,3.09-1.391,3.09-3.088L28.577,10.535L28.577,10.535L28.577,10.535z M20.785,4.189c0-0.473,0.39-0.863,0.861-0.863
                                               c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863c-0.472,0-0.861-0.39-0.861-0.863V4.189z M8.206,4.189
                                               c0-0.473,0.389-0.863,0.862-0.863c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863
                                               c-0.473,0-0.862-0.39-0.862-0.863V4.189z M5.254,6.472h1.67c0.112,1.085,1.03,1.948,2.17,1.948c1.113,0,2.031-0.863,2.143-1.948
                                               h8.266c0.111,1.085,1.03,1.948,2.143,1.948c1.113,0,2.031-0.863,2.144-1.948h1.67c0.974,0,1.781,0.807,1.781,1.809v1.615H3.446
                                               V8.28C3.446,7.279,4.253,6.472,5.254,6.472L5.254,6.472z M25.488,26.703H5.254c-1.002,0-1.808-0.807-1.808-1.779V11.175H27.27
                                               v13.749C27.27,25.896,26.462,26.703,25.488,26.703z"
                            />
                          </g>
                        </svg>
                      }
                      startDate={
                        !cf.isValueEmpty(this.state.departureDateValue)
                          ? this.state.departureDateValue
                          : ""
                      }
                      endDate={
                        !cf.isValueEmpty(this.state.goingToDateValue)
                          ? this.state.goingToDateValue
                          : ""
                      }
                      startDateLimit={
                        !cf.isValueEmpty(this.state.dateLimit)
                          ? this.state.dateLimit
                          : ""
                      }
                      dateFormat={"DD-MMM-YYYY"}
                      changeVisibility={(bool) =>
                        this.onchangeVisibility(bool, "departure")
                      }
                      isVisible={this.state.expandDepartureDateBoolean}
                      type={"double"}
                      doubleSelection={false}
                    />
                  </div>
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_depart_going_date
                    }
                  >
                    <DesktopCalender
                      label="Return Date"
                      class={this.props.styles.left}
                      styles={this.props.calendarStyles}
                      value={
                        this.state.isOnewaySearch === false
                          ? !cf.isValueEmpty(this.state.goingToDateValue)
                            ? this.state.goingToDateValue
                            : ""
                          : ""
                      }
                      placholder="Arrival Date"
                      onChangeDate={(value) => {
                        this.onChangeDepartureGoingDateValue(value, "goingTo");
                      }}
                      iconImage={
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <g>
                            <path
                              fill="currentColor"
                              d="M7.258,17.91H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.002,1.002,1.002h0.083
                                               c0.558,0,1.003-0.445,1.003-1.002v-0.111C8.262,18.355,7.816,17.91,7.258,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,17.91h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.084
                                               c0.556,0,1.002-0.445,1.002-1.002v-0.111C12.324,18.355,11.878,17.91,11.322,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,17.91h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.002,1.002,1.002h0.112
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C16.415,18.355,15.97,17.91,15.414,17.91L15.414,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M7.258,13.792H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.001,1.002,1.001h0.083
                                               c0.558,0,1.003-0.445,1.003-1.001v-0.111C8.262,14.237,7.816,13.792,7.258,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,13.792h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.084
                                               c0.556,0,1.002-0.445,1.002-1.001v-0.111C12.324,14.237,11.878,13.792,11.322,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,13.792h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.001,1.002,1.001h0.112
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C16.415,14.237,15.97,13.792,15.414,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,17.91h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.002,1.003,1.002h0.111
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C20.506,18.355,20.033,17.91,19.478,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,17.91h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.112
                                               c0.557,0,1.002-0.445,1.002-1.002v-0.111C24.57,18.355,24.125,17.91,23.568,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,13.792h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.001,1.003,1.001h0.111
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C20.506,14.237,20.033,13.792,19.478,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,13.792h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.112
                                               c0.557,0,1.002-0.445,1.002-1.001v-0.111C24.57,14.237,24.125,13.792,23.568,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M7.258,22.002H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.445,1.004,1.002,1.004h0.083
                                               c0.558,0,1.003-0.445,1.003-1.004v-0.109C8.262,22.473,7.816,22.002,7.258,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,22.002h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.084
                                               c0.556,0,1.002-0.445,1.002-1.004v-0.109C12.324,22.473,11.878,22.002,11.322,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,22.002h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.446,1.004,1.002,1.004h0.112
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C16.415,22.473,15.97,22.002,15.414,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,22.002h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.109c0,0.559,0.445,1.004,1.003,1.004h0.111
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C20.506,22.473,20.033,22.002,19.478,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,22.002h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.112
                                               c0.557,0,1.002-0.445,1.002-1.004v-0.109C24.57,22.473,24.125,22.002,23.568,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M28.577,10.535L28.577,10.535V8.281c0-1.726-1.392-3.118-3.089-3.118h-1.643V4.189
                                               c0-1.196-0.973-2.171-2.171-2.171c-1.196,0-2.17,0.975-2.17,2.171v0.974l-8.238,0V4.19c0-1.197-0.974-2.171-2.17-2.171
                                               c-1.197,0-2.17,0.974-2.17,2.171v0.974H5.283c-1.725,0-3.117,1.392-3.117,3.118v16.643c0,1.697,1.392,3.088,3.117,3.088h20.232
                                               c1.698,0,3.09-1.391,3.09-3.088L28.577,10.535L28.577,10.535L28.577,10.535z M20.785,4.189c0-0.473,0.39-0.863,0.861-0.863
                                               c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863c-0.472,0-0.861-0.39-0.861-0.863V4.189z M8.206,4.189
                                               c0-0.473,0.389-0.863,0.862-0.863c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863
                                               c-0.473,0-0.862-0.39-0.862-0.863V4.189z M5.254,6.472h1.67c0.112,1.085,1.03,1.948,2.17,1.948c1.113,0,2.031-0.863,2.143-1.948
                                               h8.266c0.111,1.085,1.03,1.948,2.143,1.948c1.113,0,2.031-0.863,2.144-1.948h1.67c0.974,0,1.781,0.807,1.781,1.809v1.615H3.446
                                               V8.28C3.446,7.279,4.253,6.472,5.254,6.472L5.254,6.472z M25.488,26.703H5.254c-1.002,0-1.808-0.807-1.808-1.779V11.175H27.27
                                               v13.749C27.27,25.896,26.462,26.703,25.488,26.703z"
                            />
                          </g>
                        </svg>
                      }
                      startDate={
                        !cf.isValueEmpty(this.state.departureDateValue)
                          ? this.state.departureDateValue
                          : ""
                      }
                      endDate={
                        !cf.isValueEmpty(this.state.goingToDateValue)
                          ? this.state.goingToDateValue
                          : ""
                      }
                      startDateLimit={
                        !cf.isValueEmpty(this.state.departureDateValue)
                          ? this.state.departureDateValue
                          : ""
                      }
                      dateFormat={"DD-MMM-YYYY"}
                      changeVisibility={(bool) =>
                        this.onchangeVisibility(bool, "goingTo")
                      }
                      isVisible={this.state.expandGoingToDateBoolean}
                      type={"double"}
                      doubleSelection={false}
                    />
                  </div>
                </div>
                <div className={this.props.styles.flight_search_details_row}>
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_pax
                    }
                  >
                    <label>Adult</label>
                    <span>(+12 Years)</span>
                    <PaxConfig
                      value={
                        !cf.isValueEmpty(this.state.paxAdultValue)
                          ? this.state.paxAdultValue
                          : 0
                      }
                      onChangePaxValue={(value, type) =>
                        this.onClickChangePaxNumber("adult", value, type)
                      }
                      maxLimit={9}
                      minLimit={1}
                      totalPax={
                        +this.state.paxChildrenValue + +this.state.paxAdultValue
                      }
                      styles={this.props.styles}
                    />
                  </div>
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_pax
                    }
                  >
                    <label>Children</label>
                    <span>(2-11 Years)</span>
                    <PaxConfig
                      value={
                        !cf.isValueEmpty(this.state.paxChildrenValue)
                          ? this.state.paxChildrenValue
                          : 0
                      }
                      onChangePaxValue={(value, type) =>
                        this.onClickChangePaxNumber("children", value, type)
                      }
                      maxLimit={9}
                      minLimit={0}
                      totalPax={
                        +this.state.paxChildrenValue + +this.state.paxAdultValue
                      }
                      styles={this.props.styles}
                    />
                  </div>
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_pax
                    }
                  >
                    <label>Infant</label>
                    <span>(Below 2 Years)</span>
                    <PaxConfig
                      value={
                        !cf.isValueEmpty(this.state.paxInfantValue)
                          ? this.state.paxInfantValue
                          : 0
                      }
                      onChangePaxValue={(value, type) =>
                        this.onClickChangePaxNumber("infant", value, type)
                      }
                      maxLimit={+this.state.paxAdultValue}
                      minLimit={0}
                      totalPax={this.state.paxInfantValue}
                      styles={this.props.styles}
                    />
                  </div>
                  
                  <div
                    className={
                      this.props.styles.flight_search_column +
                      " " +
                      this.props.styles.flight_search_class
                    }
                  >
                    <div
                      className={this.props.styles.flight_cabin_class_cont}
                      onClick={() => this.onClickOpenCabinClassList()}
                    >
                      <div
                        className={this.props.styles.flight_cabin_class_icon}
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          className={
                            this.props.styles.flight_cabin_class_icon_img
                          }
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <g>
                            <path
                              fill="currentColor"
                              d="M15.018,1.971c-3.493,0-6.843,1.388-9.312,3.858c-2.471,2.469-3.858,5.819-3.858,9.312
		c0,3.494,1.388,6.842,3.858,9.313c2.469,2.47,5.819,3.856,9.312,3.856c3.493,0,6.841-1.387,9.313-3.856
		c2.47-2.47,3.856-5.819,3.856-9.313c-0.004-3.492-1.393-6.84-3.861-9.309C21.857,3.363,18.511,1.975,15.018,1.971L15.018,1.971z
		 M15.018,26.432c-2.994,0-5.865-1.189-7.982-3.308c-2.117-2.118-3.307-4.989-3.307-7.983c0-2.994,1.19-5.865,3.307-7.982
		c2.117-2.117,4.988-3.307,7.982-3.307c2.994,0,5.866,1.19,7.983,3.307c2.116,2.117,3.306,4.988,3.306,7.982
		c-0.002,2.994-1.192,5.862-3.311,7.978C20.88,25.237,18.011,26.428,15.018,26.432L15.018,26.432z"
                            />
                          </g>
                          <rect
                            x="7.623"
                            y="7.496"
                            fill="none"
                            width="14.792"
                            height="15.306"
                          />
                          <g>
                            <path
                              fill="currentColor"
                              d="M13.789,18.303l6.39,0.064c0.762,0.008,1.372,0.626,1.372,1.387v1.356c0,0.438-0.354,0.792-0.791,0.792
		c-0.023,0-0.048,0-0.072-0.003l-9.27-0.852c-0.204-0.018-0.36-0.188-0.36-0.394v-1.93L8.498,8.874
		C8.432,8.624,8.622,8.379,8.88,8.379h1.434c1.303,0,2.4,0.974,2.556,2.267L13.789,18.303z M9.392,9.171l2.369,9.106h1.227
		l-0.905-7.537c-0.107-0.896-0.867-1.57-1.769-1.57L9.392,9.171z M11.85,20.292l8.91,0.818v-1.356c0-0.326-0.262-0.592-0.588-0.595
		l-8.322-0.085V20.292z M14.854,12.301c-0.088-0.2,0.001-0.434,0.201-0.522c0.2-0.089,0.434,0.001,0.522,0.201
		c0.312,0.696,0.312,1.408,0,2.104c-0.218,0.492-0.218,0.969,0,1.461c0.09,0.198,0,0.433-0.201,0.521c-0.2,0.09-0.434,0-0.522-0.2
		c-0.309-0.696-0.309-1.407,0-2.104C15.072,13.27,15.072,12.793,14.854,12.301z M19.211,12.301c-0.091-0.2,0.001-0.434,0.199-0.522
		c0.201-0.089,0.436,0.001,0.523,0.201c0.31,0.696,0.31,1.408,0,2.104c-0.218,0.492-0.218,0.969,0,1.461
		c0.09,0.198-0.001,0.433-0.201,0.521c-0.199,0.09-0.434,0-0.521-0.2c-0.31-0.696-0.31-1.407,0-2.104
		C19.429,13.27,19.429,12.793,19.211,12.301z M17.033,10.519c-0.09-0.2,0-0.434,0.2-0.522s0.434,0.001,0.522,0.201
		c0.31,0.696,0.31,1.407,0,2.104c-0.218,0.492-0.218,0.969,0,1.46c0.089,0.2-0.002,0.434-0.201,0.523
		c-0.2,0.089-0.435-0.001-0.521-0.201c-0.312-0.696-0.312-1.407,0-2.104C17.251,11.488,17.251,11.011,17.033,10.519L17.033,10.519z"
                            />
                          </g>
                        </svg>
                      </div>
                      <div
                        className={this.props.styles.flight_cabin_class_name}
                      >
                        {!cf.isValueEmpty(this.props.SelectedClassValue)
                          ? this.props.SelectedClassValue.label
                          : ""}
                      </div>
                      <div
                        className={this.props.styles.flight_cabin_class_arrow}
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <path
                            id="XMLID_224_"
                            fill="currentColor"
                            d="M1.869,11.074l12.576,12.575c0.236,0.236,0.556,0.368,0.889,0.368
	c0.334,0,0.654-0.132,0.889-0.368l12.576-12.575c0.489-0.492,0.489-1.288,0-1.779c-0.492-0.491-1.288-0.491-1.779,0L15.333,20.98
	L3.647,9.295C3.401,9.049,3.08,8.926,2.757,8.926c-0.322,0-0.643,0.123-0.889,0.369C1.377,9.786,1.377,10.582,1.869,11.074z"
                          />
                        </svg>
                      </div>
                      {this.state.openCabinClassList === true ? (
                        <div
                          ref={this.economyPopUpWrapper}
                          className={this.props.styles.flight_cabin_class_list}
                        >
                          {cabinClass.map((element, index) => {
                            return (
                              <div
                                key={"cabinClass" + index}
                                className={
                                  this.props.styles.flight_cabin_class_ele
                                }
                                onClick={() => {
                                  this.props.onChangeClassValue(element)
                                  this.setState({
                                    openCabinClassList: false,
                                  });
                                }}
                              >
                                {element.label}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                        {/* {this.state.isMultiCitySearch === false && */}
                  <div className={this.props.styles.flight_search_class}>
                    <button
                      className={this.props.styles.flight_search_widget_btn}
                      tabIndex="2"
                      onKeyDown={this.modifySearchKeyDownHandler}
                      onClick={() => this.onClickSearchFlight()}
                    >
                      {this.props.isSRP ? "Modify" : "Search"}
                    </button>
                  </div> 
                   {/* } */}
                </div>
                </>
                  }
                  {this.state.isMultiCitySearch && !this.state.openAirlines &&
                  <div className={this.props.styles.flight_search_details_row_multicity}>
                  <div className={this.props.styles.flight_search_class}>
                    <button
                      className={this.props.styles.flight_search_widget_btn}
                      tabIndex="2"
                      onKeyDown={this.modifySearchKeyDownHandler}
                      onClick={() => this.onClickSearchFlight()}
                    >
                      {this.props.isSRP ? "Modify" : "Search"}
                    </button>
                  </div>
                  </div>
  }
                 
              </div>
            </div>
            <div className={`${this.props.styles.adv_tabs_split} ${this.props.styles.fare_container}`}>
              {/* <div className={props.styles.icon_box_adv}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19">
                  <path fill-rule="evenodd" fill="#01445F" d="M7.668 18.778H1.577c-.42 0-.761-.341-.761-.608 0-.574.341-.915.761-.915h6.091c1.212 0 .761.341.761.915 0 .267.451.608-.761.608zm-1.523-3.047H1.577a.762.762 0 0 1 0-1.523h4.568a.76.76 0 1 1 0 1.523zm-1.523-3.047H1.577a.762.762 0 0 1 0-1.524h3.045c.808 0 .762.341.762.762s.046.762-.762.762zm6.091 6.06c-.42.019-.776-.307-.795-.584-.019-.564.307-.921.727-.939a7.618 7.618 0 0 0 6.888-8.281c-.383-4.19-4.088-7.276-8.275-6.47C5.6 2.382 2.703 5.283 2.37 8.943a.762.762 0 1 1-1.517-.137C1.309 3.778 5.751.71 10.776.527c4.709.427 9.124 4.378 9.104 9.11-.768 4.736-4.382 8.698-9.098 9.104a.814.814 0 0 1-.069.003zM9.952 5.66c-.421 0-.762-.253-.762.167v3.81c0 .202.081.683.223.538l2.284 2.286a.761.761 0 0 0 1.077-.019c.816-.295.816-.763 0-1.059l-2.061-2.062V5.827c0-.42-.341-.167-.761-.167z"/>
              </svg>
              </div> */}
            <div className={`${this.props.styles.departure_main_box} ${this.props.styles.fare_mainBox}`}>
              <div className={this.props.styles.label_fare}>
                <label htmlFor="fname" className="lablename-htl">
                Select Fare Type
                </label>
              </div>
              <div className={`${this.props.styles.icon_box_advInputNw}`}>
                       {this.props.selectFareTypeDropdown()}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
