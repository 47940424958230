import React, { useEffect, useState } from "react";
import commonFunction from "../../../../lib/utils/common";

export default function PaxConfig(props) {
  
  const onClickHandler = (key) => {
    if (key === "decrease") {
      if (!commonFunction.isValueEmpty(props.value)) {
        if (props.value > props.minLimit) {
          props.onChangePaxValue(+props.value - 1, "minus");
        }
      }
    }
    if (key === "increase") {
      if (!commonFunction.isValueEmpty(props.value)) {
        if (
          props.totalPax >= props.minLimit &&
          props.totalPax <= props.maxLimit
        ) {
          props.onChangePaxValue(+props.value + 1, "add");
        }
      }
    }
  };

  function convert(n) {
    n = String(n);
    if (n.length == 1) n = "0" + n;
    return n;
  }

  const keyDownHandler = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    onClickHandler(e.target.id);
  };

  const cleanValue = (value) => {
    return String(value).replace(/<[^>]*>/g, "").trim();
  };

  const sanitizedValue = cleanValue(props.value);

  return (
    <div className={props.styles.flight_search_wid_inputfield}>
      <div
        id="decrease"
        className={props.styles.pax_main}
        onClick={() => {
          onClickHandler("decrease");
        }}
      >
        <svg
          width="26"
          height="26"
          className={
            props.value > props.minLimit
              ? props.styles.buttons_icon
              : props.styles.buttons_icon +
                " " +
                props.styles.buttons_icon_disbale
          }
        >
          <path
            fill-rule="evenodd"
            fill="#397CC6"
            d="M12.1 26.003c-6.282 0-12.104-5.822-12.104-13.004C-.004 5.818 5.818-.004 12.1-.004c8.082 0 13.903 5.822 13.903 13.003-.01 7.178-5.826 12.993-13.903 13.004zm0-24.444c-5.419 0-10.541 5.122-10.541 11.44 0 6.319 5.122 11.441 10.541 11.441 7.219 0 12.341-5.122 12.341-11.441-.006-6.315-5.125-11.434-12.341-11.44zm6.603 12.221H7.297a.78.78 0 1 1 0-1.562h11.406a.781.781 0 0 1 0 1.562z"
          ></path>
        </svg>
      </div>
      {/* <span> {props.showDoubleDigit ? convert(sanitizedValue) : sanitizedValue}</span> */}
      <span dangerouslySetInnerHTML={{ __html: props.showDoubleDigit ? convert(sanitizedValue) : sanitizedValue }} />
      <div
        id="increase"
        className={props.styles.pax_main}
        onClick={() => {
          onClickHandler("increase");
        }}
      >
        <svg
          className={
            props.totalPax == props.maxLimit
              ? props.styles.buttons_icon +
                " " +
                props.styles.buttons_icon_disbale
              : props.styles.buttons_icon
          }
          width="26"
          height="26"
        >
          <path
            fill-rule="evenodd"
            fill="#397CC6"
            d="M12.1 26.003c-6.282 0-12.104-5.822-12.104-13.004C-.004 5.818 5.818-.004 12.1-.004c8.082 0 13.903 5.822 13.903 13.003-.01 7.178-5.826 12.993-13.903 13.004zm0-24.444c-5.419 0-10.541 5.122-10.541 11.44 0 6.319 5.122 11.441 10.541 11.441 7.219 0 12.341-5.122 12.341-11.441-.006-6.315-5.125-11.434-12.341-11.44zm6.603 12.221h-4.922v4.922c0 .432-.349.782-1.681.782.469 0 .119-.35.119-.782V13.78H7.297a.78.78 0 1 1 0-1.562h4.922V7.297c0-.432.35-.782-.119-.782 1.332 0 1.681.35 1.681.782v4.921h4.922a.781.781 0 0 1 0 1.562z"
          ></path>
        </svg>
      </div>
    </div>
  );
}
