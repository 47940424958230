import React, { useEffect, useState } from "react";

import commonFunction from "../../../../lib/utils/common";
// import styles from "./index.module.css";

export default function HotelAutoComplete(props) {
  return (
    <div className={props?.styles?.hotel_search_wid_inputfield}>
      <label>{props.label}</label>
      <div className={props?.styles?.hotel_search_wid_inputfield_main}>
        {/* <img src={props.leftIcon} className={props.styles?.hotel_search_left_icon} /> */}

        <span
          className={props.styles?.hotel_search_left_icon}
          onClick={() => {
            props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
            props.onChangeHotelName("");
            props.onClickHotelInput();
          }}
        >
          {props.leftIcon}
        </span>
        <input
          type="text"
          autoComplete={"off"}
          id="myTextField"
          tabindex="1"
          readOnly
          // onFocus={props.onInputFocus}
          // onKeyDown={props.onKeyDownArrow}
          placeholder={
            !commonFunction.isValueEmpty(props.placholder)
              ? props.placholder
              : ""
          }
          className={props.styles?.search_widget_input_new}
          value={!commonFunction.isValueEmpty(props.value) && !props.expandAutoCompleteBool ? props.value : ""}
          // onChange={(event) => {
          //   props.onChangeHotelName(event.target.value);
          // }}
          onClick={() => {
            props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
            // props.onChangeHotelName("");
            props.onClickHotelInput();
          }}
        />
        {!commonFunction.isValueEmpty(props.isError) &&
          props.isError === true ? (
          <div className={props.styles?.hotel_search_error_message}>
            <span>
              {!commonFunction.isValueEmpty(props.errorMessage)
                ? props.errorMessage
                : "Invalid field"}
            </span>
          </div>
        ) : null}
        {props.expandAutoCompleteBool === true ? (
          <div
            className={props.styles?.hotel_autocomplete_value}
            id="hotelDataArray"
          >
            <div className={props.styles?.input_search_destination}>
              <svg width="50px" height="50px" viewBox="0,0,256,256"><g fill="#3479c5" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path></g></g></svg>
              <input
                type="text"
                autoComplete={"off"}
                id="myTextField"
                tabindex="1"
                onFocus={props.onInputFocus}
                autoFocus
                onKeyDown={props.onKeyDownArrow}
                placeholder={
                  !commonFunction.isValueEmpty(props.placholder)
                    ? props.placholder
                    : ""
                }
                className={props.styles?.search_widget_input_new}
                value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
                onChange={(event) => {
                  props.onChangeHotelName(event.target.value);
                }}
                onClick={() => {
                  props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
                  props.onChangeHotelName("");
                  props.onClickHotelInput();
                }}
              />
            </div>

            {!commonFunction.isValueEmpty(props.autoCompleteData) &&
              props.autoCompleteData.length > 0 &&
              props.autoCompleteData.map((element) => {
                return (
                  <div
                    id="hotelData"
                    className={props.styles?.hotel_autocomplete_element}
                    onClick={() => {
                      props.onClickHotelName(element);
                      props.isAutoCompleteExpand(false);
                    }}
                  >
                    <img
                      src={
                        element.property_count === 0
                          ? "/images/hotels-new.svg"
                          : "/images/location-icon.svg"
                      }
                    />
                    <div>
                      <div className={props.styles?.hotel_autocomplete_name}>
                        {element.search_text}
                      </div>
                      {element.property_count === 0 ? (
                        ""
                      ) : (
                        <div className={props.styles?.hotel_autocomplete_tag}>
                          {element.property_count} Properties
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : null}
      </div>
    </div>
  );
}
