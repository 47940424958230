import React from "react";
import commonFunction from "../../../../lib/utils/common";

export default function FlightAutoComplete(props) {
  const onClickHandler = (e) => {
    // if (props.expandAutoCompleteBool === true) props.isAutoCompleteExpand(false)
    // else if (props.expandAutoCompleteBool === false) {
    props.onclickFlightAutoComplete();
    // props.isAutoCompleteExpand(true)
    // }
  };
  const onFocusInputHandler = (e) => {
    props.onFocusInput();
  };
  const selectListClickHandler = (element) => {
    props.onClickSourceAndDestination(
      element.CITY_NAME,
      element.AIRPORT_CODE,
      element.AIRPORT_NAME
    );
    // props.isAutoCompleteExpand(false)
  };

  return (
    <div
      className={props.styles.flight_search_wid_inputfield}
      ref={props.listWrapper}
      id={`Flight_${props.label}_${props.id}`}
    >
      <label>{props.label}</label>
      {/* <img src={props.leftIcon} className={props.styles.flight_search_left_icon} /> */}
      <div className={props.styles.autocomplet_wrap}>
        <span className={props.styles.flight_search_left_icon}>
          {props.leftIcon}
        </span>

        <input
          type="text"
          autoComplete={"off"}
          id="myTextField"
          placeholder={
            !commonFunction.isValueEmpty(props.placholder)
              ? props.placholder
              : ""
          }
          className={props.styles.search_widget_input_new}
          value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
          onChange={(event) => {
            props.onChangeSourceAndDestination(
              event.target.value.replace(/[^a-zA-Z\s]/g, "")
            );
          }}
          onClick={onClickHandler}
          onFocusCapture={onClickHandler}
          onFocus={onFocusInputHandler}
        />
      </div>
      {/* <div className={props.styles.flight_ptext}>
            <p>{!commonFunction.isValueEmpty(props.airPortName) ? props.airPortName : ""}</p>
            </div> */}
      {!commonFunction.isValueEmpty(props.isError) && props.isError === true ? (
        <div
          className={
            props.isModifySearch
              ? props.styles.error_srp
              : props.styles.flight_search_error_message
          }
        >
          <span>
            {!commonFunction.isValueEmpty(props.errorMessage)
              ? props.errorMessage
              : "Invalid field"}
          </span>
        </div>
      ) : null}
      {props?.state?.isMultiCitySearch === true &&
      props.state.selectedDepartureIndex === props.index &&
      props.label === "From" &&
      props.expandAutoCompleteBool === true ? (
        <div
          ref={props.listWrapper}
          tabIndex="2"
          className={props.styles.flight_autocomplete_value}
          id={`Flight_${props.label}_${props.id}`}
        >
          {!commonFunction.isValueEmpty(props.autoCompleteData) &&
            props.autoCompleteData.length > 0 &&
            props.autoCompleteData.map((element, i) => {
              return (
                <div
                  id="flightData"
                  key={"autoCompleteData" + (i + 1)}
                  className={props.styles.flight_autocomplete_element}
                  onClick={() => {
                    selectListClickHandler(element);
                  }}
                  tabIndex="2"
                >
                  <div className={props.styles.fltg_icon}>
                  <span >
                    {props.leftIcon}
                  </span>
                  </div>
                  <div className={props.styles.flight_autocomplete_name}>
                    {element.CITY_NAME}, {element.COUNTRY_NAME}
                    <span>{element.AIRPORT_NAME}</span>
                  </div>
                  <div className={props.styles.flight_autocomplete_tag}>
                    {element.AIRPORT_CODE}
                  </div>
                </div>
              );
            })}
        </div>
      ) : null}
      {props?.state?.isMultiCitySearch === true &&
      props.state.selectedGoingToIndex === props.index &&
      props.label === "To" &&
      props.expandAutoCompleteBool === true ? (
        <div
          ref={props.listWrapper}
          tabIndex="2"
          className={props.styles.flight_autocomplete_value}
          id={`Flight_${props.label}_${props.id}`}
        >
          {!commonFunction.isValueEmpty(props.autoCompleteData) &&
            props.autoCompleteData.length > 0 &&
            props.autoCompleteData.map((element, i) => {
              return (
                <div
                  id="flightData"
                  key={"autoCompleteData" + (i + 1)}
                  className={props.styles.flight_autocomplete_element}
                  onClick={() => {
                    selectListClickHandler(element);
                  }}
                  tabIndex="2"
                >
                  <div className={props.styles.fltg_icon}>
                  <span >
                    {props.leftIcon}
                  </span>
                  </div>
                  <div className={props.styles.flight_autocomplete_name}>
                    {element.CITY_NAME}, {element.COUNTRY_NAME}
                    <span>{element.AIRPORT_NAME}</span>
                  </div>
                  <div className={props.styles.flight_autocomplete_tag}>
                    {element.AIRPORT_CODE}
                  </div>
                </div>
              );
            })}
        </div>
      ) : null}
      {props?.state?.isMultiCitySearch === false &&
      props.expandAutoCompleteBool === true ? (
        <div
          ref={props.listWrapper}
          tabIndex="2"
          className={props.styles.flight_autocomplete_value}
          id={`Flight_${props.label}_${props.id}`}
        >
          {/* <svg className={props.styles.arrow_wiget} width="151" height="101" viewBox="0 0 151 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M75.5 1.3811L140.885 76.0953H10.1151L75.5 1.3811Z" fill="white"/>
                    <line y1="-1.5" x2="100.715" y2="-1.5" transform="matrix(0.666347 -0.745642 0.939264 0.343196 9.5873 76.4784)" stroke="#2FC7C9" stroke-width="3"/>
                    <path d="M76.0269 1.19861L140.214 76.0953" stroke="#2FC7C9" stroke-width="3"/>
                    </svg> */}

          <div
            className={
              !commonFunction.isValueEmpty(props.autoCompleteData) &&
              props.autoCompleteData.length > 0
                ? props.styles.flights_city_parent
                : props.styles.flights_city_parent_unset
            }
          >
            {!commonFunction.isValueEmpty(props.autoCompleteData) &&
            props.autoCompleteData.length > 0 ? (
              props.autoCompleteData.map((element, i) => {
                return (
                  <div className={props.styles.flights_city_main}>
                    {/* <svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" style="
"><path fill="currentColor" d="M4.108,11.369l-2.693,1.089l5.507,4.903l8.443-2.754l-2.087,6.658l3.571-1.454l3.358-6.778l6.325-2.058l0,0 c0.702-0.193,1.292-0.669,1.631-1.314c0.34-0.645,0.396-1.401,0.157-2.089c-0.239-0.688-0.753-1.246-1.419-1.542 c-0.665-0.295-1.424-0.302-2.094-0.018l-6.174,2.27L10.01,4.621L6.409,6.073l6.778,4.54l-5.628,2.452L4.108,11.369z M16.425,19.358 l-2.179,0.876l1.846-5.839l3.328-1.089L16.425,19.358z M7.709,6.194l2.3-0.938l8.595,3.692l6.415-2.361l0,0 c0.519-0.235,1.112-0.241,1.635-0.017c0.524,0.224,0.929,0.658,1.116,1.195c0.188,0.538,0.141,1.129-0.129,1.63 c-0.271,0.501-0.739,0.866-1.291,1.004L7.075,16.694L2.536,12.67l1.543-0.636l3.45,1.725l6.929-3.025L7.709,6.194z"></path><rect x="9.625" y="23.5" fill="currentColor" width="18.841" height="0.75"></rect></svg> */}
                    <div
                      id="flightData"
                      key={"autoCompleteData" + (i + 1)}
                      className={props.styles.flight_autocomplete_element}
                      onClick={() => {
                        selectListClickHandler(element);
                      }}
                      tabIndex="2"
                    >
                      <div className={props.styles.fltg_icon}>
                      <span >
                    {props.leftIcon}
                  </span>
                      </div>
                      <div className={props.styles.flight_autocomplete_name}>
                        {element.CITY_NAME}, {element.COUNTRY_NAME}
                        {/* <div className={props.styles.flight_autocomplete_tag}>({element.AIRPORT_CODE})</div> */}
                        <span>{element.AIRPORT_NAME}</span>
                      </div>
                      <div className={props.styles.flight_autocomplete_tag}>
                        {element.AIRPORT_CODE}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p> No Results Found !!</p>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
