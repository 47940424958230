import React, { useEffect, useState } from "react";
import DesktopCalender from "../../../calendar/DesktopCalender";
import FlightAutoComplete from "./FlightAutoComplete";
import PaxConfig from "./PaxConfig";
import cf from "../../../../lib/utils/common";
import MulticityForm from './MulticityForm'


export default function Multicity(props) {
  // console.log("test",props?.state?.multicityFlightData.length);
  return (
    <>
      <div className={props.styles.container_widget} >
        {/* SELECTION BOXES SECTION  */}
        <div className={props.styles.selectors_boxes}>
        <div className={props.styles.flight_search_details_container}>
              <div className={props.styles.flight_search_details_main}>
                <div className={props.styles.flight_search_details_row}>
                  <div className={props.styles.conditional_comp}>
                  {props?.state?.multicityFlightData.map((data,i)=>{
                    // console.log(i,">>>>");
                    return  (
                      <div className={props.styles.returns_div}>
                    <MulticityForm props={props} 
                    data={data}
                    index={i}/>
                    </div>
                    )
                  })}
                  </div>

                 
                {/* <ul className={props.styles.additional_row}>{Array.from(Array(props.state.addFlight), (e, i) => {
    return <MulticityForm props={props}/>
  })}</ul> */}
                 <div className={props.styles.non_repeat_comp}>
                  <div
                    className={
                      props.styles.flight_search_column +
                      " " +
                      props.styles.flight_search_pax_multicity
                    }
                  >
                    <div className={props.styles.label_text}>
                    <label>Adult</label>
                    <span>(+12 Years)</span>
                    </div>
                    <PaxConfig
                      value={
                        !cf.isValueEmpty(props.state.paxAdultValue)
                          ? props.state.paxAdultValue
                          : 0
                      }
                      onChangePaxValue={(value, type) =>
                        props.onClickChangePaxNumber("adult", value, type)
                      }
                      maxLimit={9}
                      minLimit={1}
                      totalPax={
                        +props.state.paxChildrenValue + +props.state.paxAdultValue
                      }
                      styles={props.styles}
                    />
                  </div>
                  <div
                    className={
                      props.styles.flight_search_column +
                      " " +
                      props.styles.flight_search_pax_multicity
                    }
                  >
                    <div className={props.styles.label_text}>
                    <label>Children</label>
                    <span>(2-11 Years)</span>
                    </div>
                    <PaxConfig
                      value={
                        !cf.isValueEmpty(props.state.paxChildrenValue)
                          ? props.state.paxChildrenValue
                          : 0
                      }
                      onChangePaxValue={(value, type) =>
                        props.onClickChangePaxNumber("children", value, type)
                      }
                      maxLimit={9}
                      minLimit={0}
                      totalPax={
                        +props.state.paxChildrenValue + +props.state.paxAdultValue
                      }
                      styles={props.styles}
                    />
                  </div>
                  <div
                    className={
                      props.styles.flight_search_column +
                      " " +
                      props.styles.flight_search_pax_multicity
                    }
                  >
                    <div className={props.styles.label_text}>
                    <label>Infant</label>
                    <span>(Below 2 Years)</span>
                    </div>
                    <PaxConfig
                      value={
                        !cf.isValueEmpty(props.state.paxInfantValue)
                          ? props.state.paxInfantValue
                          : 0
                      }
                      onChangePaxValue={(value, type) =>
                        props.onClickChangePaxNumber("infant", value, type)
                      }
                      maxLimit={+props.state.paxAdultValue}
                      minLimit={0}
                      totalPax={props.state.paxInfantValue}
                      styles={props.styles}
                    />
                  </div>
                
                  <div
                    className={
                      props.styles.flight_search_column +
                      " " +
                      props.styles.flight_search_class_multicity
                    }
                    onClick={() => props.onClickOpenCabinClassList()}
                  >
                    <div
                      className={props.styles.flight_cabin_class_cont}
                    >
                      <div
                        className={props.styles.flight_cabin_class_icon}
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          className={
                            props.styles.flight_cabin_class_icon_img
                          }
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <g>
                            <path
                              fill="currentColor"
                              d="M15.018,1.971c-3.493,0-6.843,1.388-9.312,3.858c-2.471,2.469-3.858,5.819-3.858,9.312
		c0,3.494,1.388,6.842,3.858,9.313c2.469,2.47,5.819,3.856,9.312,3.856c3.493,0,6.841-1.387,9.313-3.856
		c2.47-2.47,3.856-5.819,3.856-9.313c-0.004-3.492-1.393-6.84-3.861-9.309C21.857,3.363,18.511,1.975,15.018,1.971L15.018,1.971z
		 M15.018,26.432c-2.994,0-5.865-1.189-7.982-3.308c-2.117-2.118-3.307-4.989-3.307-7.983c0-2.994,1.19-5.865,3.307-7.982
		c2.117-2.117,4.988-3.307,7.982-3.307c2.994,0,5.866,1.19,7.983,3.307c2.116,2.117,3.306,4.988,3.306,7.982
		c-0.002,2.994-1.192,5.862-3.311,7.978C20.88,25.237,18.011,26.428,15.018,26.432L15.018,26.432z"
                            />
                          </g>
                          <rect
                            x="7.623"
                            y="7.496"
                            fill="none"
                            width="14.792"
                            height="15.306"
                          />
                          <g>
                            <path
                              fill="currentColor"
                              d="M13.789,18.303l6.39,0.064c0.762,0.008,1.372,0.626,1.372,1.387v1.356c0,0.438-0.354,0.792-0.791,0.792
		c-0.023,0-0.048,0-0.072-0.003l-9.27-0.852c-0.204-0.018-0.36-0.188-0.36-0.394v-1.93L8.498,8.874
		C8.432,8.624,8.622,8.379,8.88,8.379h1.434c1.303,0,2.4,0.974,2.556,2.267L13.789,18.303z M9.392,9.171l2.369,9.106h1.227
		l-0.905-7.537c-0.107-0.896-0.867-1.57-1.769-1.57L9.392,9.171z M11.85,20.292l8.91,0.818v-1.356c0-0.326-0.262-0.592-0.588-0.595
		l-8.322-0.085V20.292z M14.854,12.301c-0.088-0.2,0.001-0.434,0.201-0.522c0.2-0.089,0.434,0.001,0.522,0.201
		c0.312,0.696,0.312,1.408,0,2.104c-0.218,0.492-0.218,0.969,0,1.461c0.09,0.198,0,0.433-0.201,0.521c-0.2,0.09-0.434,0-0.522-0.2
		c-0.309-0.696-0.309-1.407,0-2.104C15.072,13.27,15.072,12.793,14.854,12.301z M19.211,12.301c-0.091-0.2,0.001-0.434,0.199-0.522
		c0.201-0.089,0.436,0.001,0.523,0.201c0.31,0.696,0.31,1.408,0,2.104c-0.218,0.492-0.218,0.969,0,1.461
		c0.09,0.198-0.001,0.433-0.201,0.521c-0.199,0.09-0.434,0-0.521-0.2c-0.31-0.696-0.31-1.407,0-2.104
		C19.429,13.27,19.429,12.793,19.211,12.301z M17.033,10.519c-0.09-0.2,0-0.434,0.2-0.522s0.434,0.001,0.522,0.201
		c0.31,0.696,0.31,1.407,0,2.104c-0.218,0.492-0.218,0.969,0,1.46c0.089,0.2-0.002,0.434-0.201,0.523
		c-0.2,0.089-0.435-0.001-0.521-0.201c-0.312-0.696-0.312-1.407,0-2.104C17.251,11.488,17.251,11.011,17.033,10.519L17.033,10.519z"
                            />
                          </g>
                        </svg>
                      </div>
                      <div
                        className={props.styles.flight_cabin_class_name}
                      >
                        {!cf.isValueEmpty(props.SelectedClassValue)
                          ? props.SelectedClassValue.label
                          : ""}
                      </div>
                      <div
                        className={props.styles.flight_cabin_class_arrow_additional}
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                          style={{width: '18px', height: '18px', marginTop: '5px'}}
                        >
                          <path
                            id="XMLID_224_"
                            fill="currentColor"
                            d="M1.869,11.074l12.576,12.575c0.236,0.236,0.556,0.368,0.889,0.368
	c0.334,0,0.654-0.132,0.889-0.368l12.576-12.575c0.489-0.492,0.489-1.288,0-1.779c-0.492-0.491-1.288-0.491-1.779,0L15.333,20.98
	L3.647,9.295C3.401,9.049,3.08,8.926,2.757,8.926c-0.322,0-0.643,0.123-0.889,0.369C1.377,9.786,1.377,10.582,1.869,11.074z"
                          />
                        </svg>
                      </div>
                      {props.state.openCabinClassList === true ? (
                        <div
                          ref={props.economyPopUpWrapper}
                          className={props.styles.flight_cabin_class_list}
                        >
                          {props.cabinClass.map((element, index) => {
                            return (
                              <div
                                key={"cabinClass" + index}
                                className={
                                  props.styles.flight_cabin_class_ele
                                }
                                onClick={() => {
                                  props.onChangeClassValue(element)
                                  props.setState({
                                    openCabinClassList: false,
                                  });
                                }}
                              >
                                {element.label}
                              </div>
                            );
                          })}
                        </div>
                       ) 
                      : null} 
                    </div>
                  </div>

                  </div>
        {props?.state?.multicityFlightData.length <= 5 &&
          <div class={props.styles.search_button_section}>
          <button
                    onClick={props.AddFlightButton}
                    > Add More flight(4 Max) +</button>
            
        </div>
}
                </div>
                <div>
              </div>
            </div>
        </div>
        </div>
        </div>
      {/* </div> */}
    </>
  );
}


