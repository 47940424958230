import Theme1 from "./theme1"
import Theme2 from "./theme2";
import Theme4 from "./theme4";
import MobileTheme4 from "./theme4/mobile";

export function B2BWidgets(props) {
    const { theme } = props
    switch (theme) {
        case 'theme1':
            return <Theme1 {...props} />
        case 'theme2':
            return <Theme2 {...props} />
        case 'theme4':
            if (props.isMobile) {
                return <Theme4  {...props} />
            } else {
                return <Theme4  {...props} />
            }
        default:
           return <Theme1 {...props} />
    }
}

export default B2BWidgets;