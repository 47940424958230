import React, { Component } from "react";
import { add, format } from "date-fns";

import B2BWidgets from "../../../b2bwidgets/index";
import DesktopCalender from "../../../calendar/DesktopCalender";
import autoSuggest from "../../../../lib/autoSuggestExperience";
import cf from "../../../../lib/utils/common";
import { es } from "date-fns/locale";

// import {Slider, Box} from '@material-ui/core'
// import { Slider } from 'material-ui-slider';
// import Box from '@material-ui/core/Box';
// import Slider from '@material-ui/core/Slider';

// static 5 cities
const popularCities = [
  {
    destination_id: "12633",
    search_text: "Dubai",
    country_name: "United Arab Emirates",
    location_type: 1,
  },
  {
    destination_id: "12630",
    search_text: "Abu Dhabi",
    country_name: "United Arab Emirates",
    location_type: 1,
  },
  {
    destination_id: "12636",
    search_text: "Sharjah",
    country_name: "United Arab Emirates",
    location_type: 1,
  },
  {
    destination_id: "12631",
    search_text: "Ajman",
    country_name: "United Arab Emirates",
    location_type: 1,
  },
  {
    destination_id: "12634",
    search_text: "Fujairah",
    country_name: "United Arab Emirates",
    location_type: 1,
  },
  {
    destination_id: "12635",
    search_text: "Ras Al Khaimah",
    country_name: "United Arab Emirates",
    location_type: 1,
  },
];

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};
const fetchData = async (query, callBackFunc) => {
  if (query != "") {
    const res = await autoSuggest({ searchText: query });
    callBackFunc(res);
  } else {
    callBackFunc(null);
  }
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 400);

export default class Theme2 extends Component {
  constructor(props) {
    super(props);
    this.searchBoxRef = React.createRef();
    this.state = {
      activityName: "",
      countryName: "",
      isAutoSuggestVisible: false,
      checkInOutValue: "",
      checkInValue: null,
      checkOutValue: null,
      isCalendarVisible: false,
      autoSuggestCountryData: [],
      autoSuggestCityData: popularCities,
      selectedAutoSuggestData: null,
      isError: false,
      isDateError: false,
      lastArrowAction: "",
      selectedAutoSuggestIndex: -1,
      advSearch: false,
      isOneStar: false,
      isTwoStar: false,
      isThreeStar: false,
      isFourStar: false,
      isFiveStar: false,
      is2Day: false,
      is1Day: false,
      is1Hour: false,
      is10Hour: false,
      ratingSelected: [],
      responseRating: [],
      timeSelected: [],
      responseTime: [],
      guest_id: "",
    };
  }
  //Add client
  adGuestId = (id) => {
    this.setState({ guest_id: id });
  };
  onClickAdvSearch = () => {
    if (!this.state.advSearch) {
      this.setState({ advSearch: true });
    } else {
      this.setState({ advSearch: false });
    }
  };
  componentDidMount() {
    // this.searchBoxRef.current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "center",
    // });
    const activityname = new URLSearchParams(window.location.search).get(
      "activityname"
    );
    this.onChangeCheckInOutValue(new Date());
    // console.log("activityname from query param", activityname, this.props);
    // console.log("exp props -> ", this.props);
    if (activityname != null) {
      debouncedFetchData(activityname, (data) => {
        // console.log("dataa**********", data);
        if (
          data &&
          data.data &&
          data.data.searchBycity &&
          typeof data.data.searchBycity === "object"
        ) {
          const cityData = [...data.data.searchBycity.result];
          const selectedCity = cityData.filter((city) => {
            return city.search_text == activityname;
          })[0];
          // console.log("selectedCity", selectedCity);
          this.setState({
            autoSuggestCityData: cityData,
            isAutoSuggestVisible: false,
            activityName: selectedCity.search_text,
            countryName: selectedCity.country_name,
            selectedAutoSuggestData: selectedCity,
            isError: false,
          });
        } else if (data && data.data && data.data.status == false) {
          this.setState({
            autoSuggestCityData: popularCities,
            isAutoSuggestVisible: true,
          });
        }
      });
    }
  }

  onChangeCheckInOutValue = (dateValue) => {
    // console.log("date=value", dateValue);
    this.setState({
      checkInValue: cf.getDateWithFormat(
        // new Date(dateValue),
        add(new Date(dateValue), { days: 7 }),
        this?.props?.dateFormat
      ),
      checkOutValue: cf.getDateWithFormat(
        add(new Date(dateValue), { days: 8 }),
        this?.props?.dateFormat
      ),
      checkInOutValue: cf.getDateWithFormat(dateValue, this?.props?.dateFormat),
      isDateError: false,
    });
  };

  onChanegeSetActivityName = (value) => {
    this.setState({ activityName: value, isError: false });
    if (value.trim() == "") {
      this.setState({
        autoSuggestCityData: popularCities,
        isAutoSuggestVisible: false,
        selectedAutoSuggestData: null,
      });
    } else {
      debouncedFetchData(value, (data) => {
        if (
          data &&
          data.data &&
          data.data.searchBycity &&
          typeof data.data.searchBycity === "object"
        ) {
          this.setState({
            autoSuggestCityData: [...data.data.searchBycity.result],
            isAutoSuggestVisible: true,
          });
        } else if (data && data.data && data.data.status == false) {
          this.setState({
            autoSuggestCityData: popularCities,
            isAutoSuggestVisible: true,
          });
        }
      });
    }
  };

  onClickSearchActivity = () => {
    if (
      !(
        this.state.selectedAutoSuggestData &&
        this.state.selectedAutoSuggestData.search_text
      )
    ) {
      this.setState({ isError: true });
    }
    if (this.state.checkInOutValue == "") {
      this.setState({ isDateError: true });
    }
    if (
      this.state.selectedAutoSuggestData &&
      this.state.selectedAutoSuggestData.search_text &&
      this.state.checkInOutValue
    ) {
      let b2cquery = {
        location: this.state.selectedAutoSuggestData.search_text,
        country: this.state.selectedAutoSuggestData.country_name,
        checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
        checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
        from: 1,
        to: 30,
        search_type: 1, // this.state.selectedAutoSuggestData.location_type,
        search_text: this.state.selectedAutoSuggestData.destination_id,
        currency: "AED",
      };

      let b2bquery = {
        location: this.state.selectedAutoSuggestData.search_text,
        country: this.state.selectedAutoSuggestData.country_name,
        checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
        checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
        from: 1,
        to: 30,
        search_type: 1, // this.state.selectedAutoSuggestData.location_type,
        search_text: this.state.selectedAutoSuggestData.destination_id,
        currency: "AED",
        rating: [...new Set(this.state.responseRating)].join(","),
        duration: [...new Set(this.state.responseTime)].join(","),
        price_range: this.props.priceValue,
      };
      console.log(
        "url query -> ",
        this.props.userProfile?.type === "B2B" ? b2bquery : b2cquery
      );
      cf.redirectTo(
        "/experience/search",
        this.props?.userProfile
          ? this.props.userProfile?.type === "B2B"
            ? b2bquery
            : b2cquery
          : b2bquery
      );
    }
  };
  onClickSetActivityName = (element) => {
    this.setState({
      activityName: element.search_text,
      selectedAutoSuggestData: element,
      isError: false,
    });
  };
  onInputFocus = (fieldName) => {
    if (fieldName == "autocomplete") {
      /* if (this.state.isAutoSuggestVisible === false) { */
      this.setState({ isAutoSuggestVisible: true, isCalendarVisible: false });
      /* } */
    } else if (fieldName == "search") {
      this.setState({ isAutoSuggestVisible: false, isCalendarVisible: false });
    }
  };

  keyPress = (action) => {
    switch (action) {
      case "ArrowDown":
        // console.log("keyPress fn down called ", action);
        let children = document.getElementById(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        ).children;

        let selectIndex = 0;
        selectIndex =
          this.state.selectedAutoSuggestIndex == children.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex + 1;
        // console.log("children of auto-suggest", children);
        for (let index = 0; index < children.length; index++) {
          let childElement = children[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndex === index ? "#ccc" : "#fff";
          }
        }
        this.setState({
          selectedAutoSuggestIndex: selectIndex,
          lastArrowAction: "ArrowDown",
        });
        break;
      case "ArrowUp":
        // console.log("keyPress fn up called ", action);
        let childrens = document.getElementById(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        ).children;
        let selectIndexUp = 0;
        selectIndexUp =
          this.state.selectedAutoSuggestIndex == childrens.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex == -1
            ? 0
            : this.state.selectedAutoSuggestIndex - 1;
        // console.log("children of auto-suggest", childrens);
        for (let index = 0; index < childrens.length; index++) {
          let childElement = childrens[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndexUp === index ? "#ccc" : "#fff";
          }
        }
        // this.setState((prevState) => ({
        //   selectedAutoSuggestIndex:
        //     prevState.selectedAutoSuggestIndex > 0
        //       ? prevState.lastArrowAction=="ArrowDown"?prevState.selectedAutoSuggestIndex - 1:prevState.selectedAutoSuggestIndex
        //       : prevState.selectedAutoSuggestIndex+1,
        //   lastArrowAction: "ArrowUp",
        // }));
        this.setState({
          selectedAutoSuggestIndex: selectIndexUp,
          lastArrowAction: "ArrowUp",
        });
        break;
      case "Enter":
      case "Tab":
        let indexToSelect = 0;
        if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowUp"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        } else if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowDown"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        }
        // const indexToSelect = this.state.selectedAutoSuggestIndex===0?0:this.state.lastArrowAction?"ArrowUp"?this.state.selectedAutoSuggestIndex+1:this.state.selectedAutoSuggestIndex-1:0;
        const selectedItem = this.state.autoSuggestCityData[indexToSelect];
        this.setState({
          activityName: selectedItem.search_text,
          countryName: selectedItem.country_name,
          selectedAutoSuggestData: selectedItem,
          isError: false,
          isAutoSuggestVisible: false,
          selectedAutoSuggestIndex: indexToSelect,
          lastArrowAction: "",
        });
        break;

      default:
        // console.log("default", action);
        break;
    }
  };

  scrollToMyRef = () => {
    this.searchBoxRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  onClickTraveller = () => {
    // console.log("aaaaaaaa");
    if (!this.state.openTraveler) {
      // console.log("bbbbbbbbbb");
      this.setState({ openTraveler: true });
    } else {
      // console.log("ccccccccccccccc");
      this.setState({ openTraveler: false });
    }
  };

  handleStarCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleTimeCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  ratingHandler = (e) => {
    const { value, checked } = e.target;
    const { ratingSelected } = this.state;

    if (checked) {
      this.setState({
        ratingSelected: [...ratingSelected, value],
        responseRating: [...ratingSelected, value],
      });
    } else {
      this.setState({
        ratingSelected: ratingSelected.filter((e) => e !== value),
        responseRating: ratingSelected.filter((e) => e !== value),
      });
    }
  };

  timeHandler = (e) => {
    const { value, checked } = e.target;
    const { timeSelected } = this.state;

    if (checked) {
      this.setState({
        timeSelected: [...timeSelected, value],
        responseTime: [...timeSelected, value],
      });
    } else {
      this.setState({
        timeSelected: timeSelected.filter((e) => e !== value),
        responseTime: timeSelected.filter((e) => e !== value),
      });
    }
  };

  priceRangeSelector = (event, newValue) => {
    this.setState({
      priceValue: newValue,
    });
    // console.log(newValue);
  };

  rmGuestId = () => {
    this.setState({ guest_id: "" });
  };

  render() {
    return (
      <div
        className="container mx-auto xl:px-8"
        ref={this.searchBoxRef}
        onClick={(e) => {
          e.stopPropagation();
          if (this.state.isAutoSuggestVisible) {
            this.setState({
              isAutoSuggestVisible: false,
            });
          }
          if (this.state.isCalendarVisible) {
            this.setState({
              isCalendarVisible: false,
            });
          }
        }}
      >
        <div className={this.props.cssfile.activity_search_wid_container}>
          <div className={this.props.cssfile.activity_search_wid_main}>
            <div className={this.props.cssfile.activity_way_title}>
              {/* <h2>Experiences</h2> */}

              {/* {this.props.openSlider()} */}
            </div>
            {this.props?.userProfile?.type === "B2B" && (
              <B2BWidgets
                {...this.state}
                styles={this.props.b2bStyles}
                type={"experience"}
                onClickTraveller={this.onClickTraveller}
                onClickAdvSearch={this.onClickAdvSearch}
                ratingHandler={this.ratingHandler}
                removeGuestId={this.rmGuestId}
                timeHandler={this.timeHandler}
                handleStarCheck={this.handleStarCheck}
                handleTimeCheck={this.handleTimeCheck}
                openSlider={this.props.openSlider}
                priceRangeSelector={this.priceRangeSelector}
                addGuestId={this.adGuestId}
              />
            )}
            <div
              className={this.props.cssfile.activity_search_details_container}
            >
              <div className={this.props.cssfile.activity_search_details_main}>
                <div className={this.props.cssfile.activity_search_details_row}>
                  <div
                    className={
                      this.props.cssfile.activity_search_column +
                      " " +
                      this.props.cssfile.activity_search_name
                    }
                  >
                    <div
                      id="search-widget-main"
                      className={
                        this.props.cssfile.activity_search_wid_inputfield
                      }
                      onClick={(e) => {
                        this.scrollToMyRef();
                        e.stopPropagation();
                        this.setState((prevState) => {
                          return {
                            isAutoSuggestVisible:
                              !prevState.isAutoSuggestVisible,
                            isCalendarVisible: false,
                            activityName: "",
                          };
                        });
                      }}
                    >
                      <label>{"City / Destination"}</label>
                      <div
                        className={
                          this.props.cssfile.activity_search_wid_inputfield_main
                        }
                        // onClick={this.scrollToMyRef}
                      >
                        <span>
                          <svg
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="200px"
                            height="200px"
                            viewBox="0 0 200 200"
                            enableBackground="new 0 0 200 200"
                          >
                            <g>
                              <path
                                fill="currentColor"
                                d="M101,184.511c-0.031,0-0.062,0-0.094-0.001c-1.113-0.026-2.164-0.515-2.902-1.349
		c-1.537-1.736-3.586-4.031-6.147-6.885c-2.659-2.961-7.256-8.648-14.055-17.387c-6.738-8.66-12.719-17.192-17.779-25.359
		c-5.106-8.243-9.814-17.68-13.991-28.049c-4.284-10.633-6.457-20.179-6.457-28.372c0-16.481,5.985-30.635,17.79-42.068
		C69.39,23.395,84.07,17.489,101,17.489s31.546,5.842,43.442,17.365c11.932,11.556,17.982,25.772,17.982,42.255
		c0,8.19-2.106,17.607-6.26,27.989c-4.062,10.15-9.045,19.803-14.812,28.688c-5.665,8.729-11.346,17.015-16.885,24.627
		c-5.553,7.63-10.354,13.807-14.27,18.359c-0.053,0.062-0.107,0.121-0.164,0.18l-6.167,6.346
		C103.114,184.074,102.079,184.511,101,184.511z M101,25.489c-14.988,0-27.441,5.004-38.069,15.298
		C52.599,50.795,47.576,62.676,47.576,77.109c0,7.164,1.977,15.704,5.876,25.382c4.007,9.945,8.506,18.972,13.372,26.826
		c4.91,7.927,10.729,16.224,17.292,24.66c6.589,8.469,11.196,14.174,13.693,16.955c1.222,1.361,2.329,2.597,3.319,3.706l3.084-3.174
		c3.762-4.381,8.399-10.354,13.787-17.758c5.456-7.498,11.056-15.665,16.643-24.275c5.483-8.45,10.226-17.637,14.095-27.305
		c3.774-9.433,5.688-17.85,5.688-25.018c0-14.434-5.086-26.376-15.549-36.508C128.378,30.432,115.988,25.489,101,25.489z"
                              />
                            </g>
                            <g>
                              <path
                                fill="currentColor"
                                d="M101,91.748c-7.077,0-12.834-5.757-12.834-12.834S93.923,66.079,101,66.079s12.835,5.757,12.835,12.834
		S108.077,91.748,101,91.748z M101,74.079c-2.666,0-4.834,2.169-4.834,4.834s2.169,4.834,4.834,4.834
		c2.666,0,4.835-2.169,4.835-4.834S103.666,74.079,101,74.079z"
                              />
                            </g>
                          </svg>
                        </span>
                        <input
                          type="text"
                          tabIndex={1}
                          autoComplete={"off"}
                          id="myTextField"
                          readOnly
                          placeholder={`City / Destination`}
                          className={this.props.cssfile.search_widget_input_new}
                          value={
                            !cf.isValueEmpty(this.state.activityName) &&
                            !this.state.isAutoSuggestVisible
                              ? `${this.state.activityName}, ${this.state.countryName}`
                              : ""
                          }
                          onClick={(e) => {
                            this.scrollToMyRef();
                            e.stopPropagation();
                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                isAutoSuggestVisible:
                                  !prevState.isAutoSuggestVisible,
                                isCalendarVisible: false,
                                activityName: "",
                                autoSuggestCityData: popularCities,
                              };
                            });
                          }}
                          // onChange={(event) => {
                          //   event.stopPropagation();
                          //   this.onChanegeSetActivityName(event.target.value);
                          // }}

                          // onFocus={() => {
                          //   // e.stopPropagation();
                          //   // console.log("event on focus pressed", e.key);
                          //   this.onInputFocus("autocomplete");
                          // }}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                            this.keyPress(e.key);
                          }}
                        />
                        {this.state.isError && (
                          <div className={this.props.cssfile.error}>
                            {"Enter Destination"}
                          </div>
                        )}

                        {this.state.isAutoSuggestVisible === true ? (
                          <div
                            className={
                              this.props.cssfile.activity_autocomplete_value
                            }
                            id="ACTIVITY-AUTO-SUGGEST-DATA-LIST"
                          >
                            <div
                              className={
                                this.props.cssfile.input_search_destination
                              }
                            >
                              <svg
                                width="50px"
                                height="50px"
                                viewBox="0,0,256,256"
                              >
                                <g
                                  fill="#3479c5"
                                  fill-rule="nonzero"
                                  stroke="none"
                                  stroke-width="1"
                                  stroke-linecap="butt"
                                  stroke-linejoin="miter"
                                  stroke-miterlimit="10"
                                  stroke-dasharray=""
                                  stroke-dashoffset="0"
                                  font-family="none"
                                  font-weight="none"
                                  font-size="none"
                                  text-anchor="none"
                                >
                                  <g transform="scale(5.12,5.12)">
                                    <path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                                  </g>
                                </g>
                              </svg>
                              <input
                                type="text"
                                tabIndex={1}
                                autoFocus
                                autoComplete={"off"}
                                id="myTextField"
                                placeholder={`City / Destination `}
                                className={
                                  this.props.cssfile.search_widget_input_new
                                }
                                value={
                                  !cf.isValueEmpty(this.state.activityName)
                                    ? this.state.activityName
                                    : ""
                                }
                                onChange={(event) => {
                                  event.stopPropagation();
                                  this.onChanegeSetActivityName(
                                    event.target.value
                                  );
                                }}
                                onClick={(e) => {
                                  this.scrollToMyRef();
                                  e.stopPropagation();
                                  this.setState((prevState) => {
                                    return {
                                      ...prevState,
                                      isAutoSuggestVisible: true,
                                      isCalendarVisible: false,
                                      activityName: "",
                                    };
                                  });
                                }}
                                // onFocus={() => {
                                //   // e.stopPropagation();
                                //   // console.log("event on focus pressed", e.key);
                                //   this.onInputFocus("autocomplete");
                                // }}
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                  this.keyPress(e.key);
                                }}
                              />
                            </div>
                            {/* <div className={this.props.cssfile.activity_search_new}>
                              <div className={this.props.cssfile.activity_iconsearch}>
                              <svg version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="35px"
                          height="35px"
                          viewBox="0 0 35 35"
                          enableBackground="new 0 0 35 35">
                              <path id="Path_164" fill="#3479C5" d="M11.045,0.004C4.943,0.008-0.001,4.958,0.003,11.06S4.957,22.105,11.06,22.102
	c2.33,0,4.601-0.738,6.485-2.107l5.5,5.5c0.668,0.688,1.766,0.701,2.452,0.035c0.687-0.668,0.702-1.766,0.035-2.452
	c-0.013-0.013-0.023-0.022-0.035-0.035l-5.5-5.5c3.587-4.931,2.499-11.836-2.433-15.424C15.671,0.739,13.388-0.001,11.045,0.004z
	 M11.045,18.637c-4.193,0.006-7.596-3.389-7.602-7.582c-0.005-4.193,3.389-7.596,7.582-7.602c4.193-0.005,7.597,3.389,7.602,7.582
	c0.003,2.017-0.797,3.951-2.223,5.378c-1.422,1.421-3.349,2.22-5.359,2.22V18.637z"/>
                              </svg>
                              </div>
                              <div className={this.props.cssfile.activity_citysearch}>
                                <span>City / Destination / Activity</span>
                              </div>
                            </div>
                            <div className={this.props.cssfile.activity_recentsearch}>Recent Search</div> this will be picked later */}
                            {!cf.isValueEmpty(
                              this.state.autoSuggestCountryData
                            ) &&
                              this.state.autoSuggestCountryData.length > 0 &&
                              this.state.countryData.map((element, index) => {
                                return (
                                  <div
                                    id={"COUNTRY-" + index}
                                    key={element.search_text}
                                    className={
                                      this.props.cssfile
                                        .activity_autocomplete_element
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        activityName: element.search_text,
                                        isAutoSuggestVisible: false,
                                      });
                                      this.onClickSetActivityName(element);
                                    }}
                                  >
                                    <img src="images/location-01.svg" />
                                    <div>
                                      <div
                                        className={
                                          this.props.cssfile
                                            .activity_autocomplete_name
                                        }
                                      >
                                        {element.search_text}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {!cf.isValueEmpty(this.state.autoSuggestCityData) &&
                            this.state.autoSuggestCityData.length > 0 ? (
                              this.state.autoSuggestCityData.map(
                                (element, index) => {
                                  return (
                                    <div
                                      id={"ACTIVITY_CITY-" + index}
                                      key={`city-${element.search_text}`}
                                      className={
                                        this.props.cssfile
                                          .activity_autocomplete_element
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          activityName: element.search_text,
                                          countryName: element.country_name,
                                          isAutoSuggestVisible: false,
                                        });
                                        this.onClickSetActivityName(element);
                                      }}
                                    >
                                      {/* <img src="images/location-01.svg" /> */}
                                      <span></span>
                                      <div>
                                        <div
                                          className={
                                            this.props.cssfile
                                              .activity_autocomplete_name
                                          }
                                        >
                                          {`${element.search_text}, ${element.country_name}`}
                                        </div>
                                        {/* <div className={this.props.cssfile.activity_autocomplete_newname}>
                                        23 packages
                                      </div> this will be picked later */}
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div
                                key={`noresults`}
                                className={
                                  this.props.cssfile
                                    .activity_autocomplete_element
                                }
                              >
                                {/* <img src="images/location-01.svg" /> */}
                                <span></span>
                                <div>
                                  <div
                                    className={
                                      this.props.cssfile
                                        .activity_autocomplete_name
                                    }
                                  >
                                    No Results Found
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className={`${this.props.cssfile.activity_search_column} ${this.props.cssfile.activity_search_date}`}
                  >
                    <DesktopCalender
                      tabIndex={2}
                      label="Date"
                      styles={this.props.calendarStyles}
                      value={
                        !cf.isValueEmpty(this.state.checkInOutValue)
                          ? this.state.checkInOutValue
                          : ""
                      }
                      placholder="Select the date"
                      onChangeDate={(dateVal) => {
                        this.onChangeCheckInOutValue(dateVal);
                      }}
                      // iconImage={<img src="images/calendar-01.svg" />}
                      startDate={cf.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      startDateLimit={cf.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      dateFormat={this?.props?.dateFormat}
                      changeVisibility={(bool) => {
                        this.scrollToMyRef();
                        this.setState({
                          isCalendarVisible: bool,
                          isAutoSuggestVisible: false,
                          isDateError: false,
                        });
                      }}
                      isVisible={this.state.isCalendarVisible}
                      doubleSelection={false}
                    />
                    <span>
                      <small onClick={(prevState) => {
                        this.setState({
                          isCalendarVisible: !prevState.isCalendarVisible,
                          isAutoSuggestVisible: false,
                          isDateError: false,
                        });
                      }}></small>
                    </span>
                    {this.state.isDateError && (
                      <div className={this.props.cssfile.errordate}>
                        {"Please select Date"}
                      </div>
                    )}
                  </div> */}

                  <div
                    className={`${this.props.cssfile.activity_search_details_row} ${this.props.cssfile.activity_nw}`}
                  >
                    <div
                      className={`${this.props.cssfile.activity_search_class} ${this.props.cssfile.exp_ml_0}`}
                    >
                      <button
                        tabIndex={3}
                        className={
                          this.props.cssfile.activity_search_widget_btn
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          this.onClickSearchActivity();
                        }}
                        onFocus={(e) => {
                          e.stopPropagation();
                          this.onInputFocus("search");
                        }}
                      >
                        <svg width="50px" height="50px" viewBox="0,0,256,256">
                          <g
                            fill="#ffffff"
                            fill-rule="nonzero"
                            stroke="none"
                            stroke-width="1"
                            stroke-linecap="butt"
                            stroke-linejoin="miter"
                            stroke-miterlimit="10"
                            stroke-dasharray=""
                            stroke-dashoffset="0"
                            font-family="none"
                            font-weight="none"
                            font-size="none"
                            text-anchor="none"
                          >
                            <g transform="scale(5.12,5.12)">
                              <path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                            </g>
                          </g>
                        </svg>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
