import React from 'react';
import commonFunction from '../../../../lib/utils/common';

export default function FlightAutoComplete(props) {
    // console.log('flight_search_wid_inputfield', props)
    const onClickHandler = (e) => {
        // if (props.expandAutoCompleteBool === true) props.isAutoCompleteExpand(false)
        // else if (props.expandAutoCompleteBool === false) {
        props.onclickFlightAutoComplete()
        props.isAutoCompleteExpand(true)
        // }
    }

    const selectListClickHandler = (element) => {
        props.onClickSourceAndDestination(element.CITY_NAME, element.AIRPORT_CODE)
        props.isAutoCompleteExpand(false)
    }

    return (
        <div className={props.styles.flight_search_wid_inputfield}>
            <label>{props.label}</label>
            {/* <img src={props.leftIcon} className={props.styles.flight_search_left_icon} /> */}
            <span className={props.styles.flight_search_left_icon}>
                {props.leftIcon}
            </span>
            <input type='text' autoComplete={'off'} id="myTextField"
                placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
                className={props.styles.search_widget_input_new}
                value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
                onChange={(event) => { props.onChangeSourceAndDestination(event.target.value) }}
                onClick={onClickHandler}
                onFocusCapture={onClickHandler}
                tabIndex="2"
                onKeyDown={props.onKeyDownArrow}
            />
            {!commonFunction.isValueEmpty(props.isError) && props.isError === true ? (
                <div className={props.styles.flight_search_error_message}><span>{!commonFunction.isValueEmpty(props.errorMessage) ? props.errorMessage : 'Invalid field'}</span></div>
            ) : null}
            {(props?.state?.isMultiCitySearch === true && (props.state.selectedDepartureIndex === props.index) && props.label === "Departure From" && props.expandAutoCompleteBool === true) ? (
                  <div ref={props.listWrapper} tabIndex="2" className={props.styles.flight_autocomplete_value} id="flightDataArray">
                  {!commonFunction.isValueEmpty(props.autoCompleteData) && props.autoCompleteData.length > 0 &&
                      props.autoCompleteData.map((element, i) => {
                          return (
                              <div id='flightData' key={"autoCompleteData" + (i + 1)} className={props.styles.flight_autocomplete_element}
                                  onClick={() => { selectListClickHandler(element) }}
                                  tabIndex="2"
                              >
                                  <div className={props.styles.flight_autocomplete_name}>{element.CITY_NAME}, {element.COUNTRY_NAME}
                                      <span>{element.AIRPORT_NAME}</span>
                                  </div>
                                  <div className={props.styles.flight_autocomplete_tag}>{element.AIRPORT_CODE}</div>
                              </div>
                          )
                      })
                  }
              </div>
            ): null
            }
               {(props?.state?.isMultiCitySearch === true && (props.state.selectedGoingToIndex === props.index) && props.label === "Going To" && props.expandAutoCompleteBool === true) ? (
                  <div ref={props.listWrapper} tabIndex="2" className={props.styles.flight_autocomplete_value} id="flightDataArray">
                  {!commonFunction.isValueEmpty(props.autoCompleteData) && props.autoCompleteData.length > 0 &&
                      props.autoCompleteData.map((element, i) => {
                          return (
                              <div id='flightData' key={"autoCompleteData" + (i + 1)} className={props.styles.flight_autocomplete_element}
                                  onClick={() => { selectListClickHandler(element) }}
                                  tabIndex="2"
                              >
                                  <div className={props.styles.flight_autocomplete_name}>{element.CITY_NAME}, {element.COUNTRY_NAME}
                                      <span>{element.AIRPORT_NAME}</span>
                                  </div>
                                  <div className={props.styles.flight_autocomplete_tag}>{element.AIRPORT_CODE}</div>
                              </div>
                          )
                      })
                  }
              </div>
            ): null
            }
            {props?.state?.isMultiCitySearch === false && props.expandAutoCompleteBool === true  ? (
                <div ref={props.listWrapper} tabIndex="2" className={props.styles.flight_autocomplete_value} id="flightDataArray">
                    {!commonFunction.isValueEmpty(props.autoCompleteData) && props.autoCompleteData.length > 0 &&
                        props.autoCompleteData.map((element, i) => {
                            return (
                                <div id='flightData' key={"autoCompleteData" + (i + 1)} className={props.styles.flight_autocomplete_element}
                                    onClick={() => { selectListClickHandler(element) }}
                                    tabIndex="2"
                                >
                                    <div className={props.styles.flight_autocomplete_name}>{element.CITY_NAME}, {element.COUNTRY_NAME}
                                        <span>{element.AIRPORT_NAME}</span>
                                    </div>
                                    <div className={props.styles.flight_autocomplete_tag}>{element.AIRPORT_CODE}</div>
                                </div>
                            )
                        })
                    }
                </div>
            ) : null
            }
        </div>
    )
}