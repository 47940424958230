import React, { Component } from "react";
import { addDays, differenceInCalendarDays, format } from "date-fns";

import B2BWidgets from "../../../b2bwidgets/index";
import DesktopCalender from "../../../calendar/hotelCalender/theme2/DesktopCalender";
import HotelAutoComplete from "./HotelAutoComplete";
import NationalityDropdownComp from "../../../NationalityDropDown/index";
import cf from "../../../../lib/utils/common";
import getNationalitiesList from "../../../../lib/getNationalitiesList";

const childAgeList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
let regions = "";
let indexDropdown = -1;
let search_text = "";
let destination_id = "";
let search_type = "";
export default class Theme2 extends Component {
  constructor(props) {
    super(props);
    this.setScrollDown = React.createRef();
    this.wrapperRef = React.createRef();
    this.advanceSearchWrapper = React.createRef();
    this.guestDetailWrapper = React.createRef();
    this.state = {
      hotelName: "",
      expandHotelNameBoolean: false,
      checkInOutValue: "",
      checkInValue: cf.getDateWithFormat(new Date(), this?.props?.dateFormat),
      checkOutValue: cf.getDateWithFormat(
        addDays(
          new Date(),
          this.props.getDefaultDays ? this.props.getDefaultDays : 2
        ),
        this?.props?.dateFormat
      ),
      dateLimit: cf.getDateWithFormat(new Date(), this?.props?.dateFormat),
      openRoomDetailList: false,
      expandCheckInOutDateBoolean: false,
      selectedRoomDetails: { rooms: 1, guest: 2 },
      getAutoSuggestList: this.props.getAutoSuggestList,
      isError: {
        hotelName: false,
        checkInOutDate: false,
        roomGuest: false,
        selectedNationality: false,
        selectedGuest: false,
      },
      getDestinationId: "",
      getSearchType: "",
      roomList: [
        {
          id: 1,
          adults: 2,
          children: 0,
          adultAddCountDisable: false,
          adultMinusCountDisable: false,
          childrenAddCountDisable: false,
          childrenMinusCountDisable: true,
          childDetails: [],
        },
      ],
      // finalRoomList: [{ id: 1, adults: 2, children: 0, childDetails: [] }],
      finalRoomList: [
        {
          id: 1,
          adults: 2,
          children: 0,
          adultAddCountDisable: false,
          adultMinusCountDisable: true,
          childrenAddCountDisable: false,
          childrenMinusCountDisable: true,
          childDetails: [],
        },
      ],
      type: "hotel",
      openTraveler: false,
      advSearch: false,
      nationallitySearch: null,
      is_breakfast: "",
      // isBreakfastChecked: false,
      originCountries: [],
      selectedNationality: "",
      response: [],
      isOneStar: false,
      isTwoStar: false,
      isThreeStar: false,
      isFourStar: false,
      isFiveStar: false,
      selectedCheckBoxes: {},
      guest_id: "",
    };
  }

  // Nationality

  componentDidMount() {
    // Nationality
    getNationalitiesList().then((resp) => {
      let nationalityList = [];
      if (resp?.data?.data.length > 0) {
        resp?.data.data.map((item) => {
          nationalityList.push({
            ...item,
            value: item.nationality_name,
            countryCode: item.nationality_code,
          });
        });
      }
      this.setState({ originCountries: nationalityList });
    });

    regions = new URLSearchParams(window.location.search).get("region");
    if (
      !cf.isValueEmpty(this.props) &&
      this.props.isSRP === true &&
      !cf.isValueEmpty(this.props.query)
    ) {
      let checkInArr = this.props.query.checkin.split("-");
      let checkOutArr = this.props.query.checkout.split("-");
      let checkInValue = cf.getDateWithFormat(
        new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]),
        this?.props?.dateFormat
      );
      let checkOutValue = cf.getDateWithFormat(
        new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]),
        this?.props?.dateFormat
      );
      let checkInOut =
        cf.getDateWithFormat(
          new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]),
          this?.props?.dateFormat
        ) +
        " - " +
        cf.getDateWithFormat(
          new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]),
          this?.props?.dateFormat
        );
      let roomsdetail = this.props.query.rooms.split("_");
      let totalRooms = roomsdetail.length;
      let totalAdult = 0;
      let totalChildren = 0;
      let roomListArr = [];
      let finalRoomListArr = []; //new
      roomsdetail.map((ele, index) => {
        let split = ele.split("-").filter((item) => item);
        totalAdult = Number(split[0].charAt(0)) + totalAdult;
        totalChildren = Number(split[1].charAt(0)) + totalChildren;
        roomListArr = [
          ...roomListArr,
          {
            id: index + 1,
            adults: Number(split[0].charAt(0)),
            children: Number(split[1].charAt(0)),
            adultAddCountDisable: false,
            adultMinusCountDisable: true,
            childrenAddCountDisable: false,
            childrenMinusCountDisable:
              Number(split[1].charAt(0)) == 1 ? false : true,
            childDetails: [],
          },
        ];
        // finalRoomListArr = [...finalRoomListArr, { id: index + 1, adults: Number(split[0].charAt(0)), children: Number(split[1].charAt(0)), childDetails: [] }] //new
        // split.map((element, id) => {
        //     if (id > 1) roomListArr[index].childDetails = [...roomListArr[index].childDetails, { age: element, isOpen: false, isError: false }],
        //         finalRoomListArr[index].childDetails = [...finalRoomListArr[index].childDetails, { age: Number(element) }]
        // })
        split.map((element, id) => {
          if (id > 1)
            roomListArr[index].childDetails = [
              ...roomListArr[index].childDetails,
              { age: element, isOpen: false, isError: false },
            ];
        });
      });
      this.setState(
        {
          checkInOutValue: checkInOut,
          checkInValue: checkInValue,
          checkOutValue: checkOutValue,
          getDestinationId: this.props.query.destid,
          getSearchType: this.props.query.search_type,
          hotelName: this.props.query.region + ", " + this.props.query.country,
          selectedRoomDetails: {
            rooms: totalRooms,
            guest: totalAdult + totalChildren,
          },
          roomList: roomListArr,
          nationallitySearch: this.props.query.nationality,
          // response:this.props.query.ratings,
          guest_id: this.props.query.guestId,
        },
        // this.setState(prevState => ({
        //   ...prevState.selectedCheckBoxes,
        //   selectedCheckBoxes: {
        //       breakfast:[[this.props.query.bf]],
        //       room_type:[[this.props.query.rf]],
        //   }
        // })),
        () => {
          this.checkRoomDetails();
        }
      );
      const deepClone = JSON.parse(JSON.stringify(roomListArr));
      this.setState({
        finalRoomList: deepClone,
      });
    } else {
      if (regions != null && regions != "" && regions != undefined) {
        this.renderInfo();
      }
      this.setState({
        checkInOutValue: this.getCheckInOutValue(
          this.state.checkInValue,
          this.state.checkOutValue
        ),
      });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    // document.addEventListener('mousedown', function(e){ e.preventDefault(); }, false)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  checkRoomDetails() {
    let { roomList } = this.state;
    roomList.map((ele, index) => {
      let totalAccomodation = ele.adults + ele.children;
      if (totalAccomodation <= 12) {
        if (totalAccomodation === 12) {
          roomList[index].adultAddCountDisable = true;
          roomList[index].childrenAddCountDisable = true;
        } else if (totalAccomodation < 12) {
          if (
            roomList[index].adultAddCountDisable === true &&
            roomList[index].childrenAddCountDisable === true
          ) {
            roomList[index].adultAddCountDisable = false;
            roomList[index].childrenAddCountDisable = false;
          }
          if (roomList[index].adults > 1) {
            roomList[index].adultMinusCountDisable = false;
          } else if (roomList[index].adults === 1) {
            roomList[index].adultMinusCountDisable = true;
            roomList[index].adultAddCountDisable = false;
          }
          if (roomList[index].children > 1) {
            roomList[index].childrenMinusCountDisable = false;
          } else if (roomList[index].children > 0) {
            roomList[index].childrenMinusCountDisable = false;
            roomList[index].childrenAddCountDisable = false;
          }
        }
      }
    });
    this.setState({ roomList: [...roomList] });
  }
  handleClickOutside = (e) => {
    if (
      this.dropdownWrapperRef &&
      !this.dropdownWrapperRef.contains(e.target)
    ) {
      if (this.props.expandHotelNameBoolean === true)
        this.props.expandHotelNameBooleanfunc(false);
      if (
        this.advanceSearchWrapper.current &&
        !this.advanceSearchWrapper.current.contains(event.target)
      ) {
        this.setState({ advSearch: false });
      }
      if (
        this.guestDetailWrapper.current &&
        !this.guestDetailWrapper.current.contains(event.target)
      ) {
        this.setState({ openTraveler: false });
      }
      if (
        this.state.expandCheckInOutDateBoolean === true ||
        this.state.openRoomDetailList === true
      ) {
        this.setState({
          expandCheckInOutDateBoolean: false,
          openRoomDetailList: false,
        });
      }
    }
  };
  setDropdownWrapperRef = (node) => {
    this.dropdownWrapperRef = node;
  };
  onClickDoneButton = () => {
    let totalGuest = 0;
    let count = 0;
    let Validation = true;
    let validation_arr = [];
    let roomList = this.state.roomList;
    // let finalRoomListArr = []; //new
    while (count <= roomList.length) {
      if (
        !cf.isValueEmpty(roomList[count]) &&
        !cf.isValueEmpty(roomList[count].childDetails) &&
        roomList[count].childDetails.length > 0
      ) {
        // finalRoomListArr = [...finalRoomListArr, { id: count + 1, adults: roomList[count].adults, children: roomList[count].children, childDetails: [{ age: '' }] }] //new
        roomList[count].childDetails.map((element, index) => {
          if (cf.isValueEmpty(element.age)) {
            roomList[count].childDetails[index].isError = true;
          }
        });
        // Validation = roomList[count].childDetails.every(item => !cf.isValueEmpty(item.age));
        validation_arr.push(
          roomList[count].childDetails.every(
            (item) => !cf.isValueEmpty(item.age)
          )
        );
        let checker = validation_arr.every(Boolean);
        Validation = checker;
      }
      count++;
    }
    // this.setState({ roomList: [...roomList] })
    this.setState({ roomList: [...roomList] }, () => {
      if (Validation === true) {
        this.state.roomList.length > 0 &&
          this.state.roomList.map((element) => {
            totalGuest = totalGuest + element.adults + element.children;
            //new
            // element.childDetails.map((ele, i) => {
            //     finalRoomListArr[idx].childDetails[i].age = ele.age;
            // })
          });
        this.setState({
          // finalRoomList: finalRoomListArr,
          selectedRoomDetails: {
            rooms: this.state.roomList.length,
            guest: totalGuest,
          },
        });
        const deepClone = JSON.parse(JSON.stringify(roomList));
        this.setState({
          finalRoomList: deepClone,
        });
        if (this.state.openRoomDetailList === true) {
          this.setState({ openRoomDetailList: false });
        }
      }
    });
  };
  removeRoomItem = (id) => {
    const filteredRoom = this.state.roomList.filter((item) => item.id !== id);
    this.setState({ roomList: [...filteredRoom] });
  };

  addAccomodationCount = async (id, index, type) => {
    let { roomList } = this.state;
    if (type === "adult") {
      if (roomList[index].id === id) {
        roomList[index].adults = roomList[index].adults + 1;
      }
    } else {
      if (roomList[index].id === id) {
        roomList[index].children = roomList[index].children + 1;
        roomList[index].childDetails = [
          ...roomList[index].childDetails,
          { age: "", isOpen: false, isError: false },
        ];
      }
    }
    let totalAccomodation = roomList[index].adults + roomList[index].children;
    if (totalAccomodation <= 7) {
      if (totalAccomodation === 7) {
        roomList[index].adultAddCountDisable = true;
        roomList[index].childrenAddCountDisable = true;
      } else if (totalAccomodation < 7) {
        if (
          roomList[index].adultAddCountDisable === true &&
          roomList[index].childrenAddCountDisable === true
        ) {
          roomList[index].adultAddCountDisable = false;
          roomList[index].childrenAddCountDisable = false;
        }
      }
      if (roomList[index].adults > 1) {
        roomList[index].adultMinusCountDisable = false;
      }
      if (roomList[index].children > 0) {
        roomList[index].childrenMinusCountDisable = false;
      }
      await this.setState({ roomList: [...roomList] });
    }
  };
  minusAccomodationCount = async (id, index, type) => {
    let { roomList } = this.state;
    if (type === "adult") {
      if (roomList[index].id === id) {
        if (roomList[index].adults >= 2) {
          roomList[index].adults = roomList[index].adults - 1;
          roomList[index].adultMinusCountDisable = false;
          if (roomList[index].adultAddCountDisable === true) {
            roomList[index].adultAddCountDisable = false;
          }
          if (roomList[index].childrenAddCountDisable === true) {
            roomList[index].childrenAddCountDisable = false;
          }
        } else if (roomList[index].adults === 1) {
          roomList[index].adultMinusCountDisable = true;
        }
      }
    } else {
      if (roomList[index].id === id) {
        roomList[index].childDetails.pop();
        if (roomList[index].children >= 1) {
          roomList[index].children = roomList[index].children - 1;
          roomList[index].childrenMinusCountDisable = false;
          if (roomList[index].childrenAddCountDisable === true) {
            roomList[index].childrenAddCountDisable = false;
          }
          if (roomList[index].adultAddCountDisable === true) {
            roomList[index].adultAddCountDisable = false;
          }
        } else if (roomList[index].children === 0) {
          roomList[index].childrenMinusCountDisable = true;
        }
      }
    }
    await this.setState({ roomList: [...roomList] });
  };
  isChildrenAge = (index, ind, type, age) => {
    let { roomList } = this.state;
    if (type === "isOpen") {
      roomList[index].childDetails[ind].isOpen =
        !roomList[index].childDetails[ind].isOpen;
    } else {
      roomList[index].childDetails[ind].age = age;
      roomList[index].childDetails[ind].isOpen = false;
      if (roomList[index].childDetails[ind].isError === true) {
        roomList[index].childDetails[ind].isError = false;
      }
    }
    this.setState({ roomList: [...roomList] });
  };
  onChangeCheckInOutValue = (value, type) => {
    if (type === "startDate") {
      this.setState({ checkInValue: value });
    } else if (type === "endDate") {
      let differenceInDays = differenceInCalendarDays(
        new Date(value),
        new Date(this.state.checkInValue)
      );
      if (differenceInDays === 0) {
        this.setState({ checkOutValue: addDays(new Date(value), 1) }, () => {
          if (!cf.isValueEmpty(value)) {
            let checkInOut =
              cf.getDateWithFormat(
                this?.state?.checkInValue,
                this?.props?.dateFormat
              ) +
              " - " +
              cf.getDateWithFormat(
                this?.state?.checkOutValue,
                this?.props?.dateFormat
              );
            this.setState({ checkInOutValue: checkInOut });
          }
        });
      } else {
        this.setState({ checkOutValue: value }, () => {
          if (!cf.isValueEmpty(value)) {
            let checkInOut =
              cf.getDateWithFormat(
                this?.state?.checkInValue,
                this?.props?.dateFormat
              ) +
              " - " +
              cf.getDateWithFormat(value, this?.props?.dateFormat);
            this.setState({ checkInOutValue: checkInOut });
          }
        });
      }
    }
  };

  onChangeSetHotelName = (value) => {
    this.setState({ hotelName: value, getDestinationId: "" }, () => {
      if (this.state.isError.hotelName === true) {
        this.validateErrorMsg();
      }
      this.props.getAutoSuggestValue(value);
    });
  };
  validateErrorMsg = () => {
    const mapDispatch = {
      hotelName: cf.isValueEmpty(this.state.hotelName) ? true : false,
      checkInOutDate: cf.isValueEmpty(this.state.checkInOutValue)
        ? true
        : false,
      roomGuest: cf.isValueEmpty(this.state.selectedRoomDetails) ? true : false,
      selectedNationality: cf.isValueEmpty(this.state.selectedNationality)
        ? true
        : false,
      selectedGuest: cf.isValueEmpty(this.state.guest_id) ? true : false,
    };
    this.setState({ isError: mapDispatch });
  };

  onClickSearchHotel = () => {
    const mapDispatch = {
      hotelName: cf.isValueEmpty(this.state.hotelName) ? true : false,
      checkInOutDate: cf.isValueEmpty(this.state.checkInOutValue)
        ? true
        : false,
      roomGuest: cf.isValueEmpty(this.state.selectedRoomDetails) ? true : false,
      selectedNationality: cf.isValueEmpty(this.state.selectedNationality)
        ? true
        : false,
      selectedGuest: cf.isValueEmpty(this.state.guest_id) ? true : false,
    };
    if (!this.props?.userProfile?.type) {
      mapDispatch.selectedNationality = cf.isValueEmpty(
        this.state.selectedNationality
      )
        ? false
        : true;
    }
    if (this.props?.userProfile?.type !== "B2B") {
      mapDispatch.selectedNationality = cf.isValueEmpty(
        this.state.selectedNationality
      )
        ? false
        : true;
    }
    if (this.props.isOffline !== true) {
      mapDispatch.selectedGuest = cf.isValueEmpty(this.state.selectedGuest)
        ? false
        : true;
    }
    if (!this.props.isOffline) {
      mapDispatch.selectedGuest = cf.isValueEmpty(this.state.selectedGuest)
        ? false
        : true;
    }
    if (this.props.isSRP === true) {
      mapDispatch.selectedNationality = cf.isValueEmpty(
        this.state.selectedNationality
      )
        ? false
        : true;
    }
    mapDispatch.hotelName = this.state.getDestinationId ? false : true;
    this.setState({ isError: mapDispatch }, () => {
      let Validate = true;
      for (const [key, value] of Object.entries(this.state.isError)) {
        if (value === true) {
          Validate = false;
          break;
        }
      }
      if (Validate === true) {
        let hotelNameArry = this.state.hotelName.split(",");
        let roomListArr = [];
        this.state.finalRoomList.map((res, id) => {
          roomListArr = [
            ...roomListArr,
            res.adults + "a-" + res.children + "c",
          ];
          res.childDetails.map((ele) => {
            roomListArr[id] = roomListArr[id].concat("-", ele.age);
          });
        });
        //IF CHECKOUT DATE IS EMPTY
        if (!this.state.checkOutValue) {
          this.state.checkOutValue = cf.getDateWithFormat(
            addDays(new Date(this.state.checkInValue), 2),
            this?.props?.dateFormat
          );
        }
        let starb2bFilter = [...new Set(this.state.response)];
        let urlQuery = {
          region: hotelNameArry[0]?.trim(),
          country:
            this.props.isSRP === true
              ? hotelNameArry[1]?.trim()
              : hotelNameArry[hotelNameArry.length - 1]?.trim(),
          checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
          checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
          rooms: roomListArr.join("_"),
          type: "location",
          roomtype: "single",
          page: 1,
          orderby: "desc",
          mop: "accrual",
          destid: this.state.getDestinationId,
          search_type: this.state.getSearchType,
          ratings: starb2bFilter.join("_"),
          bf: this.state.selectedCheckBoxes?.breakfast?.[0]
            ? this.state.selectedCheckBoxes?.breakfast?.[0]
            : null,
          rf: this.state.selectedCheckBoxes?.room_type?.[0]
            ? this.state.selectedCheckBoxes?.room_type?.[0]
            : null,
          nationality: this.state.nationallitySearch,
          guestId: this.state.guest_id,
          is_web_app: false,
        };
        // console.log(urlQuery, "urlQuery")
        if (this.props.isOffline === false) {
          cf.redirectTo("/hotel/search", urlQuery);
        } else if (this.props.isOffline === true) {
          const searchParams = new URLSearchParams(urlQuery);
          const queryString = searchParams.toString();
          window.location = "/offlinesearch/hotel?" + queryString;
        }
      }
    });
  };

  onClickSetHotelName = (element) => {
    this.setState({
      hotelName: element.search_text,
      getDestinationId: element.destination_id,
      getSearchType: element.search_type ? element.search_type : 6,
    });
  };
  getCheckInOutValue = (checkIn, checkOut) => {
    let checkInArr = checkIn.split("/");
    let checkOutArr = checkOut.split("/");
    return checkIn + " - " + checkOut;
  };
  onClickVisibilityStatus = (type, value) => {
    this.setScrollDown.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    switch (type) {
      case "hotel":
        this.props.expandHotelNameBooleanfunc(value);
        if (
          this.state.expandCheckInOutDateBoolean === true ||
          this.state.openRoomDetailList === true
        ) {
          this.setState({
            expandCheckInOutDateBoolean: false,
            openRoomDetailList: false,
          });
        }
        break;
      case "calendar":
        this.setState({ expandCheckInOutDateBoolean: value }, () => {
          if (
            this.props.expandHotelNameBoolean === true ||
            this.state.openRoomDetailList === true
          ) {
            this.setState({ openRoomDetailList: false });
            this.props.expandHotelNameBooleanfunc(false);
          }
        });
        break;
      case "rooms":
        if (this.state.openRoomDetailList === false) {
          this.setState({ openRoomDetailList: true }, () => {
            if (
              this.props.expandHotelNameBoolean === true ||
              this.state.expandCheckInOutDateBoolean === true
            ) {
              this.setState({ expandCheckInOutDateBoolean: false });
              this.props.expandHotelNameBooleanfunc(false);
            }
          });
        } else {
          this.onClickDoneButton();
        }
        break;
      default:
        break;
    }
  };
  onClickHotelInput = () => {
    this.props.getAutoSuggestValue("");
  };
  renderInfo = () => {
    this.props.getAutoSuggestValue(regions);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      regions != null &&
      regions != "" &&
      regions != undefined &&
      !this.props.isSRP
    ) {
      if (
        prevProps.getAutoSuggestList.length <
        this.props.getAutoSuggestList.length
      ) {
        prevProps.getAutoSuggestList = this.props.getAutoSuggestList;
        this.setState(
          {
            hotelName: prevProps.getAutoSuggestList[0].search_text,
            getDestinationId: prevProps.getAutoSuggestList[0].destination_id,
            getSearchType: prevProps.getAutoSuggestList[0].search_type,
          },
          () => {
            this.props.expandHotelNameBooleanfunc(false);
          }
        );
      }
    }
  }

  onInputFocus = (fieldName) => {
    if (fieldName == "autocomplete") {
      this.onClickVisibilityStatus("hotel", true);
    }
    if (fieldName == "calendar") {
      this.onClickVisibilityStatus("calendar", true);
    }
    if (fieldName == "rooms") {
      this.onClickVisibilityStatus("rooms");
      this.setState({
        openRoomDetailList: this.state.openRoomDetailList,
      });
    }
  };

  highlightData(id, parentElement) {
    let innerContent = document.getElementById(parentElement).children;
    for (let i = 0; i < innerContent.length; i++) {
      let selectedElement = innerContent[i];
      selectedElement.style.backgroundColor = i == id ? "#ccc" : "#fff";
    }
    innerContent[id].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }
  onKeyDownArrow = (event, parentClassId, moduleType) => {
    switch (event.which) {
      case 38: // up
        indexDropdown--;
        if (indexDropdown < 0) {
          indexDropdown = 0;
        }
        this.highlightData(indexDropdown, parentClassId);
        search_text =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${this.props.getAutoSuggestList[indexDropdown].search_text}`
            : "";
        destination_id =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${this.props.getAutoSuggestList[indexDropdown].destination_id}`
            : "";
        search_type =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${
                this.props.getAutoSuggestList[indexDropdown].search_type
                  ? this.props.getAutoSuggestList[indexDropdown].search_type
                  : 1
              }`
            : "";
        break;
      case 40: // down
        indexDropdown++;
        if (indexDropdown >= this.props.getAutoSuggestList.length - 1) {
          indexDropdown = this.props.getAutoSuggestList.length - 1;
        }
        this.highlightData(indexDropdown, parentClassId);
        search_text =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${this.props.getAutoSuggestList[indexDropdown].search_text}`
            : "";
        destination_id =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${this.props.getAutoSuggestList[indexDropdown].destination_id}`
            : "";
        search_type =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${
                this.props.getAutoSuggestList[indexDropdown].search_type
                  ? this.props.getAutoSuggestList[indexDropdown].search_type
                  : 1
              }`
            : "";
        break;

      case 13: //enter
        this.setState(
          {
            hotelName: search_text,
            getDestinationId: destination_id,
            getSearchType: search_type,
          },
          () => {
            this.onClickVisibilityStatus("hotel", false);
          }
        );
        indexDropdown = -1;
        break;
      case 9: //tab
        this.setState(
          {
            hotelName: search_text,
            getDestinationId: destination_id,
            getSearchType: search_type,
          },
          () => {
            this.onClickVisibilityStatus("hotel", false);
          }
        );
        indexDropdown = -1;

        break;
      default:
    }
  };
  focusOutDone = () => {
    /*this.onClickVisibilityStatus("rooms", false) */
    this.setState({
      openRoomDetailList: this.state.openRoomDetailList,
    });
    // console.log("focus out done");
  };
  onClickTraveller = () => {
    if (!this.state.openTraveler) {
      this.setState({ openTraveler: true });
    } else {
      this.setState({ openTraveler: false });
    }
  };

  onClickAdvSearch = () => {
    if (!this.state.advSearch) {
      this.setState({ advSearch: true });
    } else {
      this.setState({ advSearch: false });
    }
  };

  onClickNationality = () => {
    if (!this.state.nationallitySearch) {
      this.setState({ nationallitySearch: true });
    } else {
      this.setState({ nationallitySearch: false });
    }
  };

  onTickAdvSearch = () => {
    cf.redirectTo("/hotel/search", urlQuery);
  };

  applyb2bFilters = (filterName, event, checkbox, index) => {
    switch (filterName) {
      case "star":
        const { value, checked } = event.target;
        if (checked && !this.state.response.includes(value)) {
          this.setState({ response: [...this.state.response, value] });
        } else {
          this.setState({
            response: this.state.response.filter((val) => {
              return val !== value;
            }),
          });
        }
        break;
      default:
        break;
    }
  };

  handleStarCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  selectNationality = (params) => {
    this.setState({ selectedNationality: params });
    this.setState({ nationallitySearch: params.countryCode });
  };

  onChangeNationalityCode = (params) => {
    this.setState({ selectedNationality: { flag: "/images/ax-tick.svg" } });
  };

  handleCheckboxSelection = (event, index) => {
    let updatedSelections = new Array(index + 1).fill(false);
    updatedSelections[index] = event.target.checked;

    this.setState((prev) => ({
      selectedCheckBoxes: {
        ...prev.selectedCheckBoxes,
        [event.target.name]: updatedSelections,
      },
    }));
  };

  adGuestId = (id) => {
    this.setState({ guest_id: id }, () => {
      if (this.state.isError.selectedGuest === true) {
        this.validateErrorMsg();
      }
      this.setState({ guest_id: id });
    });
  };

  rmGuestId = () => {
    this.setState({ guest_id: "" });
  };

  render() {
    return (
      <div
        ref={this.setScrollDown}
        className={!this.props.isSRP ? "container mx-auto xl:px-8" : ""}
      >
        <div className={this.props.styles?.hotel_search_wid_container}>
          <div className={this.props.styles?.hotel_search_wid_main}>
            {!this.props.isSRP ? (
              <div className={this.props.styles?.hotel_way_title}>
                {/* <h2>Hotels & Accomodation</h2> */}
              </div>
            ) : null}
            {!this.props.isSRP && this.props?.userProfile?.type === "B2B" && (
              <B2BWidgets
                {...this.state}
                styles={this.props.b2bStyles}
                onClickTraveller={this.onClickTraveller}
                onClickAdvSearch={this.onClickAdvSearch}
                onClickSearchHotel={this.onClickSearchHotel}
                onTickAdvSearch={this.onTickAdvSearch}
                onClickNationality={this.onClickNationality}
                applyb2bFilters={this.applyb2bFilters}
                handleCheckboxSelection={this.handleCheckboxSelection}
                selectedCheckBoxes={this.state.selectedCheckBoxes}
                handleStarCheck={this.handleStarCheck}
                isOneStar={this.state.isOneStar}
                isTwoStar={this.state.isTwoStar}
                isThreeStar={this.state.isThreeStar}
                isFourStar={this.state.isFourStar}
                isFiveStar={this.state.isFiveStar}
                addGuestId={this.adGuestId}
                removeGuestId={this.rmGuestId}
                listWrapper={this.advanceSearchWrapper}
                guestWrapper={this.guestDetailWrapper}
                isOffline={this.props.isOffline}
                errorMessage={"Select Traveler"}
                isError={this.state.isError.selectedGuest}
              />
            )}
            <div
              className={`${
                this.props.styles?.hotel_search_details_container
              } ${
                !this.props.isSRP
                  ? this.props.styles?.home_bgcolor
                  : this.props.styles?.srp_bgcolor
              }`}
            >
              <div className={this.props.styles?.hotel_search_details_main}>
                <div
                  className={this.props.styles?.hotel_search_details_row}
                  ref={this.setDropdownWrapperRef}
                >
                  <div
                    id="hotelList"
                    className={`${this.props.styles?.hotel_search_column} ${
                      this.props.styles?.hotel_search_name
                    } ${
                      this.props.isSRP
                        ? this.props.styles?.srp_hotel_search_name
                        : ""
                    }`}
                  >
                    <HotelAutoComplete
                      isDisable={this.props.isDisable === true}
                      label="City / Area / Hotel Name"
                      styles={this.props.styles}
                      value={this.state.hotelName}
                      onKeyDownArrow={(event) => {
                        this.onKeyDownArrow(event, "hotelDataArray", "hotel");
                      }}
                      placholder="City / Area / Hotel Name"
                      onInputFocus={() => {
                        this.onInputFocus("autocomplete");
                      }}
                      onClickHotelInput={() => this.onClickHotelInput()}
                      onClickHotelName={(value) =>
                        this.onClickSetHotelName(value)
                      }
                      onChangeHotelName={(value) =>
                        this.onChangeSetHotelName(value)
                      }
                      isAutoCompleteExpand={(value) =>
                        this.onClickVisibilityStatus("hotel", value)
                      }
                      autoCompleteData={
                        !cf.isValueEmpty(this.props.getAutoSuggestList)
                          ? this.props.getAutoSuggestList
                          : []
                      }
                      expandAutoCompleteBool={
                        !cf.isValueEmpty(this.props.expandHotelNameBoolean)
                          ? this.props.expandHotelNameBoolean
                          : false
                      }
                      leftIcon={
                        this.props.isSRP ? (
                          ""
                        ) : (
                          <span className={this.props.styles?.label_icon}>
                            <svg
                              version="1.1"
                              id="Layer_1"
                              x="0px"
                              y="0px"
                              width="200px"
                              height="200px"
                              viewBox="0 0 200 200"
                              enableBackground="new 0 0 200 200"
                            >
                              <g>
                                <path
                                  fill="currentColor"
                                  d="M101,184.511c-0.031,0-0.062,0-0.094-0.001c-1.113-0.026-2.164-0.515-2.902-1.349
		c-1.537-1.736-3.586-4.031-6.147-6.885c-2.659-2.961-7.256-8.648-14.055-17.387c-6.738-8.66-12.719-17.192-17.779-25.359
		c-5.106-8.243-9.814-17.68-13.991-28.049c-4.284-10.633-6.457-20.179-6.457-28.372c0-16.481,5.985-30.635,17.79-42.068
		C69.39,23.395,84.07,17.489,101,17.489s31.546,5.842,43.442,17.365c11.932,11.556,17.982,25.772,17.982,42.255
		c0,8.19-2.106,17.607-6.26,27.989c-4.062,10.15-9.045,19.803-14.812,28.688c-5.665,8.729-11.346,17.015-16.885,24.627
		c-5.553,7.63-10.354,13.807-14.27,18.359c-0.053,0.062-0.107,0.121-0.164,0.18l-6.167,6.346
		C103.114,184.074,102.079,184.511,101,184.511z M101,25.489c-14.988,0-27.441,5.004-38.069,15.298
		C52.599,50.795,47.576,62.676,47.576,77.109c0,7.164,1.977,15.704,5.876,25.382c4.007,9.945,8.506,18.972,13.372,26.826
		c4.91,7.927,10.729,16.224,17.292,24.66c6.589,8.469,11.196,14.174,13.693,16.955c1.222,1.361,2.329,2.597,3.319,3.706l3.084-3.174
		c3.762-4.381,8.399-10.354,13.787-17.758c5.456-7.498,11.056-15.665,16.643-24.275c5.483-8.45,10.226-17.637,14.095-27.305
		c3.774-9.433,5.688-17.85,5.688-25.018c0-14.434-5.086-26.376-15.549-36.508C128.378,30.432,115.988,25.489,101,25.489z"
                                />
                              </g>
                              <g>
                                <path
                                  fill="currentColor"
                                  d="M101,91.748c-7.077,0-12.834-5.757-12.834-12.834S93.923,66.079,101,66.079s12.835,5.757,12.835,12.834
		S108.077,91.748,101,91.748z M101,74.079c-2.666,0-4.834,2.169-4.834,4.834s2.169,4.834,4.834,4.834
		c2.666,0,4.835-2.169,4.835-4.834S103.666,74.079,101,74.079z"
                                />
                              </g>
                            </svg>
                          </span>
                        )
                      }
                      errorMessage={"Enter Destination"}
                      isError={this.state.isError.hotelName}
                    />
                  </div>
                  <div className={this.props.styles?.newhoteldate}>
                    <div className={this.props.styles?.newlefthoteldate}>
                      <span>
                        {/* <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                        >
                          <g>
                            <path
                              fill="currentColor"
                              d="M7.258,17.91H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.002,1.002,1.002h0.083
                                               c0.558,0,1.003-0.445,1.003-1.002v-0.111C8.262,18.355,7.816,17.91,7.258,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,17.91h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.084
                                               c0.556,0,1.002-0.445,1.002-1.002v-0.111C12.324,18.355,11.878,17.91,11.322,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,17.91h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.002,1.002,1.002h0.112
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C16.415,18.355,15.97,17.91,15.414,17.91L15.414,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M7.258,13.792H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.001,1.002,1.001h0.083
                                               c0.558,0,1.003-0.445,1.003-1.001v-0.111C8.262,14.237,7.816,13.792,7.258,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,13.792h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.084
                                               c0.556,0,1.002-0.445,1.002-1.001v-0.111C12.324,14.237,11.878,13.792,11.322,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,13.792h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.001,1.002,1.001h0.112
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C16.415,14.237,15.97,13.792,15.414,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,17.91h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.002,1.003,1.002h0.111
                                               c0.556,0,1.001-0.445,1.001-1.002v-0.111C20.506,18.355,20.033,17.91,19.478,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,17.91h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.112
                                               c0.557,0,1.002-0.445,1.002-1.002v-0.111C24.57,18.355,24.125,17.91,23.568,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,13.792h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.001,1.003,1.001h0.111
                                               c0.556,0,1.001-0.445,1.001-1.001v-0.111C20.506,14.237,20.033,13.792,19.478,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,13.792h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.112
                                               c0.557,0,1.002-0.445,1.002-1.001v-0.111C24.57,14.237,24.125,13.792,23.568,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M7.258,22.002H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.445,1.004,1.002,1.004h0.083
                                               c0.558,0,1.003-0.445,1.003-1.004v-0.109C8.262,22.473,7.816,22.002,7.258,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,22.002h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.084
                                               c0.556,0,1.002-0.445,1.002-1.004v-0.109C12.324,22.473,11.878,22.002,11.322,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,22.002h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.446,1.004,1.002,1.004h0.112
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C16.415,22.473,15.97,22.002,15.414,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,22.002h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.109c0,0.559,0.445,1.004,1.003,1.004h0.111
                                               c0.556,0,1.001-0.445,1.001-1.004v-0.109C20.506,22.473,20.033,22.002,19.478,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,22.002h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.112
                                               c0.557,0,1.002-0.445,1.002-1.004v-0.109C24.57,22.473,24.125,22.002,23.568,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M28.577,10.535L28.577,10.535V8.281c0-1.726-1.392-3.118-3.089-3.118h-1.643V4.189
                                               c0-1.196-0.973-2.171-2.171-2.171c-1.196,0-2.17,0.975-2.17,2.171v0.974l-8.238,0V4.19c0-1.197-0.974-2.171-2.17-2.171
                                               c-1.197,0-2.17,0.974-2.17,2.171v0.974H5.283c-1.725,0-3.117,1.392-3.117,3.118v16.643c0,1.697,1.392,3.088,3.117,3.088h20.232
                                               c1.698,0,3.09-1.391,3.09-3.088L28.577,10.535L28.577,10.535L28.577,10.535z M20.785,4.189c0-0.473,0.39-0.863,0.861-0.863
                                               c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863c-0.472,0-0.861-0.39-0.861-0.863V4.189z M8.206,4.189
                                               c0-0.473,0.389-0.863,0.862-0.863c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863
                                               c-0.473,0-0.862-0.39-0.862-0.863V4.189z M5.254,6.472h1.67c0.112,1.085,1.03,1.948,2.17,1.948c1.113,0,2.031-0.863,2.143-1.948
                                               h8.266c0.111,1.085,1.03,1.948,2.143,1.948c1.113,0,2.031-0.863,2.144-1.948h1.67c0.974,0,1.781,0.807,1.781,1.809v1.615H3.446
                                               V8.28C3.446,7.279,4.253,6.472,5.254,6.472L5.254,6.472z M25.488,26.703H5.254c-1.002,0-1.808-0.807-1.808-1.779V11.175H27.27
                                               v13.749C27.27,25.896,26.462,26.703,25.488,26.703z"
                            />
                          </g>
                        </svg> */}
                      </span>
                      <DesktopCalender
                        styles={this.props.styles}
                        value={
                          !cf.isValueEmpty(this.state.checkInOutValue)
                            ? this.state.checkInOutValue
                            : ""
                        }
                        onChangeDate={(type, value) => {
                          this.onChangeCheckInOutValue(type, value);
                        }}
                        onInputFocus={() => {
                          this.onInputFocus("calendar");
                        }}
                        iconImage={
                          <svg
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="30px"
                            height="30px"
                            viewBox="0 0 30 30"
                            enable-background="new 0 0 30 30"
                          >
                            <g>
                              <path
                                fill="currentColor"
                                d="M7.258,17.91H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.002,1.002,1.002h0.083
                                       c0.558,0,1.003-0.445,1.003-1.002v-0.111C8.262,18.355,7.816,17.91,7.258,17.91z"
                              />
                              <path
                                fill="currentColor"
                                d="M11.322,17.91h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.084
                                       c0.556,0,1.002-0.445,1.002-1.002v-0.111C12.324,18.355,11.878,17.91,11.322,17.91z"
                              />
                              <path
                                fill="currentColor"
                                d="M15.414,17.91h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.002,1.002,1.002h0.112
                                       c0.556,0,1.001-0.445,1.001-1.002v-0.111C16.415,18.355,15.97,17.91,15.414,17.91L15.414,17.91z"
                              />
                              <path
                                fill="currentColor"
                                d="M7.258,13.792H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.001,1.002,1.001h0.083
                                       c0.558,0,1.003-0.445,1.003-1.001v-0.111C8.262,14.237,7.816,13.792,7.258,13.792z"
                              />
                              <path
                                fill="currentColor"
                                d="M11.322,13.792h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.084
                                       c0.556,0,1.002-0.445,1.002-1.001v-0.111C12.324,14.237,11.878,13.792,11.322,13.792z"
                              />
                              <path
                                fill="currentColor"
                                d="M15.414,13.792h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.001,1.002,1.001h0.112
                                       c0.556,0,1.001-0.445,1.001-1.001v-0.111C16.415,14.237,15.97,13.792,15.414,13.792z"
                              />
                              <path
                                fill="currentColor"
                                d="M19.478,17.91h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.002,1.003,1.002h0.111
                                       c0.556,0,1.001-0.445,1.001-1.002v-0.111C20.506,18.355,20.033,17.91,19.478,17.91z"
                              />
                              <path
                                fill="currentColor"
                                d="M23.568,17.91h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.112
                                       c0.557,0,1.002-0.445,1.002-1.002v-0.111C24.57,18.355,24.125,17.91,23.568,17.91z"
                              />
                              <path
                                fill="currentColor"
                                d="M19.478,13.792h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.001,1.003,1.001h0.111
                                       c0.556,0,1.001-0.445,1.001-1.001v-0.111C20.506,14.237,20.033,13.792,19.478,13.792z"
                              />
                              <path
                                fill="currentColor"
                                d="M23.568,13.792h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.112
                                       c0.557,0,1.002-0.445,1.002-1.001v-0.111C24.57,14.237,24.125,13.792,23.568,13.792z"
                              />
                              <path
                                fill="currentColor"
                                d="M7.258,22.002H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.445,1.004,1.002,1.004h0.083
                                       c0.558,0,1.003-0.445,1.003-1.004v-0.109C8.262,22.473,7.816,22.002,7.258,22.002z"
                              />
                              <path
                                fill="currentColor"
                                d="M11.322,22.002h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.084
                                       c0.556,0,1.002-0.445,1.002-1.004v-0.109C12.324,22.473,11.878,22.002,11.322,22.002z"
                              />
                              <path
                                fill="currentColor"
                                d="M15.414,22.002h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.446,1.004,1.002,1.004h0.112
                                       c0.556,0,1.001-0.445,1.001-1.004v-0.109C16.415,22.473,15.97,22.002,15.414,22.002z"
                              />
                              <path
                                fill="currentColor"
                                d="M19.478,22.002h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.109c0,0.559,0.445,1.004,1.003,1.004h0.111
                                       c0.556,0,1.001-0.445,1.001-1.004v-0.109C20.506,22.473,20.033,22.002,19.478,22.002z"
                              />
                              <path
                                fill="currentColor"
                                d="M23.568,22.002h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.112
                                       c0.557,0,1.002-0.445,1.002-1.004v-0.109C24.57,22.473,24.125,22.002,23.568,22.002z"
                              />
                              <path
                                fill="currentColor"
                                d="M28.577,10.535L28.577,10.535V8.281c0-1.726-1.392-3.118-3.089-3.118h-1.643V4.189
                                       c0-1.196-0.973-2.171-2.171-2.171c-1.196,0-2.17,0.975-2.17,2.171v0.974l-8.238,0V4.19c0-1.197-0.974-2.171-2.17-2.171
                                       c-1.197,0-2.17,0.974-2.17,2.171v0.974H5.283c-1.725,0-3.117,1.392-3.117,3.118v16.643c0,1.697,1.392,3.088,3.117,3.088h20.232
                                       c1.698,0,3.09-1.391,3.09-3.088L28.577,10.535L28.577,10.535L28.577,10.535z M20.785,4.189c0-0.473,0.39-0.863,0.861-0.863
                                       c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863c-0.472,0-0.861-0.39-0.861-0.863V4.189z M8.206,4.189
                                       c0-0.473,0.389-0.863,0.862-0.863c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863
                                       c-0.473,0-0.862-0.39-0.862-0.863V4.189z M5.254,6.472h1.67c0.112,1.085,1.03,1.948,2.17,1.948c1.113,0,2.031-0.863,2.143-1.948
                                       h8.266c0.111,1.085,1.03,1.948,2.143,1.948c1.113,0,2.031-0.863,2.144-1.948h1.67c0.974,0,1.781,0.807,1.781,1.809v1.615H3.446
                                       V8.28C3.446,7.279,4.253,6.472,5.254,6.472L5.254,6.472z M25.488,26.703H5.254c-1.002,0-1.808-0.807-1.808-1.779V11.175H27.27
                                       v13.749C27.27,25.896,26.462,26.703,25.488,26.703z"
                              />
                            </g>
                          </svg>
                        }
                        startDate={
                          !cf.isValueEmpty(this.state.checkInValue)
                            ? this.state.checkInValue
                            : ""
                        }
                        endDate={
                          !cf.isValueEmpty(this.state.checkOutValue)
                            ? this.state.checkOutValue
                            : ""
                        }
                        startDateLimit={
                          !cf.isValueEmpty(this.state.dateLimit)
                            ? this.state.dateLimit
                            : ""
                        }
                        dateFormat={this?.props?.dateFormat}
                        changeVisibility={(bool) =>
                          this.onClickVisibilityStatus("calendar", bool)
                        }
                        isVisible={this.state.expandCheckInOutDateBoolean}
                        type={"double"}
                        doubleSelection={true}
                        theme={"theme2"}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      this.props.styles?.hotel_search_column +
                      " " +
                      this.props.styles?.hotel_search_accomodation
                    }
                  >
                    <div
                      className={this.props.styles?.hotel_cabin_class_cont}
                      onClick={() => this.onClickVisibilityStatus("rooms")}
                    >
                      {/* <div
                        className={this.props.styles?.hotel_cabin_class_icon}
                      >
                        {this.props.isSRP ? (
                          ""
                        ) : (
                          <svg
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="30px"
                            height="30px"
                            viewBox="0 0 30 30"
                            enable-background="new 0 0 30 30"
                          >
                            <rect
                              x="3.833"
                              y="1.033"
                              fill="none"
                              width="22.334"
                              height="27.964"
                            />
                            <g>
                              <path
                                fill="currentColor"
                                d="M9.683,7.213c0-2.827,2.366-5.146,5.317-5.146c2.952,0,5.316,2.318,5.316,5.146
		c0,2.828-2.364,5.146-5.316,5.146C12.048,12.359,9.683,10.042,9.683,7.213L9.683,7.213z M15,1.038c-3.491,0-6.346,2.75-6.346,6.175
		c0,3.425,2.855,6.175,6.346,6.175c3.49,0,6.346-2.75,6.346-6.175C21.346,3.789,18.49,1.038,15,1.038z M7.674,17.063
		c4.801-2.187,10.323-2.137,15.083,0.138c1.444,0.689,2.363,2.146,2.363,3.746v6.355l-5.047,0.428
		c-3.208,0.271-6.435,0.277-9.644,0.018L4.88,27.3V21.11c0-1.691,0.987-3.224,2.525-3.924L7.674,17.063z M23.202,16.272
		c-5.035-2.405-10.877-2.458-15.955-0.146l-0.269,0.122c-1.905,0.868-3.127,2.768-3.127,4.862v7.138l6.496,0.526
		c3.266,0.264,6.549,0.256,9.814-0.021l5.989-0.507v-7.301C26.149,18.952,25.002,17.134,23.202,16.272L23.202,16.272z"
                              />
                            </g>
                          </svg>
                        )}
                      </div> */}
                      {/* <label>Rooms & Guests</label> */}

                      <div
                        className={this.props.styles?.hotel_cabin_class_name}
                      >
                        {/* <input
                          tabindex="3"
                          onFocus={() => {
                            this.onInputFocus("rooms");
                          }}
                          value={
                            !cf.isValueEmpty(this.state.selectedRoomDetails)
                              ? this.state.selectedRoomDetails.rooms +
                                " Room, " +
                                this.state.selectedRoomDetails.guest +
                                " Guest"
                              : "1 Room, 2 Guest"
                          }
                        /> */}
                        <div
                          tabindex="3"
                          onFocus={() => {
                            this.onInputFocus("rooms");
                          }}
                          className={this.props.styles?.adult_room_flex}
                        >
                          <span>
                            {this.state.selectedRoomDetails.guest} Adults{" "}
                          </span>
                          <span>
                            {this.state.selectedRoomDetails.rooms} Rooms{" "}
                          </span>
                          <svg
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="20px"
                            height="20px"
                            viewBox="0 0 30 30"
                            enable-background="new 0 0 30 30"
                          >
                            <path
                              id="Path_1101"
                              data-name="Path 1101"
                              d="M339.293,85.293l1.414,1.414L334,93.414l-6.707-6.707,1.414-1.414L334,90.586Z"
                              transform="translate(-327.293 -85.293)"
                              fill-rule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        {/* <div className={this.props.styles?.hotel_class_arrow}>
                          <svg
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="30px"
                            height="30px"
                            viewBox="0 0 30 30"
                            enableBackground="new 0 0 30 30"
                          >
                            <path
                              id="XMLID_224_"
                              fill="currentColor"
                              d="M1.869,11.074l12.576,12.575c0.236,0.236,0.556,0.368,0.889,0.368
	c0.334,0,0.654-0.132,0.889-0.368l12.576-12.575c0.489-0.492,0.489-1.288,0-1.779c-0.492-0.491-1.288-0.491-1.779,0L15.333,20.98
	L3.647,9.295C3.401,9.049,3.08,8.926,2.757,8.926c-0.322,0-0.643,0.123-0.889,0.369C1.377,9.786,1.377,10.582,1.869,11.074z"
                            />
                          </svg>
                        </div> */}
                      </div>
                    </div>
                    {this.state.openRoomDetailList === true ? (
                      <div className={this.props.styles?.hotel_room_list}>
                        <div
                          className={this.props.styles?.hotel_room_list_main}
                        >
                          {this.state.roomList.map((element, index) => {
                            return (
                              <div
                                className={
                                  this.props.styles?.hotel_room_detail_ele
                                }
                                key={index}
                              >
                                <div
                                  className={this.props.styles?.hotel_room_head}
                                >
                                  <div
                                    className={
                                      this.props.styles?.hotel_room_title
                                    }
                                  >
                                    Room {index + 1}
                                  </div>
                                  {index > 0 ? (
                                    <div
                                      className={
                                        this.props.styles?.hotel_room_remove
                                      }
                                      onClick={() =>
                                        this.removeRoomItem(element.id)
                                      }
                                    >
                                      Remove
                                    </div>
                                  ) : null}
                                </div>
                                <div
                                  className={
                                    this.props.styles?.hotel_accomodation_main
                                  }
                                >
                                  <div
                                    className={
                                      this.props.styles
                                        ?.hotel_adult_accomodation
                                    }
                                  >
                                    <div className="hotel_adult_accomodation_tag">
                                      <p>Adult</p>
                                      <span>(12 yrs & above)</span>
                                    </div>
                                    <div
                                      className={
                                        this.props.styles
                                          .hotel_accomodation_conf
                                      }
                                    >
                                      <span
                                        className={
                                          element.adultMinusCountDisable
                                            ? this.props.styles
                                                .disbale_traveller +
                                              " " +
                                              this.props.styles
                                                .hotel_accomodation_icon
                                            : this.props.styles
                                                .hotel_accomodation_icon
                                        }
                                        onClick={() =>
                                          this.minusAccomodationCount(
                                            element.id,
                                            index,
                                            "adult"
                                          )
                                        }
                                      >
                                        <svg
                                          version="1.1"
                                          id="Layer_1"
                                          x="0px"
                                          y="0px"
                                          width="30px"
                                          height="30px"
                                          viewBox="0 0 30 30"
                                          enable-background="new 0 0 30 30"
                                        >
                                          <g>
                                            <path
                                              fill="currentColor"
                                              d="M15.401,29.146c-7.616,0-13.813-6.196-13.813-13.813S7.785,1.521,15.401,1.521
		c7.617,0,13.813,6.196,13.813,13.813S23.018,29.146,15.401,29.146z M15.401,2.521c-7.065,0-12.813,5.747-12.813,12.813
		c0,7.064,5.748,12.813,12.813,12.813s12.813-5.748,12.813-12.813C28.214,8.268,22.466,2.521,15.401,2.521z"
                                            />
                                          </g>
                                          <path
                                            fill="currentColor"
                                            d="M24.65,15.333c0,0.368-0.299,0.666-0.667,0.666H6.818c-0.368,0-0.667-0.298-0.667-0.666l0,0
	c0-0.368,0.298-0.667,0.667-0.667h17.166C24.352,14.666,24.65,14.965,24.65,15.333L24.65,15.333z"
                                          />
                                        </svg>
                                      </span>
                                      <div
                                        className={this.props.styles?.ac_count}
                                      >
                                        {element.adults}
                                      </div>
                                      <span
                                        className={
                                          element.adultAddCountDisable
                                            ? this.props.styles
                                                .disbale_traveller +
                                              " " +
                                              this.props.styles
                                                .hotel_accomodation_icon
                                            : this.props.styles
                                                .hotel_accomodation_icon
                                        }
                                        onClick={() =>
                                          this.addAccomodationCount(
                                            element.id,
                                            index,
                                            "adult"
                                          )
                                        }
                                      >
                                        {/* <img src="/images/plus.png" className={this.this.props.styles?.hotel_accomodation_icon} /> */}
                                        <svg
                                          version="1.1"
                                          id="Layer_1"
                                          x="0px"
                                          y="0px"
                                          width="30px"
                                          height="30px"
                                          viewBox="0 0 30 30"
                                          enable-background="new 0 0 30 30"
                                        >
                                          <g>
                                            <path
                                              fill="currentColor"
                                              d="M14.813,28.979C7.196,28.979,1,22.783,1,15.167S7.196,1.354,14.813,1.354
		c7.617,0,13.813,6.196,13.813,13.813S22.43,28.979,14.813,28.979z M14.813,2.354C7.748,2.354,2,8.102,2,15.167
		c0,7.064,5.748,12.813,12.813,12.813c7.064,0,12.813-5.748,12.813-12.813C27.625,8.102,21.877,2.354,14.813,2.354z"
                                            />
                                          </g>
                                          <g>
                                            <path
                                              fill="currentColor"
                                              d="M24.063,15.499c0,0.368-0.299,0.666-0.668,0.666H6.229c-0.368,0-0.667-0.298-0.667-0.666l0,0
		c0-0.368,0.298-0.667,0.667-0.667h17.165C23.764,14.832,24.063,15.131,24.063,15.499L24.063,15.499z"
                                            />
                                            <path
                                              fill="currentColor"
                                              d="M14.813,24.416c-0.368,0-0.666-0.299-0.666-0.667V6.584c0-0.368,0.298-0.667,0.666-0.667l0,0
		c0.368,0,0.667,0.299,0.667,0.667v17.165C15.48,24.117,15.181,24.416,14.813,24.416L14.813,24.416z"
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="hotel_children_accomodation">
                                    <div className="hotel_children_accomodation_tag">
                                      <p>Children</p>
                                      <span>(0 - 12 yrs)</span>
                                    </div>
                                    <div
                                      className={
                                        this.props.styles
                                          .hotel_accomodation_conf
                                      }
                                    >
                                      <span
                                        className={
                                          element.childrenMinusCountDisable
                                            ? this.props.styles
                                                .disbale_traveller +
                                              " " +
                                              this.props.styles
                                                .hotel_accomodation_icon
                                            : this.props.styles
                                                .hotel_accomodation_icon
                                        }
                                        onClick={() =>
                                          this.minusAccomodationCount(
                                            element.id,
                                            index,
                                            "children"
                                          )
                                        }
                                      >
                                        <svg
                                          version="1.1"
                                          id="Layer_1"
                                          x="0px"
                                          y="0px"
                                          width="30px"
                                          height="30px"
                                          viewBox="0 0 30 30"
                                          enable-background="new 0 0 30 30"
                                        >
                                          <g>
                                            <path
                                              fill="currentColor"
                                              d="M15.401,29.146c-7.616,0-13.813-6.196-13.813-13.813S7.785,1.521,15.401,1.521
		c7.617,0,13.813,6.196,13.813,13.813S23.018,29.146,15.401,29.146z M15.401,2.521c-7.065,0-12.813,5.747-12.813,12.813
		c0,7.064,5.748,12.813,12.813,12.813s12.813-5.748,12.813-12.813C28.214,8.268,22.466,2.521,15.401,2.521z"
                                            />
                                          </g>
                                          <path
                                            fill="currentColor"
                                            d="M24.65,15.333c0,0.368-0.299,0.666-0.667,0.666H6.818c-0.368,0-0.667-0.298-0.667-0.666l0,0
	c0-0.368,0.298-0.667,0.667-0.667h17.166C24.352,14.666,24.65,14.965,24.65,15.333L24.65,15.333z"
                                          />
                                        </svg>
                                      </span>
                                      <div
                                        className={this.props.styles?.ac_count}
                                      >
                                        {element.children}
                                      </div>
                                      <span
                                        className={
                                          element.childrenAddCountDisable
                                            ? this.props.styles
                                                .disbale_traveller +
                                              " " +
                                              this.props.styles
                                                .hotel_accomodation_icon
                                            : this.props.styles
                                                .hotel_accomodation_icon
                                        }
                                        onClick={() =>
                                          this.addAccomodationCount(
                                            element.id,
                                            index,
                                            "children"
                                          )
                                        }
                                      >
                                        {/* <img src="/images/plus.png" className={this.this.props.styles?.hotel_accomodation_icon} /> */}
                                        <svg
                                          version="1.1"
                                          id="Layer_1"
                                          x="0px"
                                          y="0px"
                                          width="30px"
                                          height="30px"
                                          viewBox="0 0 30 30"
                                          enable-background="new 0 0 30 30"
                                        >
                                          <g>
                                            <path
                                              fill="currentColor"
                                              d="M14.813,28.979C7.196,28.979,1,22.783,1,15.167S7.196,1.354,14.813,1.354
		c7.617,0,13.813,6.196,13.813,13.813S22.43,28.979,14.813,28.979z M14.813,2.354C7.748,2.354,2,8.102,2,15.167
		c0,7.064,5.748,12.813,12.813,12.813c7.064,0,12.813-5.748,12.813-12.813C27.625,8.102,21.877,2.354,14.813,2.354z"
                                            />
                                          </g>
                                          <g>
                                            <path
                                              fill="currentColor"
                                              d="M24.063,15.499c0,0.368-0.299,0.666-0.668,0.666H6.229c-0.368,0-0.667-0.298-0.667-0.666l0,0
		c0-0.368,0.298-0.667,0.667-0.667h17.165C23.764,14.832,24.063,15.131,24.063,15.499L24.063,15.499z"
                                            />
                                            <path
                                              fill="currentColor"
                                              d="M14.813,24.416c-0.368,0-0.666-0.299-0.666-0.667V6.584c0-0.368,0.298-0.667,0.666-0.667l0,0
		c0.368,0,0.667,0.299,0.667,0.667v17.165C15.48,24.117,15.181,24.416,14.813,24.416L14.813,24.416z"
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {element.childDetails.length > 0 &&
                                  element.childDetails.map((item, ind) => {
                                    return (
                                      <div
                                        className={
                                          this.props.styles?.child_row_container
                                        }
                                        key={ind}
                                      >
                                        <div
                                          className={
                                            this.props.styles?.child_row_main
                                          }
                                        >
                                          <span>
                                            <label>
                                              Age of Child {ind + 1}
                                            </label>
                                            <input
                                              type="text"
                                              className={
                                                this.props.styles?.selected_age
                                              }
                                              placeholder="Select Age"
                                              value={item.age}
                                              onClick={() =>
                                                this.isChildrenAge(
                                                  index,
                                                  ind,
                                                  "isOpen"
                                                )
                                              }
                                              readOnly
                                            />
                                            {item.isError === true ? (
                                              <span
                                                className={
                                                  this.props.styles
                                                    .err_message_text
                                                }
                                              >
                                                Please enter valid age
                                              </span>
                                            ) : null}
                                            <span
                                              className={
                                                this.props.styles
                                                  .child_age_select_arrow
                                              }
                                              onClick={() =>
                                                this.isChildrenAge(
                                                  index,
                                                  ind,
                                                  "isOpen"
                                                )
                                              }
                                            >
                                              <svg
                                                version="1.1"
                                                id="Layer_1"
                                                x="0px"
                                                y="0px"
                                                width="30px"
                                                height="30px"
                                                viewBox="0 0 30 30"
                                                enable-background="new 0 0 30 30"
                                              >
                                                <path
                                                  id="XMLID_224_"
                                                  fill="currentColor"
                                                  d="M1.869,11.074l12.576,12.575c0.236,0.236,0.556,0.368,0.889,0.368
	c0.334,0,0.654-0.132,0.889-0.368l12.576-12.575c0.489-0.492,0.489-1.288,0-1.779c-0.492-0.491-1.288-0.491-1.779,0L15.333,20.98
	L3.647,9.295C3.401,9.049,3.08,8.926,2.757,8.926c-0.322,0-0.643,0.123-0.889,0.369C1.377,9.786,1.377,10.582,1.869,11.074z"
                                                />
                                              </svg>
                                            </span>
                                          </span>
                                          {item.isOpen === true ? (
                                            <div
                                              className={
                                                this.props.styles?.age_list_cont
                                              }
                                            >
                                              <div
                                                className={
                                                  this.props.styles
                                                    .age_list_main
                                                }
                                              >
                                                {childAgeList.map((ele, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className={
                                                        this.props.styles
                                                          .age_list_ele
                                                      }
                                                      onClick={() =>
                                                        this.isChildrenAge(
                                                          index,
                                                          ind,
                                                          "clickAge",
                                                          ele
                                                        )
                                                      }
                                                    >
                                                      <span>{ele}</span>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            );
                          })}
                          {this.state.roomList.length < 4 ? (
                            <div
                              className={this.props.styles?.hotel_add_rooms}
                              onClick={() => {
                                this.setState({
                                  roomList: [
                                    ...this.state.roomList,
                                    {
                                      id: this.state.roomList.length + 1,
                                      adults: 1,
                                      children: 0,
                                      adultAddCountDisable: false,
                                      adultMinusCountDisable: true,
                                      childrenAddCountDisable: false,
                                      childrenMinusCountDisable: true,
                                      childDetails: [],
                                    },
                                  ],
                                });
                              }}
                            >
                              <span> + </span>
                              <p style={{ paddingLeft: 3 }}>ADD ROOM </p>
                            </div>
                          ) : null}
                        </div>
                        <div className={this.props.styles?.done_button_main}>
                          <input
                            tabindex="4"
                            value="Done"
                            className={this.props.styles?.done_button}
                            readOnly
                            onClick={() => this.onClickDoneButton()}
                            onBlur={this.focusOutDone}
                          />
                          {/* <button  className={this.props.styles?.done_button} onClick={() => this.onClickDoneButton()}>Done</button> */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className={this.props.styles?.hotel_search_btn_new}>
                    <button
                      tabindex="5"
                      className={this.props.styles?.hotel_search_widget_btn}
                      onClick={() => this.onClickSearchHotel()}
                    >
                      <svg width="50px" height="50px" viewBox="0,0,256,256">
                        <g
                          fill="#ffffff"
                          fill-rule="nonzero"
                          stroke="none"
                          stroke-width="1"
                          stroke-linecap="butt"
                          stroke-linejoin="miter"
                          stroke-miterlimit="10"
                          stroke-dasharray=""
                          stroke-dashoffset="0"
                          font-family="none"
                          font-weight="none"
                          font-size="none"
                          text-anchor="none"
                        >
                          <g transform="scale(5.12,5.12)">
                            <path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                          </g>
                        </g>
                      </svg>
                      {this.props.isSRP ? "Update Search" : "Search"}
                    </button>
                  </div>
                </div>
                <div
                  className={`${this.props.styles?.hotel_search_details_row}`}
                >
                  {!this.props.isSRP &&
                    this.props?.userProfile?.type === "B2B" && (
                      <div className={this.props.styles?.nationality_cnt_wd}>
                        <div className={this.props.styles?.nationality_wrap}>
                          {/* <label htmlFor="fname" className="lablename-htl">Select Nationality</label> */}
                          {/* <input type="text" className={this.props.styles?.input_nationality_wd} name="fname" id="nationallity" placeholder="Select Nationality" onkeyup={{}} autoComplete="off" />
                                                <span className={this.props.styles?.pg_down_arrow}> <i className="fa fa-caret-down" onClick={() => this.onClickNationality()} aria-hidden="true" /></span> */}
                          {/* <CountryDropdownComp
                                                    countryFlag={this.state.originCountries}
                                                    searchText={""}
                                                    selectCountry={this.onClickNationalityHandler}
                                                    selectedCountry={this.state.selectedNationality}
                                                    onChangeInputCountryCode={this.onChangeInputNationality}
                                                    placeholder={'Select Nationality'}
                                                    isSignUpNationality={false}
                                                    isB2B={true}
                                                    {...this.props.Countrystyles}
                                                // {...this.props.data.Countrystyles}
                                                /> */}
                          <NationalityDropdownComp
                            countryFlag={this.state.originCountries}
                            searchText={""}
                            selectNationality={this.selectNationality}
                            selectedNationality={this.state.selectedNationality}
                            onChangeNationalityCode={
                              this.onChangeNationalityCode
                            }
                            placeholder={"Select Nationality"}
                            isSignUpNationality={false}
                            isB2B={true}
                            errorMessage={"Select Nationality"}
                            isError={this.state.isError.selectedNationality}
                            styles={this.props.styles}
                            {...this.props.Countrystyles}
                            // {...this.props.data.Countrystyles}
                          />
                        </div>
                        {/* {this.state.nationallitySearch &&

                                                <div id="myNation" className="dropdown-content-Guest dropdown-content-nation dropdown-content-nation-htl">
                                                    <div className={this.props.styles?.inputsearch_result}><input type="text" placeholder="Search.." id="myInputNation" onkeyup={{}} />
                                                    </div>
                                                    <div id="suggested_nation" />
                                                </div>
                                            } */}
                      </div>
                    )}
                  {/* <div className={`${this.props?.userProfile?.type === 'B2B' ? this.props.styles?.hotel_search_class : this.props.styles?.hotel_search_details_row_mg}`}> */}
                  {/* <div
                    className={`${
                      this.props.isSRP
                        ? this.props.styles?.hotel_search_class
                        : this.props.styles?.hotel_search_btnClass
                    }`}
                  >
                    <button
                      tabindex="5"
                      className={this.props.styles?.hotel_search_widget_btn}
                      onClick={() => this.onClickSearchHotel()}
                    >
                      {this.props.isSRP ? "Modify" : "Search"}
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
