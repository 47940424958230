const Theme2 = (props) => {
    const {type} =props.data.type
    switch (type) {
        case 'flight':
            return <div>Theme2 Flight quotes</div>
        case 'hotel':
            return <div>Theme2 Hotel cookies</div>
        case 'activity':
            return <div>Theme2 Activity cookies</div>
        default:
            return <div>Theme2 Holiday cookies </div>
    }
}
export default Theme2;