import React, { Component, createRef } from "react";



export default class MulticitySrp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flightLabel: ""

        }
    }

    componentDidMount() {
        console.log("🚀 ~ MulticitySrp ~ constructor ~ props:", this.props)


    }
    componentWillReceiveProps(nextProps) {

        console.log(
            "🚀 ~ file: index.js:288 ~ Theme1 ~ componentWillReceiveProps ~ nextProps:q",
            nextProps
        );


        let str = ""
        nextProps.FlightData.forEach((element, id) => {
            if (id !== 0) {
                str += ", " + element.srcValue
            }
        });
       
         str = str.replace(",","")


         this.setState({flightLabel : str})

        console.log("🚀 ~ MulticitySrp ~ componentWillReceiveProps ~ flighDataCopy:", str)


    }

    render() {

        return (
            <div className={this.props.styles.flight_search_details_container}>
                <div onClick={this.props.showmulticity}>
                    <span class="From">
                        From {" "}
                    </span>
                    <span class="Mumbai-To-Ranchi">
                        {this.props?.FlightData[0]?.srcValue} To {this.props?.FlightData[this.props?.FlightData?.length - 1]?.destValue
                        }
                    </span>
                    <span class="Via-New-Delhi-Patna">
                    {" "}    Via {this.state.flightLabel}
                    </span>

                </div>

                {this.props.travellerCabinEle()}

            </div>

            
        )
    }



}