import React, { Component, createRef } from 'react';
import {
    addDays, differenceInCalendarDays, differenceInMonths, endOfMonth, format, getMonth, getYear, isAfter,
    isBefore, isFriday, isMonday, isSameDay, isSaturday, isThursday, isTuesday, isWednesday, startOfMonth,
    startOfToday, subDays
} from 'date-fns';
import cf from '../../../../lib/utils/common';
import moment from 'moment';
const weekList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export default class DesktopCalender extends Component {
    constructor(props) {
        super(props);
        this.calendarWrapper = createRef();
        this.DateCellWrapper = createRef();
        this.state = {
            dateFormat: !cf.isValueEmpty(this.props.dateFormat) ? this.props.dateFormat : "",
            numberOfClicks: 0,
            twoCalendarsArray: [],
            isDoubleSelection: !cf.isValueEmpty(this.props.doubleSelection) ? this.props.doubleSelection : false,
            dateLimitArr: this.props.startDateLimit,
            currentMonths: [getMonth(startOfMonth(new Date()))],
            selectedMonthStartDate: startOfMonth(startOfToday()),
            selectedMonthEndDate: endOfMonth(startOfMonth(new Date())),
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            hoverEndDate: this.props.endDate,
            totalDays: "",
            pageX: "",
            pageY: "",
            initPageX: "",
            initPageY: "",
            selectingForDate: "",
        };
    }


    componentDidMount() {
        let { getYear: year, getMonth: month, getDay: day } = this.getSpecificDateElement(this.state.dateLimitArr, "a", "didmount datelimit")
        console.log("year, month, day", year, month, day)
        this.setState({
            currentMonths: this.props.type === "double" ?
                !cf.isValueEmpty(this.state.dateLimitArr) ?
                    [getMonth(startOfMonth(new Date(year, month, day))), getMonth(startOfMonth(new Date(year, month, day))) + 1]
                    :
                    [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1]
                :
                !cf.isValueEmpty(this.state.dateLimitArr) ?
                    [getMonth(startOfMonth(new Date(year, month, day)))]
                    :
                    [getMonth(startOfMonth(new Date()))],
            selectedMonthStartDate: !cf.isValueEmpty(this.state.startDate) ? startOfMonth(new Date(year, month, day)) : startOfMonth(startOfToday()),
            selectedMonthEndDate: !cf.isValueEmpty(this.state.startDate) ? endOfMonth(new Date(year, month, day)) : endOfMonth(startOfMonth(new Date())),
        }, () => {
            console.log("🚀 ~ file: DesktopCalenderNew.js:52 ~ DesktopCalender ~ componentDidMount ~ selectedMonthStartDate:", this.state.startDate, this.state.endDate)

        })
        document.addEventListener("mousedown", this.handleClickOutside, false);
        document.addEventListener("keydown", this.handleTabOutside, false);
        // window.addEventListener("mousemove", this.logMousePosition, false);
    }
    componentDidUpdate() {
        if (this.props.endDate !== this.state.endDate) {
            this.setState({ endDate: this.props.endDate });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.startDateLimit) {
            this.setState({
                dateLimitArr: nextProps.startDateLimit,
                startDate: nextProps.startDate
            }, () => {
                let { getYear: year, getMonth: month, getDay: day } = this.getSpecificDateElement(this.state.dateLimitArr, "a", "dateLimit")
                console.log("🚀 ~ file: DesktopCalenderNew.js:74 ~ DesktopCalender ~ componentWillReceiveProps ~ year, month, day:", year, month, day)
                const { getYear: startDateYear, getMonth: startDateMonth, getDay: startDateDay } = this.getSpecificDateElement(this.state.startDate, "a", "startdate")
                console.log("🚀 ~ file: DesktopCalenderNew.js:75 ~ DesktopCalender ~ componentWillReceiveProps ~ this.state.startDate:", this.state.startDate, startDateYear, startDateMonth, startDateDay)
                this.setState({
                    currentMonths: this.props.type === "double" ?
                        !cf.isValueEmpty(this.state.dateLimitArr) ?
                            [getMonth(startOfMonth(new Date(year, month, day))), getMonth(startOfMonth(new Date(year, month, day))) + 1]
                            :
                            [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1]
                        :
                        !cf.isValueEmpty(this.state.dateLimitArr) ?
                            [getMonth(startOfMonth(new Date(year, month, day)))]
                            :
                            [getMonth(startOfMonth(new Date()))],
                    selectedMonthStartDate: !cf.isValueEmpty(this.state.startDate) ? startOfMonth(new Date(startDateYear, startDateMonth, startDateDay)) : startOfMonth(startOfToday()),
                    selectedMonthEndDate: !cf.isValueEmpty(this.state.startDate) ? endOfMonth(new Date(startDateYear, startDateMonth, startDateDay)) : endOfMonth(startOfMonth(new Date())),
                })
            })
        }
    }


    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
        document.removeEventListener('keydown', this.handleTabOutside, false);
        document.removeEventListener('mousemove', this.logMousePosition, false);
    }
    handleClickOutside = event => {
        if (this.calendarWrapper.current && !this.calendarWrapper.current.contains(event.target)) {
            this.props.changeVisibility(false)
        }
    };
    handleTabOutside = (e) => {
        if (e.key !== "Tab") {
            return
        }
        if (this.calendarWrapper.current && !this.calendarWrapper.current.contains(event.target)) {
            this.props.changeVisibility(false)
        }
    }
    // logMousePosition = event => {
    //     if (this.props.isVisible) {
    //         if (this.calendarWrapper.current && !this.calendarWrapper.current.contains(event.target)) {

    //         }
    //     }
    // }


    onClickPrevNextArrow = (type) => {
        let startMonth = subDays(this.state.selectedMonthStartDate, 1);
        let endMonth = addDays(this.state.selectedMonthEndDate, 1);
        if (this.props.type === "double") {
            if (type === "prev") {
                this.state.currentMonths[1] = getMonth(startMonth) - 1;
            } else {
                this.state.currentMonths[1] = getMonth(endMonth) + 1;
            }
        }
        if (type === "prev") {
            this.state.currentMonths[0] = getMonth(this.state.selectedMonthStartDate) - 1;
            this.setState({
                currentMonths: [...this.state.currentMonths],
                selectedMonthStartDate: startOfMonth(startMonth),
                selectedMonthEndDate: endOfMonth(startMonth)
            })
        } else {
            this.state.currentMonths[0] = getMonth(this.state.selectedMonthStartDate) - 1;
            this.setState({
                currentMonths: [...this.state.currentMonths],
                selectedMonthStartDate: startOfMonth(endMonth),
                selectedMonthEndDate: endOfMonth(endMonth)
            })
        }
    }

    getSpecificDateElement(date, type, temp) {
        try {
            let getDate = cf.isValueEmpty(this.state.dateFormat) ? moment(new Date(date)).format('DD/MMM/yyyy') : moment(date).format(this.state.dateFormat);
            let getYear = typeof date === 'string' ? new Date(getDate).getFullYear() : date.getFullYear();
            let getMonth = typeof date === 'string' ? new Date(getDate).getMonth() : date.getMonth();
            let getDay = typeof date === 'string' ? new Date(getDate).getDate() : date.getDate();
            switch (type) {
                case 'y':
                    return getYear
                case 'm':
                    return getMonth
                case 'd':
                    return getDay
                case 'a':
                    return { getYear, getMonth, getDay };
                default:
            }
        } catch (err) {
            console.log('getSpecificDate error:', err)
        }
    }

    onClickChangeVisibility = (type) => {
        // this.props.changeVisibility(true)  /* !this.props.isVisible */
        this.setState({
            selectingForDate: type
        }, () => {
            this.props.changeVisibility(true)
        })
    }

    onHoverDayCell = (currentDate, e) => {
        let date = moment(new Date(currentDate.year, currentDate.month, currentDate.day)).format(this.props.dateFormat ? this.props.dateFormat : "DD/MMM/yyyy");
        this.setState({
            hoverEndDate: date
        })
        if (!cf.isValueEmpty(this.props.startDate) && cf.isValueEmpty(this.state.endDate)) {
            this.setState({
                hoverEndDate: date, pageX: Number(e.clientX) - Number(this.state.initPageX), pageY: Number(e.clientY) - Number(this.state.initPageY)
            }, () => {
                let difference = differenceInCalendarDays(new Date(currentDate.year, currentDate.month, currentDate.day), new Date(this.props.startDate))
                if (difference > 0) this.setState({ totalDays: difference })
                else this.setState({ totalDays: '' })
            })
        }
    }
    onMouseMoveCalendar = (e, eventUsed) => {
        switch (eventUsed) {
            case 'onMouseEnter':
                this.setState({ initPageX: e.clientX, initPageY: e.clientY })
                break;
            default:
                break;
        }
        e.preventDefault()
    }

    onClickDateCell = (day, month, year, isDisable, journeyType, selectionType) => {
        console.log("journeyType,selectionType", journeyType, selectionType)
        if (!isDisable) { //new added
            if (journeyType === "roundTrip" && selectionType === "depart") {
                let date = new Date(year, month, day);
                if (this.state.numberOfClicks === 0) {
                    this.setState({ numberOfClicks: 1, totalDays: '' }, () => {
                        this.props.onChangeDate(date, 'startDate');
                    });
                }
                if (this.state.numberOfClicks === 1) {
                    let differenceInDays = differenceInCalendarDays(new Date(date), new Date(this.props.startDate))
                    this.setState({ numberOfClicks: 0, totalDays: '' }, () => {
                        if (differenceInDays < 0) {
                            this.props.onChangeDate(date, 'startDate');
                        } else {
                            this.setState({ numberOfClicks: 0 }, () => {
                                this.props.onChangeDate(date, 'endDate');
                                this.props.changeVisibility(!this.props.isVisible, selectionType)
                            });
                        }
                    });
                }
            } else {
                if (isDisable === false) {
                    let date = new Date(year, month, day);
                    this.props.onChangeDate(date, selectionType === "return" ? "endDate" : "startDate");

                    this.props.changeVisibility(!this.props.isVisible, selectionType)
                }
            }
        }
    }

    renderCalendar(journeyType, selectionType) {
        console.log("journeyType", journeyType, "selectionType", selectionType)
        let temp = this.state.selectedMonthStartDate;
        let twoCalendarsArray = [];
        for (let i = 0; i < this.state.currentMonths.length; i++) {
            let StartDateOfMonth = temp;
            console.log("🚀 ~ file: DesktopCalenderNew.js:213 ~ DesktopCalender ~ renderCalendar ~ StartDateOfMonth:", StartDateOfMonth)
            let monthHead = addDays(StartDateOfMonth, i);
            let endDateOfMonth = endOfMonth(monthHead);
            let dayDifference = differenceInCalendarDays(endDateOfMonth, monthHead);
            let days = [];
            let month = getMonth(monthHead)
            let year = getYear(monthHead)
            let startOfMonthDay = isMonday(monthHead) ? 0 : isTuesday(monthHead) ? 1 : isWednesday(monthHead) ? 2 : isThursday(monthHead) ? 3 : isFriday(monthHead) ? 4 : isSaturday(monthHead) ? 5 : 6;
            let startDateLimit = this.props.startDateLimit;
            let isHoliday = this.props.isHoliday ? this.props.isHoliday : false;
            let holidayStartDate = this.props.holidayStartDate ? this.props.holidayStartDate : '';
            let holidayEndDate = this.props.holidayEndDate ? this.props.holidayEndDate : '';
            let startDate = this.props.startDate;
            let endDate = this.state.endDate;
            let hoverDate = this.state.hoverEndDate;
            let { getYear: startDateyear, getMonth: startDatemonth, getDay: startDateday } = this.getSpecificDateElement(startDate, "a", "props startdate")
            let { getYear: endDateyear, getMonth: endDatemonth, getDay: endDateday } = this.getSpecificDateElement(endDate, "a", "props enddate")
            let { getYear: hoverDateyear, getMonth: hoverDatemonth, getDay: hoverDateday } = this.getSpecificDateElement(hoverDate, "a", "hoverdate")

            for (let k = 0; k < startOfMonthDay; k++) {
                days = [...days, { day: "", month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
            }
            let { getYear: startDateyearLimit, getMonth: startDatemonthLimit, getDay: startDatedayLimit } = this.getSpecificDateElement(startDateLimit, "a", "startdatelimit")

            for (let j = 0; j <= dayDifference; j++) {
                let isBeforeDate = isBefore(new Date(year, month, j + 1), new Date(startDateyearLimit, startDatemonthLimit, startDatedayLimit));

                let disableDate = false;
                if (isHoliday) {
                    let { getYear: holidayStartDateyear, getMonth: holidayStartDatemonth, getDay: holidayStartDateday } = this.getSpecificDateElement(holidayStartDate, "a", "holidayStartDate")
                    let { getYear: holidayEndDateyear, getMonth: holidayEndDatemonth, getDay: holidayEndDateday } = this.getSpecificDateElement(holidayEndDate, "a", "holidayEndDate")

                    let isHLDBeforeDate = isBefore(new Date(year, month, j + 1), new Date(holidayStartDateyear, holidayStartDatemonth, holidayStartDateday));
                    let isHLDAfterDate = isAfter(new Date(year, month, j + 1), new Date(holidayEndDateyear, holidayEndDatemonth, holidayEndDateday));
                    disableDate = isHLDBeforeDate == true || isHLDAfterDate == true ? true : false;
                }
                if (isBeforeDate === true || disableDate == true) {
                    days = [...days, { day: j + 1, month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
                } else {
                    days = [...days, { day: j + 1, month: month, year: year, isDisable: false, selectedDate: false, highlight: false }];
                }
            }
            if (journeyType === "roundTrip") {

                let startDateIndex = days.findIndex((items) => !cf.isValueEmpty(items.day) && new Date(items.year, items.month, items.day).toString() === new Date(startDateyear, startDatemonth, startDateday).toString());

                let endDateIndex = days.findIndex((items) => !cf.isValueEmpty(items.day) && new Date(items.year, items.month, items.day).toString() === new Date(endDateyear, endDatemonth, endDateday).toString());
                if (startDateIndex !== -1) {
                    days[startDateIndex].selectedDate = true;
                }
                if (endDateIndex !== -1) {
                    days[endDateIndex].selectedDate = true;
                }
            } else {
                let SelectedDate = this.props.departureValue ;
                let { getYear: selectedDateyear, getMonth: selectedDatemonth, getDay: selectedDateday } = this.getSpecificDateElement(SelectedDate, "a", "SelectedDate")

                let selectedDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(selectedDateyear, selectedDatemonth, selectedDateday).toString())
                if (selectedDateIndex !== -1) {
                    days[selectedDateIndex].selectedDate = true;
                }
            }
            temp = endDateOfMonth;
            twoCalendarsArray.push(
                <div className={this.props.styles.calendar_whole_body}>
                    <div className={this.props.styles.calendar_head}>
                        <div className={this.props.styles.calendar_head_center_side}>
                            <h3>{format(monthHead, 'MMMM yyyy')}</h3>
                        </div>
                    </div>
                    <div className={this.props.styles.calendar_body}>
                        <div className={this.props.styles.calendar_week_heads}>
                            {weekList.map((ele, idx) => {
                                return (
                                    <div className={this.props.styles.coln_center_week} key={"weekl" + idx}>
                                        {ele}
                                    </div>
                                )
                            })}
                        </div>
                        <div className={this.props.styles.calendar_day_list}>
                            {days.map((ele, idx) => {
                                let isHighLight = false;
                                let isHoverDate = false;
                                if (journeyType === "roundTrip") {
                                    let isBeforeDate = isBefore(new Date(ele.year, ele.month, ele.day), new Date(hoverDateyear, hoverDatemonth, hoverDateday));
                                    let isBeforeEndDate = isBefore(new Date(ele.year, ele.month, ele.day), new Date(endDateyear, endDatemonth, endDateday));
                                    let isAfterDate = isAfter(new Date(ele.year, ele.month, ele.day), new Date(startDateyear, startDatemonth, startDateday));
                                    isHoverDate = isSameDay(new Date(ele.year, ele.month, ele.day), new Date(hoverDateyear, hoverDatemonth, hoverDateday));
                                    if ((isAfterDate && isBeforeDate && cf.isValueEmpty(endDate)) || (isAfterDate && isBeforeEndDate && (!cf.isValueEmpty(endDate)))) {
                                        isHighLight = true;
                                    }
                                }
                                // console.log("selectedDate", ele.selectedDate , ele.day , isHighLight ,ele.isDisable)  
                                return (

                                    <div className={(ele.selectedDate === true && ele.day) ? this.props.styles.day_cell_center + ' ' + this.props.styles.selected_calendar_date : isHighLight === true && ele.day != "" ? this.props.styles.day_cell_center_highlight : this.props.styles.day_cell_center} key={i}
                                        onClick={() => this.onClickDateCell(ele.day, ele.month, ele.year, ele.isDisable, journeyType, selectionType)} onMouseEnter={(e) => this.onHoverDayCell(ele, e)} >
                                        {/* {this.state.isDoubleSelection === true && !cf.isValueEmpty(ele.day) && ele.isDisable === false && !cf.isValueEmpty(hoverDate) && isHoverDate && !cf.isValueEmpty(this.state.totalDays) && <div class={this.props.styles.container_tooltip}>{this.state.totalDays} Night</div>} */}
                                        <span className={ele.isDisable === true ? this.props.styles.calendar_day_disable : ''}>{ele.day}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        }
        return twoCalendarsArray
    }

    render() {
        return (
            <div className={this.props.styles.calendar_wid_container} >
                {/* <label>{this.props.label}</label> */}
                <div className={this.props.styles.calendar_wid_main}>
                    {/* departure value */}
                    {!cf.isValueEmpty(this.props.iconImage) ? (
                        <span className={this.props.styles.flight_search_wid_inputfield_img_cal} onClick={() => this.onClickChangeVisibility("depart")}>
                            {this.props.iconImage}
                        </span>
                    ) : null}
                    <div className={this.props.styles.flex_cont_cal} onClick={() => this.onClickChangeVisibility("depart")}>
                        <div className={this.props.styles.new_cal_digit}>
                            <h1 className={this.props.styles.new_cal_digit_text}>{this.props.departureValue ? this.props.departureValue.split('-')[0] : ''}</h1>
                        </div>
                        <div className={this.props.styles.new_cal_text}>
                            <p className={this.props.styles.new_cal_month}>{this.props.departureValue ? this.props.departureValue.split('-')[1] : ''}</p>
                            <p className={this.props.styles.new_cal_year}>{this.props.departureValue ? this.props.departureValue.split('-')[2] : ''}</p>
                        </div>
                    </div>
                    {/* departure value */}

                    {/* return value */}

                    {!cf.isValueEmpty(this.props.iconImage) ? (
                        <span className={this.props.styles.flight_search_wid_inputfield_img_cal} onClick={() => this.onClickChangeVisibility("return")}>
                            {this.props.iconImage}
                        </span>
                    ) : null}

                    {this.props.returnValue == 'Tap to add return' || this.props.searchType == "oneWay" ?
                        <div className={this.props.styles.flex_cont_cal} onClick={() => {
                            this.props.searchType == "oneWay" ? this.setState({ selectingForDate: "return" }, () => {
                                this.props.oneWayReturnClick();
                            }) : this.onClickChangeVisibility("return")
                        }}>
                            <div className={this.props.styles.new_cal_digit}>
                                <h1 className={this.props.styles.new_tap_text}>{'Tap to add return'}</h1>
                            </div>
                        </div>

                        :
                        <>

                            { this.props.showCrossIcon ? <div style={{ position: "absolute", top: "2px", right: "4px", alignItems: "center", cursor: "pointer" }}
                                onClick={() => { this.props.roundWayReturnClick()}}
                            >
                                {
                                    <img src='/images/close-red.svg' tabIndex="2" style={{ width: "15px", height: "15px", right: "0" }} alt='close-icon' />
                                }
                            </div> : null}
                            <div className={this.props.styles.flex_cont_cal} onClick={() => this.onClickChangeVisibility("return")}>
                                <div className={this.props.styles.new_cal_digit}>
                                    <h1 className={this.props.styles.new_cal_digit_text}>{this.props.returnValue ? this.props.returnValue.split('-')[0] : ''}</h1>
                                </div>
                                <div className={this.props.styles.new_cal_text}>
                                    <p className={this.props.styles.new_cal_month}>{this.props.returnValue ? this.props.returnValue.split('-')[1] : ''}</p>
                                    <p className={this.props.styles.new_cal_year}>{this.props.returnValue ? this.props.returnValue.split('-')[2] : ''}</p>
                                </div>
                            </div>
                        </>
                    }
                    {/* return value */}


                    {this.props.isVisible === true ? (
                        // calender container
                        <div
                            ref={this.calendarWrapper}
                            onMouseEnter={(e) => this.onMouseMoveCalendar(e, "onMouseEnter")}
                            className={this.props.type === 'double' ? this.props.styles.calendar_container + " " + this.props.styles.calendar_container_double : this.props.styles.calendar_container + " " + this.props.styles.calendar_container_single}
                        >
                            {/* left arrow */}
                            <div onClick={(e) => { e.stopPropagation() }} className={this.props.styles.calendar_main} >
                                {(getMonth(this.state.selectedMonthStartDate) !== getMonth(new Date()) ||
                                    (differenceInMonths(new Date(this.state.selectedMonthStartDate), new Date()) < 12) && differenceInMonths(new Date(this.state.selectedMonthStartDate), new Date()) >= 1) ?
                                    (
                                        <div className={this.props.styles.calendar_head_left_side} onClick={(e) => { e.stopPropagation(); this.onClickPrevNextArrow('prev') }}>
                                            <span className={this.props.styles.calendar_head_icon}>
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </div>
                                    ) : null}
                                {/* left arrow */}

                                {/* Right Arrow */}
                                {differenceInMonths(new Date(this.state.selectedMonthStartDate), new Date()) < 11 ?
                                    <div className={this.props.styles.calendar_head_right_side} onClick={(e) => { e.stopPropagation(); this.onClickPrevNextArrow('next') }}>
                                        <span className={this.props.styles.calendar_head_icon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </div> : null}
                                {/* Right Arrow */}

                                {this.renderCalendar(this.props.searchType, this.state.selectingForDate)}
                            </div>
                        </div>
                    ) : null}

                </div>
            </div>
        )
    }

}