import React, { Component } from "react";
import { addDays, differenceInCalendarDays, format } from "date-fns";

import B2BWidgets from "../../../b2bwidgets/index";
import DesktopCalender from "../../../calendar/hotelCalender/theme3/DesktopCalender";
import HotelAutoComplete from "./HotelAutoComplete";
import NationalityDropdownComp from "../../../NationalityDropDown/index";
import cf from "../../../../lib/utils/common";
import getNationalitiesList from "../../../../lib/getNationalitiesList";

const childAgeList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
let regions = "";
let indexDropdown = -1;
let search_text = "";
let destination_id = "";
let search_type = "";
export default class Theme1 extends Component {
  constructor(props) {
    super(props);
    this.setScrollDown = React.createRef();
    this.wrapperRef = React.createRef();
    this.advanceSearchWrapper = React.createRef();
    this.guestDetailWrapper = React.createRef();
    this.state = {
      hotelName: "",
      expandHotelNameBoolean: false,
      checkInOutValue: "",
      checkInValue: cf.getDateWithFormat(new Date(), this?.props?.dateFormat),
      checkOutValue: cf.getDateWithFormat(
        addDays(
          new Date(),
          this.props.getDefaultDays ? this.props.getDefaultDays : 2
        ),
        this?.props?.dateFormat
      ),
      dateLimit: cf.getDateWithFormat(new Date(), this?.props?.dateFormat),
      openRoomDetailList: false,
      expandCheckInOutDateBoolean: false,
      selectedRoomDetails: { rooms: 1, guest: 2 },
      getAutoSuggestList: this.props.getAutoSuggestList,
      isError: {
        hotelName: false,
        checkInOutDate: false,
        roomGuest: false,
        selectedNationality: false,
        selectedGuest: false,
      },
      getDestinationId: "",
      getSearchType: "",
      roomList: [
        {
          id: 1,
          adults: 2,
          children: 0,
          adultAddCountDisable: false,
          adultMinusCountDisable: false,
          childrenAddCountDisable: false,
          childrenMinusCountDisable: true,
          childDetails: [],
        },
      ],
      // finalRoomList: [{ id: 1, adults: 2, children: 0, childDetails: [] }],
      finalRoomList: [
        {
          id: 1,
          adults: 2,
          children: 0,
          adultAddCountDisable: false,
          adultMinusCountDisable: true,
          childrenAddCountDisable: false,
          childrenMinusCountDisable: true,
          childDetails: [],
        },
      ],
      type: "hotel",
      openTraveler: false,
      advSearch: false,
      nationallitySearch: null,
      is_breakfast: "",
      // isBreakfastChecked: false,
      originCountries: [],
      selectedNationality: "",
      response: [],
      isOneStar: false,
      isTwoStar: false,
      isThreeStar: false,
      isFourStar: false,
      isFiveStar: false,
      selectedCheckBoxes: {},
      guest_id: "",
    };
  }

  // Nationality

  componentDidMount() {
    // Nationality
    getNationalitiesList().then((resp) => {
      let nationalityList = [];
      if (resp?.data?.data.length > 0) {
        resp?.data.data.map((item) => {
          nationalityList.push({
            ...item,
            value: item.nationality_name,
            countryCode: item.nationality_code,
          });
        });
      }
      this.setState({ originCountries: nationalityList });
    });

    regions = new URLSearchParams(window.location.search).get("region");
    if (
      !cf.isValueEmpty(this.props) &&
      this.props.isSRP === true &&
      !cf.isValueEmpty(this.props.query)
    ) {
      let checkInArr = this.props?.query?.checkin?.split("-");
      let checkOutArr = this.props?.query?.checkout?.split("-");
      let checkInValue = cf.getDateWithFormat(
        new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]),
        this?.props?.dateFormat
      );
      let checkOutValue = cf.getDateWithFormat(
        new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]),
        this?.props?.dateFormat
      );
      let checkInOut =
        cf.getDateWithFormat(
          new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]),
          this?.props?.dateFormat
        ) +
        " - " +
        cf.getDateWithFormat(
          new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]),
          this?.props?.dateFormat
        );
      let roomsdetail = this.props.query.rooms.split("_");
      let totalRooms = roomsdetail.length;
      let totalAdult = 0;
      let totalChildren = 0;
      let roomListArr = [];
      let finalRoomListArr = []; //new
      roomsdetail.map((ele, index) => {
        let split = ele.split("-").filter((item) => item);
        totalAdult = Number(split[0].charAt(0)) + totalAdult;
        totalChildren = Number(split[1].charAt(0)) + totalChildren;
        roomListArr = [
          ...roomListArr,
          {
            id: index + 1,
            adults: Number(split[0].charAt(0)),
            children: Number(split[1].charAt(0)),
            adultAddCountDisable: false,
            adultMinusCountDisable: true,
            childrenAddCountDisable: false,
            childrenMinusCountDisable:
              Number(split[1].charAt(0)) == 1 ? false : true,
            childDetails: [],
          },
        ];
        // finalRoomListArr = [...finalRoomListArr, { id: index + 1, adults: Number(split[0].charAt(0)), children: Number(split[1].charAt(0)), childDetails: [] }] //new
        // split.map((element, id) => {
        //     if (id > 1) roomListArr[index].childDetails = [...roomListArr[index].childDetails, { age: element, isOpen: false, isError: false }],
        //         finalRoomListArr[index].childDetails = [...finalRoomListArr[index].childDetails, { age: Number(element) }]
        // })
        split.map((element, id) => {
          if (id > 1)
            roomListArr[index].childDetails = [
              ...roomListArr[index].childDetails,
              { age: element, isOpen: false, isError: false },
            ];
        });
      });
      this.setState(
        {
          checkInOutValue: checkInOut,
          checkInValue: checkInValue,
          checkOutValue: checkOutValue,
          getDestinationId: this.props.query.destid,
          getSearchType: this.props.query.search_type,
          hotelName: this.props.query.region + ", " + this.props.query.country,
          selectedRoomDetails: {
            rooms: totalRooms,
            guest: totalAdult + totalChildren,
          },
          roomList: roomListArr,
          nationallitySearch: this.props.query.nationality,
          // response:this.props.query.ratings,
          guest_id: this.props.query.guestId,
        },
        // this.setState(prevState => ({
        //   ...prevState.selectedCheckBoxes,
        //   selectedCheckBoxes: {
        //       breakfast:[[this.props.query.bf]],
        //       room_type:[[this.props.query.rf]],
        //   }
        // })),
        () => {
          this.checkRoomDetails();
        }
      );
      const deepClone = JSON.parse(JSON.stringify(roomListArr));
      this.setState({
        finalRoomList: deepClone,
      });
    } else {
      if (regions != null && regions != "" && regions != undefined) {
        this.renderInfo();
      }
      this.setState({
        checkInOutValue: this.getCheckInOutValue(
          this.state.checkInValue,
          this.state.checkOutValue
        ),
      });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    // document.addEventListener('mousedown', function(e){ e.preventDefault(); }, false)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  checkRoomDetails() {
    let { roomList } = this.state;
    roomList.map((ele, index) => {
      let totalAccomodation = ele.adults + ele.children;
      if (totalAccomodation <= 12) {
        if (totalAccomodation === 12) {
          roomList[index].adultAddCountDisable = true;
          roomList[index].childrenAddCountDisable = true;
        } else if (totalAccomodation < 12) {
          if (
            roomList[index].adultAddCountDisable === true &&
            roomList[index].childrenAddCountDisable === true
          ) {
            roomList[index].adultAddCountDisable = false;
            roomList[index].childrenAddCountDisable = false;
          }
          if (roomList[index].adults > 1) {
            roomList[index].adultMinusCountDisable = false;
          } else if (roomList[index].adults === 1) {
            roomList[index].adultMinusCountDisable = true;
            roomList[index].adultAddCountDisable = false;
          }
          if (roomList[index].children > 1) {
            roomList[index].childrenMinusCountDisable = false;
          } else if (roomList[index].children > 0) {
            roomList[index].childrenMinusCountDisable = false;
            roomList[index].childrenAddCountDisable = false;
          }
        }
      }
    });
    this.setState({ roomList: [...roomList] });
  }
  handleClickOutside = (e) => {
    if (
      this.dropdownWrapperRef &&
      !this.dropdownWrapperRef.contains(e.target)
    ) {
      if (this.props.expandHotelNameBoolean === true)
        this.props.expandHotelNameBooleanfunc(false);
      if (
        this.advanceSearchWrapper.current &&
        !this.advanceSearchWrapper.current.contains(event.target)
      ) {
        this.setState({ advSearch: false });
      }
      if (
        this.guestDetailWrapper.current &&
        !this.guestDetailWrapper.current.contains(event.target)
      ) {
        this.setState({ openTraveler: false });
      }
      if (
        this.state.expandCheckInOutDateBoolean === true ||
        this.state.openRoomDetailList === true
      ) {
        this.setState({
          expandCheckInOutDateBoolean: false,
          openRoomDetailList: false,
        });
      }
    }
  };
  setDropdownWrapperRef = (node) => {
    this.dropdownWrapperRef = node;
  };
  onClickDoneButton = () => {
    let totalGuest = 0;
    let count = 0;
    let Validation = true;
    let validation_arr = [];
    let roomList = this.state.roomList;
    // let finalRoomListArr = []; //new
    while (count <= roomList.length) {
      if (
        !cf.isValueEmpty(roomList[count]) &&
        !cf.isValueEmpty(roomList[count].childDetails) &&
        roomList[count].childDetails.length > 0
      ) {
        // finalRoomListArr = [...finalRoomListArr, { id: count + 1, adults: roomList[count].adults, children: roomList[count].children, childDetails: [{ age: '' }] }] //new
        roomList[count].childDetails.map((element, index) => {
          if (cf.isValueEmpty(element.age)) {
            roomList[count].childDetails[index].isError = true;
          }
        });
        // Validation = roomList[count].childDetails.every(item => !cf.isValueEmpty(item.age));
        validation_arr.push(
          roomList[count].childDetails.every(
            (item) => !cf.isValueEmpty(item.age)
          )
        );
        let checker = validation_arr.every(Boolean);
        Validation = checker;
      }
      count++;
    }
    // this.setState({ roomList: [...roomList] })
    this.setState({ roomList: [...roomList] }, () => {
      if (Validation === true) {
        this.state.roomList.length > 0 &&
          this.state.roomList.map((element) => {
            totalGuest = totalGuest + element.adults + element.children;
            //new
            // element.childDetails.map((ele, i) => {
            //     finalRoomListArr[idx].childDetails[i].age = ele.age;
            // })
          });
        this.setState({
          // finalRoomList: finalRoomListArr,
          selectedRoomDetails: {
            rooms: this.state.roomList.length,
            guest: totalGuest,
          },
        });
        const deepClone = JSON.parse(JSON.stringify(roomList));
        this.setState({
          finalRoomList: deepClone,
        });
        if (this.state.openRoomDetailList === true) {
          this.setState({ openRoomDetailList: false });
        }
      }
    });
  };
  removeRoomItem = (id) => {
    const filteredRoom = this.state.roomList.filter((item) => item.id !== id);
    this.setState({ roomList: [...filteredRoom] });
  };

  addAccomodationCount = async (id, index, type) => {
    let { roomList } = this.state;
    if (type === "adult") {
      if (roomList[index].id === id) {
        roomList[index].adults = roomList[index].adults + 1;
      }
    } else {
      if (roomList[index].id === id) {
        roomList[index].children = roomList[index].children + 1;
        roomList[index].childDetails = [
          ...roomList[index].childDetails,
          { age: "", isOpen: false, isError: false },
        ];
      }
    }
    let totalAccomodation = roomList[index].adults + roomList[index].children;
    if (totalAccomodation <= 7) {
      if (totalAccomodation === 7) {
        roomList[index].adultAddCountDisable = true;
        roomList[index].childrenAddCountDisable = true;
      } else if (totalAccomodation < 7) {
        if (
          roomList[index].adultAddCountDisable === true &&
          roomList[index].childrenAddCountDisable === true
        ) {
          roomList[index].adultAddCountDisable = false;
          roomList[index].childrenAddCountDisable = false;
        }
      }
      if (roomList[index].adults > 1) {
        roomList[index].adultMinusCountDisable = false;
      }
      if (roomList[index].children > 0) {
        roomList[index].childrenMinusCountDisable = false;
      }
      await this.setState({ roomList: [...roomList] });
    }
  };
  minusAccomodationCount = async (id, index, type) => {
    let { roomList } = this.state;
    if (type === "adult") {
      if (roomList[index].id === id) {
        if (roomList[index].adults >= 2) {
          roomList[index].adults = roomList[index].adults - 1;
          roomList[index].adultMinusCountDisable = false;
          if (roomList[index].adultAddCountDisable === true) {
            roomList[index].adultAddCountDisable = false;
          }
          if (roomList[index].childrenAddCountDisable === true) {
            roomList[index].childrenAddCountDisable = false;
          }
        } else if (roomList[index].adults === 1) {
          roomList[index].adultMinusCountDisable = true;
        }
      }
    } else {
      if (roomList[index].id === id) {
        roomList[index].childDetails.pop();
        if (roomList[index].children >= 1) {
          roomList[index].children = roomList[index].children - 1;
          roomList[index].childrenMinusCountDisable = false;
          if (roomList[index].childrenAddCountDisable === true) {
            roomList[index].childrenAddCountDisable = false;
          }
          if (roomList[index].adultAddCountDisable === true) {
            roomList[index].adultAddCountDisable = false;
          }
        } else if (roomList[index].children === 0) {
          roomList[index].childrenMinusCountDisable = true;
        }
      }
    }
    await this.setState({ roomList: [...roomList] });
  };
  isChildrenAge = (index, ind, type, age) => {
    let { roomList } = this.state;
    if (type === "isOpen") {
      roomList[index].childDetails[ind].isOpen =
        !roomList[index].childDetails[ind].isOpen;
    } else {
      roomList[index].childDetails[ind].age = age;
      roomList[index].childDetails[ind].isOpen = false;
      if (roomList[index].childDetails[ind].isError === true) {
        roomList[index].childDetails[ind].isError = false;
      }
    }
    this.setState({ roomList: [...roomList] });
  };
  onChangeCheckInOutValue = (value, type) => {
    if (type === "startDate") {
      this.setState({ checkInValue: value });
    } else if (type === "endDate") {
      let differenceInDays = differenceInCalendarDays(
        new Date(value),
        new Date(this.state.checkInValue)
      );
      if (differenceInDays === 0) {
        this.setState({ checkOutValue: addDays(new Date(value), 1) }, () => {
          if (!cf.isValueEmpty(value)) {
            let checkInOut =
              cf.getDateWithFormat(
                this?.state?.checkInValue,
                this?.props?.dateFormat
              ) +
              " - " +
              cf.getDateWithFormat(
                this?.state?.checkOutValue,
                this?.props?.dateFormat
              );
            this.setState({ checkInOutValue: checkInOut });
          }
        });
      } else {
        this.setState({ checkOutValue: value }, () => {
          if (!cf.isValueEmpty(value)) {
            let checkInOut =
              cf.getDateWithFormat(
                this?.state?.checkInValue,
                this?.props?.dateFormat
              ) +
              " - " +
              cf.getDateWithFormat(value, this?.props?.dateFormat);
            this.setState({ checkInOutValue: checkInOut });
          }
        });
      }
    }
  };

  onChangeSetHotelName = (value) => {
    this.setState({ hotelName: value, getDestinationId: "" }, () => {
      if (this.state.isError.hotelName === true) {
        this.validateErrorMsg();
      }
      this.props.getAutoSuggestValue(value);
      
    });
  };
  validateErrorMsg = () => {
    const mapDispatch = {
      hotelName: cf.isValueEmpty(this.state.hotelName) ? true : false,
      checkInOutDate: cf.isValueEmpty(this.state.checkInOutValue)
        ? true
        : false,
      roomGuest: cf.isValueEmpty(this.state.selectedRoomDetails) ? true : false,
      selectedNationality: cf.isValueEmpty(this.state.selectedNationality)
        ? true
        : false,
      selectedGuest: cf.isValueEmpty(this.state.guest_id) ? true : false,
    };
    this.setState({ isError: mapDispatch });
  };

  onClickSearchHotel = () => {
    const mapDispatch = {
      hotelName: cf.isValueEmpty(this.state.hotelName) ? true : false,
      checkInOutDate: cf.isValueEmpty(this.state.checkInOutValue)
        ? true
        : false,
      roomGuest: cf.isValueEmpty(this.state.selectedRoomDetails) ? true : false,
      selectedNationality: cf.isValueEmpty(this.state.selectedNationality)
        ? true
        : false,
      selectedGuest: cf.isValueEmpty(this.state.guest_id) ? true : false,
    };
    if (!this.props?.userProfile?.type) {
      mapDispatch.selectedNationality = cf.isValueEmpty(
        this.state.selectedNationality
      )
        ? false
        : true;
    }
    if (this.props?.userProfile?.type !== "B2B") {
      mapDispatch.selectedNationality = cf.isValueEmpty(
        this.state.selectedNationality
      )
        ? false
        : true;
    }
    if (this.props.isOffline !== true) {
      mapDispatch.selectedGuest = cf.isValueEmpty(this.state.selectedGuest)
        ? false
        : true;
    }
    if (!this.props.isOffline) {
      mapDispatch.selectedGuest = cf.isValueEmpty(this.state.selectedGuest)
        ? false
        : true;
    }
    if (this.props.isSRP === true) {
      mapDispatch.selectedNationality = cf.isValueEmpty(
        this.state.selectedNationality
      )
        ? false
        : true;
    }
    mapDispatch.hotelName = this.state.getDestinationId ? false : true;
    this.setState({ isError: mapDispatch }, () => {
      let Validate = true;
      for (const [key, value] of Object.entries(this.state.isError)) {
        if (value === true) {
          Validate = false;
          break;
        }
      }
      if (Validate === true) {
        let hotelNameArry = this.state.hotelName.split(",");
        let roomListArr = [];
        this.state.finalRoomList.map((res, id) => {
          roomListArr = [
            ...roomListArr,
            res.adults + "a-" + res.children + "c",
          ];
          res.childDetails.map((ele) => {
            roomListArr[id] = roomListArr[id].concat("-", ele.age);
          });
        });
        //IF CHECKOUT DATE IS EMPTY
        if (!this.state.checkOutValue) {
          this.state.checkOutValue = cf.getDateWithFormat(
            addDays(new Date(this.state.checkInValue), 2),
            this?.props?.dateFormat
          );
        }
        let starb2bFilter = [...new Set(this.state.response)];
        let urlQuery = {
          region: hotelNameArry[0]?.trim(),
          country:
            this.props.isSRP === true
              ? hotelNameArry[1]?.trim()
              : hotelNameArry[hotelNameArry.length - 1]?.trim(),
          checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
          checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
          rooms: roomListArr.join("_"),
          type: "location",
          roomtype: "single",
          page: 1,
          orderby: "desc",
          mop: "accrual",
          destid: this.state.getDestinationId,
          search_type: this.state.getSearchType,
          ratings: starb2bFilter.join("_"),
          bf: this.state.selectedCheckBoxes?.breakfast?.[0]
            ? this.state.selectedCheckBoxes?.breakfast?.[0]
            : null,
          rf: this.state.selectedCheckBoxes?.room_type?.[0]
            ? this.state.selectedCheckBoxes?.room_type?.[0]
            : null,
          nationality: this.state.nationallitySearch,
          guestId: this.state.guest_id,
          is_web_app: false,
        };
        // console.log(urlQuery, "urlQuery")
        if (this.props.isOffline === false) {
          cf.redirectTo("/hotel/search", urlQuery);
        } else if (this.props.isOffline === true) {
          const searchParams = new URLSearchParams(urlQuery);
          const queryString = searchParams.toString();
          window.location = "/offlinesearch/hotel?" + queryString;
        }
      }
    });
  };

  onClickSetHotelName = (element) => {
    // this.setState({ isError: mapDispatch }, () => {})
    // console.log("setHotelName", element)
    if(element.search_text){
      if (this.state.isError.hotelName === true) {
        this.state.isError.hotelName = false;
      }
    }
    
    this.setState({
      hotelName: element.search_text,
      getDestinationId: element.destination_id,
      getSearchType: element.search_type ? element.search_type : 6,
    });
  };
  getCheckInOutValue = (checkIn, checkOut) => {
    let checkInArr = checkIn.split("/");
    let checkOutArr = checkOut.split("/");
    return checkIn + " - " + checkOut;
  };
  onClickVisibilityStatus = (type, value) => {
    this.setScrollDown.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    switch (type) {
      case "hotel":
        this.props.expandHotelNameBooleanfunc(value);
        if (
          this.state.expandCheckInOutDateBoolean === true ||
          this.state.openRoomDetailList === true
        ) {
          this.setState({
            expandCheckInOutDateBoolean: false,
            openRoomDetailList: false,
          });
        }
        break;
      case "calendar":
        this.setState({ expandCheckInOutDateBoolean: value }, () => {
          if (
            this.props.expandHotelNameBoolean === true ||
            this.state.openRoomDetailList === true
          ) {
            this.setState({ openRoomDetailList: false });
            this.props.expandHotelNameBooleanfunc(false);
          }
        });
        break;
      case "rooms":
        if (this.state.openRoomDetailList === false) {
          this.setState({ openRoomDetailList: true }, () => {
            if (
              this.props.expandHotelNameBoolean === true ||
              this.state.expandCheckInOutDateBoolean === true
            ) {
              this.setState({ expandCheckInOutDateBoolean: false });
              this.props.expandHotelNameBooleanfunc(false);
            }
          });
        } else {
          this.onClickDoneButton();
        }
        break;
      default:
        break;
    }
  };
  onClickHotelInput = () => {
    this.props.getAutoSuggestValue("");
  };
  renderInfo = () => {
    this.props.getAutoSuggestValue(regions);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      regions != null &&
      regions != "" &&
      regions != undefined &&
      !this.props.isSRP
    ) {
      if (
        prevProps.getAutoSuggestList.length <
        this.props.getAutoSuggestList.length
      ) {
        prevProps.getAutoSuggestList = this.props.getAutoSuggestList;
        this.setState(
          {
            hotelName: prevProps.getAutoSuggestList[0].search_text,
            getDestinationId: prevProps.getAutoSuggestList[0].destination_id,
            getSearchType: prevProps.getAutoSuggestList[0].search_type,
          },
          () => {
            this.props.expandHotelNameBooleanfunc(false);
          }
        );
      }
    }
  }

  onInputFocus = (fieldName) => {
    if (fieldName == "autocomplete") {
      this.onClickVisibilityStatus("hotel", true);
    }
    if (fieldName == "calendar") {
      this.onClickVisibilityStatus("calendar", true);
    }
    if (fieldName == "rooms") {
      this.onClickVisibilityStatus("rooms");
      this.setState({
        openRoomDetailList: this.state.openRoomDetailList,
      });
    }
  };

  highlightData(id, parentElement) {
    let innerContent = document.getElementById(parentElement).children;
    for (let i = 0; i < innerContent.length; i++) {
      let selectedElement = innerContent[i];
      selectedElement.style.backgroundColor = i == id ? "#ccc" : "#fff";
    }
    innerContent[id].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }
  onKeyDownArrow = (event, parentClassId, moduleType) => {
    switch (event.which) {
      case 38: // up
        indexDropdown--;
        if (indexDropdown < 0) {
          indexDropdown = 0;
        }
        this.highlightData(indexDropdown, parentClassId);
        search_text =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${this.props.getAutoSuggestList[indexDropdown].search_text}`
            : "";
        destination_id =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${this.props.getAutoSuggestList[indexDropdown].destination_id}`
            : "";
        search_type =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${this.props.getAutoSuggestList[indexDropdown].search_type
              ? this.props.getAutoSuggestList[indexDropdown].search_type
              : 1
            }`
            : "";
        break;
      case 40: // down
        indexDropdown++;
        if (indexDropdown >= this.props.getAutoSuggestList.length - 1) {
          indexDropdown = this.props.getAutoSuggestList.length - 1;
        }
        this.highlightData(indexDropdown, parentClassId);
        search_text =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${this.props.getAutoSuggestList[indexDropdown].search_text}`
            : "";
        destination_id =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${this.props.getAutoSuggestList[indexDropdown].destination_id}`
            : "";
        search_type =
          this.props.getAutoSuggestList.length >= indexDropdown
            ? `${this.props.getAutoSuggestList[indexDropdown].search_type
              ? this.props.getAutoSuggestList[indexDropdown].search_type
              : 1
            }`
            : "";
        break;

      case 13: //enter
        this.setState(
          {
            hotelName: search_text,
            getDestinationId: destination_id,
            getSearchType: search_type,
          },
          () => {
            this.onClickVisibilityStatus("hotel", false);
          }
        );
        indexDropdown = -1;
        break;
      case 9: //tab
        this.setState(
          {
            hotelName: search_text,
            getDestinationId: destination_id,
            getSearchType: search_type,
          },
          () => {
            this.onClickVisibilityStatus("hotel", false);
          }
        );
        indexDropdown = -1;

        break;
      default:
    }
  };
  focusOutDone = () => {
    /*this.onClickVisibilityStatus("rooms", false) */
    this.setState({
      openRoomDetailList: this.state.openRoomDetailList,
    });
    console.log("focus out done");
  };
  onClickTraveller = () => {
    if (!this.state.openTraveler) {
      this.setState({ openTraveler: true });
    } else {
      this.setState({ openTraveler: false });
    }
  };

  onClickAdvSearch = () => {
    if (!this.state.advSearch) {
      this.setState({ advSearch: true });
    } else {
      this.setState({ advSearch: false });
    }
  };

  onClickNationality = () => {
    if (!this.state.nationallitySearch) {
      this.setState({ nationallitySearch: true });
    } else {
      this.setState({ nationallitySearch: false });
    }
  };

  onTickAdvSearch = () => {
    cf.redirectTo("/hotel/search", urlQuery);
  };

  applyb2bFilters = (filterName, event, checkbox, index) => {
    switch (filterName) {
      case "star":
        const { value, checked } = event.target;
        if (checked && !this.state.response.includes(value)) {
          this.setState({ response: [...this.state.response, value] });
        } else {
          this.setState({
            response: this.state.response.filter((val) => {
              return val !== value;
            }),
          });
        }
        break;
      default:
        break;
    }
  };

  handleStarCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  selectNationality = (params) => {
    this.setState({ selectedNationality: params });
    this.setState({ nationallitySearch: params.countryCode });
  };

  onChangeNationalityCode = (params) => {
    this.setState({ selectedNationality: { flag: "/images/ax-tick.svg" } });
  };

  handleCheckboxSelection = (event, index) => {
    let updatedSelections = new Array(index + 1).fill(false);
    updatedSelections[index] = event.target.checked;

    this.setState((prev) => ({
      selectedCheckBoxes: {
        ...prev.selectedCheckBoxes,
        [event.target.name]: updatedSelections,
      },
    }));
  };

  adGuestId = (id) => {
    this.setState({ guest_id: id }, () => {
      if (this.state.isError.selectedGuest === true) {
        this.validateErrorMsg();
      }
      this.setState({ guest_id: id });
    });
  };

  rmGuestId = () => {
    this.setState({ guest_id: "" });
  };

  render() {
    return (
      <div
        ref={this.setScrollDown}
        className={
          !this.props.isSRP ? "container mx-auto xl:px-8" : "container "
        }
      >
        <div className={this.props.styles.hotel_search_wid_container}>
          <div className={this.props.styles.hotel_search_wid_main}>
            {!this.props.isSRP ? (
              <div className={this.props.styles.hotel_way_title}>
                <svg width="40px" height="40px" viewBox="0 0 200 200">
                  <g id="Layer_2">
                    <path
                      fill="#30C8CA"
                      d="M166.2,75.467c0-15.693-12.841-28.533-28.534-28.533H66.333c-15.693,0-28.533,12.84-28.533,28.533v27.106   c-4.28,3.567-7.133,9.273-7.133,15.693v19.261v2.14v14.267c0,4.28,2.854,7.133,7.133,7.133c4.28,0,7.133-2.853,7.133-7.133V146.8   h114.133v7.134c0,4.28,2.854,7.133,7.134,7.133c4.279,0,7.133-2.853,7.133-7.133v-14.267v-2.14v-19.261   c0-6.42-2.854-12.126-7.133-15.693V75.467z M52.066,75.467c0-7.847,6.42-14.267,14.267-14.267h71.333   c7.847,0,14.267,6.42,14.267,14.267v21.4H144.8v-7.134c0-12.126-9.273-21.4-21.4-21.4h-7.133c-5.707,0-10.7,2.14-14.267,5.707   c-3.567-3.566-8.561-5.707-14.267-5.707H80.6c-12.126,0-21.4,9.273-21.4,21.4v7.134h-7.133V75.467z M130.533,89.733v7.134h-21.4   v-7.134c0-4.28,2.854-7.133,7.134-7.133h7.133C127.68,82.6,130.533,85.454,130.533,89.733z M94.866,89.733v7.134h-21.4v-7.134   c0-4.28,2.854-7.133,7.133-7.133h7.133C92.013,82.6,94.866,85.454,94.866,89.733z M159.066,132.533H44.933v-14.267   c0-4.279,2.854-7.133,7.133-7.133h21.4h21.4h14.267h21.4h21.399c4.28,0,7.134,2.854,7.134,7.133V132.533z"
                    />
                  </g>
                </svg>
                Hotels
              </div>
            ) : null}
            {!this.props.isSRP && this.props?.userProfile?.type === "B2B" && (
              <B2BWidgets
                {...this.state}
                styles={this.props.b2bStyles}
                onClickTraveller={this.onClickTraveller}
                onClickAdvSearch={this.onClickAdvSearch}
                onClickSearchHotel={this.onClickSearchHotel}
                onTickAdvSearch={this.onTickAdvSearch}
                onClickNationality={this.onClickNationality}
                applyb2bFilters={this.applyb2bFilters}
                handleCheckboxSelection={this.handleCheckboxSelection}
                selectedCheckBoxes={this.state.selectedCheckBoxes}
                handleStarCheck={this.handleStarCheck}
                isOneStar={this.state.isOneStar}
                isTwoStar={this.state.isTwoStar}
                isThreeStar={this.state.isThreeStar}
                isFourStar={this.state.isFourStar}
                isFiveStar={this.state.isFiveStar}
                addGuestId={this.adGuestId}
                removeGuestId={this.rmGuestId}
                listWrapper={this.advanceSearchWrapper}
                guestWrapper={this.guestDetailWrapper}
                isOffline={this.props.isOffline}
                errorMessage={"Select Traveler"}
                isError={this.state.isError.selectedGuest}
              />
            )}
            <div
              className={`${this.props.styles.hotel_search_details_container} ${!this.props.isSRP
                  ? this.props.styles.home_bgcolor
                  : this.props.styles.srp_bgcolor
                }`}
            >
              <div className={this.props.styles.hotel_search_details_main}>
                <div
                  className={`${this.props.styles.hotel_search_details_row} ${this.props.styles.mod_search_flx}`}
                  ref={this.setDropdownWrapperRef}
                >
                  <div
                    id="hotelList"
                    className={`${this.props.styles.hotel_search_column} ${this.props.styles.hotel_search_name
                      } ${this.props.isSRP
                        ? this.props.styles.srp_hotel_search_name
                        : ""
                      }`}
                  >
                    <HotelAutoComplete
                      tabIndex={1}
                      isDisable={this.props.isDisable === true}
                      label="ENTER CITY NAME,LOCATION OR SPECIFIC HOTEL"
                      styles={this.props.styles}
                      value={this.state.hotelName }
                      onKeyDownArrow={(event) => {
                        this.onKeyDownArrow(event, "hotelDataArray", "hotel");
                      }}
                      placholder="City / Area / Hotel Name"
                      onInputFocus={() => {
                        this.onInputFocus("autocomplete");
                      }}
                      onClickHotelInput={() => this.onClickHotelInput()}
                      onClickHotelName={(value) =>
                        this.onClickSetHotelName(value)
                      }
                      onChangeHotelName={(value) =>
                        this.onChangeSetHotelName(value)
                      }
                      isAutoCompleteExpand={(value) =>
                        this.onClickVisibilityStatus("hotel", value)
                      }
                      autoCompleteData={
                        !cf.isValueEmpty(this.props.getAutoSuggestList)
                          ? this.props.getAutoSuggestList
                          : []
                      }
                      expandAutoCompleteBool={
                        !cf.isValueEmpty(this.props.expandHotelNameBoolean)
                          ? this.props.expandHotelNameBoolean
                          : false
                      }
                      leftIcon={
                        this.props.isSRP ? (
                          ""
                        ) : (
                          <span>
                            <img src="images/location-01.svg" alt="" />
                          </span>
                        )
                      }
                      errorMessage={"Enter Destination"}
                      isError={this.state.isError.hotelName}
                      isSrp={this.props.isSRP}
                    
                    />
                  </div>
                  <div
                    className={`${this.props.styles.hotel_search_column} ${this.props.styles.hotel_search_date
                      } ${this.props.isSRP
                        ? this.props.styles.srp_hotel_search_date
                        : ""
                      }`}
                  >
                    <DesktopCalender
                      // tabIndex={2}
                      // label="Check In & Check Out Date"
                      styles={this.props.styles}
                      value={
                        !cf.isValueEmpty(this.state.checkInOutValue)
                          ? this.state.checkInOutValue
                          : ""
                      }
                      placholder="Select the date"
                      onChangeDate={(type, value) => {
                        this.onChangeCheckInOutValue(type, value);
                      }}
                      onInputFocus={() => {
                        this.onInputFocus("calendar");
                      }}
                      iconImage={
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="30px"
                          height="30px"
                          viewBox="0 0 30 30"
                          enable-background="new 0 0 30 30"
                        >
                          <g>
                            <path
                              fill="currentColor"
                              d="M7.258,17.91H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.002,1.002,1.002h0.083
                                       c0.558,0,1.003-0.445,1.003-1.002v-0.111C8.262,18.355,7.816,17.91,7.258,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,17.91h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.084
                                       c0.556,0,1.002-0.445,1.002-1.002v-0.111C12.324,18.355,11.878,17.91,11.322,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,17.91h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.002,1.002,1.002h0.112
                                       c0.556,0,1.001-0.445,1.001-1.002v-0.111C16.415,18.355,15.97,17.91,15.414,17.91L15.414,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M7.258,13.792H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.001,1.002,1.001h0.083
                                       c0.558,0,1.003-0.445,1.003-1.001v-0.111C8.262,14.237,7.816,13.792,7.258,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,13.792h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.084
                                       c0.556,0,1.002-0.445,1.002-1.001v-0.111C12.324,14.237,11.878,13.792,11.322,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,13.792h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.001,1.002,1.001h0.112
                                       c0.556,0,1.001-0.445,1.001-1.001v-0.111C16.415,14.237,15.97,13.792,15.414,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,17.91h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.002,1.003,1.002h0.111
                                       c0.556,0,1.001-0.445,1.001-1.002v-0.111C20.506,18.355,20.033,17.91,19.478,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,17.91h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.112
                                       c0.557,0,1.002-0.445,1.002-1.002v-0.111C24.57,18.355,24.125,17.91,23.568,17.91z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,13.792h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.001,1.003,1.001h0.111
                                       c0.556,0,1.001-0.445,1.001-1.001v-0.111C20.506,14.237,20.033,13.792,19.478,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,13.792h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.112
                                       c0.557,0,1.002-0.445,1.002-1.001v-0.111C24.57,14.237,24.125,13.792,23.568,13.792z"
                            />
                            <path
                              fill="currentColor"
                              d="M7.258,22.002H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.445,1.004,1.002,1.004h0.083
                                       c0.558,0,1.003-0.445,1.003-1.004v-0.109C8.262,22.473,7.816,22.002,7.258,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M11.322,22.002h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.084
                                       c0.556,0,1.002-0.445,1.002-1.004v-0.109C12.324,22.473,11.878,22.002,11.322,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M15.414,22.002h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.446,1.004,1.002,1.004h0.112
                                       c0.556,0,1.001-0.445,1.001-1.004v-0.109C16.415,22.473,15.97,22.002,15.414,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M19.478,22.002h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.109c0,0.559,0.445,1.004,1.003,1.004h0.111
                                       c0.556,0,1.001-0.445,1.001-1.004v-0.109C20.506,22.473,20.033,22.002,19.478,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M23.568,22.002h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.112
                                       c0.557,0,1.002-0.445,1.002-1.004v-0.109C24.57,22.473,24.125,22.002,23.568,22.002z"
                            />
                            <path
                              fill="currentColor"
                              d="M28.577,10.535L28.577,10.535V8.281c0-1.726-1.392-3.118-3.089-3.118h-1.643V4.189
                                       c0-1.196-0.973-2.171-2.171-2.171c-1.196,0-2.17,0.975-2.17,2.171v0.974l-8.238,0V4.19c0-1.197-0.974-2.171-2.17-2.171
                                       c-1.197,0-2.17,0.974-2.17,2.171v0.974H5.283c-1.725,0-3.117,1.392-3.117,3.118v16.643c0,1.697,1.392,3.088,3.117,3.088h20.232
                                       c1.698,0,3.09-1.391,3.09-3.088L28.577,10.535L28.577,10.535L28.577,10.535z M20.785,4.189c0-0.473,0.39-0.863,0.861-0.863
                                       c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863c-0.472,0-0.861-0.39-0.861-0.863V4.189z M8.206,4.189
                                       c0-0.473,0.389-0.863,0.862-0.863c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863
                                       c-0.473,0-0.862-0.39-0.862-0.863V4.189z M5.254,6.472h1.67c0.112,1.085,1.03,1.948,2.17,1.948c1.113,0,2.031-0.863,2.143-1.948
                                       h8.266c0.111,1.085,1.03,1.948,2.143,1.948c1.113,0,2.031-0.863,2.144-1.948h1.67c0.974,0,1.781,0.807,1.781,1.809v1.615H3.446
                                       V8.28C3.446,7.279,4.253,6.472,5.254,6.472L5.254,6.472z M25.488,26.703H5.254c-1.002,0-1.808-0.807-1.808-1.779V11.175H27.27
                                       v13.749C27.27,25.896,26.462,26.703,25.488,26.703z"
                            />
                          </g>
                        </svg>
                      }
                      startDate={
                        !cf.isValueEmpty(this.state.checkInValue)
                          ? this.state.checkInValue
                          : ""
                      }
                      endDate={
                        !cf.isValueEmpty(this.state.checkOutValue)
                          ? this.state.checkOutValue
                          : ""
                      }
                      startDateLimit={
                        !cf.isValueEmpty(this.state.dateLimit)
                          ? this.state.dateLimit
                          : ""
                      }
                      dateFormat={this?.props?.dateFormat}
                      changeVisibility={(bool) =>
                        this.onClickVisibilityStatus("calendar", bool)
                      }
                      isVisible={this.state.expandCheckInOutDateBoolean}
                      type={"double"}
                      doubleSelection={true}
                    />
                  </div>
                  <div
                    className={
                      this.props.styles.hotel_search_column +
                      " " +
                      this.props.styles.hotel_search_accomodation
                    }
                  >
                    <div
                      className={this.props.styles.hotel_cabin_class_cont}
                      onClick={() => this.onClickVisibilityStatus("rooms")}
                    >
                      <div className={this.props.styles.hotel_cabin_class_icon}>
                        {/* {this.props.isSRP ? (
                          ""
                        ) : ( */}
                          
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 width="200px" height="200px" viewBox="0 0 200 200" enable-background="new 0 0 200 200" >
<g id="User__x2F__User_x5F_Circle">
	<g id="Vector">
		<path fill="#30C8CA" d="M102,190.5c-18.541,0-35.737-5.862-49.833-15.833c-0.208-0.117-0.407-0.258-0.592-0.423
			C29.741,158.526,15.5,132.896,15.5,104c0-47.696,38.803-86.5,86.5-86.5c47.696,0,86.5,38.804,86.5,86.5
			c0,28.901-14.247,54.538-36.089,70.254c-0.178,0.158-0.368,0.292-0.566,0.405C137.747,184.632,120.544,190.5,102,190.5z
			 M58.958,171.183C71.384,179.171,86.161,183.81,102,183.81s30.613-4.638,43.04-12.626c-11.435-11.255-26.912-17.642-43.04-17.642
			C85.871,153.542,70.394,159.928,58.958,171.183z M102,146.852c18.293,0,35.831,7.407,48.59,20.422
			c18.972-14.604,31.22-37.537,31.22-63.273c0-44.007-35.803-79.81-79.81-79.81S22.19,59.993,22.19,104
			c0,25.736,12.247,48.668,31.218,63.272C66.168,154.258,83.707,146.852,102,146.852z M102,125.824
			c-17.129,0-31.063-13.936-31.063-31.063c0-17.128,13.935-31.063,31.063-31.063s31.063,13.935,31.063,31.063
			C133.063,111.889,119.129,125.824,102,125.824z M102,70.388c-13.439,0-24.373,10.934-24.373,24.373
			c0,13.438,10.934,24.372,24.373,24.372c13.439,0,24.373-10.934,24.373-24.372C126.373,81.322,115.439,70.388,102,70.388z"/>
	</g>
</g>
</svg>

                        {/* )} */}
                      </div>
                      {this.props.isSRP ?"":
                      <label>Rooms & Guests</label>}

                      <div className={this.props.styles.hotel_cabin_class_name}>
                        <input
                          tabindex="3"
                          onFocus={() => {
                            this.onInputFocus("rooms");
                          }}
                          value={
                            !cf.isValueEmpty(this.state.selectedRoomDetails)
                              ? this.state.selectedRoomDetails.rooms +
                              " Room, " +
                              this.state.selectedRoomDetails.guest +
                              " Guest"
                              : "1 Room, 2 Guest"
                          }
                        />
                      </div>
                    </div>
                    {this.state.openRoomDetailList === true ? (
                      <div className={this.props.styles.hotel_room_list}>
                        <svg className={this.props.styles.hotel_arrow_pax} width="154" height="101" viewBox="0 0 151 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M75.5 1.3811L140.885 76.0953H10.1151L75.5 1.3811Z" fill="white"/>
                      <line y1="-1.5" x2="100.715" y2="-1.5" transform="matrix(0.666347 -0.745642 0.939264 0.343196 9.5873 76.4784)" stroke="#2FC7C9" stroke-width="3"/>
                      <path d="M76.0269 1.19861L140.214 76.0953" stroke="#2FC7C9" stroke-width="3"/>
                      </svg>
                        <div className={this.props.styles.hotel_room_list_main}>
                          {this.state.roomList.map((element, index) => {
                            return (
                              <div
                                className={
                                  this.props.styles.hotel_room_detail_ele
                                }
                                key={index}
                              >
                                <div
                                  className={this.props.styles.hotel_room_head}
                                >
                                  <div
                                      className={
                                        this.props.styles
                                          .hotel_adult_accomodation_tag
                                      }
                                    >
                                      <p>
                                      Room {index + 1} (Max 8)
                                      </p>
                                    </div>
                                  {index > 0 ? (
                                    <div
                                      className={
                                        this.props.styles.hotel_room_remove
                                      }
                                      onClick={() =>
                                        this.removeRoomItem(element.id)
                                      }
                                    >
                                      Remove
                                    </div>
                                  ) : null}
                                </div>
                                <div
                                  className={
                                    this.props.styles.hotel_accomodation_main
                                  }
                                >
                                  {/* <div
                                    className={
                                      this.props.styles.hotel_adult_accomodation
                                    }
                                  >
                                    <div
                                      className={
                                        this.props.styles
                                          .hotel_adult_accomodation_tag
                                      }
                                    >
                                      <p style={{ paddingLeft: 3 }}>
                                      Room {index + 1} (Max 8)
                                      </p>
                                    </div>
                                    <div
                                      className={
                                        this.props.styles
                                          .hotel_accomodation_conf
                                      }
                                    >
                                       <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          id="minus"
                                        >
                                          <path
                                            fill="#00A4F3"
                                            d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"
                                          ></path>
                                        </svg>
                                      <div> 1 </div>
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          id="plus"
                                        >
                                          <path
                                            fill="#00A4F3"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                          ></path>
                                        </svg>
                                    </div>
                                    </div> */}
                                    <div
                                    className={
                                      this.props.styles.hotel_adult_accomodation
                                    }
                                  >
                                    <div
                                      className={
                                        this.props.styles
                                          .hotel_adult_accomodation_tag
                                      }
                                    >
                                      <p>Adult </p>
                                      <span> (12+ yrs)</span>
                                    </div>
                                    <div
                                      className={
                                        this.props.styles
                                          .hotel_accomodation_conf
                                      }
                                    >
                                      <span
                                        className={
                                          element.adultMinusCountDisable
                                            ? this.props.styles
                                              .disbale_traveller +
                                            " " +
                                            this.props.styles
                                              .hotel_accomodation_icon
                                            : this.props.styles
                                              .hotel_accomodation_icon
                                        }
                                        onClick={() =>
                                          this.minusAccomodationCount(
                                            element.id,
                                            index,
                                            "adult"
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          id="minus"
                                        >
                                          <path
                                            fill="#00A4F3"
                                            d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"
                                          ></path>
                                        </svg>
                                      </span>
                                      <div
                                        className={this.props.styles.ac_count}
                                      >
                                        {element.adults}
                                      </div>
                                      <span
                                        className={
                                          element.adultAddCountDisable
                                            ? this.props.styles
                                              .disbale_traveller +
                                            " " +
                                            this.props.styles
                                              .hotel_accomodation_icon
                                            : this.props.styles
                                              .hotel_accomodation_icon
                                        }
                                        onClick={() =>
                                          this.addAccomodationCount(
                                            element.id,
                                            index,
                                            "adult"
                                          )
                                        }
                                      >
                                        {/* <img src="/images/plus.png" className={this.this.props.styles.hotel_accomodation_icon} /> */}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          id="plus"
                                        >
                                          <path
                                            fill="#00A4F3"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      this.props.styles
                                        .hotel_children_accomodation
                                    }
                                  >
                                    <div
                                      className={
                                        this.props.styles
                                          .hotel_children_accomodation_tag
                                      }
                                    >
                                      <p>Children</p>
                                      <span>(0-12 yrs)</span>
                                    </div>
                                    <div
                                      className={
                                        this.props.styles
                                          .hotel_accomodation_conf
                                      }
                                    >
                                      <span
                                        className={
                                          element.childrenMinusCountDisable
                                            ? this.props.styles
                                              .disbale_traveller +
                                            " " +
                                            this.props.styles
                                              .hotel_accomodation_icon
                                            : this.props.styles
                                              .hotel_accomodation_icon
                                        }
                                        onClick={() =>
                                          this.minusAccomodationCount(
                                            element.id,
                                            index,
                                            "children"
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          id="minus"
                                        >
                                          <path
                                            fill="#00A4F3"
                                            d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"
                                          ></path>
                                        </svg>
                                      </span>
                                      <div
                                        className={this.props.styles.ac_count}
                                      >
                                        {element.children}
                                      </div>
                                      <span
                                        className={
                                          element.childrenAddCountDisable
                                            ? this.props.styles
                                              .disbale_traveller +
                                            " " +
                                            this.props.styles
                                              .hotel_accomodation_icon
                                            : this.props.styles
                                              .hotel_accomodation_icon
                                        }
                                        onClick={() =>
                                          this.addAccomodationCount(
                                            element.id,
                                            index,
                                            "children"
                                          )
                                        }
                                      >
                                        {/* <img src="/images/plus.png" className={this.this.props.styles.hotel_accomodation_icon} /> */}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          id="plus"
                                        >
                                          <path
                                            fill="#00A4F3"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.props.styles.child_row_container
                                  }
                                  >
                                {element.childDetails.length > 0 &&
                                  element.childDetails.map((item, ind) => {
                                    return (
                                        <div
                                          className={
                                            this.props.styles.child_row_main
                                          }
                                          key={ind}
                                        >
                                          <span>
                                            <label>
                                              Child {ind + 1} Age
                                            </label>
                                            <input
                                              type="text"
                                              className={
                                                this.props.styles.selected_age
                                              }
                                              placeholder="Select Age"
                                              value={item.age}
                                              onClick={() =>
                                                this.isChildrenAge(
                                                  index,
                                                  ind,
                                                  "isOpen"
                                                )
                                              }
                                              readOnly
                                            />
                                            {item.isError === true ? (
                                              <span
                                                className={
                                                  this.props.styles
                                                    .err_message_text
                                                }
                                              >
                                                Please enter valid age
                                              </span>
                                            ) : null}
                                            <span
                                              className={
                                                this.props.styles
                                                  .child_age_select_arrow
                                              }
                                              onClick={() =>
                                                this.isChildrenAge(
                                                  index,
                                                  ind,
                                                  "isOpen"
                                                )
                                              }
                                            >
                                          <svg width="20px" height="24px" viewBox="0 0 200 200"><polygon points="180.8,40.25 19.2,40.25 100.202,166.5 "></polygon></svg>
                                            </span>
                                          </span>
                                          {item.isOpen === true ? (
                                            <div
                                              className={
                                                this.props.styles.age_list_cont
                                              }
                                            >
                                              <div
                                                className={
                                                  this.props.styles
                                                    .age_list_main
                                                }
                                              >
                                                {childAgeList.map((ele, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className={
                                                        this.props.styles
                                                          .age_list_ele
                                                      }
                                                      onClick={() =>
                                                        this.isChildrenAge(
                                                          index,
                                                          ind,
                                                          "clickAge",
                                                          ele
                                                        )
                                                      }
                                                    >
                                                      <span>{ele}</span>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                    );
                                  })}
                                  </div>
                              </div>
                            );
                          })}
                          {this.state.roomList.length < 4 ? (
                            <div
                              className={this.props.styles.hotel_add_rooms}
                              onClick={() => {
                                this.setState({
                                  roomList: [
                                    ...this.state.roomList,
                                    {
                                      id: this.state.roomList.length + 1,
                                      adults: 1,
                                      children: 0,
                                      adultAddCountDisable: false,
                                      adultMinusCountDisable: true,
                                      childrenAddCountDisable: false,
                                      childrenMinusCountDisable: true,
                                      childDetails: [],
                                    },
                                  ],
                                });
                              }}
                            >
                              <span> + </span>
                              <p style={{ paddingLeft: 3 }}>ADD ROOM </p>
                              {/* <span > - </span> */}
                            </div>
                          ) : null}
                        </div>
                        <div className={this.props.styles.done_button_main}>
                          <input
                            tabindex="4"
                            value="Done"
                            className={this.props.styles.done_button}
                            readOnly
                            onClick={() => this.onClickDoneButton()}
                            onBlur={this.focusOutDone}
                          />
                          {/* <button  className={this.props.styles.done_button} onClick={() => this.onClickDoneButton()}>Done</button> */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`${this.props.styles.hotel_search_details_row} ${this.props.styles.hotel_search_details_row_button}`}
                >
                  {!this.props.isSRP &&
                    this.props?.userProfile?.type === "B2B" && (
                      <div className={this.props.styles.nationality_cnt_wd}>
                        <div className={this.props.styles.nationality_wrap}>
                          {/* <label htmlFor="fname" className="lablename-htl">Select Nationality</label> */}
                          {/* <input type="text" className={this.props.styles.input_nationality_wd} name="fname" id="nationallity" placeholder="Select Nationality" onkeyup={{}} autoComplete="off" />
                                                <span className={this.props.styles.pg_down_arrow}> <i className="fa fa-caret-down" onClick={() => this.onClickNationality()} aria-hidden="true" /></span> */}
                          {/* <CountryDropdownComp
                                                    countryFlag={this.state.originCountries}
                                                    searchText={""}
                                                    selectCountry={this.onClickNationalityHandler}
                                                    selectedCountry={this.state.selectedNationality}
                                                    onChangeInputCountryCode={this.onChangeInputNationality}
                                                    placeholder={'Select Nationality'}
                                                    isSignUpNationality={false}
                                                    isB2B={true}
                                                    {...this.props.Countrystyles}
                                                // {...this.props.data.Countrystyles}
                                                /> */}
                          <NationalityDropdownComp
                            countryFlag={this.state.originCountries}
                            searchText={""}
                            selectNationality={this.selectNationality}
                            selectedNationality={this.state.selectedNationality}
                            onChangeNationalityCode={
                              this.onChangeNationalityCode
                            }
                            placeholder={"Select Nationality"}
                            isSignUpNationality={false}
                            isB2B={true}
                            errorMessage={"Select Nationality"}
                            isError={this.state.isError.selectedNationality}
                            styles={this.props.styles}
                            {...this.props.Countrystyles}
                          // {...this.props.data.Countrystyles}
                          />
                        </div>
                        {/* {this.state.nationallitySearch &&

                                                <div id="myNation" className="dropdown-content-Guest dropdown-content-nation dropdown-content-nation-htl">
                                                    <div className={this.props.styles.inputsearch_result}><input type="text" placeholder="Search.." id="myInputNation" onkeyup={{}} />
                                                    </div>
                                                    <div id="suggested_nation" />
                                                </div>
                                            } */}
                      </div>
                    )}
                  {/* <div className={`${this.props?.userProfile?.type === 'B2B' ? this.props.styles.hotel_search_class : this.props.styles.hotel_search_details_row_mg}`}> */}
                  <div
                    className={`${this.props.isSRP
                        ? this.props.styles.hotel_search_class
                        : this.props.styles.hotel_search_btnClass
                      }`}
                  >
                    <button
                      tabindex="5"
                      className={this.props.styles.hotel_search_widget_btn}
                      onClick={() => this.onClickSearchHotel()}
                    >
                      {this.props.isSRP ? "Modify Search" : "SEARCH HOTELS"}
                      {this.props.isSRP ?"":<svg width="200px" height="200px" viewBox="0 0 200 200"><path fill="#FFFFFF" d="M179,103c0,2.21-0.878,4.33-2.44,5.893l-58.334,58.333c-3.254,3.254-8.53,3.254-11.784,0 c-3.255-3.254-3.255-8.53,0-11.785l44.106-44.107H37.333c-4.603,0-8.333-3.73-8.333-8.333s3.731-8.333,8.333-8.333h113.214 l-44.106-44.108c-3.255-3.254-3.255-8.531,0-11.785c3.254-3.254,8.53-3.254,11.784,0l58.334,58.333 C178.122,98.67,179,100.79,179,103z"></path></svg>}
                    </button>
                    

  
{/* <svg  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="200px" height="200px" viewBox="0 0 200 200" enable-background="new 0 0 200 200">
<g>
	<path fill="#ffffff" d="M109.039,50.665C66.53,11.72,8.749,69.501,47.694,112.01c8.4,8.4,19.6,12.728,30.545,12.728
		C116.421,124.737,136.275,77.646,109.039,50.665z M105.221,108.192c-14.254,14.763-39.708,14.763-53.963,0
		C27.331,84.52,44.64,42.774,78.239,43.029C111.839,42.774,129.148,84.52,105.221,108.192z"/>
	<path fill="#ffffff" d="M169.875,155.537l-34.363-34.363c-3.055-3.055-7.382-3.563-10.437-1.527l-6.108-6.109
		c38.69-52.436-29.782-113.526-77.382-68.981c-20.363,20.363-20.363,53.2,0,73.308c18.582,18.582,48.363,20.109,68.727,4.073
		l6.364,6.363c-1.782,2.8-1.273,7.127,1.781,10.182l34.363,34.363c1.782,1.782,4.328,2.801,6.364,2.801
		c1.781,0,3.563-0.51,4.836-1.782l6.873-6.873C173.693,163.938,173.185,158.846,169.875,155.537z M45.149,114.301
		c-29.527-29.272-7.891-80.181,33.09-79.926c11.964,0,23.927,4.582,33.091,13.745C153.584,93.938,90.967,156.556,45.149,114.301z
		 M114.894,117.864c0.255-0.254,0.509-0.509,0.764-0.764l5.854,5.854l-1.527,1.527l-5.854-5.854
		C114.385,118.374,114.639,118.119,114.894,117.864z M167.33,163.174l-6.873,6.872c-0.764,0.764-2.546,0.509-4.072-1.018
		l-34.364-34.364c-1.781-2.036-1.526-3.563-0.254-4.836l5.091-5.091l1.018-1.019c1.273-1.018,2.801-0.254,4.073,1.019l34.363,34.363
		C167.839,160.628,168.094,162.41,167.33,163.174z"/>
</g>
</svg> */}
 



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
