import React, { Component } from 'react';

class PassengerCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      travelers:{ adults: this.props.travelers.adults, children: this.props.travelers.children, infants: this.props.travelers.infants  },
      show: false,
      passangerCount : this.props.travelers.adults + this.props.travelers.children + this.props.travelers.infants,
      maxPaxAllowed: this.props.maxPaxAllowed
    }

    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
  }

  componentDidMount() {
    this.setState({travelers: {
      ...this.state.travelers,
      adults: this.props.travelers.adults, 
      children: this.props.travelers.children, 
      infants: this.props.travelers.infants
    }})
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({travelers:{
        ...this.state.travelers,
        adults:nextProps.travelers.adults ,
        children:nextProps.travelers.children,
        infants:nextProps.travelers.infants
    },
    passangerCount:nextProps.travelers.adults+nextProps.travelers.children+nextProps.travelers.infants
    });
  }

  componentWillUnmount = () => {
    document.removeEventListener(
        'mousedown',
        this.handleClickOutside
    );
  };

  increment(key){
    this.setState({travelers:{
      ...this.state.travelers,
      [key]: this.state.travelers[key]+1
  }}, () => {
    this.setState({passangerCount:this.state.travelers.adults+this.state.travelers.children+this.state.travelers.infants});
  });
  };

  decrement(key){
    this.setState({travelers:{
      ...this.state.travelers,
      [key]:this.state.travelers[key]-1
  }}, () => {
    this.setState({passangerCount:this.state.travelers.adults+this.state.travelers.children+this.state.travelers.infants});
  });
  };

  setTravellerWrapperRef = node => {
    this.travelerWrapperRef = node;
  };

  handleClickOutside = event => {
    if (this.travelerWrapperRef && !this.travelerWrapperRef.contains(event.target)) {
        this.props.onTravellerData(this.state.travelers);
        if(this.state.show===true){
            this.setState({ show: false },()=>{
                this.onClickDone();
            });
        }
    }
  };

  onClickDone=()=>{
    // this.setState({passangerCount:this.state.travelers.adults+this.state.travelers.children+this.state.travelers.infants+this.state.travelers.seniorscount+this.state.travelers.youthscount+this.state.travelers.travelerscount,popupVisible:false});
    this.props.onTravellerData(this.state.travelers);
  }

  showOnFocus(bool) {
    this.setState({ show: bool })
  }

  render() {
    return (
      <div className={this.props.cssfile.passenger_counter}>
        <div className={this.props.cssfile.passenger_counter_input}>
        <label>No. of Adult, Child, Infant</label>
        <div className={this.props.cssfile.passenger_icon_main}>
        <span className={this.props.cssfile.passenger_icon}>
          <svg width="15px" height="15px" viewBox="0 0 200 200"><g><path fill="#01445f" d="M100,188.716c-48.918,0-88.716-39.798-88.716-88.716S51.082,11.284,100,11.284S188.716,51.082,188.716,100 S148.918,188.716,100,188.716z M45.883,162.507c14.516,12.586,33.442,20.209,54.117,20.209c20.675,0,39.602-7.624,54.118-20.209 c-1.134-27.888-38.089-30.935-54.118-30.935S47.016,134.62,45.883,162.507z M100,17.284c-45.61,0-82.716,37.106-82.716,82.716 c0,22.237,8.82,42.452,23.146,57.331c1.983-10.823,9.079-19.279,20.815-24.682c12.708-5.85,28.09-7.077,38.755-7.077 c10.666,0,26.048,1.228,38.756,7.077c11.736,5.402,18.832,13.858,20.814,24.682c14.326-14.88,23.146-35.094,23.146-57.331 C182.716,54.39,145.609,17.284,100,17.284z M100,103c-17.409,0-31.572-14.163-31.572-31.572c0-17.409,14.163-31.572,31.572-31.572 c17.409,0,31.572,14.163,31.572,31.572C131.572,88.837,117.409,103,100,103z M100,45.856c-14.1,0-25.572,11.472-25.572,25.572 C74.428,85.528,85.9,97,100,97c14.101,0,25.572-11.472,25.572-25.572C125.572,57.328,114.101,45.856,100,45.856z"></path></g></svg>
        </span>
          <input type='text' className={this.props.cssfile.selected_pax} value={`${this.state.travelers.adults} Adults, ${this.state.travelers.children} Child, ${this.state.travelers.infants} Infant`} onFocus={() => this.showOnFocus(true)} />
          </div>
        </div>
        {this.state.show ?
        <div className={this.props.cssfile.pax_start_div}>
          <div className={this.props.cssfile.pax_start_new} ref={this.setTravellerWrapperRef}>
          <PassengerSelector
            title="Adults"
            count={this.state.travelers.adults}
            onIncrement={() => this.increment('adults')}
            onDecrement={() => this.decrement('adults')}
            // showOnFocus={() => this.showOnFocus.bind(this)}
            props={this.props}
            passangerCount={this.state.passangerCount}
            maxPaxAllowed={this.state.maxPaxAllowed}
          />
          <PassengerSelector
            title="Children"
            count={this.state.travelers.children}
            onIncrement={() => this.increment('children')}
            onDecrement={() => this.decrement('children')}
            // showOnFocus={() => this.showOnFocus.bind(this)}
            props={this.props}
            passangerCount={this.state.passangerCount}
            maxPaxAllowed={this.state.maxPaxAllowed}
          />
          <PassengerSelector
            title="Infants"
            count={this.state.travelers.infants}
            onIncrement={() => this.increment('infants')}
            onDecrement={() =>this.decrement('infants')}
            // showOnFocus={() => this.showOnFocus.bind(this)}
            props={this.props}
            passangerCount={this.state.passangerCount}
            maxPaxAllowed={this.state.maxPaxAllowed}
          />
         
          </div>
          <div className={this.props.cssfile.button_wrapper}>
            <button type="button" className={this.props.cssfile.btn_done} onClick={() => this.showOnFocus(false)} >Done</button>
          </div>
        </div>
        :null
        }
      </div>
    );
  }
};

const PassengerSelector = ({ title, count, onIncrement, onDecrement, props, passangerCount, maxPaxAllowed }) => {
    return (
      <div className={props.cssfile.passenger_wrapper}>
        <div className={props.cssfile.passenger_selector}>
          <table className={props.cssfile.table_head}>
            <thead>
              <tr>
                <th>
                  {title}
                  <br />
                  <span className={props.cssfile.table_subhead}>{" "}{title == 'Adults' ? '12+ yrs' : title == 'Children' ? '2-12 yrs' : '0-2 yrs'}</span>
                </th>
              </tr>
            </thead>
          </table>
          <div className={props.cssfile.hotel_room_detail_ele}>
            <table className={props.cssfile.table_body}>
              <tr>
                <td>
                  <div className={props.cssfile.hotel_accomodation_conf}>
                    <span className={title != 'Adults' ? (count > 0 ? props.cssfile.hotel_accomodation_icon : props.cssfile.disbale_traveller + " " + props.cssfile.hotel_accomodation_icon ) : (count > 1 ? props.cssfile.hotel_accomodation_icon : props.cssfile.disbale_traveller + " " + props.cssfile.hotel_accomodation_icon)} onClick={onDecrement}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="minus">
                        <path
                          fill="#00A4F3"
                          d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"
                        ></path>
                      </svg>
                    </span>
                    <div className={props.cssfile.ac_count}>{count}</div>
                    <span
                      className={passangerCount < maxPaxAllowed ? props.cssfile.hotel_accomodation_icon : props.cssfile.disbale_traveller + " " + props.cssfile.hotel_accomodation_icon}
                      onClick={onIncrement}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="plus">
                        <path
                          fill="#00A4F3"
                          d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default PassengerCounter;
