import Theme1 from "./theme1";
import Theme2 from "./theme2";
import Theme4 from "./theme4";
import Theme5 from "./theme5";
import Theme7 from "./theme7";

export default function SearchWidget(props) {
    const theme = props.pageTheme;
    switch (theme) {
        case 'theme1':
            return <Theme1 {...props} />
        case 'theme2':
            return <Theme2 {...props} />    
        case 'theme4':
            return <Theme4 {...props} />
        case 'theme5':
            return <Theme5 {...props} />
        case 'theme7':
            return <Theme7 {...props} />         
        default:
            return <Theme1  {...props}/>
    }
}