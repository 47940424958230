import React from 'react';
import commonFunction from '../../../../lib/utils/common';

export default function FlightAutoComplete(props) {
    const onClickHandler = (e) => {
        // if (props.expandAutoCompleteBool === true) props.isAutoCompleteExpand(false)
        // else if (props.expandAutoCompleteBool === false) {
        props.onclickFlightAutoComplete()
        props.isAutoCompleteExpand(true)
        // }
    }

    const selectListClickHandler = (element) => {
        props.onClickSourceAndDestination(element.CITY_NAME, element.AIRPORT_CODE,element.AIRPORT_NAME)
        props.isAutoCompleteExpand(false)
    }

    return (
        <div className={props.styles.flight_search_wid_inputfield}>
            <label>{props.label}</label>
            {/* <img src={props.leftIcon} className={props.styles.flight_search_left_icon} /> */}
            <span className={props.styles.flight_search_left_icon}>
                {props.leftIcon}
            </span>
            <input type='text' autoComplete={'off'} id="myTextField"
                placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
                className={props.styles.search_widget_input_new}
                readOnly
                value={!commonFunction.isValueEmpty(props.value) && (!props.expandAutoCompleteBool) ? props.value : ""}
                // onChange={(event) => { props.onChangeSourceAndDestination(event.target.value) }}
                onClick={onClickHandler}
                // onFocusCapture={onClickHandler}
                // tabIndex="2"
                // onKeyDown={props.onKeyDownArrow}
            />
            <div className={props.styles.flight_ptext}>
            <p>{!commonFunction.isValueEmpty(props.airPortName) ? props.airPortName : ""}</p>
            </div>
            {!commonFunction.isValueEmpty(props.isError) && props.isError === true ? (
                <div className={props.styles.flight_search_error_message}><span>{!commonFunction.isValueEmpty(props.errorMessage) ? props.errorMessage : 'Invalid field'}</span></div>
            ) : null}
            {props.expandAutoCompleteBool === true ? (
                <div ref={props.listWrapper} tabIndex="2" className={props.styles.flight_autocomplete_value} >

                      <div className={props.styles.flight_search_inpbox}>
                            <svg width="50px" height="50px" viewBox="0,0,256,256"><g fill="#3479c5" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path></g></g></svg>
                            <input type='text' autoComplete={'off'} id="myTextField"
                                placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
                                className={props.styles.search_widget_input_new}
                                autoFocus
                                value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
                                onChange={(event) => { props.onChangeSourceAndDestination(event.target.value) }}
                                onClick={onClickHandler}
                                onFocusCapture={onClickHandler}
                                tabIndex="2"
                                onKeyDown={props.onKeyDownArrow}
                            />
                        </div>
                        
                    <div className={props.styles.autocomp_container} id="flightDataArray">
                        {!commonFunction.isValueEmpty(props.autoCompleteData) && props.autoCompleteData.length > 0 &&
                            props.autoCompleteData.map((element, i) => {
                                return (
                                    <>
                                    <div id='flightData' key={"autoCompleteData" + (i + 1)} className={props.styles.flight_autocomplete_element}
                                        onClick={() => { selectListClickHandler(element) }}
                                        tabIndex="2">
                                            <div className={props.styles.flgt_auto_icon}>
                                                {/* <img src="theme2/flight_iconm.svg" alt="" /> */}
                                                <svg width="15" height="15" viewBox="0 0 18.344 18.363">
                                                    <path id="Path_1075" data-name="Path 1075" d="M133.626,72.322a1.484,1.484,0,0,0-1.649.683,1.531,1.531,0,0,0-.141,1.2,1.578,1.578,0,0,0,.8.9l2.591,1.178a.92.92,0,0,1,.471.471l1.178,2.591a1.578,1.578,0,0,0,.9.8l.448.071a1.459,1.459,0,0,0,.754-.212,1.484,1.484,0,0,0,.683-1.649l-.565-2.1,3.416-3.11,2.262,4.193h0a2.332,2.332,0,0,0,1.7,1.178h.33a2.285,2.285,0,0,0,2.214-2.921l-.72-2.539-1.164-4.1,2.12-1.932h0a3.04,3.04,0,0,0-4.335-4.264L143,64.854l-6.643-1.885h0a2.356,2.356,0,0,0-2.262.589,2.238,2.238,0,0,0-.636,1.955,2.332,2.332,0,0,0,1.178,1.7l4.193,2.261-3.11,3.416Zm14.464,3.557a1.295,1.295,0,0,1-.353,1.319,1.272,1.272,0,0,1-1.131.377,1.3,1.3,0,0,1-1.013-.683l-2.356-4.405,3.109-2.8Zm-13-9.493a1.3,1.3,0,0,1-.683-1.013,1.272,1.272,0,0,1,.377-1.131,1.3,1.3,0,0,1,1.319-.353l6.2,1.743-2.8,3.109Zm1.154,7.35,9.352-10.341a2.167,2.167,0,0,1,1.484-.612,2.215,2.215,0,0,1,1.508.612,2.144,2.144,0,0,1,0,2.968l-10.341,9.375a.5.5,0,0,0-.141.471l.636,2.4a.565.565,0,0,1-.235.589.59.59,0,0,1-.448.047.471.471,0,0,1-.306-.283l-1.2-2.615a1.933,1.933,0,0,0-.919-.919l-2.615-1.2a.471.471,0,0,1-.283-.306.59.59,0,0,1,.047-.448.636.636,0,0,1,.471-.259h.118l2.4.66a.5.5,0,0,0,.471-.141Z" transform="translate(-131.772 -61.85)" fill="#4f4f54"></path>
                                                </svg>
                                            </div>
                                        <div className={props.styles.flight_autocomplete_name}>{element.CITY_NAME}, {element.COUNTRY_NAME}
                                            <span>{element.AIRPORT_NAME}</span>
                                        </div>
                                        <div className={props.styles.flight_autocomplete_tag}>{element.AIRPORT_CODE}</div>
                                    </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            ) : null
            }
        </div>
    )
}