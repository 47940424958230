import React, { Component, createRef } from "react";
import {
  addDays,
  differenceInCalendarDays,
  differenceInMonths,
  endOfMonth,
  format,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isFriday,
  isMonday,
  isSameDay,
  isSaturday,
  isThursday,
  isTuesday,
  isWednesday,
  startOfMonth,
  startOfToday,
  subDays,
} from "date-fns";

import cf from "../../../../lib/utils/common";
import moment from "moment";

// import styles from "./calendar.module.css";

const weekList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const weekday = ["Sun" ,"Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export default class DesktopCalender extends Component {
  constructor(props) {
    super(props);
    this.calendarWrapper = createRef();
    this.DateCellWrapper = createRef();
    this.state = {
      dateFormat: !cf.isValueEmpty(this.props.dateFormat)
        ? this.props.dateFormat
        : "",
      numberOfClicks: 0,
      twoCalendarsArray: [],
      isDoubleSelection: !cf.isValueEmpty(this.props.doubleSelection)
        ? this.props.doubleSelection
        : false,
      dateLimitArr: this.props.startDateLimit,
      currentMonths: [getMonth(startOfMonth(new Date()))],
      selectedMonthStartDate: startOfMonth(startOfToday()),
      selectedMonthEndDate: endOfMonth(startOfMonth(new Date())),
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      hoverEndDate: this.props.endDate,
      totalDays: "",
      pageX: "",
      pageY: "",
      initPageX: "",
      initPageY: "",
      dateStart: "",
      dateEnd: "",
      day:this.props?.startDate ? new Date(this.props.startDate) : "",
      // newDay:day.slice(-2)
    };
  }
  componentDidMount() {
    // console.log("🚀 ~ file: DesktopCalender.js:56 ~ DesktopCalender ~ componentDidMount ~ componentDidMount:5", this.props)

    this.setState({
        currentMonths: this.props.type === "double" ? !cf.isValueEmpty(this.state.dateLimitArr) ?
            [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))),
            getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))) + 1] :
            [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1] : !cf.isValueEmpty(this.state.dateLimitArr) ?
            [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd'))))] :
            [getMonth(startOfMonth(new Date()))],
        selectedMonthStartDate: !cf.isValueEmpty(this.state.startDate) ? startOfMonth(new Date(this.specDateEle(this.state.startDate, 'y'), this.specDateEle(this.state.startDate, 'm'), this.specDateEle(this.state.startDate, 'd'))) : startOfMonth(startOfToday()),
    })
    document.addEventListener("mousedown", this.handleClickOutside, false);
    document.addEventListener("keydown", this.handleTabOutside, false);
    window.addEventListener("mousemove", this.logMousePosition, false);
}
  componentDidUpdate() {
    if (this.props.endDate !== this.state.endDate) {
      this.setState({ endDate: this.props.endDate });
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
    document.removeEventListener("keydown", this.handleTabOutside, false);
    document.removeEventListener("mousemove", this.logMousePosition, false);
  }

//   componentWillReceiveProps(nextProps) {
//     if (nextProps.startDateLimit ) {
//         this.setState({
//             dateLimitArr:nextProps.startDateLimit,
//             startDate:nextProps.startDate
//         },()=>{
//             this.setState({
//                 currentMonths: this.props.type === "double" ?
//                  !cf.isValueEmpty(this.state.dateLimitArr) ?
//                     [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))),getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))) + 1] :
//                     [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1] : !cf.isValueEmpty(this.state.dateLimitArr) ?
//                     [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd'))))] :
//                     [getMonth(startOfMonth(new Date()))],
//                     selectedMonthStartDate: !cf.isValueEmpty(this.state.startDate) ? startOfMonth(new Date(this.specDateEle(this.state.startDate, 'y'), this.specDateEle(this.state.startDate, 'm'), this.specDateEle(this.state.startDate, 'd'))) : startOfMonth(startOfToday()),
//                 })
//         })
//     }
// }
  handleTabOutside = (e) => {
    if (e.key !== "Tab") {
      return;
    }
    if (
      this.calendarWrapper.current &&
      !this.calendarWrapper.current.contains(event.target)
    ) {
      this.props.changeVisibility(false);
    }
  };
  handleClickOutside = (event) => {
    if (
      this.calendarWrapper.current &&
      !this.calendarWrapper.current.contains(event.target)
    ) {
      this.props.changeVisibility(false);
    }
  };
  logMousePosition = (event) => {
    if (this.props.isVisible) {
      if (
        this.calendarWrapper.current &&
        !this.calendarWrapper.current.contains(event.target)
      ) {
      }
    }
  };
  specDateEle(date, type) {
    try {
      let getDate = cf.isValueEmpty(this.state.dateFormat)
        ? moment(new Date(date)).format("DD/MMM/yyyy")
        : moment(date).format(this.state.dateFormat);
      let getYear =
        typeof date === "string"
          ? new Date(getDate).getFullYear()
          : date.getFullYear();
      let getMonth =
        typeof date === "string"
          ? new Date(getDate).getMonth()
          : date.getMonth();
      let getDay =
        typeof date === "string" ? new Date(getDate).getDate() : date.getDate();
      switch (type) {
        case "y":
          return getYear;
        case "m":
          return getMonth;
        case "d":
          return getDay;
        case "a":
          return [getYear, getMonth, getDay];
        default:
      }
    } catch (err) {
      console.log("catch yyyyy", err);
    }
  }
  onClickChangeVisibility = () => {
    this.props.changeVisibility(/* !this.props.isVisible */ true);
    // this.setState({
    //   isVisible: true,
    // });
    // console.log("onClickChangeVisibility",this.state.numberOfClicks,"this.state.numberOfClicks")
    // this.setState({
    //   selectedMonthStartDate: this.state.selectedMonthStartDate,
    //   selectedMonthEndDate: this.state.selectedMonthEndDate,
    // })
  };
  onClickPrevNextArrow = (type) => {
    let startMonth = subDays(this.state.selectedMonthStartDate, 1);
    let endMonth = addDays(this.state.selectedMonthEndDate, 1);
    if (this.props.type === "double") {
      if (type === "prev") {
        this.state.currentMonths[1] = getMonth(startMonth) - 1;
      } else {
        this.state.currentMonths[1] = getMonth(endMonth) + 1;
      }
    }
    if (type === "prev") {
      this.state.currentMonths[0] =
        getMonth(this.state.selectedMonthStartDate) - 1;
      this.setState({
        currentMonths: [...this.state.currentMonths],
        selectedMonthStartDate: startOfMonth(startMonth),
        selectedMonthEndDate: endOfMonth(startMonth),
      });
    } else {
      this.state.currentMonths[0] =
        getMonth(this.state.selectedMonthStartDate) - 1;
      this.setState({
        currentMonths: [...this.state.currentMonths],
        selectedMonthStartDate: startOfMonth(endMonth),
        selectedMonthEndDate: endOfMonth(endMonth),
      });
    }
  };
  onClickDateCell = (day, month, year, bool) => {
    // let isAfterThirtyNights = isAfter(
    //   new Date(year, month, j + 1),
    //   addDays(new Date(this.props.startDate), 30)
    // );
    if (!bool) {
      //new added
      if (this.state.isDoubleSelection === true) {
        let date = new Date(year, month, day);
        if (this.state.numberOfClicks === 0) {
          this.setState({ numberOfClicks: 1, totalDays: "" }, () => {
            this.props.onChangeDate(date, "startDate");
            this.props.onChangeDate("", "endDate");
          });
        }
        if (this.state.numberOfClicks === 1) {
        // if (this.state.numberOfClicks === 1&&!isAfterThirtyNights) {
          let differenceInDays = differenceInCalendarDays(
            new Date(date),
            new Date(this.props.startDate)
          );
          this.setState({ numberOfClicks: 0, totalDays: "" }, () => {
            if (differenceInDays < 0) {
              this.props.onChangeDate(date, "startDate");
              this.props.onChangeDate("", "endDate");
            } else {
              this.setState({ numberOfClicks: 0 }, () => {
                this.props.onChangeDate(date, "endDate");
                this.props.changeVisibility(!this.props.isVisible);
              });
            }
          });
        }
      } else {
        if (bool === false) {
          let date = new Date(year, month, day);
          this.props.onChangeDate(date);
          this.props.changeVisibility(!this.props.isVisible);
        }
      }
    }
  };

  cleanValue = (value) => {
    return String(value).replace(/<[^>]*>/g, "").trim();
  };

  sanitizedValue = (value) => this.cleanValue(value);

  renderCalendar() {
    let temp = this.state.selectedMonthStartDate;
    let twoCalendarsArray = [];
    for (let i = 0; i < this.state.currentMonths.length; i++) {
      let StartDateOfMonth = temp;
      let monthHead = addDays(StartDateOfMonth, i);
      let endDateOfMonth = endOfMonth(monthHead);
      let dayDifference = differenceInCalendarDays(endDateOfMonth, monthHead);
      let days = [];
      let month = getMonth(monthHead);
      let year = getYear(monthHead);
      let startOfMonthDay = isMonday(monthHead)
        ? 0
        : isTuesday(monthHead)
        ? 1
        : isWednesday(monthHead)
        ? 2
        : isThursday(monthHead)
        ? 3
        : isFriday(monthHead)
        ? 4
        : isSaturday(monthHead)
        ? 5
        : 6;
      let startDateLimit = this.props.startDateLimit;
      let isHoliday = this.props.isHoliday ? this.props.isHoliday : false;
      let holidayStartDate = this.props.holidayStartDate
        ? this.props.holidayStartDate
        : "";
      let holidayEndDate = this.props.holidayEndDate
        ? this.props.holidayEndDate
        : "";
      let startDate = this.props.startDate;
      let endDate = this.state.endDate;
      let hoverDate = this.state.hoverEndDate;
      // this.setState({
      //   dateStart:startDate,
      //   dateEnd:endDate
      // });
      for (let k = 0; k < startOfMonthDay; k++) {
        days = [
          ...days,
          {
            day: "",
            month: month,
            year: year,
            isDisable: true,
            selectedDate: false,
            highlight: false,
          },
        ];
      }
      for (let j = 0; j <= dayDifference; j++) {
        let isBeforeDate = isBefore(
          new Date(year, month, j + 1),
          new Date(
            this.specDateEle(startDateLimit, "y"),
            this.specDateEle(startDateLimit, "m"),
            this.specDateEle(startDateLimit, "d")
          )
        );
        let disableDate = false;
        if (isHoliday) {
          let isHLDBeforeDate = isBefore(
            new Date(year, month, j + 1),
            new Date(
              this.specDateEle(holidayStartDate, "y"),
              this.specDateEle(holidayStartDate, "m"),
              this.specDateEle(holidayStartDate, "d")
            )
          );
          let isHLDAfterDate = isAfter(
            new Date(year, month, j + 1),
            new Date(
              this.specDateEle(holidayEndDate, "y"),
              this.specDateEle(holidayEndDate, "m"),
              this.specDateEle(holidayEndDate, "d")
            )
          );
          disableDate =
            isHLDBeforeDate == true || isHLDAfterDate == true ? true : false;
        }
        let isAfterThirtyNights = isAfter(
          new Date(year, month, j + 1),
          addDays(new Date(this.props.startDate), 30)
        );
        // console.log("isAfterThirtyNights", isAfterThirtyNights,"this.props.startDate",this.props.startDate,"this.props.isVisible",this.props.isVisible);
        if (isBeforeDate === true || disableDate == true || isAfterThirtyNights == true&&this.state.numberOfClicks!==0) {
          days = [
            ...days,
            {
              day: j + 1,
              month: month,
              year: year,
              isDisable: true,
              selectedDate: false,
              highlight: false,
            },
          ];
        } else {
          days = [
            ...days,
            {
              day: j + 1,
              month: month,
              year: year,
              isDisable: false,
              selectedDate:
              year == this.specDateEle(startDate, "y") &&
              month == this.specDateEle(startDate, "m") &&
              j + 1 == this.specDateEle(startDate, "d")
                ? true
                : false,
            highlight:
              year == this.specDateEle(hoverDate, "y") &&
              month == this.specDateEle(hoverDate, "m") &&
              j + 1 == this.specDateEle(hoverDate, "d")
                ? true
                : false,
            },
          ];
        }
      }
      if (this.state.isDoubleSelection === true) {
        let startDateIndex = days.findIndex(
          (items) =>
            !cf.isValueEmpty(items.day) &&
            new Date(items.year, items.month, items.day).toString() ===
              new Date(
                this.specDateEle(startDate, "y"),
                this.specDateEle(startDate, "m"),
                this.specDateEle(startDate, "d")
              ).toString()
        );
        let endDateIndex = days.findIndex(
          (items) =>
            !cf.isValueEmpty(items.day) &&
            new Date(items.year, items.month, items.day).toString() ===
              new Date(
                this.specDateEle(endDate, "y"),
                this.specDateEle(endDate, "m"),
                this.specDateEle(endDate, "d")
              ).toString()
        );
        if (startDateIndex !== -1) {
          days[startDateIndex].selectedDate = true;
        }
        if (endDateIndex !== -1) {
          days[endDateIndex].selectedDate = true;
        }
      } else {
        let SelectedDate = this.props.value;
        let selectedDateIndex = days.findIndex(
          (items) =>
            new Date(items.year, items.month, items.day).toString() ===
            new Date(
              this.specDateEle(SelectedDate, "y"),
              this.specDateEle(SelectedDate, "m"),
              this.specDateEle(SelectedDate, "d")
            ).toString()
        );
        if (selectedDateIndex !== -1) {
          days[selectedDateIndex].selectedDate = true;
        }
      }
      temp = endDateOfMonth;
      twoCalendarsArray.push(
        <div className={this.props.styles?.calendar_whole_body}>
          <div className={this.props.styles?.calendar_head}>
            <div className={this.props.styles?.calendar_head_center_side}>
              {/* <h3>{format(monthHead, "MMMM")}{' '}<span>{format(monthHead, "yyyy")}</span></h3> */}
              <h3 dangerouslySetInnerHTML={{ __html: this.sanitizedValue(format(monthHead, "MMMM yyyy")) }} />

            </div>
          </div>
          <div className={this.props.styles?.calendar_body}>
            <div className={this.props.styles?.calendar_week_heads}>
              {weekList.map((ele, idx) => {
                return (
                  <div
                    className={this.props.styles?.coln_center_week}
                    key={"weekl" + idx}
                  >
                    {ele}
                  </div>
                );
              })}
            </div>
            <div className={this.props.styles?.calendar_day_list}>
              {days.map((ele, idx) => {
                let isHighLight = false;
                let isHoverDate = false;
                if (this.state.isDoubleSelection === true) {
                  let isBeforeDate = isBefore(
                    new Date(ele.year, ele.month, ele.day),
                    new Date(
                      this.specDateEle(hoverDate, "y"),
                      this.specDateEle(hoverDate, "m"),
                      this.specDateEle(hoverDate, "d")
                    )
                  );
                  let isBeforeEndDate = isBefore(
                    new Date(ele.year, ele.month, ele.day),
                    new Date(
                      this.specDateEle(endDate, "y"),
                      this.specDateEle(endDate, "m"),
                      this.specDateEle(endDate, "d")
                    )
                  );
                  let isAfterDate = isAfter(
                    new Date(ele.year, ele.month, ele.day),
                    new Date(
                      this.specDateEle(startDate, "y"),
                      this.specDateEle(startDate, "m"),
                      this.specDateEle(startDate, "d")
                    )
                  );
                  isHoverDate = isSameDay(
                    new Date(ele.year, ele.month, ele.day),
                    new Date(
                      this.specDateEle(hoverDate, "y"),
                      this.specDateEle(hoverDate, "m"),
                      this.specDateEle(hoverDate, "d")
                    )
                  );
                  if (
                    (isAfterDate && isBeforeDate && cf.isValueEmpty(endDate)) ||
                    (isAfterDate &&
                      isBeforeEndDate &&
                      !cf.isValueEmpty(endDate))
                  ) {
                    isHighLight = true;
                  }
                }
                return (
                  <div
                    className={
                      ele.selectedDate === true && ele.day
                        ? this.props.styles.day_cell_center +
                          " " +
                          this.props.styles.selected_calendar_date
                        : isHighLight === true && ele.day != ""
                        ? this.props.styles.day_cell_center_highlight
                        : this.props.styles.day_cell_center
                    }
                    key={i}
                    onClick={() =>
                      this.onClickDateCell(
                        ele.day,
                        ele.month,
                        ele.year,
                        ele.isDisable
                      )
                    }
                    onMouseEnter={(e) => this.onHoverDayCell(ele, e)}
                  >
                    {this.state.isDoubleSelection === true &&
                      !cf.isValueEmpty(ele.day) &&
                      ele.isDisable === false &&
                      !cf.isValueEmpty(hoverDate) &&
                      isHoverDate &&
                      !cf.isValueEmpty(this.state.totalDays) && (
                        <div class={this.props.styles?.container_tooltip}>
                          {this.state.totalDays} Night
                        </div>
                      )}
                    <span
                      className={
                        ele.isDisable === true
                          ? this.props.styles.calendar_day_disable
                          : ""
                      }
                    >
                      {ele.day}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    return twoCalendarsArray;
  }
  onHoverDayCell = (currentDate, e) => {
    let date = moment(
      new Date(currentDate.year, currentDate.month, currentDate.day)
    ).format(this.props.dateFormat ? this.props.dateFormat : "DD/MMM/yyyy");
    this.setState({
      hoverEndDate: date,
    });
    if (
      !cf.isValueEmpty(this.props.startDate) &&
      cf.isValueEmpty(this.state.endDate)
    ) {
      this.setState(
        {
          hoverEndDate: date,
          pageX: Number(e.clientX) - Number(this.state.initPageX),
          pageY: Number(e.clientY) - Number(this.state.initPageY),
        },
        () => {
          let difference = differenceInCalendarDays(
            new Date(currentDate.year, currentDate.month, currentDate.day),
            new Date(this.props.startDate)
          );
          if (difference > 0) this.setState({ totalDays: difference });
          else this.setState({ totalDays: "" });
        }
      );
    }
  };
  onMouseMoveCalendar = (e, eventUsed) => {
    switch (eventUsed) {
      case "onMouseEnter":
        this.setState({ initPageX: e.clientX, initPageY: e.clientY });
        break;
      default:
        break;
    }
    e.preventDefault();
  };

  render() {
    return (
      <div className={this.props.styles?.calendar_wid_container}>
        <label>{this.props.label}</label>
        <div className={this.props.styles?.calendar_wid_main}>
          {!cf.isValueEmpty(this.props.iconImage) ? (
            // <img src={this.props.iconImage} className={this.props.styles?.flight_search_wid_inputfield_img} />
            <span
              className={this.props.styles?.flight_search_wid_inputfield_img}
              onClick={() => this.onClickChangeVisibility()}
            >
              {/* {this.props.iconImage} */}
            </span>
          ) : null}
          {this.state.isDoubleSelection === true ? (
            <>
              {this.props.theme === "theme2" ? (
                <div
                  className={this.props.styles?.new_cal_main}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onClickChangeVisibility();
                  }}
                >
                  <div className={this.props.styles?.flex_cont_cal}>
                    <div className={this.props.styles?.drop_wrap}>
                      <label>Check-In</label>

                      <span className={`${this.props.styles?.hotel_dropdown} ${
                        this.props.isVisible === true ? this.props.styles?.arrow_rotate:""
                      }`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="11">
                            <path fill-rule="evenodd" fill="#266AAC" d="m17.373 2.754-7.394 7.457c-.139.14-.3.243-.472.312a1.388 1.388 0 0 1-1.513-.31L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.442 0 1.991z"/>
                        </svg>
                      </span>

                    </div>
                    <div className={this.props.styles?.new_cal_digit}>
                      <h2 className={this.props.styles?.new_cal_digit_text}>
                       <span> {this.props.value ? this.props.value.split("-")[0] : ""}{' '}</span>
                       <span>{this.props.value ? this.props.value.split("-")[1] : ""}</span>
                       {`, `}
                       <span>{this.props.value ? weekday[(this.props?.startDate ? new Date(this.props.startDate) : "").getDay()]:""}</span>
                      </h2>
                    </div>
                    {/* <div className={this.props.styles?.new_cal_text}>
                      <p className={this.props.styles?.new_cal_month}>
                        {this.props.value ? this.props.value.split("-")[1] : ""}
                      </p>
                      <p className={this.props.styles?.new_cal_year}>
                        {this.props.value ? this.props.value.split("-")[2] : ""}
                      </p>
                    </div> */}
                  </div>

                  <div className={this.props.styles?.flex_cont_cal}>
                    <div className={this.props.styles?.drop_wrap}>
                      <label>Check-Out</label>
                      <span className={`${this.props.styles?.hotel_dropdown} ${
                        this.props.isVisible === true ? this.props.styles?.arrow_rotate:""
                      }`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="11">
                            <path fill-rule="evenodd" fill="#266AAC" d="m17.373 2.754-7.394 7.457c-.139.14-.3.243-.472.312a1.388 1.388 0 0 1-1.513-.31L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.442 0 1.991z"/>
                        </svg>
                      </span>
                    </div>
                  
                    <div className={this.props.styles?.new_cal_digit}>
                      <h1 className={this.props.styles?.new_cal_digit_text}>
                       <span> {this.props.value ? this.props.value.split("-")[3] : ""}{' '}</span>
                       <span> {this.props.value ? this.props.value.split("-")[4] : ""}</span>
                       {`, `}
                       <span>{this.props.value ? weekday[(this.props?.endDate ? new Date(this.props.endDate) : new Date(this.state.day)).getDay()]:""}</span>
                      </h1>
                    </div>
                    {/* <div className={this.props.styles?.new_cal_text}>
                      <p className={this.props.styles?.new_cal_month}>
                        {this.props.value ? this.props.value.split("-")[4] : ""}
                      </p>
                      <p className={this.props.styles?.new_cal_year}>
                        {this.props.value ? this.props.value.split("-")[5] : ""}
                      </p>
                    </div> */}
                  </div>
                </div>
              ) : (
                <>
                  <input
                    type="text"
                    autoComplete={"off"}
                    id="myTextField"
                    className="search_widget_input_new"
                    readOnly
                    placeholder={
                      !cf.isValueEmpty(this.props.placholder)
                        ? this.props.placholder
                        : ""
                    }
                    value={
                      !cf.isValueEmpty(this.props.value) ? this.props.value : ""
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onClickChangeVisibility();
                    }}
                    onFocus={(e) => {
                      /* typeof this.props.onInputFocus === "function" ? this.props.onInputFocus() : () => { } */ e.stopPropagation();
                      this.onClickChangeVisibility();
                    }}
                    tabindex="2"
                  />
                </>
              )}
            </>
          ) : (
            <>
              {this.props.theme === "theme2" ? (
                <div
                  className={this.props.styles?.new_cal_main}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onClickChangeVisibility();
                  }}
                >
                  <div className={this.props.styles?.flex_cont_cal}>
                    <div className={this.props.styles?.new_cal_digit}>
                      <h1 className={this.props.styles?.new_cal_digit_text}>
                        {this.props.value ? this.props.value.split("-")[0] : ""}
                      </h1>
                    </div>
                    <div className={this.props.styles?.new_cal_text}>
                      <p className={this.props.styles?.new_cal_month}>
                        {this.props.value ? this.props.value.split("-")[1] : ""}
                      </p>
                      <p className={this.props.styles?.new_cal_year}>
                        {this.props.value ? this.props.value.split("-")[2] : ""}
                      </p>
                    </div>
                  </div>

                  <div className={this.props.styles?.flex_cont_cal}>
                    <div className={this.props.styles?.new_cal_digit}>
                      <h1 className={this.props.styles?.new_cal_digit_text}>
                        {this.props.value ? this.props.value.split("-")[3] : ""}
                      </h1>
                    </div>
                    <div className={this.props.styles?.new_cal_text}>
                      <p className={this.props.styles?.new_cal_month}>
                        {this.props.value ? this.props.value.split("-")[4] : ""}
                      </p>
                      <p className={this.props.styles?.new_cal_year}>
                        {this.props.value ? this.props.value.split("-")[5] : ""}
                      </p>
                    </div>
                  </div>
                </div>
              ) : this.props.theme === "flight_theme2" ? (
                <div
                  className={this.props.styles?.new_cal_main}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onClickChangeVisibility();
                  }}
                >
                  <div className={this.props.styles?.flex_cont_cal}>
                    <div className={this.props.styles?.new_cal_digit}>
                      <h1
                        className={
                          this.props.value === "Tap to add return"
                            ? this.props.styles.new_tap_text
                            : this.props.styles.new_cal_digit_text
                        }
                      >
                        {this.props.value ? this.props.value.split("-")[0] : ""}
                      </h1>
                    </div>
                    <div className={this.props.styles?.new_cal_text}>
                      <p className={this.props.styles?.new_cal_month}>
                        {this.props.value ? this.props.value.split("-")[1] : ""}
                      </p>
                      <p className={this.props.styles?.new_cal_year}>
                        {this.props.value ? this.props.value.split("-")[2] : ""}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <input
                    type="text"
                    autoComplete={"off"}
                    id={this?.props?.id || "" + "myTextField"}
                    className="search_widget_input_new"
                    readOnly
                    placeholder={
                      !cf.isValueEmpty(this.props.placholder)
                        ? this.props.placholder
                        : ""
                    }
                    value={
                      !cf.isValueEmpty(this.props.value) ? this.props.value : ""
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onClickChangeVisibility();
                    }}
                    onFocus={(e) => {
                      /* typeof this.props.onInputFocus === "function" ? this.props.onInputFocus() : () => { } */ e.stopPropagation();
                      this.onClickChangeVisibility();
                    }}
                    tabindex="2"
                  />
                </>
              )}
            </>
          )}
        </div>
        {this.props.isVisible === true ? (
          <div 
          ref={this.calendarWrapper}
          onMouseEnter={(e) => this.onMouseMoveCalendar(e, "onMouseEnter")}
          className={
            this.props.type === "double"
              ? this.props.styles.calendar_container +
                " " +
                this.props.styles.calendar_container_double
              : this.props.styles.calendar_container +
                " " +
                this.props.styles.calendar_container_single
          }>
            <div className={this.props.styles.calCheckin_cont}>
              <div className={this.props.styles.calCheckin_box}>
                <span className={this.props.styles.calChec_icon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18">
                      <path fill-rule="evenodd" fill="#474747" d="M14.532 2.084h-1.453V.676c0-.373-.289-.675-.646-.675-.356 0-.646.302-.646.675v1.408H5.213V.676c0-.373-.289-.675-.646-.675-.357 0-.646.302-.646.675v1.408H2.468C1.105 2.086 0 3.24-.001 4.664v10.754c.001 1.424 1.106 2.578 2.469 2.58h12.064c1.363-.002 2.467-1.156 2.469-2.58V4.664c-.002-1.424-1.106-2.578-2.469-2.58zM6.359 8.358v1.917H4.524V8.358h1.835zm2.991 0v1.917H7.651V8.358H9.35zm3.126 0v1.917h-1.834V8.358h1.834zm3.233 0v1.917h-1.941V8.358h1.941zM9.35 11.625v1.706H7.651v-1.706H9.35zm-6.118-1.35H1.291V8.358h1.941v1.917zm-1.941 1.35h1.941v1.706H1.291v-1.706zm3.233 0h1.835v1.706H4.524v-1.706zm1.835 3.056v1.967H4.524v-1.967h1.835zm1.292 0H9.35v1.967H7.651v-1.967zm2.991 0h1.834v1.967h-1.834v-1.967zm0-1.35v-1.706h1.834v1.706h-1.834zm3.126-1.706h1.941v1.706h-1.941v-1.706zm-11.3-8.191h1.453V4.1c0 .373.29.676.647.675.356 0 .645-.302.645-.675v-.666h6.574V4.1c0 .373.29.676.646.676.357 0 .646-.303.646-.676v-.666h1.453c.65.001 1.176.551 1.177 1.23v2.344H1.291V4.664c.001-.679.527-1.229 1.177-1.23zM1.291 15.418v-.737h1.941v1.967h-.764c-.65-.001-1.176-.552-1.177-1.23zm13.241 1.23h-.764v-1.967h1.941v.737c-.001.678-.527 1.229-1.177 1.23z"/>
                  </svg>
                </span>
               
                <div className={this.props.styles.calCheck_date}>{this.props?.startDate ? moment(new Date(this.props.startDate)).format("DD MMM YY") :""}</div>
              </div>
              <div className={this.props.styles.calCheckin_box}>
                <span className={this.props.styles.calChec_icon}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18">
                      <path fill-rule="evenodd" fill="#474747" d="M14.532 2.084h-1.453V.676c0-.373-.289-.675-.646-.675-.356 0-.646.302-.646.675v1.408H5.213V.676c0-.373-.289-.675-.646-.675-.357 0-.646.302-.646.675v1.408H2.468C1.105 2.086 0 3.24-.001 4.664v10.754c.001 1.424 1.106 2.578 2.469 2.58h12.064c1.363-.002 2.467-1.156 2.469-2.58V4.664c-.002-1.424-1.106-2.578-2.469-2.58zM6.359 8.358v1.917H4.524V8.358h1.835zm2.991 0v1.917H7.651V8.358H9.35zm3.126 0v1.917h-1.834V8.358h1.834zm3.233 0v1.917h-1.941V8.358h1.941zM9.35 11.625v1.706H7.651v-1.706H9.35zm-6.118-1.35H1.291V8.358h1.941v1.917zm-1.941 1.35h1.941v1.706H1.291v-1.706zm3.233 0h1.835v1.706H4.524v-1.706zm1.835 3.056v1.967H4.524v-1.967h1.835zm1.292 0H9.35v1.967H7.651v-1.967zm2.991 0h1.834v1.967h-1.834v-1.967zm0-1.35v-1.706h1.834v1.706h-1.834zm3.126-1.706h1.941v1.706h-1.941v-1.706zm-11.3-8.191h1.453V4.1c0 .373.29.676.647.675.356 0 .645-.302.645-.675v-.666h6.574V4.1c0 .373.29.676.646.676.357 0 .646-.303.646-.676v-.666h1.453c.65.001 1.176.551 1.177 1.23v2.344H1.291V4.664c.001-.679.527-1.229 1.177-1.23zM1.291 15.418v-.737h1.941v1.967h-.764c-.65-.001-1.176-.552-1.177-1.23zm13.241 1.23h-.764v-1.967h1.941v.737c-.001.678-.527 1.229-1.177 1.23z"/>
                  </svg>
                </span>
                <div className={this.props.styles.calCheck_date}>{this.state?.hoverEndDate ? moment(new Date(this.state?.hoverEndDate)).format("DD MMM YY") :""}</div>
              </div>
            
            </div>
          <div
            className={this.props.styles.calendarMain_container}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={this.props.styles?.calendar_main}
            >
              {getMonth(this.state.selectedMonthStartDate) !==
                getMonth(new Date()) ||
              (differenceInMonths(
                new Date(this.state.selectedMonthStartDate),
                new Date()
              ) < 12 &&
                differenceInMonths(
                  new Date(this.state.selectedMonthStartDate),
                  new Date()
                ) >= 1) ? (
                <div
                  className={this.props.styles?.calendar_head_left_side}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onClickPrevNextArrow("prev");
                  }}
                >
                  <span className={this.props.styles?.calendar_head_icon}>
                    {" "}
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width="25px" height="14px" viewBox="0 0 25 14" enable-background="new 0 0 25 14">
                    <path fill="#266AAC" d="M0.001,7.296c-0.043-0.099,0.834-0.205,0.834-0.314s-0.877-0.216-0.834-0.315  C0.041,6.583,0.093,6.506,0.158,6.44c0.01-0.012,0.014-0.025,0.025-0.037l6.391-6.168c0.336-0.318,0.862-0.318,1.197,0  c0.318,0.305,0.331,0.81,0.027,1.129c-0.01,0.009-0.018,0.018-0.027,0.026L2.824,6.165h21.265c0.468,0,0.847,0.366,0.847,0.817  c0,0.45-0.379,0.816-0.847,0.816H2.824l4.947,4.773c0.319,0.305,0.331,0.811,0.026,1.13c-0.009,0.009-0.017,0.018-0.026,0.026  c-0.335,0.319-0.861,0.319-1.197,0L0.183,7.559C0.106,7.484,0.044,7.395,0.001,7.296L0.001,7.296z"/>
                    </svg>{" "}
                  </span>
                </div>
              ) : null}
              {differenceInMonths(
                new Date(this.state.selectedMonthStartDate),
                new Date()
              ) < 11 ? (
                <div
                  className={this.props.styles?.calendar_head_right_side}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onClickPrevNextArrow("next");
                  }}
                >
                  <span className={this.props.styles?.calendar_head_icon}>
                    {" "}
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="14">
                        <path fill-rule="evenodd" fill="#266AAC" d="M24.934 6.668c.043.099-.834.205-.834.314 0 .109.877.216.834.315a.824.824 0 0 1-.157.227c-.01.011-.014.025-.025.036l-6.391 6.168a.868.868 0 0 1-1.197 0 .798.798 0 0 1 0-1.155l4.947-4.774H.846C.379 7.799 0 7.433 0 6.982c0-.45.379-.816.846-.816h21.265l-4.947-4.774a.799.799 0 0 1 0-1.156.868.868 0 0 1 1.197 0l6.391 6.169a.827.827 0 0 1 .182.263z"/>
                    </svg>{" "}
                  </span>
                </div>
              ) : null}
              {this.renderCalendar()}
            </div>
          </div>
          </div>
        ) : null}
      </div>
    );
  }
}
