import axios from "axios";
import config from "../../../config/config.json";
import { serialize } from "cookie";

export const getSiteData = async (req) => {
  try {
    //console.log("getSiteData host", req?.headers?.host);
    let host = config.BASE_URL;
    if (config?.HOST_FLOW) {
      //console.log("HOST_FLOW TRUE", req?.headers?.host);
      host = req?.headers?.host;
    } else {
      //console.log("HOST_FLOW FALSE", host);
    }
    //get the data according to the host
    let { data } = await axios.post(`${config.BASE_URL}/api/shared/getsite`, {
      host: host,
    });
    //console.log("data====>", data);
    if (data.length) {
      //set the site id in the header
      req.headers.site_id = data[0]._id;
      return data;
    }
  } catch (error) {
    //console.log("get the site data", error);
    return {};
  }
};

export const checkUserSession = async (req, res) => {
  try {
    let cookies = req.cookies;
    //check user access token in cookies
    if (!cookies.userToken) throw "No ACCESS TOKEN FOUND";
    let request = {
      accessToken: cookies.userToken,
      site_id: req?.headers?.site_id || "",
    };
    //check user session
    let { data: accessTokenResponse } = await axios.post(
      `${config.BASE_URL}/api/sso/checksession`,
      request,
      {
        headers: {
          "Content-Type": "application/json",
          site_id: req?.headers?.site_id || "",
        },
      }
    );
    //console.log("first accessTokenResponse", accessTokenResponse);
    //if accesstoke is expaired or invalid user refresh token
    if (!accessTokenResponse.status) {
      if (!cookies.refreshToken) throw "NO Refresh token";
      request = {
        site_id: req?.headers?.site_id || "",
        refreshToken: cookies.refreshToken,
      };
      //call refresh token api to get new access token and refresh token
      let { data: refreshTokenResponse } = await axios.post(
        `${config.BASE_URL}/api/sso/refreshtoken`,
        request,
        {
          headers: {
            "Content-Type": "application/json",
            site_id: req?.headers?.site_id || "",
          },
        }
      );
      res?.setHeader("Set-Cookie", [
        serialize("refreshToken", "", { maxAge: -1, path: "/" }),
        serialize("userToken", "", { maxAge: -1, path: "/" }),
      ]);
      //console.log("first refreshTokenResponse", refreshTokenResponse);
      //set the user token and the access token;
      if (refreshTokenResponse.status) {
        //set the new access token and refresh token
        res?.setHeader("Set-Cookie", [
          serialize("refreshToken", refreshTokenResponse.refreshToken, {
            path: "/",
          }),
          serialize("userToken", refreshTokenResponse.accessToken, {
            path: "/",
          }),
        ]);
        //call ckeck session again to validate the access token
        request = {
          accessToken: refreshTokenResponse.accessToken,
          site_id: req?.headers?.site_id || "",
        };
        //check user session again
        let { data: accessTokenResponse } = await axios.post(
          `${config.BASE_URL}/api/sso/checksession`,
          request,
          {
            headers: {
              "Content-Type": "application/json",
              site_id: req?.headers?.site_id || "",
            },
          }
        );
        //console.log("second accessTokenResponse", accessTokenResponse);
        //retur the user data
        return accessTokenResponse;
      }
      return accessTokenResponse;
    } else {
      return accessTokenResponse;
    }
  } catch (error) {
    //console.log("errorrrrr in checkUserSession", error);
    res?.setHeader("Set-Cookie", [
      serialize("refreshToken", "", { maxAge: -1, path: "/" }),
      serialize("userToken", "", { maxAge: -1, path: "/" }),
    ]);
    return {};
  }
};

export const getPagePath = async (req) => {
  try {
    let service_home = req.query.index ? "/" + req.query.index : req.params[0];
    //console.log("=========req service_home======>",service_home)
    let pagepath = "home";
    if (service_home == "/hotels") {
      pagepath = "hotel_home";
    } else if (service_home == "/flights") {
      pagepath = "flight_home";
    } else if (service_home == "/activities") {
      pagepath = "activity_home";
    } else if (service_home == "/holiday") {
      pagepath = "holiday_search";
    } else if (service_home == "/experiences") {
      pagepath = "experience_home";
    } else if (service_home == "/offline") {
      pagepath = "offline_home";
    }
    //console.log('=========pagepath======>', pagepath)
    return pagepath;
  } catch (error) {
    //console.log("get the site data", error);
    return "";
  }
};
