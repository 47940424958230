import React, { Component, useState } from "react";

import commonFunction from "../../../lib/utils/common";
import { debounce } from "lodash";
import { email } from "@/data/siteMetadata";
// import { getGuestList } from "../../../../common/lib/guestSuggest";
import {
  getColleague,
  addClient,
  updateClient,
} from "../../../../common/lib/colleagues/colleaguesAPI";
export default class ColleagueDetail extends Component {
  constructor(props) {
    super(props);
    let loginUser =
      this?.props?.props?.userProfile?.user_type === "CT"
        ? this.props.props.userProfile._id
        : "";
    this.state = {
      setAddGuest: [],
      setGuest: [],
      loginGuest: [{ loginUser }],
      EmployeeGuest: [],
      EmployeeGuestAdded: [],
      guestId: "",
      employeeguestId: [this.props.props.userProfile._id],
      isAddTab: true,
      showDefaultText: true,
    };
    this.myRef = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.addEmployGuest = this.addEmployGuest.bind(this);
  }

  searchGuest = debounce((event) => {
    let requestedData = {
      active: true,
      search_text: event.target.value,
      client_id: "",
      search_by_date: {
        from: "",
        to: "",
      },
      sort_by: {},
      offset: 0,
      limit: 10,
    };
    if (event.target.value) {
      getColleague(requestedData).then((data) =>
        // console.log("123==>",data),
      
        this.setState({
          setGuest: data?.list,
          // loginGuest: [{loginUser}],
          EmployeeGuest:event.target.value!=""? (data?.list).filter(
            (user) => user._id !== this.props.props.userProfile._id
          ): [],
          showDefaultText: false,
        })
      );
    } else {
      this.setState({
        setGuest: [],
        // loginGuest: [{loginUser}],
        EmployeeGuest: [],
        showDefaultText: false,
      })
    }
  }, 200);

  addGuest = async (
    first_name,
    last_name,
    userid,
    email,
    nationality,
    corporate_detail
  ) => {
    // console.log(first_name,last_name,userid,email)
    let isAdd = true;
    await this.setState({
      setAddGuest: [
        {
          f_name: first_name,
          l_name: last_name,
          mail: email,
          userid: userid,
          nationality: nationality,
          corporate_detail: corporate_detail,
        },
      ],
    });
    await this.setState({ guestId: userid });
    await this.props.props.addGuestId(this.state.guestId);
    await this.props.props.guestDetail(this.state.setAddGuest);
    (await this.props?.props?.clientNationality)
      ? this.props?.props?.clientNationality(nationality, userid, email, isAdd)
      : null;
    await this.setState({ isAddTab: false });
  };

  addEmployGuest = async (item) => {
    const updatedArray = this.state.EmployeeGuest.filter(
      (data) => data._id !== item._id
    );
    const sum =
      parseFloat(this.state.EmployeeGuestAdded.length) +
      parseFloat(this.state.loginGuest.length);
    const totalPassenger = parseFloat(this?.props?.props?.totalPax);

    if (totalPassenger > sum) {
      this.setState(
        (prevState) => ({
          EmployeeGuestAdded: [...prevState.EmployeeGuestAdded, item],
          EmployeeGuest: updatedArray,
          employeeguestId: [...prevState.employeeguestId, item._id],
        }),
        async () => {
          await this.props.props.addEmployeeGuestId(item._id);
          console.log(
            "EmployeeGuestAdded length after update:",
            this.state.EmployeeGuestAdded.length
          );
        }
      );
    } else {
      console.log("Cannot add more guests. Total passenger limit reached.");
    }
  };

  removeGuest = async (first_name, last_name, userid, email, nationality) => {
    let isAdd = false;
    this.setState({ setAddGuest: (this.state.setAddGuest = 0) });
    this.setState({ guestId: "" });
    await this.props.props.removeGuestId(this.state.guestId);
    (await this.props?.props?.clientNationality)
      ? this.props?.props?.clientNationality(nationality, userid, email, isAdd)
      : null;
    await this.setState({ isAddTab: true });
  };

  removeEmployeeGuest = async (item) => {
    console.log("item=====>", item, "this.state.EmployeeGuest 1=>", this.state.EmployeeGuest);
    const updatedArray = this.state.EmployeeGuestAdded.filter(
      (data) => data._id !== item._id
    );
    const updatedId = this.state.employeeguestId.filter(
      (id) => id !== item._id
    );

    this.setState((prevState) => ({
      EmployeeGuestAdded: updatedArray,
      EmployeeGuest: [...prevState.EmployeeGuest, item],
      employeeguestId: updatedId,
    }));
    // let isAdd = false;
    // this.setState({ setAddGuest: (this.state.setAddGuest = 0) });
    // this.setState({ guestId: "" });
    await this.props.props.removeEmployeeGuestId(updatedId);
    // (await this.props?.props?.clientNationality)
    //   ? this.props?.props?.clientNationality(nationality, userid, email, isAdd)
    //   : null;
    // await this.setState({ isAddTab: true });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  }

  handleOutsideClick(event) {
    const isClickInsideDropdown = this.myRef.current && this.myRef.current.contains(event.target);
    if (!isClickInsideDropdown && !this.props.props?.openTraveler) {
      this.setState({ EmployeeGuest: [], setGuest: [] });
    }
  }

  render() {
    let showTravText =
      this?.props?.props?.userProfile?.user_type === "ADMIN" ||
      this?.props?.props?.userProfile?.user_type === "TD" ||
      this.props.props.bookingTypeId == 2 ||
      this.props.props.bookingTypeId == 3
        ? true
        : false;
    return (
      <div
        ref={this.props.props.guestWrapper}
        className={this.props?.props?.styles?.collapsible_title_add}
      >
        <div className="flex">
          <div className="fname form-inputs addguestbtn-width" id="htlguestdiv">
            <div className={this.props.props.styles.addguestbtn}>
              <div className={this.props.props.styles.trav_flex}>
                {/* <label for="fname" className="lablename-htl">
                  {" "}
                  &nbsp;
                </label> */}
               <div className={this.props.props.styles.flexalign}>
               <span className={this.props.props.styles.travtext}>
                  {"  Traveler:"}{" "}
                </span>
                {this?.props?.props?.userProfile?.user_type === "CT"
                  ? this?.props?.props?.userProfile?.first_name && (
                      <p className={this.props.props.styles.travNameCap}>
                        {this?.props?.props?.userProfile?.first_name +
                          " " +
                          this?.props?.props?.userProfile?.last_name}
                          {this.props.props.employeeguestId.length > 1 ? ` + ${this.props.props.employeeguestId.length - 1}` : ''}
                      </p>
                    )
                  : this.state?.setAddGuest[0]?.f_name && (
                      <p className={this.props.props.styles.travNameCap}>
                        {(this.state?.setAddGuest[0]?.f_name).toUpperCase()}{" "}
                        {this.state?.setAddGuest[0]?.l_name
                          ? (this.state?.setAddGuest[0]?.l_name).toUpperCase()
                          : ""}
                      </p>
                    )}
               </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => this.props.props?.onClickTraveller()}
                  className={this.props.props.styles.adju_flex}
                >
                  <button
                    type="button"
                    className={this.props.props?.styles?.traveller_button}
                    id=""
                  >
                    {this.props.props.openTraveler ? (
                      <img src="/theme4/adv_minus.svg" />
                    ) : (
                      <img src="/theme4/adv_plus.svg" />
                    )}
                  </button>
                  <span className={this.props.props.styles.guesttext}>
                    {/* {this.props.props.selectedTripType == "exceptional"
                      ? "Add Guests"
                      : this?.props?.props?.userProfile?.user_type ==='CT' ? "Add Traveler"
                      : "Add Colleagues"} */}
                    {/* {this?.props?.props?.userProfile?.user_type ==='ADMIN'||this?.props?.props?.userProfile?.user_type ==='TD'||this.props.props.bookingTypeId==2||this.props.props.bookingTypeId==3?"Add Traveler":"Add Colleagues"} */}
                    {showTravText ? "Add Traveler" : "Add Colleagues"}
                  </span>
                </div>
              </div>
              {!commonFunction.isValueEmpty(this.props.props.isError) &&
              this.props.props.isError === true ? (
                <div
                  style={{
                    fontSize: "11px",
                    color: "#e8664e",
                    marginTop: "3px",
                  }}
                >
                  <span>
                    {!commonFunction.isValueEmpty(this.props.props.errorMessage)
                      ? this.props.props.errorMessage
                      : ""}
                  </span>
                </div>
              ) : null}
              <div id="guest_count" className="guest_counts" value=""></div>
              {this.props.props?.openTraveler && (
                <div
                  id="myGuest"
                  className={this.props.props.styles.dropdown_Guest_container}
                  ref={this.myRef}
                >
                  <div className={this.props.props.styles.inputsearch}>
                   <label>Type the name or email address</label>
                    <input
                      type="text"
                      id="myInputNation"
                      onkeyup="searchFuest(event)"
                      onChange={(event) => this.searchGuest(event)}
                      placeholder={
                        showTravText ? "Search Traveler" : "Search Colleagues"
                      }
                    />
                  </div>
                  {this?.props?.props?.userProfile?.user_type === "CT" ? (
                    <div
                      id="guestlist"
                      className={this.props.props.styles.gest_container}
                    >
                      {(this.state?.EmployeeGuestAdded?.length > 0 ||
                        this.state.loginGuest.length > 0) && (
                        <div className={this.props.props.styles.travel_gest}>
                          <p>{showTravText ? "Selected Traveler" : " Selected Colleagues"}</p>
                          <div
                            className={this.props.props.styles.travlr_scroll}
                          >
                            <div
                              className={this.props.props.styles.travlr_wrap}
                            >
                              <div
                                className={this.props.props.styles.travlr_name}
                              >
                                <span>
                                  {this?.props?.props?.userProfile?.email}
                                </span>
                                <br />
                                <span
                                  className={
                                    this.props.props.styles.client_name
                                  }
                                >
                                  {this?.props?.props?.userProfile?.first_name +
                                    " " +
                                    this?.props?.props?.userProfile?.last_name}
                                </span>
                              </div>
                            </div>
                            {this.state?.EmployeeGuestAdded?.map((item) => (
                              <div
                                className={this.props.props.styles.travlr_wrap}
                              >
                                <div
                                  className={
                                    this.props.props.styles.travlr_name
                                  }
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    this.removeEmployeeGuest(item);
                                  }}
                                >
                                  <span>{item.email}</span>
                                  <br />
                                  <span
                                    className={
                                      this.props.props.styles.client_name
                                    }
                                  >
                                    {item.first_name + " " + item.last_name}
                                  </span>
                                </div>
                                <button
                                  className={this.props.props.styles.travlr_btn}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    this.removeEmployeeGuest(item);
                                  }}
                                >
                                  -
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {this.state?.EmployeeGuest?.length > 0 &&
                      this.state?.isAddTab ? (
                        <div className={this.props.props.styles.travel_gest}>
                          <p>
                            {" "}
                            {showTravText ? "Add Traveler" : "Add Colleagues"}
                            {/* {this?.props?.props?.userProfile?.user_type ==='ADMIN'||this?.props?.props?.userProfile?.user_type ==='TD'||this.props.props.bookingTypeId==2||this.props.props.bookingTypeId==3?"Add Traveler":"Add Colleagues"} */}
                          </p>
                          <div
                            className={this.props.props.styles.travlr_scroll}
                          >
                            {this.state?.EmployeeGuest?.map((item) => (
                              <div
                                className={this.props.props.styles.travlr_wrap}
                                key={item.id}
                                // onClick={() => {
                                //   this.addEmployGuest(
                                //     item.first_name,
                                //     item.last_name,
                                //     item._id,
                                //     item.email,
                                //     item.nationality
                                //   );
                                // }}
                              >
                                {/* {console.log("item",item)} */}
                                <div
                                  className={`${parseFloat(this.state.EmployeeGuestAdded.length) + parseFloat(this.state.loginGuest.length) === parseFloat(this?.props?.props?.totalPax) ? this.props.props.styles.travlr_name + ' ' + this.props.props.styles.travlr_name_disabled : this.props.props.styles.travlr_name}`}
                                  onClick={() => {
                                    this.addEmployGuest(item);
                                  }}
                                >
                                  <span> {item.email}</span>
                                  <br />
                                  <span>
                                    {showTravText ? "Traveler" : "Colleague"}:{" "}
                                    <span
                                      className={
                                        this.props.props.styles.client_name
                                      }
                                    >
                                      {item.first_name + " " + item.last_name}
                                    </span>
                                  </span>
                                </div>
                                <button
                                  className={parseFloat(this.state.EmployeeGuestAdded.length) + parseFloat(this.state.loginGuest.length) === parseFloat(this?.props?.props?.totalPax) ? this.props.props.styles.travlr_btn + ' ' + this.props.props.styles.travlr_btn_disabled : this.props.props.styles.travlr_btn}
                                  disabled={parseFloat(this.state.EmployeeGuestAdded.length) + parseFloat(this.state.loginGuest.length) === parseFloat(this?.props?.props?.totalPax)}
                                  onClick={() => {
                                    this.addEmployGuest(item);
                                  }}
                                  // onClick={() => {
                                  //   this.addEmployGuest(
                                  //     item.first_name,
                                  //     item.last_name,
                                  //     item._id,
                                  //     item.email,
                                  //     item.nationality
                                  //   );
                                  // }}
                                >
                                  +
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div style={{ paddingTop: 20, textAlign: "center" }}>
                          <h3>
                            {this.state.showDefaultText
                              ? "  "
                              : "No Result Found"}
                          </h3>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      id="guestlist"
                      className={this.props.props.styles.gest_container}
                    >
                      {this.state?.setAddGuest?.length ? (
                        <div className={this.props.props.styles.travel_gest}>
                          <p>
                            {showTravText
                              ? "Remove Traveler"
                              : "Remove Colleagues"}
                          </p>
                          <div
                            className={this.props.props.styles.travlr_scroll}
                          >
                            {this.state?.setAddGuest?.map((item) => (
                              <div
                                className={this.props.props.styles.travlr_wrap}
                                onClick={() => {
                                  this.removeGuest(
                                    item.f_name,
                                    item.l_name,
                                    item.userid,
                                    item.mail,
                                    item.nationality
                                  );
                                }}
                              >
                                <div
                                  className={
                                    this.props.props.styles.travlr_name
                                  }
                                >
                                  <span>{item.mail}</span>
                                  <br />

                                  <span
                                    className={
                                      this.props.props.styles.client_name
                                    }
                                  >
                                    {item.f_name + " " + item.l_name}
                                  </span>
                                </div>
                                <button
                                  className={this.props.props.styles.travlr_btn}
                                  onClick={() => {
                                    this.removeGuest(
                                      item.f_name,
                                      item.l_name,
                                      item.userid,
                                      item.mail,
                                      item.nationality
                                    );
                                  }}
                                >
                                  -
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : this.state?.setGuest?.length &&
                        this.state?.isAddTab ? (
                        <div className={this.props.props.styles.travel_gest}>
                          <p>
                            {" "}
                            {showTravText ? "Add Traveler" : "Add Colleagues"}
                            {/* {this?.props?.props?.userProfile?.user_type ==='ADMIN'||this?.props?.props?.userProfile?.user_type ==='TD'||this.props.props.bookingTypeId==2||this.props.props.bookingTypeId==3?"Add Traveler":"Add Colleagues"} */}
                          </p>
                          <div
                            className={this.props.props.styles.travlr_scroll}
                          >
                            {this.state?.setGuest?.map((item) => (
                              <div
                                className={this.props.props.styles.travlr_wrap}
                                key={item.id}
                                onClick={() => {
                                  this.addGuest(
                                    item.first_name,
                                    item.last_name,
                                    item._id,
                                    item.email,
                                    item.nationality,
                                    item.corporate_detail
                                  );
                                }}
                              >
                                {/* {console.log("item",item)} */}
                                <div
                                  className={
                                    this.props.props.styles.travlr_name
                                  }
                                >
                                  <span> {item.email}</span>
                                  <br />
                                  <span>
                                    {showTravText ? "Traveler" : "Colleague"}:{" "}
                                    <span
                                      className={
                                        this.props.props.styles.client_name
                                      }
                                    >
                                      {item.first_name + " " + item.last_name}
                                    </span>
                                  </span>
                                </div>
                                <button
                                  className={this.props.props.styles.travlr_btn}
                                  onClick={() => {
                                    this.addGuest(
                                      item.first_name,
                                      item.last_name,
                                      item._id,
                                      item.email,
                                      item.nationality,
                                      item.corporate_detail
                                    );
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div style={{ paddingTop: 20, textAlign: "center" }}>
                          <h3>
                            {this.state.showDefaultText
                              ? " "
                              : "No Result Found"}
                          </h3>
                        </div>
                      )}
                    </div>
                  )}

                  {/* Old Flow ===>  */}
                  {/* <div
                      id="guestlist"
                      className={this.props.props.styles.gest_container}
                    >
                      {this.state?.setAddGuest?.length ? (
                        <div className={this.props.props.styles.travel_gest}>
                          <p>Remove Client</p>
                          <div
                            className={this.props.props.styles.travlr_scroll}
                          >
                            {this.state?.setAddGuest?.map((item) => (
                              
                              <div
                                className={this.props.props.styles.travlr_wrap}
                                onClick={() => {
                                  this.removeGuest(
                                    item.f_name,
                                    item.l_name,
                                    item.userid                                      ,
                                    item.mail,
                                    item.nationality
                                  );
                                }}
                              >
                                <div
                                  className={
                                    this.props.props.styles.travlr_name
                                  }
                                >
                                  <span>{item.mail}</span>
                                  <br />{console.log("item",item)}
                                  <span className={this.props.props.styles.client_name}>{item.f_name + " " + item.l_name}</span>
                                </div>
                                <button
                                  className={this.props.props.styles.travlr_btn}
                                  onClick={() => {
                                    this.removeGuest(
                                      item.f_name,
                                      item.l_name,
                                      item.userid                                      ,
                                      item.mail,
                                      item.nationality
                                    );
                                  }}
                                >
                                  -
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      
                      {this.state?.setGuest?.length && this.state?.isAddTab ? (
                        <div className={this.props.props.styles.travel_gest}>
                          <p>Add Client</p>
                          <div
                            className={this.props.props.styles.travlr_scroll}
                          >
                            {this.state?.setGuest?.map((item) => (
                              <div
                                className={this.props.props.styles.travlr_wrap}
                                key={item.id}
                                onClick={() => {
                                  this.addGuest(
                                    item.first_name,
                                    item.last_name,
                                    item._id,
                                    item.email,
                                    item.nationality
                                  );
              
                                }}
                              >
                               
                                <div
                                  className={
                                    this.props.props.styles.travlr_name
                                  }
                                >
                                  <span> {item.email}</span>
                                  <br />
                                  <span>Client:
                                    {" "}
                                    <span   className={this.props.props.styles.client_name}>{item.first_name + " " + item.last_name}</span>
                                  </span>
                                </div>
                                <button
                                  className={this.props.props.styles.travlr_btn}
                                  onClick={() => {
                                    this.addGuest(
                                      item.first_name,
                                      item.last_name,
                                      item._id,
                                      item.email,
                                      item.nationality
                                    );
                
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
