import GuestDetails from "../../GuestDetails/index";
import ColleagueDetails from "../../ColleagueDetails/index";

export default function Theme1(props) {
  console.log(props, "pooja>>>>>>");
  let styles = props?.styles;
  switch (props?.type) {
    case "flight":
      return (
        <>
          {/* Advance Search */}

          {props.isOffline === false && <AdvanceFlightSearch props={props} />}
          {/* Guest Details */}
          {props.userProfile?.type === "B2B" && !props.isSRP && (
            <GuestDetails
              theme="theme4"
              props={props}
              isMobile={props.isMobile}
            />
          )}
          {props.userProfile?.type === "B2E" &&
            !props.isguestChecked &&
            !props.isSRP && <ColleagueDetails theme="theme4" props={props} />}
            {props.userProfile?.type === "B2E" && props.isSRP  &&
              <div className={props?.styles?.collapsible_title_add}>
                        <div className="flex">
          <div className="fname form-inputs addguestbtn-width" id="htlguestdiv">
            <div className={props.styles.addguestbtn}>
              <div className={props.styles.trav_flex}>
                <span className={props.styles.travtext}>
                  {"  Traveler:"}{" "}
                </span>
                {props?.userProfile?.user_type === "CT"
                  ? props?.userProfile?.first_name && (
                      <p className={props.styles.travNameCap}>
                        {props?.userProfile?.first_name +
                          " " +
                          props?.userProfile?.last_name}
                          {typeof props?.employeeguestIdList == 'object' && props?.employeeguestIdList?.length > 1 ? ` + ${props?.employeeguestIdList?.length - 1}` : ''}
                      </p>
                    ) : props?.getGuest?.client_fname && (
                      <p className={props.styles.travNameCap}>
                        {(props?.getGuest?.client_fname).toUpperCase()}{" "}
                        {props?.getGuest?.client_lname
                          ? (props?.getGuest?.client_lname).toUpperCase()
                          : ""}
                      </p>
                    )
                  }
                </div>
                </div>
                </div>
                </div>
              </div>
            }
          {(props?.userProfile?.user_type === "TD" ||props?.userProfile?.assit_bookerId||(props.userProfile?.type === "B2E"&&props?.userProfile?.user_type === "ADMIN")) &&
            !props.isSRP &&
            props.bookingType[3]?.selected && (
              <>
                {" "}
                <div className={props.styles.guestpostion}>
                  {" "}
                  <input
                    type="checkbox"
                    className={props.styles.pointer}
                    id={"guest"}
                    checked={props.isguestChecked}
                    onChange={props.handleGuestCheckbox}
                  />
                  <label for="guest">Guest</label>{" "}
                </div>
              </>
            )}
        </>
      );
    case "hotel":
      return (
        <>
          <div className={props.styles.guestmaindiv}>
            <div className={props.styles.adv_search_container}>
              <div className={props.styles.adv_search_wrap}>
                {/* Advance Search */}

                {props.isOffline === false &&
                  (props?.userProfile?.user_type === "TD" ||
                    props?.userProfile?.is_assisted_booking_allowed) && (
                    <AdvanceHotelSearch props={props} />
                  )}
                {/* Guest Details */}
                {props.userProfile.type === "B2B" && (
                  <GuestDetails theme="theme4" props={props} />
                )}
                {props.userProfile.type === "B2E" && !props.isguestChecked && (
                  <ColleagueDetails theme="theme4" props={props} />
                )}
                {(props?.userProfile?.user_type === "TD" || props?.userProfile?.assit_bookerId||(props.userProfile?.type === "B2E"&&props?.userProfile?.user_type === "ADMIN"))&&
                  props.bookingType[3]?.selected && (
                    <>
                      {" "}
                      <div className={props.styles.guestpostion}>
                        {" "}
                        <input
                          type="checkbox"
                          className={props.styles.pointer}
                          id={"guest"}
                          checked={props.isguestChecked}
                          onChange={props.handleGuestCheckbox}
                        />
                        <label for="guest">Guest</label>{" "}
                      </div>
                    </>
                  )}
                {/*  */}
              </div>
            </div>
          </div>
        </>
      );
    case "activity":
      return (
        <div className={props.styles.adv_search_container}>
          <div className={props.styles.adv_search_wrap}>
            <GuestDetails theme="theme4" props={props} />
            {/* Advance Search */}
            {/* {props.isOffline === true ? null : <ActivityAdvanceSearch props={props} />
            } */}
          </div>
        </div>
      );
    case "experience":
      return (
        <div className={props.styles.adv_search_container}>
          <div className={props.styles.adv_search_wrap}>
            <GuestDetails theme="theme4" props={props} />
            {/* Advance Search */}

            <ExpAdvanceSearch props={props} />
          </div>
        </div>
      );

    case "holiday":
      return (
        <div className={props.styles.adv_search_container}>
          <div className={props.styles.adv_search_wrap}>
            <GuestDetails theme="theme4" props={props} />
          </div>
        </div>
      );

    default:
      return <div>Theme2 Holiday cookies </div>;
  }
}

const AdvanceHotelSearch = ({ props }) => {
  return (
    <div ref={props.listWrapper} className={props.styles.collapsible_title}>
      <div className={{}}>
        <div className="form-input-width-htl advanced_search" id="htladvserch">
          <div className={props.styles.advbtn}>
            {/* <button type="button" className="advbtnn addbtn guestdropbtn advancedbtn advancedbtn-htl" id="advanceSearchButton" /> */}
            <button
              type="button"
              onClick={() => props?.onClickAdvSearch()}
              className={props?.styles?.traveller_button}
              id=""
            >
              {props.advSearch ? (
                <img src="/theme4/adv_minus.svg" />
              ) : (
                <img src="/theme4/adv_plus.svg" />
              )}
            </button>
            {/* <span className="guesttext">  Advanced Search</span> */}
            <span
              className={props.styles.guesttext}
              onClick={() => props?.onClickAdvSearch()}
            >
              {props?.type === "flight"
                ? "  Advanced Search"
                : "  Advanced Search"}{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ActivityAdvanceSearch = ({ props }) => {
  return (
    <div ref={props.listWrapper} className={props.styles.collapsible_title}>
      <div className={{}}>
        <div className="form-input-width-htl advanced_search" id="htladvserch">
          <div className={props.styles.advbtn}>
            {/* <button type="button" className="advbtnn addbtn guestdropbtn advancedbtn advancedbtn-htl" id="advanceSearchButton" /> */}
            <button
              type="button"
              onClick={() => props?.onClickAdvSearch()}
              className={props?.styles?.traveller_button}
              id=""
            >
              {props.advSearch ? "-" : "+"}
            </button>
            {/* <span className="guesttext">  Advanced Search</span> */}
            <span className={props.styles.guesttext}>
              {props?.type === "flight"
                ? "  Advanced Search"
                : "  Advanced Search"}{" "}
            </span>
          </div>
          {props?.advSearch && (
            <div
              className={props.styles.advmodal_dropcontent}
              id="advanceSearchForm"
            >
              <div className="advsearch-modal-content">
                {/* <span className="closem" id="closem" onclick="closeAdvanceSearchModal()">×</span> */}
                <div className="adv-fm-pref adv-fm-pref-htl">
                  <div className="advance-form content">
                    <div className="adv-check ">
                      <div className={props.styles.adv_text}>Activity Star</div>
                      <div className={props.styles.filter_container}>
                        <div className="col-3-adv ">
                          <input
                            type="checkbox"
                            id="res"
                            name="isOneStar"
                            value={1}
                            onChange={(e) => {
                              props.ratingHandler(e);
                              props.handleStarCheck(e);
                            }}
                            checked={props.isOneStar}
                            className="check-ticks-adv"
                          />
                          <label
                            className={props.styles.check_advance_labels}
                            htmlFor="res"
                          >
                            {" "}
                            1 Star
                          </label>
                          <br />
                        </div>
                        <div className="col-3-adv ">
                          <input
                            type="checkbox"
                            id="bar"
                            name="isTwoStar"
                            value={2}
                            onChange={(e) => {
                              props.ratingHandler(e);
                              props.handleStarCheck(e);
                            }}
                            checked={props.isTwoStar}
                            className="check-ticks-adv"
                          />
                          <label
                            className={props.styles.check_advance_labels}
                            htmlFor="bar"
                          >
                            {" "}
                            2 Star
                          </label>
                          <br />
                        </div>
                        <div className="col-3-adv ">
                          <input
                            type="checkbox"
                            id="breakfast"
                            name="isThreeStar"
                            value={3}
                            onChange={(e) => {
                              props.ratingHandler(e);
                              props.handleStarCheck(e);
                            }}
                            checked={props.isThreeStar}
                            className="check-ticks-adv"
                          />
                          <label
                            className={props.styles.check_advance_labels}
                            htmlFor
                          >
                            {" "}
                            3 Star
                          </label>
                          <br />
                        </div>
                        <div className="col-3-adv ">
                          <input
                            type="checkbox"
                            id="pool"
                            name="isFourStar"
                            value={4}
                            onChange={(e) => {
                              props.ratingHandler(e);
                              props.handleStarCheck(e);
                            }}
                            checked={props.isFourStar}
                            className="check-ticks-adv"
                          />
                          <label
                            className={props.styles.check_advance_labels}
                            htmlFor="pool"
                          >
                            {" "}
                            4 Star
                          </label>
                          <br />
                        </div>
                        <div className="col-3-adv">
                          <input
                            type="checkbox"
                            id
                            name="isFiveStar"
                            value={5}
                            onChange={(e) => {
                              props.ratingHandler(e);
                              props.handleStarCheck(e);
                            }}
                            checked={props.isFiveStar}
                            className="check-ticks-adv"
                          />
                          <label
                            className={props.styles.check_advance_labels}
                            htmlFor
                          >
                            {" "}
                            5 Star
                          </label>
                          <br />
                        </div>
                      </div>
                    </div>
                    {/* <div className="adv-check">
                    <div className={props.styles.adv_text}>Activity Type</div>
                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="res" name="qc" defaultValue={119} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="res"> Refundable</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="qc" defaultValue="Free Cancelation" className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar"> Non Refundable</label><br />
                      </div>
                    </div>
                  </div> */}
                    {/* <div className="adv-check">
                    <div className={props.styles.adv_text}>Breakfast</div>

                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="res" name="qc" onClick={props.onTickAdvSearch} defaultValue={119} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="res"> With Breakfast</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="qc" defaultValue="Free Cancelation" className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar">Without Breakfast</label><br />
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ExpAdvanceSearch = ({ props }) => {
  console.log("exp adv search props =>", props);
  return (
    <div className={props.styles.collapsible_title}>
      <div className={{}}>
        <div className="form-input-width-htl advanced_search" id="htladvserch">
          <div className={props.styles.advbtn}>
            {/* <button type="button" className="advbtnn addbtn guestdropbtn advancedbtn advancedbtn-htl" id="advanceSearchButton" /> */}
            <button
              type="button"
              onClick={() => props?.onClickAdvSearch()}
              className={props?.styles?.traveller_button}
              id=""
            >
              {props.advSearch ? "-" : "+"}
            </button>
            {/* <span className="guesttext">  Advanced Search</span> */}
            <span className={props.styles.guesttext}>
              {props?.type === "experience"
                ? "  Advanced Search"
                : "  Advanced Search"}{" "}
            </span>
          </div>
          {props?.advSearch && (
            <div
              className={props.styles.advmodal_dropcontent}
              id="advanceSearchForm"
            >
              <div className="advsearch-modal-content">
                {/* <span className="closem" id="closem" onclick="closeAdvanceSearchModal()">×</span> */}
                <div className="adv-fm-pref adv-fm-pref-htl">
                  <div className="advance-form content">
                    <div className="adv-check ">
                      <div className={props.styles.adv_text}>
                        Experiences Star
                      </div>
                      <div className={props.styles.filter_container}>
                        <div className="col-3-adv ">
                          <input
                            type="checkbox"
                            id="res"
                            name="isOneStar"
                            value={1}
                            onChange={(e) => {
                              props.ratingHandler(e);
                              props.handleStarCheck(e);
                            }}
                            checked={props.isOneStar}
                            className="check-ticks-adv"
                          />
                          <label
                            className={props.styles.check_advance_labels}
                            htmlFor="res"
                          >
                            {" "}
                            1 Star or Less
                          </label>
                          <br />
                        </div>
                        <div className="col-3-adv ">
                          <input
                            type="checkbox"
                            id="bar"
                            name="isTwoStar"
                            value={2}
                            onChange={(e) => {
                              props.ratingHandler(e);
                              props.handleStarCheck(e);
                            }}
                            checked={props.isTwoStar}
                            className="check-ticks-adv"
                          />
                          <label
                            className={props.styles.check_advance_labels}
                            htmlFor="bar"
                          >
                            {" "}
                            2 Star or Less{" "}
                          </label>
                          <br />
                        </div>
                        <div className="col-3-adv ">
                          <input
                            type="checkbox"
                            id="breakfast"
                            name="isThreeStar"
                            value={3}
                            onChange={(e) => {
                              props.ratingHandler(e);
                              props.handleStarCheck(e);
                            }}
                            checked={props.isThreeStar}
                            className="check-ticks-adv"
                          />
                          <label
                            className={props.styles.check_advance_labels}
                            htmlFor
                          >
                            {" "}
                            3 Star or Less
                          </label>
                          <br />
                        </div>
                        <div className="col-3-adv ">
                          <input
                            type="checkbox"
                            id="pool"
                            name="isFourStar"
                            value={4}
                            onChange={(e) => {
                              props.ratingHandler(e);
                              props.handleStarCheck(e);
                            }}
                            checked={props.isFourStar}
                            className="check-ticks-adv"
                          />
                          <label
                            className={props.styles.check_advance_labels}
                            htmlFor="pool"
                          >
                            {" "}
                            4 Star or Less{" "}
                          </label>
                          <br />
                        </div>
                        <div className="col-3-adv">
                          <input
                            type="checkbox"
                            id
                            name="isFiveStar"
                            value={5}
                            onChange={(e) => {
                              props.ratingHandler(e);
                              props.handleStarCheck(e);
                            }}
                            checked={props.isFiveStar}
                            className="check-ticks-adv"
                          />
                          <label
                            className={props.styles.check_advance_labels}
                            htmlFor
                          >
                            {" "}
                            5 Star or Less{" "}
                          </label>
                          <br />
                        </div>
                      </div>
                    </div>
                    {/* <div className="adv-check">
                    <div className={props.styles.adv_text}>Time</div>
                    <div className={props.styles.filter_container}>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="res" name="is2Day" value={'2 DAYS'} onChange={(e) => {props.timeHandler(e); props.handleTimeCheck(e)}} checked={props.is2Day} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="res"> 2 Day</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="is1Day" value={'1 DAYS'} onChange={(e) => {props.timeHandler(e); props.handleTimeCheck(e)}} checked={props.is1Day} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar"> 1 Day</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="is1Hour" value={'Approx 1 Hour'} onChange={(e) => {props.timeHandler(e); props.handleTimeCheck(e)}} checked={props.is1Hour} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar"> 1 Hour Approx</label><br />
                      </div>
                      <div className="col-3-adv ">
                        <input type="checkbox" id="bar" name="is10Hour" value={'10 Hours (Approx)'} onChange={(e) => {props.timeHandler(e); props.handleTimeCheck(e)}} checked={props.is10Hour} className="check-ticks-adv" />
                        <label className={props.styles.check_advance_labels} htmlFor="bar"> 10 Hour Approx</label><br />
                      </div>
                    </div>
                  </div> */}
                    {/* <div className="adv-check">
                    <div className={props.styles.adv_text}>Price</div>
                      {props.openSlider()}
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AdvanceFlightSearch = ({ props }) => {
  return (
    <div ref={props.listWrapper} className={props.styles.advance_add}>
      <div
        className={props.styles.advance_search_card_container}
        //  onClick={() => props?.onClickAirlines()}
      >
        <button
          type="button"
          onClick={() => props?.onClickAirlines()}
          className={props?.styles?.traveller_button}
          id="advanceSearchButton"
        >
          <span className={props?.styles?.plus_arr}>
            {props.openAirlines ? (
              <img src="/theme4/adv_minus.svg" />
            ) : (
              <img src="/theme4/adv_plus.svg" />
            )}
          </span>
        </button>

        <span
          onClick={() => props?.onClickAirlines()}
          className={props.styles.guesttext}
        >
          {" "}
          Advanced Search
        </span>
      </div>
    </div>
  );
};
