import commonFunction from "../../../../lib/utils/common"
import PaxConfig from "./PaxConfig"

const EconomyPopup = ({ travellerDivRef, economyPopUpWrapper, state, styles, cabinClass, onClickChangePaxNumber,
    onClickOpenCabinClassList, onDoneBtnClickHandler, cabinClassSelectHandler }) => {

    return <div ref={travellerDivRef} className={styles.flighttraveller_drop}>
        <svg className={styles.pass_arrow_mod} width="151" height="101" viewBox="0 0 151 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M75.5 1.3811L140.885 76.0953H10.1151L75.5 1.3811Z" fill="white"/>
            <line y1="-1.5" x2="100.715" y2="-1.5" transform="matrix(0.666347 -0.745642 0.939264 0.343196 9.5873 76.4784)" stroke="#2FC7C9" stroke-width="3"/>
            <path d="M76.0269 1.19861L140.214 76.0953" stroke="#2FC7C9" stroke-width="3"/>
            </svg>

        <div className={styles.flighttraveller_drop_flex} >
            <div className={styles.flighttraveller_drop_adult}>
                <label><b>Adult(s)</b></label>
                <label>(12 yrs & above)</label>
            </div>
            <div className={styles.flighttraveller_drop_paxconfig}>
                <PaxConfig
                    value={!commonFunction.isValueEmpty(state.paxAdultValue) ? state.paxAdultValue : 0}
                    onChangePaxValue={(value, type) => onClickChangePaxNumber("adult", value, type)}
                    maxLimit={9}
                    minLimit={1}
                    totalPax={(+state.paxChildrenValue) + (+state.paxAdultValue)}
                    styles={styles}
                />
            </div>
        </div>
        <div className={styles.flighttraveller_drop_flex} >
            <div className={styles.flighttraveller_drop_adult}>
                <label><b>Child(s)</b></label>
                <label>(2-12 yrs)</label>
            </div>
            <div className={styles.flighttraveller_drop_paxconfig}>
                <PaxConfig
                    value={!commonFunction.isValueEmpty(state.paxChildrenValue) ? state.paxChildrenValue : 0}
                    onChangePaxValue={(value, type) => onClickChangePaxNumber("children", value, type)}
                    maxLimit={9}
                    minLimit={0}
                    totalPax={(+state.paxChildrenValue) + (+state.paxAdultValue)}
                    styles={styles}
                />
            </div>
        </div>
        <div className={styles.flighttraveller_drop_flex} >
            <div className={styles.flighttraveller_drop_adult}>
                <label><b>Infant(s)</b></label>
                <label>(below 2 yrs)</label>
            </div>
            <div className={styles.flighttraveller_drop_paxconfig}>
                <PaxConfig
                    value={!commonFunction.isValueEmpty(state.paxInfantValue) ? state.paxInfantValue : 0}
                    onChangePaxValue={(value, type) => onClickChangePaxNumber("infant", value, type)}
                    maxLimit={+state.paxAdultValue}
                    minLimit={0}
                    totalPax={state.paxInfantValue}
                    styles={styles}
                />
            </div>
        </div>
        <div className={styles.flighttraveller_drop_economy}>
                <div className={styles.class_div}>
                      <div className={styles.flight_pax_title}>Cabin Class</div>

                { cabinClass ? (
                    <div ref={economyPopUpWrapper}   className={styles?.flight_cabin_class_list_them}>
                        {cabinClass.map((element, index) => {
                            return (
                            
                                <div className={styles?.flight_cabin_bxd}> 
                                <input type="radio" 
                                className={styles?.pax_input}
                                   checked={element.label === state.SelectedClassValuenew.label}
                                onClick={() => { cabinClassSelectHandler(element) }}
                                  
                                  id={element.label} 
                                 value={element.label} 
                               />
                                <label  >{element.label} </label>
                              
                                </div>
                                );
                        })
                        }
                            
                    </div>
                    
       
                ) : null
                }
     </div>
            {/* </div> */}
        </div>
        <div className={styles.flighttraveller_drop_done}>
            <button onClick={onDoneBtnClickHandler}>Done</button>
        </div>
    </div>
}

export default EconomyPopup