import React, { useEffect, useState } from "react";

import commonFunction from "../../../../lib/utils/common";
// import styles from "./index.module.css";

export default function HotelAutoComplete(props) {
  return (
    <div className={props.styles?.hotel_search_wid_inputfield}>
      <label>{props.label}</label>
      <div className={props.styles?.hotel_search_wid_inputfield_main}>
        {/* <img src={props.leftIcon} className={props.styles?.hotel_search_left_icon} /> */}

        <span
          className={props.styles?.hotel_search_left_icon}
          onClick={() => {
            props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
            props.onChangeHotelName("");
            props.onClickHotelInput();
          }}
        >
          {props.leftIcon}
        </span>
        <input
          type="text"
          autoComplete={"off"}
          id="myTextField"
          autoFocus
          tabindex="1"
          autoFocus
          onFocus={props.onInputFocus}
          onKeyDown={props.onKeyDownArrow}
          placeholder={
            !commonFunction.isValueEmpty(props.placholder)
              ? props.placholder
              : ""
          }
          className={props.styles?.search_widget_input_new}
          value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
          onChange={(event) => {
            props.onChangeHotelName(event.target.value);
          }}
          onClick={() => {
            props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
            props.onChangeHotelName("");
            props.onClickHotelInput();
          }}
        />
        {!commonFunction.isValueEmpty(props.isError) &&
        props.isError === true ? (
          <div className={props.styles?.hotel_search_error_message}>
            <span>
              {!commonFunction.isValueEmpty(props.errorMessage)
                ? props.errorMessage
                : "Invalid field"}
            </span>
          </div>
        ) : null}
        {props.expandAutoCompleteBool === true ? (
          <div
            className={props.styles?.hotel_autocomplete_value}
            id="hotelDataArray"
          >
            {!commonFunction.isValueEmpty(props.autoCompleteData) &&
              props.autoCompleteData.length > 0 &&
              props.autoCompleteData.map((element) => {
                return (
                  <div
                    id="hotelData"
                    className={props.styles?.hotel_autocomplete_element}
                    onClick={() => {
                      props.onClickHotelName(element);
                      props.isAutoCompleteExpand(false);
                    }}
                  >
                    <img
                      src={
                        element.property_count === 0
                          ? "/images/hotels.svg"
                          : "/images/location.svg"
                      }
                      alt=""
                    />
                    <div>
                      <div className={props.styles?.hotel_autocomplete_name}>
                        {element.search_text}
                      </div>
                      {element.property_count === 0 ? (
                        ""
                      ) : (
                        <div className={props.styles?.hotel_autocomplete_tag}>
                          {element.property_count} Properties
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : null}
      </div>
    </div>
  );
}
